import axios from 'axios';
import React from 'react';
import { getOneVpQuery } from '../Lists/getOneVPQuery';
import { getManyVpQuery } from '../Lists/getManyVpQuery';


export const RemoveMember = (vpHandler, memberName, type) => {
  let _object = vpHandler.get
  let index = _object[type].findIndex(obj => obj.name === memberName)
  if (index !== -1) {
    _object[type].splice(index, 1);
    vpHandler.set({..._object})
  }
}

export const checkDisabled = (vpHandler) => {
  let disabled = null
  if (vpHandler.action === "add" || vpHandler.action === "edit") disabled = false
  else disabled = true
  return disabled
}

export const AddTeamMember = (vpHandler, member) => {
  let _object = vpHandler.get
  _object.team.push({...member})
  vpHandler.set({..._object})
}

export const AddMember = (vpHandler, member, type) => {
  let _object = vpHandler.get
  _object[type].push({...member})
  vpHandler.set({..._object})
}

export const notify = (msg, inputEl, type) => {
  //console.log(`notify: ${JSON.stringify(msg)}, ${inputEl}, ${JSON.stringify(type)}`)
  let options = {
    place: 'tr',
    message: (
      <div className="alert-text fs--2 ">
        <span className="ml-2">
          {msg}
        </span>
      </div>
    ),
    type: type, //add custom type
    icon: "ni ni-bell-55 fs--2",
    autoDismiss: 4
  };

  if(inputEl.current !== null )inputEl.current.notificationAlert(options);
};

export const getAction = (props) => {
  //console.log(props)
  return props.action
}

export const getUuid = (props) => {
  return props.id
}

let index = {
  ideation: 0,
  customerVal: 1,
  mvp: 2,
  optimize: 3,
  grow: 4,
  scale: 5,
}

export const getState = (current, selected) => {
  let _currentStageIndex = index[current]
  let _currentItemIndex = index[selected]
  if(_currentItemIndex < _currentStageIndex) return true
}
export const getStateInput = (current, selected) => {
  let _currentStageIndex = index[current]
  let _currentItemIndex = index[selected]
  if(_currentItemIndex <= _currentStageIndex) return true
}

export const setFunding = (vpHandler, e) => {
  let _object = vpHandler.get
  _object.funding[e.target.name] = Number(e.target.value)
  vpHandler.set({..._object})
}

export const createVPDetails = (vpHandler, e) => {
  let _object = vpHandler.get
  _object[e.target.name] = e.target.value
  vpHandler.set({..._object})
  //console.log(JSON.stringify(_object))
}

export const createVPDetailsList = (vpHandler, e, field) => {
  let _object = vpHandler.get
  _object[field] = []
  e.forEach(item => {
    _object[field].push(item.label)
  })
  vpHandler.set({..._object})
}

export const createVPDetailsSingle = (vpHandler, e, field) => {
  let _object = vpHandler.get
  _object[field] = e.label
  vpHandler.set({..._object})
}

export const updateStageGate = (e, stage, sov, kpi, vpHandler) => {
  let _object = vpHandler.get
  _object.stageGate[stage] = {..._object.stageGate[stage]} || {}
  _object.stageGate[stage][sov] = {..._object.stageGate[stage][sov]} || {}
  _object.stageGate[stage][sov][kpi] = {..._object.stageGate[stage][sov][kpi]} || {}
  let _field = _object.stageGate[stage][sov][kpi] || {}
  _field[e.target.name] = e.target.value
  _object.stageGate[stage][sov][kpi] = _field
  vpHandler.set({..._object})
}

export const sgBool = (e, stage, sov, kpi, point, vpHandler) => {
  let _object = vpHandler.get
  _object.stageGate[stage] = {..._object.stageGate[stage]} || {}
  _object.stageGate[stage][sov] = {..._object.stageGate[stage][sov]} || {}
  _object.stageGate[stage][sov][kpi] = {..._object.stageGate[stage][sov][kpi]} || {}
  let _field = _object.stageGate[stage][sov][kpi] || {}
  _field[point] = e.target.name === "true" ? true : false
  _object.stageGate[stage][sov][kpi] = _field
  vpHandler.set({..._object})
}

export const sgDropdown = (e, stage, sov, kpi, point, vpHandler) => {
  let _object = vpHandler.get
  _object.stageGate[stage] = {..._object.stageGate[stage]} || {}
  _object.stageGate[stage][sov] = {..._object.stageGate[stage][sov]} || {}
  _object.stageGate[stage][sov][kpi] = {..._object.stageGate[stage][sov][kpi]} || {}
  let _field = _object.stageGate[stage][sov][kpi] || {}
  _field[point] = parseFloat(e.target.name)
  _object.stageGate[stage][sov][kpi] = _field
  vpHandler.set({..._object})
}

export const addVP = (vpHandler) => {
axios
  .post(process.env.REACT_APP_API, {
    query: `
    mutation AddVP($vp:ValuePropInputType){
      AddVP(vp:$vp){
      uuid
      }
    }`,
    variables: {vp: vpHandler.get},
  })
  .then(res => {
    console.log(`addVP return: ${res.data.data.AddVP.uuid}`) //
    vpHandler.history.push(`/monitor/individual/view/${res.data.data.AddVP.uuid}`)
    notify("VP Created Succesfully.", vpHandler.ref, "success")

  })
  .catch(err => console.log(err))
}


export const getOneVp = (query, uuid, vpHandler) => {
  axios
.post(process.env.REACT_APP_API, {
  query: query,
  variables: {
    'vp':
      {
        'uuid': uuid
      }
    },
  })
.then(res => {
    //console.log(res.data.data.GetVP)
    vpHandler.set({...res.data.data.GetVP})
  //setCurrentStage(res.data.data.GetVP.currentStage)
})
.catch(err => console.log(err))
}

export const getManyVp = (setAllVp, setAllVpBackup) => {
  axios
.post(process.env.REACT_APP_API, {
  query: getManyVpQuery,
  })
.then(res => {
  setAllVp([...res.data.data.GetManyVP])
  setAllVpBackup([...res.data.data.GetManyVP])

})
.catch(err => console.log(err))
}

export const updateVP = (vpHandler, history) => {
  var md5 = require('md5');

  axios
    .post(process.env.REACT_APP_API, {
      query: `
      mutation UpdateVP($vp: ValuePropInputType) {
        UpdateVP(vp: $vp) {
          name
          uuid
        }
      }`,
      variables: {'vp': vpHandler.get }
    }) 
    .then(res => {
      //console.log(res.data.data.UpdateVP)
      //vpHandler.history.push(`/monitor/individual/view/${res.data.data.UpdateVP.uuid}`)
      notify("Your changes have been saved successfully.", vpHandler.ref, "success")
      vpHandler.setHash(md5(JSON.stringify(vpHandler.get)))
    })
    .catch(err => {
      console.log(err)
      notify("There was an error saving your changes.", vpHandler.ref, "danger")
    })
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  const GetDownloadLink = (fileId, vpHandler) => {
    console.log(`fileid: ${fileId}, fileName ${fileName}`)
    let fileName = `${vpHandler.get.name} - ${vpHandler.get.currentStage}.pdf`
    axios
      .post( process.env.REACT_APP_API, {
        query:`query GetDownload($fileId: String, $fileName: String){
          GetDownload(fileId:$fileId, fileName:$fileName)
        }`,
        variables: {
          fileId: fileId,
          fileName: fileName
        }
      })
    .then(res => {
      console.log(res.data.data.GetDownload)
      openInNewTab(res.data.data.GetDownload)
    })
    .catch(err => console.log(err))
  }

  export const createPDF = (uuid, save, vpHandler, cb) => {
    //console.log(`uuid: ${uuid}, save: ${save}, cb: ${cb} `)
    axios
      .post(process.env.REACT_APP_API, {
        query:`mutation GenerateReport($uuid:String, $save:Boolean){
          GenerateReport(uuid:$uuid, save:$save)
            {
              uuid
              name
              link
              mime
              date
            }
        }`,
        variables: {
          uuid: uuid,
          save: save,
        }
      })
      .then(async res => {
        console.log(res.data.data.GenerateReport)
        await sleep(1000)

        GetDownloadLink(res.data.data.GenerateReport.uuid, vpHandler )
        // if(!save){
        //   await sleep(500)
        //   openInNewTab(String(res.data.data.GenerateReport))
        //   console.log(`report: ${JSON.stringify(res.data.data)}`)
        // }
        notify("PDF added to Files card.", vpHandler.ref, "success")
        getOneVp(getOneVpQuery, vpHandler.get.uuid, vpHandler)
        cb(res.data.data.GenerateReport)

      })
    .catch(err => console.log(err))
  }

  export const uploadFile = (uuid, selectedFile, vpHandler) => {

    //console.log('uploading file')
    const formData = new FormData()

    formData.append("operations", JSON.stringify({ "query": "mutation UploadFile($file:Upload!, $uuid:String) { UploadFile(file: $file, uuid:$uuid)}", "variables": { "uuid": uuid }}));
    formData.append("map", JSON.stringify({"0": ["variables.file"]}));
    formData.append("0", selectedFile);

    //console.log(`formdata:${JSON.stringify(formData)}`)

    axios({
        url: process.env.REACT_APP_API,
        method: "post",
        data: formData
    })

    .then(response => {
      //console.log(`response:${JSON.stringify(response)}`)
      notify("Upload Completed.", vpHandler.ref, "success")
      getOneVp(getOneVpQuery, vpHandler.get.uuid, vpHandler)
    })
    .catch(error => {
      console.log(error)
      notify("Upload Error.", vpHandler.ref, "danger")
    });
  }

  export const uploadAttachment = (uuid, selectedFile, vpHandler) => {

    //console.log('uploading file')
    const formData = new FormData()

    formData.append("operations", JSON.stringify({ "query": "mutation UploadFile($file:Upload!, $uuid:String) { UploadFile(file: $file, uuid:$uuid)}", "variables": { "uuid": uuid }}));
    formData.append("map", JSON.stringify({"0": ["variables.file"]}));
    formData.append("0", selectedFile);

    //console.log(`formdata:${JSON.stringify(formData)}`)

    axios({
        url: process.env.REACT_APP_API,
        method: "post",
        data: formData
    })

    .then(response => {
      console.log(`response:${JSON.stringify(response)}`)
      //notify("Upload Completed.", vpHandler.ref, "success")
      //getOneVp(getOneVpQuery, vpHandler.get.uuid, vpHandler)
    })
    .catch(error => {
      console.log(error)
      //notify("Upload Error.", vpHandler.ref, "danger")
    });
  }

  export const removeFile = (uuid, fileId, vpHandler) => {
    axios
  .post(process.env.REACT_APP_API, {
    query: `mutation DeleteFile($uuid:String, $fileId: String){
      DeleteFile(uuid:$uuid, fileId:$fileId)
    }`,
    variables: {
      'uuid': uuid,
      'fileId': fileId
    }
  })
  .then(res => {
    notify("File Removed.", vpHandler.ref, "success")
    getOneVp(getOneVpQuery, vpHandler.get.uuid, vpHandler)
  })
  .catch(err => {
    console.log(err)
    notify("Error Removing File.", vpHandler.ref, "danger")
  })
  }
