import React from 'react';
import ReactEcharts from 'echarts-for-react';

const BenchmarkScatter = ({ dataObject, yAxisName }) => {

  const option = {    
    tooltip: {
      trigger: 'item',
      show: true
    },
    xAxis: {
      show: false,
      scale: true,
      min: 0,
      max: 7,
      splitnumber: 1,
      interval: 1,
      //data: ['Ideation', 'Customer Validation', 'MVP', 'Optimize', 'Grow', 'Scale']
    },
    yAxis: {
      name: yAxisName,
      nameLocation: 'center',
      nameGap: 30,
      scale: true,
      min: 0,
      //max: 100,
      //interval: 10,
      // axisLabel: {
      //   interval: 1,
      //   //rotate: 30 //If the label names are too long you can manage this by rotating the label.
      // }
    },
    series: [
      
      {
        type: 'effectScatter',
        symbolSize: 15,
        data: dataObject.highlight,
        color: '#000096'
      },
      {

        type: 'scatter',
        symbolSize: 15,
        data: dataObject.ideation,
        name: `01. Ideation ${dataObject.kpi? "("+dataObject.kpi+")" : ""}`,
        markLine: {
          tooltip: {},
          lineStyle: {
            type: 'solid',
            width: 4
          },
          animation: true,
          data: [
            [
              {
                name: '  avg',
                symbol: 'roundRect',
                xAxis: 0.8,
                yAxis: dataObject.ideationAvg
              },
              {
                symbol: 'roundRect',
                xAxis: 1.2,
                yAxis: dataObject.ideationAvg
              }
            ]
          ]
        }
      },
      {
        type: 'scatter',
        symbolSize: 15,
        data: dataObject.customerVal,
        name: `02. Customer Validation ${dataObject.kpi? "("+dataObject.kpi+")" : ""}`,
        markLine: {
          lineStyle: {
            type: 'solid',
            width: 4
          },
          animation: true,
          data: [
            [
              {
                name: '  avg',
                symbol: 'roundRect',
                xAxis: 1.8,
                yAxis: dataObject.customerValAvg
              },
              {
                symbol: 'roundRect',
                xAxis: 2.2,
                yAxis: dataObject.customerValAvg
              }
            ]
          ]
        }
      },
      {
        type: 'scatter',
        symbolSize: 15,
        data: dataObject.mvp,
        name: `03. MVP ${dataObject.kpi? "("+dataObject.kpi+")" : ""}`,
        test: console.log(dataObject.kpi),
        markLine: {
          lineStyle: {
            type: 'solid',
            width: 4
          },
          animation: true,
          //animationDuration: 100,
          data: [
            [
              {
                name: `  avg`, //  ${dataObject.mvpAvg? ": "+dataObject.mvpAvg: ""}
                symbol: 'roundRect',
                xAxis: 2.8,
                yAxis: dataObject.mvpAvg
              },
              {
                symbol: 'roundRect',
                xAxis: 3.2,
                yAxis: dataObject.mvpAvg
              }
            ]
          ]
        }
      },
      {
        type: 'scatter',
        symbolSize: 15,
        data: dataObject.optimize,
        name: `04. Optimize ${dataObject.kpi? "("+dataObject.kpi+")" : ""}`,
        markLine: {
          lineStyle: {
            type: 'solid',
            width: 4
          },
          animation: true,
          data: [
            [
              {
                name: '  avg',
                symbol: 'roundRect',
                xAxis: 3.8,
                yAxis: dataObject.optimizeAvg
              },
              {
                symbol: 'roundRect',
                xAxis: 4.2,
                yAxis: dataObject.optimizeAvg
              }
            ]
          ]
        }
      },
      {
        type: 'scatter',
        symbolSize: 15,
        data: dataObject.grow,
        name: `05. Grow ${dataObject.kpi? "("+dataObject.kpi+")" : ""}`,
        markLine: {
          lineStyle: {
            type: 'solid',
            width: 4
          },
          animation: true,
          data: [
            [
              {
                name: '  avg',
                symbol: 'roundRect',
                xAxis: 4.8,
                yAxis: dataObject.growAvg
              },
              {
                symbol: 'roundRect',
                xAxis: 5.2,
                yAxis: dataObject.growAvg
              }
            ]
          ]
        }
      },
      {
        type: 'scatter',
        symbolSize: 15,
        data: dataObject.scale,
        name: `06. Scale ${dataObject.kpi? "("+dataObject.kpi+")" : ""}`,
        markLine: {
          lineStyle: {
          type: 'solid',
          width: 4
          },
          animation: true,
          data: [
            [
              {
                name: `  avg`,
                symbol: 'roundRect',
                xAxis: 5.8,
                yAxis: dataObject.scaleAvg
              },
              {
                symbol: 'roundRect',
                xAxis: 6.2,
                yAxis: dataObject.scaleAvg
              }
            ]
          ]
        }
      },
    ],
    color: ['#add136', '#50C1EF', '#FFC32D', '#00B050', '#5F7D95', '#6F3996']
  };

  return <ReactEcharts option={option} style={{ padding: 0, margin: 0, height: '500px' }} />;
};

export default BenchmarkScatter;
