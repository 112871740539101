export const getBrands = `
query GetBrands {
  GetBrands {
    AllBrands {
      _id
      brandName
      activity
      uuid
      revenue
    	country
    	region
      website
    	mmi
    	mmni
    	cts
    	mtt
    	cat
    	dog
    }
  }
}`;
