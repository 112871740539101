export const valuePropositionList = [
    {
      label: 'New VP',
      value: 'New VP'
    },
    {
      label: 'Long Tail + PF + AS',
      value: 'Long Tail + PF + AS'
    },
    {
      label: 'Breeder R2C',
      value: 'Breeder R2C'
    },
    {
      label: 'Shelter R2C',
      value: 'Shelter R2C'
    },
    {
      label: 'Assistant Dog R2C',
      value: 'Assistant Dog R2C'
    },
    {
      label: 'Individuals',
      value: 'Individuals'
    },
    {
      label: 'VO VET',
      value: 'VO VET'
    },
    {
      label: 'VET Marketplace',
      value: 'VET Marketplace'
    },
    {
      label: 'Home Delivery',
      value: 'Home Delivery'
    },
    {
      label: 'Offline FGS',
      value: 'Offline FGS'
    },
    {
      label: 'DIY Portfolio',
      value: 'DIY Portfolio'
    },
    {
      label: 'PET Services',
      value: 'PET Services'
    },
    {
      label: 'Urban Pets / Individualization',
      value: 'Urban Pets / Individualization'
    },
    {
      label: 'Pedigree',
      value: 'Pedigree'
    },
    {
      label: 'IAMS',
      value: 'IAMS'
    },
    {
      label: 'NUTRO SUPREMO CAT',
      value: 'NUTRO SUPREMO CAT'
    },
    {
      label: 'GREENIES',
      value: 'GREENIES'
    },
    {
      label: 'FRESH FOOD',
      value: 'FRESH FOOD'
    },
    {
      label: 'My Perfect Fit',
      value: 'My Perfect Fit'
    },
    {
      label: 'James Wellbeloved',
      value: 'James Wellbeloved'
    },
    {
      label: 'CRAVE',
      value: 'CRAVE'
    },
    {
      label: 'SHEBA',
      value: 'SHEBA'
    },
    {
      label: 'Natusan',
      value: 'Natusan'
    },
    {
      label: 'Dentastix',
      value: 'Dentastix'
    },
    {
      label: 'Lovebug',
      value: 'Lovebug'
    },
    {
      label: 'ULTRA',
      value: 'ULTRA'
    },
    {
      label: 'KARMA',
      value: 'KARMA'
    },
    {
      label: 'Multi-Brand',
      value: 'Multi-Brand'
    }
]