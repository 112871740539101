import React from 'react';
import PageTitle from "../../components/header/PageTitle";
import { GetValueProps } from '../../components/API/ControlTowerAPI';  
import ValuePropositionTable from '../dashboards/ValuePropositionTable';
import * as vpApi from "./../../components/API/IndividualVpAPI";

const SearchReviewVP = (props) => {
  const [init, setInit] = React.useState(false)
  const [init2, setInit2] = React.useState(false)
  const [valueProps, setValueProps] = React.useState([]);
  const [allVp, setAllVp] = React.useState([]);
  const [allVpBackup, setAllVpBackup] = React.useState([]);

  React.useEffect(() => {
    if (!init) {
      setInit(true);
      GetValueProps(setValueProps);
      vpApi.getManyVp(setAllVp, setAllVpBackup)

    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);

  React.useEffect(() => {
    if (!init2 && allVp.length > 0 ) {
      setInit2(true);
      let _data = allVp.filter(item => item.active === false);
      setAllVp([..._data])
      //console.log(`_data; ${JSON.stringify(_data)}`)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allVp]);

  return (
    <div class="">
      <div class="px-3 pt-3">
          <PageTitle title="D2C Accelerator" subtitle="Manage value propositions" />
          <ValuePropositionTable valueProps={allVp} itemsPerPage={26} origin="review" />
          <div style={{height: '1000px'}}></div>
        </div>
    </div>
  );
};

export default SearchReviewVP;
