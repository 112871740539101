import React, { useState } from "react";
import {
  Button,
  Modal,
  Spinner
} from "reactstrap";
import NotificationAlert from 'react-notification-alert';

import CompanyForm from './CompanyForm'
import FindCompany from './FindCompany'
import axios from 'axios'; 

import * as api from './../API/IndividualVpAPI'

const CreateCompanyQuery = `
  mutation AddCompany($brand:CompanyInputType){
    AddCompany(brand:$brand){
      uuid
    }
  }
`; 

// const GetCompaniesQuery = `
//   query GetCompanies {
//     GetCompanies {
//       companyName
//       companyUrl
//     }
//   }
// `;

const NewCompany = ({getCompanies}) => {
  const inputEl = React.useRef(null)
  const [init, setInit] = React.useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] =  useState({companyName: "", companyUrl: ""})
  const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);
  const [focusIndex, setFocusIndex] = useState({})

  const [processing, setProcessing] = useState(false);
  const showProcessing = () => setProcessing(!processing);
  const [allCompanies, setAllCompanies] = React.useState([])

  React.useEffect(() => {
    if(!init){
      setInit(true)
      getCompanies(setAllCompanies)
    }
    // eslint-disable-next-line
  }, [init]);

  // React.useEffect(() => {
  //   console.log(allCompanies)
  //   // eslint-disable-next-line
  // }, [allCompanies]);

  const notify = (msg, inputEl, type) => {
    //console.log(`notify: ${JSON.stringify(msg)}, ${inputEl}, ${JSON.stringify(type)}`)
    let options = {
      place: 'tr',
      message: (
        <div className="alert-text fs--2 ">
          <span className="ml-2">
            {msg}
          </span><br/>
          {type==="success"&&<span className="ml-2">
            It normally takes about 14 days for the new entry to show up in the list with competitors.
          </span>}
        </div>
      ),
      type: type, //add custom type
      icon: "ni ni-bell-55 fs--2",
      autoDismiss: 6
    };
  
    if(inputEl.current !== null )inputEl.current.notificationAlert(options);
  };


  const createCompany = () => {
    //console.log(allCompanies.find(item => item.companyUrl === formData.companyUrl))
    if(!allCompanies.find(item => item.companyUrl === formData.companyUrl)){
      console.log(`creatingCompany`);
      if(formData.companyName !== "" && formData.companyUrl !== ""){
        showProcessing();
        axios
        .post(process.env.REACT_APP_API, {
          query: CreateCompanyQuery,
          variables: {
            brand: {
              ...formData
            }
          }
          })
        .then(res => {
            // setNewBrands({...res.data.data.AddCompany})
            setProcessing(false)
            toggleModal()
            getCompanies()
            notify(`${formData.companyName} has been submitted for review.`, inputEl, "success")
            setFormData({companyName: "", companyUrl: ""})
            console.log(`done`)

        })
        .catch(err => console.log(err))
      }
    }
    else notify(`${formData.companyName} has already been submitted for review.`, inputEl, "danger")
    // noCacheClient
    //   .mutate(
    //   {
    //     mutation: CreateCompany,
    //     variables: {
    //       company: {
    //         ...formData
    //       }
    //     }
    //   })
    //   .then(token => {
    //     console.log('resp:' + JSON.stringify(token))
    //     showProcessing();
    //   })
    //   .catch(console.log);
  }

  // const getCompanies = () => {
  //   console.log(`getCompanies`);
  //   //showProcessing();
  //   axios
  //   .post(process.env.REACT_APP_API, {
  //     query: GetCompaniesQuery,
  //     variables: {}
  //     })
  //     .then(res => {
  //       console.log({...res.data.data.GetCompanies})
  //       // setNewBrands({...res.data.data.AddCompany})
  //       //setProcessing(false)
  //     })
  //       .catch(err => console.log(err))
  //   }


  const handleSubmit = () => {
    //console.log(`data:${JSON.stringify(formData)}`)
    setFormData({companyName: "", companyUrl: ""})
    toggleModal();
    getCompanies();
  }

  const handleChange = (e) => {
    let tempForm = formData;
    tempForm[e.target.name] = e.target.value;
    setFormData({...tempForm})
  }

  const handleFocus = (e) => {
      //console.log(`name:${e.target.name}`)
      //console.log(`index:${JSON.stringify(focusIndex)}`)
      if(!focusIndex[e.target.name]){
        //console.log(`not set`)
        let tempFocus = focusIndex;
        let tempForm = formData;

        tempForm[e.target.name] = 'null';
        tempFocus[e.target.name] = true;
        setFormData({...tempForm})
        setFocusIndex(tempFocus)
      }
  }

  const handleUrl = (url) => {
    let tempForm = formData;
    tempForm.companyUrl = url;
    //console.log(`tempForm:${JSON.stringify(tempForm)}`);
    setFormData({...tempForm});
  }

  const setData = (name, url) => {
    let _formData = formData
    _formData.companyUrl = url
    _formData.companyName = name
    setFormData({..._formData})
    //console.log(`formData: ${JSON.stringify(formData)}`)
  }

  const getBgColor = () => {
    if(formData.companyUrl !== "" && formData.companyName !== "") return "#000096"
    else return "#CCCCCC"
  }

  return (
    <>
      {// <Button 
      //   color="primary"
      //   type="button"
      //   onClick={toggleModal}
      // >
      //   Add company
      // </Button>
      }
      <div class="ml-2 me-2 fs--2 mars-blue" onClick={e => toggleModal()} role="button">
        <i class="fas fa-plus mr-1" />
        {`${window.innerWidth>991?"Add New Brand":"Add New"}`}
      </div>
      <NotificationAlert ref={inputEl} />

      <Modal
        className="modal-dialog-centered"
        isOpen={modal}
        toggle={toggleModal}
      >
        <div class="modal-header fs--3 fw-900">
          <div className="modal-title mars-blue">
            Add company
          </div>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <CompanyForm
            isOpen={isOpen}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            handleFocus={handleFocus}
            values={formData}
          />
          <FindCompany
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            companyName={formData.companyName}
            handleUrl={handleUrl}
            setData={setData}
          />
        </div>
        <div className="modal-footer">
          {// <Button
          //   color="secondary"
          //   data-dismiss="modal"
          //   type="button"
          //   onClick={toggleModal}
          // >
          //   Close
          // </Button>
          // <Button color="primary" onClick={createCompany}>
          //   {!processing ? "Submit" : null }
          //   {processing ? (
          //     <Spinner
          //       style={{ paddingTop: '0px', width: "1rem", height: "1rem" }}
          //       color="light"
          //     />
          //   ):null}
          // </Button>
          // <Button
          //   color="primary"
          //   type="button"
          //   onClick={handleSubmit}
          // >
          //   Save
          // </Button>
          }
          
          <div class="pr-1">
            <Button onClick={e=>createCompany()} height="16px" style={{ border: '0px', minWidth: '80px', backgroundColor: getBgColor(), fontSize: '11px' }}>
              {processing ? (
                <Spinner
                  style={{ paddingTop: '0px', width: "1rem", height: "1rem" }}
                  color="light"
                />
              ):"Add"}
            </Button>
          </div>
          <div class="pr-1">
            <Button onClick={e=>handleSubmit()} height="16px" style={{ border: '0px', minWidth: '80px', backgroundColor: '#CCCCCC', fontSize: '11px' }}>
              Close
            </Button>
          </div>
          {// <div class="pr-1">
          //   <Button onClick={e=>handleSubmit()} height="16px" style={{ border: '0px', minWidth: '80px', backgroundColor: '#000096', fontSize: '11px' }}>
          //     Save
          //   </Button>
          // </div>
          }
        </div>
      </Modal>
    </>
  );
}

export default NewCompany;
