import React, { useContext } from 'react';
import { Navbar } from 'reactstrap';
import classNames from 'classnames';
import AppContext from '../../context/Context';
import Logo from './Logo';
// import SearchBox from './SearchBox';
// import TopNavRightSideNavItem from './TopNavRightSideNavItem';
// import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
// import autoCompleteInitialItem from '../../data/autocomplete/autocomplete';

const NavbarTop = () => {
  const {
    showBurgerMenu,
    setShowBurgerMenu,
    isTopNav,
    isVertical,
    isCombo,
    navbarCollapsed,
    setNavbarCollapsed
  } = useContext(AppContext);
  const handleBurgerMenu = () => {
    isTopNav && !isCombo && setNavbarCollapsed(!navbarCollapsed);
    (isCombo || isVertical) && setShowBurgerMenu(!showBurgerMenu);
  };

  
  // React.useEffect(() => {
  //   console.log(`showBurgerMenu ${showBurgerMenu}`)
  // }, [showBurgerMenu]);

  const checkLogo = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth > 991) return true
    else return false
  }
  return (
    <Navbar
      onBlur={e=>setShowBurgerMenu(false)}
      className="navbar-transparent fs--1 font-weight-semi-bold pb-0" // row navbar-top sticky-kit
      expand={isTopNav && topNavbarBreakpoint}
    >
      <div
      
        className={classNames('toggle-icon-wrapper mr-md-3 mr-2', {
          'd-lg-none': isTopNav && !isCombo,
          [`d-${navbarBreakPoint}-none`]: isVertical || isCombo
        })}
      >
        <button
          className="mb-2 navbar-toggler-humburger-icon btn btn-link"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </div>
      <div class="mr-auto ml-2">
        {!checkLogo() && <Logo at="navbar-top" width={150} id="topLogo" />}
      </div>
      {/*{isTopNav ? (
        <Collapse navbar isOpen={navbarCollapsed} className="scrollbar">
          <Nav navbar>
          </Nav>
        </Collapse>
      ) : (
        <Nav navbar className={`align-items-center d-none d-${topNavbarBreakpoint}-block`}>
          <NavItem>
          </NavItem>
        </Nav>
      )} */}
    </Navbar>
  );
};

export default NavbarTop;




// import React, { useContext, useState, useEffect } from 'react';
// import { Navbar } from 'reactstrap';
// import SearchBox from './SearchBox';
// import autoCompleteInitialItem from '../../data/autocomplete/autocomplete';
// import { Nav, Row, Col, Media, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
// import { useOktaAuth } from '@okta/okta-react';

// const NavbarTop = () => {
//   const [userInfo, setUserInfo] = useState(null);
//   const [init, setInit] = useState(false);
//   const { oktaAuth, authState } = useOktaAuth();
//   const logout = async () => oktaAuth.signOut('/');

//   return (
//     <Navbar className="navbar-top" style={{backgroundColor: 'white'}}>
//       <Nav class="row" style={{width: '100%'}}>
//         <div class="ml-3" style={{width: "33%"}}>        
//           <SearchBox autoCompleteItem={autoCompleteInitialItem} className="search-box" />
//         </div>
//         <div class="ml-auto mr-5 d-none">
//           <UncontrolledDropdown style={{ zIndex: '6', cursor: 'pointer'}}>
//             <DropdownToggle className="nav-link" color="" tag="a" style={{ zIndex: '5' }}>
//               <Row style={{ zIndex: '5' }}>
//                 <div className="font-weight-400 text-white" style={{ zIndex: '6' }}>
//                   {userInfo && userInfo.name}
//                 </div>
//                 <Media className="" style={{ zIndex: '6' }}>
//                   <span className="" style={{ zIndex: '5' }}>
//                     <img
//                       alt="..."
//                       src={require(`../../assets/img/humanIcon.png`)}
//                       width="30"
//                       style={{ marginTop: '2px' }}
//                     />
//                   </span>
//                 </Media>
//               </Row>
//             </DropdownToggle>
//           </UncontrolledDropdown>
//         </div>
        
//       </Nav>
//     </Navbar>
//   );
// };

// export default NavbarTop;
