import React from "react";
import { Input } from "reactstrap";
//import 'assets/scss/custom/_individualVP.scss';
// import { hoverCopyList } from "../../components/Lists/hoverCopyList";
import * as api from "../../components/API/IndividualVpAPI";


export const InputFieldDiscover = ({value, title, brand, setBrand, companyInfo}) => {

  const handleChange = (e) => {
    let _brand = brand
    if((companyInfo && brand.companyInfo)) _brand.companyInfo[value] = e.target.value
    else _brand[value] = e.target.value
    //brand[value] = e.target.value
    setBrand({..._brand})
  }
 
  return(
    <Input 
      className="" 
      type="textfield" 
      name={title.toLowerCase()}
      style={{fontSize: '12px', height: '28px'}}
      onChange={e=>handleChange(e)}
      //onChange={e => api.createVPDetails(vpHandler, e)}
      placeholder={`Define ${title} ...`}
      //value={brand[value]}
      value={(companyInfo && brand.companyInfo)? brand.companyInfo[value]:brand[value]}

      //disabled={api.checkDisabled(vpHandler)}
    />
  )
}