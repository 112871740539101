import React from "react";
import axios from "axios";
import {
  Button,
  Modal,
  Spinner,
  FormGroup,
  Form,
  Input
} from "reactstrap";
import {useDropzone} from 'react-dropzone';
import CU from "../../../assets/img/icons/cloud-upload.svg";

export const AddNewPlaybookBar = ({getLearnFile, allBrands}) => {
  const [formData, setFormData] =  React.useState({name: "", version: "", description: ""})
  const [focusIndex, setFocusIndex] = React.useState({})
  const [processing, setProcessing] = React.useState(false);
  const showProcessing = () => setProcessing(!processing);
  const [resourceObject, setResourceObject] = React.useState({name: "", version: "", description: "", destination: "playbooks", brand: ""});
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  const [modal, setModal] = React.useState(false);
    const toggleModal = () => setModal(!modal);

  const handleSubmit = () => {
    //console.log(`data:${JSON.stringify(formData)}`)
    setFormData({companyName: "", companyUrl: ""})
    toggleModal();
    //getCompanies();
  }

  const getName = () => {
    if(acceptedFiles.length > 0) return acceptedFiles[0].path
    else return "Select File"
  }

  React.useEffect(() => {
    if(acceptedFiles.length > 0) {
      if(acceptedFiles[0].path !== "false"){
        console.log(`file: ${JSON.stringify(acceptedFiles[0])}`)
        //api.uploadFile(vpHandler.get.uuid, acceptedFiles[0], vpHandler)
      }
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const resourceForm = [
    {
      title:'Name',
      name:'name',
      placeholder:'Building the Box',
      type:'text'
    },
    // {
    //   title: 'Linked Brand',
    //   name: 'brand',
    //   placeholder: '',
    //   type: 'text'
    // },
    {
      title:'Version Number',
      name:'version',
      placeholder:'Version 1.0',
      type:'text'
    },
    {
      title:'Description',
      name:'description',
      placeholder:'To be used by the Mars Team.',
      type:'text'
    }
  ]


  const handleChange = (e) => {
    let _resourceObject = resourceObject;
    _resourceObject[e.target.name] = e.target.value;
    setResourceObject({..._resourceObject})
  }

  React.useEffect(() => {
    console.log(`resourceObject ${JSON.stringify(resourceObject)}`)
    // eslint-disable-next-line
  }, [resourceObject]);

  const handleFocus = (e) => {
      console.log(`name:${e.target.name}`)
      console.log(`index:${JSON.stringify(focusIndex)}`)
      if(!focusIndex[e.target.name]){
        console.log(`not set`)
        let tempFocus = focusIndex;
        let tempForm = formData;

        tempForm[e.target.name] = 'null';
        tempFocus[e.target.name] = true;
        setFormData({...tempForm})
        setFocusIndex(tempFocus)
      }
  }

  const handleUrl = (url) => {
    let tempForm = formData;
    tempForm.companyUrl = url;
    console.log(`tempForm:${JSON.stringify(tempForm)}`);
    setFormData({...tempForm});
  }

  const setData = (name, url) => {
    let _formData = formData
    _formData.companyUrl = url
    _formData.companyName = name
    setFormData({..._formData})
    //console.log(`formData: ${JSON.stringify(formData)}`)
  }

  const getBgColor = () => {
    if(resourceObject.name !== "" && resourceObject.version !== "" && resourceObject.description !== "" && acceptedFiles.length > 0) return "#000096"
    else return "#CCCCCC"
  }

  const uploadLearnFile = () => {

    const _resourceObject = new FormData()
    console.log(`object: ${JSON.stringify(resourceObject)}`)
    _resourceObject.append("operations", JSON.stringify({ "query": "mutation UploadLearnFile($file: Upload!, $name: String, $description:String, $version:String, $destination:String, $brand:String ) { UploadLearnFile(file: $file, name: $name, description:$description, version:$version, destination:$destination, brand:$brand) }", "variables": { "name": resourceObject.name, "version": resourceObject.version, "description": resourceObject.description, "destination": resourceObject.destination, "brand": resourceObject.brand }}));
    _resourceObject.append("map", JSON.stringify({"0": ["variables.file"]}));
    _resourceObject.append("0", acceptedFiles[0]);

    axios({
        url: process.env.REACT_APP_API,
        method: "post",
        data: _resourceObject
    })

    .then(response => {
      console.log(`response:${JSON.stringify(response)}`)
      getLearnFile()
      //notify("Upload Completed.", vpHandler.ref, "success")
      //getOneVp(getOneVpQuery, vpHandler.get.uuid, vpHandler)
    })
    .catch(error => {
      console.log(error)
      //notify("Upload Error.", vpHandler.ref, "danger")
    });
  }

  const AddFunctie = () => {
    if(resourceObject.name !== "" && resourceObject.version !== "" && resourceObject.description !== "" && acceptedFiles.length > 0){
      uploadLearnFile()
      toggleModal()
    }
  }
  

  return(
    localStorage.getItem("customUserGroup").includes("admin") &&
      <div 
        class="mb-2"
      >
        <button class={`btn mars-button btn-sm  mb-1 fs--2`} type="button" onClick={e => toggleModal()}> 
          {/* <span class={`${minWidth() ? "far fa-check-square mr-2" : ""}`}/> */}
          Add New Resource
        </button>
        
        <Modal
          className="modal-dialog-centered"
          isOpen={modal}
          toggle={toggleModal}
        >
          <div class="modal-header fs--3 fw-900">
            <div className="modal-title mars-blue">
              Add New Resource
            </div>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleModal}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              {resourceForm.map((index, i) => (
                <FormGroup key={i}>
                  <label
                    className="fs--2"
                  >
                    {index.title}
                  </label>
                  <Input
                    //onFocus={handleFocus}
                    //disabled={checkDisabled(index)}
                    placeholder={index.placeholder}
                    //value={values[index.name]}
                    id={index.name}
                    name={index.name}
                    type={index.type}
                    onChange={handleChange}
                  />
                </FormGroup>)
              )}
                <FormGroup>
                  <label
                    className="fs--2"
                  >
                    Linked Brand (if applicable)
                  </label>
                  <Input
                    //onFocus={handleFocus}
                    //disabled={checkDisabled(index)}
                    placeholder={"Brand"}
                    //value={values[index.name]}
                    id={"brand"}
                    name={"brand"}
                    type={"select"}
                    onChange={handleChange}
                  >
                    <option value={""}>None</option>
                    {allBrands.map((brand) => {
                      return <option value={brand.brandName}>{brand.brandName}</option>
                    })}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="fs--2"
                  >
                    Destination
                  </label>
                  <Input
                    //onFocus={handleFocus}
                    //disabled={checkDisabled(index)}
                    placeholder={"Destination"}
                    //value={values[index.name]}
                    id={"destination"}
                    name={"destination"}
                    type={"select"}
                    onChange={handleChange}
                  >
                    <option value={"playbooks"}>Playbooks & Guides</option>
                    <option value={"tools"}>Tools & Templates</option>
                    <option value={"other"}>Other Resources</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label
                    className="fs--2"
                  >
                    Select File: 
                  </label>
                  <div class={`dropzone dropzone-single p-0 hoverhand`} data-dropzone="data-dropzone" data-options='{"url":"valid/url","maxFiles":1,"dictDefaultMessage":"Choose or Drop a file here"}'>
                    <div {...getRootProps({className: 'dropzone'})} class="dz-message rounded" style={{border: "2px dashed #d8e2ef"}} data-dz-message="data-dz-message">
                      <div class="dz-message-text fs--2 row">
                        <div class="ml-auto"><img class="mr-2" src={CU} width={`${acceptedFiles.length > 0 ? "0" :"25"}`} alt="" /></div>
                        <input {...getInputProps()} />
                        <div class="mr-auto mt-1">{getName()}</div>
                      </div>
                    </div>
                  </div>
                </FormGroup>
                
            </Form>

            {/* <Form>
                <FormGroup>
                  <label
                    className="fs--2"
                  >
                    Select File: 
                  </label>
                  <div class={`dropzone dropzone-single p-0 hoverhand`} data-dropzone="data-dropzone" data-options='{"url":"valid/url","maxFiles":1,"dictDefaultMessage":"Choose or Drop a file here"}'>
                    <div {...getRootProps({className: 'dropzone'})} class="dz-message rounded" style={{border: "2px dashed #d8e2ef"}} data-dz-message="data-dz-message">
                      <div class="dz-message-text fs--2 row">
                        <div class="ml-auto"><img class="mr-2" src={CU} width="25" alt="" /></div>
                        <input {...getInputProps()} />
                        <div class="mr-auto mt-1">Select File</div>
                      </div>
                    </div>
                  </div>
                </FormGroup>
            </Form> */}
            
            {/* <CompanyForm
              isOpen={isOpen}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleFocus={handleFocus}
              values={formData}
            />
            <FindCompany
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              companyName={formData.companyName}
              handleUrl={handleUrl}
              setData={setData}
            /> */}
          </div>
          <div className="modal-footer">
            {// <Button
            //   color="secondary"
            //   data-dismiss="modal"
            //   type="button"
            //   onClick={toggleModal}
            // >
            //   Close
            // </Button>
            // <Button color="primary" onClick={createCompany}>
            //   {!processing ? "Submit" : null }
            //   {processing ? (
            //     <Spinner
            //       style={{ paddingTop: '0px', width: "1rem", height: "1rem" }}
            //       color="light"
            //     />
            //   ):null}
            // </Button>
            // <Button
            //   color="primary"
            //   type="button"
            //   onClick={handleSubmit}
            // >
            //   Save
            // </Button>
            }
            
            <div class="pr-1">
              <Button onClick={e=>AddFunctie()} height="16px" style={{ border: '0px', minWidth: '80px', backgroundColor: getBgColor(), fontSize: '11px' }}>
                Add
              </Button>
            </div>
            <div class="pr-1">
              <Button onClick={e=>handleSubmit()} height="16px" style={{ border: '0px', minWidth: '80px', backgroundColor: '#CCCCCC', fontSize: '11px' }}>
                Close
              </Button>
            </div>
            {// <div class="pr-1">
            //   <Button onClick={e=>handleSubmit()} height="16px" style={{ border: '0px', minWidth: '80px', backgroundColor: '#000096', fontSize: '11px' }}>
            //     Save
            //   </Button>
            // </div>
            }
          </div>
        </Modal>
      </div>
    )}