export const getManyWebEngagementQuery = `
query GetManyWebEngagement {
  GetManyWebEngagement {
    cid
    history {
      date
      uniqueVisitor
    }
  }
}
`
