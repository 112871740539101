export const pipelineShape = `
query PipelineShape {
  PipelineShape {
    Initiatives{
      _id
      stage
    }
    Funding{
      _id
      ideationFunding
      customerValFunding
      mvpFunding
      optimizeFunding
      growthFunding
      scaleFunding
      fundingAggregate
    }
  }
}
`;
