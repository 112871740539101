import axios from "axios";
import { getSpecificSocials } from "./queries/GetSpecificSocials";
import { getSpecificWebTraffic } from "./queries/GetSpecificWebTraffic";
import { getBrandsSpecific } from "./queries/GetBrandsSpecifc";

export const GetSocialSpecific = (setState, cid) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: getSpecificSocials,
      variables: {cid: cid },
    },
  })
    .then((result) => {
      //console.log("Social API: " , result.data.data.GetSpecificSocials)
      setState(result.data.data.GetSpecificSocials);
    })
    .catch((err) => console.log(err));
};

export const GetBrandSpecific = (setState, cid) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: getBrandsSpecific,
      variables: {cid: cid },
    },
  })
    .then((result) => {
      //console.log("Brand API: " , result.data.data.GetBrandsSpecific.BrandsSpecific[0])
      setState(result.data.data.GetBrandsSpecific.BrandsSpecific[0]);
    })
    .catch((err) => console.log(err));
};

export const GetTrafficSpecific = (setState, cid) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: getSpecificWebTraffic,
      variables: {cid: cid },
    },
  })
    .then((result) => {
      //console.log("Traffic API: " , result.data.data.GetSpecificWebTraffic)
      setState(result.data.data.GetSpecificWebTraffic);
    })
    .catch((err) => console.log(err));
};


