export const getAllWebTraffic = `
query GetAllWebTraffic{
    GetAllWebTraffic{
      _id
      visitsFive
      visitsFour
      visitsThree
      visitsTwo
      visitsOne
      visitsZero
    }
  }
  `;
