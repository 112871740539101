import React from 'react';
import ReactEchart from 'echarts-for-react';
import { colors } from '../lookupTables/Colors';

const TreeMap = ({data, title}) => {

let values = [];
let value = {};

const getValue = (item) => {
  //console.log(item.companyInfo!==null?item.companyInfo.revenue:item.revenue)
   if (item.companyInfo) return item.companyInfo.revenue || 0 
   else return item.revenue || 0
}

data.forEach(item => {
  
  (value = {
    name: item.brandName || item.name,
    value: getValue(item)
  });
    values.push(value);
});

const option = {
  tooltip: {
    show: true,
    trigger: 'item',
    formatter: '{b}: ${c}M'
  },
  
  title: {
    show: false,
    text: title
  },
  grid: {
    left: '0%',
    right: '0%',
    bottom: '0%',
    top: '5%',
    containLabel: true
  },
  series: [
    {
      type: 'treemap',
      data: values,
    }
  ],
  color: colors,
  textStyle: {
    fontFamily: `'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default`,
    color: '#748194'
  }
};

return <ReactEchart option={option}/>;

}

export default TreeMap;