import React, {useState, useEffect} from 'react';
import PaginationTable from '../../components/table/PaginationTable';
// import { FilterDeckDiscover }  from '../../components/Cards/FilterDeckDiscover';

import * as brandAPI from '../../components/API/BrandsTableAPI';
import { countryList } from '../../components/Lists/countryList';
// import { getProductComparison } from '../../components/API/queries/GetProductComparison';
// import { getAllWebTraffic } from '../../components/API/queries/GetAllWebTraffic';

const BrandsTable = ({ data, setSelectedBrand, getCompanies, string, setString }) => {
  const [init, setInit] = useState(false);
  // const [backup, setBackup] = useState([]);
  const [page, setPage] = useState(0);
  const [tableItems, setTableItems] = useState([]);
  // const [tableItemsBackup, setTableItemsBackup] = useState([]);

  const [brandTableBrands, setBrandTableBrands] = useState([]);
  const [brandTableSocials, setBrandTableSocials] = useState([]);
  const [brandTableTraffic, setBrandTableTraffic] = useState([]);

  const [autofillList, setAutofillList] = useState([]);

  // 
  // data.forEach(item => console.log(item.image))


  const tableColumns = [
    { columnName: '', id: 'image', isSortable: false, defaultSort: true, isNumeric: false },
    { columnName: 'Brand', id: 'name', isSortable: true, isNumeric: false },
    { columnName: 'Country', id: 'country', isSortable: true, isNumeric: false },
    { columnName: 'Region', id: 'region', isSortable: true, isNumeric: false },
    { columnName: 'Species', id: 'species', isSortable: true, isNumeric: false },
    { columnName: 'Product', id: 'product', isSortable: true, isNumeric: false },
    { columnName: 'Revenue', id: 'revenue', isSortable: true, isNumeric: true },
    { columnName: 'Web Traffic', id: 'traffic', isSortable: true, isNumeric: true },
    { columnName: 'Delta', id: 'delta1', isSortable: true, isNumeric: true },
    { columnName: 'Followers', id: 'followers', isSortable: true, isNumeric: true },
    { columnName: 'Delta', id: 'delta2', isSortable: true, isNumeric: true },
    { columnName: '', id: '', isSortable: false, isNumeric: false }
  ];



  useEffect(() => {
    if (!init) {
      setInit(true);
      //brandAPI.GetBrands(setBrandTableBrands);
      brandAPI.GetSocialAnalysisData(setBrandTableSocials);
      brandAPI.GetSocialAnalysisWebTraffic(setBrandTableTraffic);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]); 

  useEffect(() => {
    //console.log(`data ${JSON.stringify(data)}`)
    setBrandTableBrands([...data])
    setPage(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]); 

  // useEffect(() => {
  //   console.log(`brandTableSocials: ${JSON.stringify(brandTableSocials)}`)
  // }, [brandTableSocials]);

  useEffect(() => {
    if (brandTableBrands.length > 0 && brandTableSocials.length > 0) 
      fillTable();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandTableBrands, brandTableSocials, data]); 

  useEffect(() => {
    if (tableItems.length > 0 ) 
      createSearchBarList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableItems]); 
  
  const fixNumber = (input) => {
    let output = input.toFixed(1)
    output = output.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return output
  }
  
  const getSpecies = (item) => {
    // if(!item.showDiscover){
    //   if(item.cat && item.dog) return "Both"
    //   if(item.cat && !item.dog) return "Cat"
    //   if(!item.cat && item.dog) return "Dog"
    // }
    // else{
    if(item.species){
      if(item.species.length == 2) return "Both"
      else return item.species[0]
    } else return null
    //}
  }

  const getProduct = (item) => {
    if(item.mmi) return "MMI"
    if(item.mmni) return "MMNI"
    if(item.cts) return "CTS"
    if(item.mtt) return "MTT"
    else return "N/A"
  }

  const getTraffic = (item) => {
    let obj = brandTableTraffic.find((trafficItem) => trafficItem._id === item.cid)
    //console.log(`traffic: ${JSON.stringify(obj)}`)
    if(obj !== undefined) return fixNumber(obj.visits) + "k"
    else return "N/A"
  }

  const getTrafficDelta = (item) => {
    let obj = brandTableTraffic.find((trafficItem) => trafficItem._id === item.cid)
    if(obj !== undefined) return obj.change
    else return "N/A"
  }

  const getSocial = (item) => {
    let obj = brandTableSocials.find((socialItem) => socialItem._id === item.cid)
    if(obj !== undefined) return fixNumber(obj.currentTotalFollowers) + "k"
    else return "N/A"    
    
  }


  const getSocialDelta = (item) => {
    let obj = brandTableSocials.find((socialItem) => socialItem._id === item.cid)
    let curr = null
    let prev = null
    let change = null

    if(obj !== undefined){
      curr = (obj.currentTwitterFollowers + obj.currentTiktokFollowers + obj.currentInstagramFollowers + obj.currentFacebookLikes)
      prev = (obj.previousTwitterFollowers + obj.previousTiktokFollowers + obj.previousInstagramFollowers + obj.previousFacebookLikes)
      change = (prev * 100 / curr)
      //console.log(`curr: ${curr}, prev: ${prev}, change ${(Math.round((100-change ) * 100) / 100).toFixed(2)}`)
      return (Math.round((100-change ) * 100) / 100).toFixed(2)
    }
    else return "N/A"
  }

  const getCountryCode = (country) => {
    //console.log(country)
    let obj = countryList.find(obj => obj.Name === country)
    if(obj !== undefined) return obj.Code
  }

  const getRegion = (country) => {

    let obj = countryList.flat().find(obj => obj.Code === country)
    if(obj !== undefined) return obj.Region
  }

  const fillTable = () => {
    let table = [];
    let obj = {}

    data.forEach((item) => {
      //console.log(`${item.name} ${item.country}`)
      obj = {
        image:      `${item.cid || encodeURIComponent(item.name) }`,
        name:       `${item.brandName || item.name}`,
        uuid:       `${item.uuid}`,
        country:    `${item.country}`, //country:    `${item.geography? getCountryCode(item.geography) : item.country }`,
        region:     `${getRegion(item.country)}`, //`${item.region || getRegion(item.geography)}`,
        species:    getSpecies(item),
        product:    getProduct(item),
        revenue:    `${item.companyInfo?item.companyInfo.revenue:item.revenue}M`,
        traffic:    getTraffic(item),
        delta1:     getTrafficDelta(item),
        followers:  getSocial(item),
        delta2:     getSocialDelta(item),
        socials:    `-`,
      }
      //console.log(`item: ${JSON.stringify(obj)}`)

      table.push({...obj})
    })
    table.sort((a, b) => a.name.localeCompare(b.name))
    setTableItems([...table]);
  }
  

  const createSearchBarList = () => {
    let _data = tableItems;
    let _list = [];

    _data.forEach((item) => {
      let object = {
        name: item.name,
        cid: item.image,
      };
      _list.push({ ...object });
    });
    setAutofillList([..._list]);
  };

  return ( 
    <>
    {//<FilterDeckDiscover tableItems={tableItems} setTableItems={setTableItems}/>
    }
    <PaginationTable 
      string={string}
      setString={setString}
      getCompanies={getCompanies} 
      page={page} 
      setPage={setPage} 
      setSelectedBrand={setSelectedBrand} 
      columns={tableColumns} 
      tableItems={tableItems} 
      itemsPerPage={4} 
      setTableItems={setTableItems} 
      autofillList={autofillList}
      
    />
    </>
  )
};

export default BrandsTable;

// fill table stuff 
// const fillTable = () => {
//   let table = [];
//   // let identifyer = brandTableSocials;
//   // if (brandTableBrands.length > identifyer.length) identifyer = brandTableBrands;
//   // if (brandTableTraffic.length > identifyer.length) identifyer = brandTableTraffic;
  
//   let identifyer = brandTableBrands;

//   for (let i = 0; i < identifyer.length ; i++){
//     console.log(`identifyer: ${JSON.stringify(identifyer[i])}`)
//     let currentCompany = identifyer[i]._id;
    
//     let socialCompany = brandTableSocials.filter(item => item._id === currentCompany);
//     let brandCompany = brandTableBrands.filter(item => item._id === currentCompany);
//     let trafficCompany = brandTableTraffic.filter(item => item._id === currentCompany);

//     let image = "-";
//     let name = "-";
//     let country = "-";
//     let region = "-";
//     let species = [];
//     let product = "-";
//     let revenue = "0";
//     let traffic = "0";
//     let trafficDelta = "0";
//     let followers = "0";
//     let followersDelta = "0";
//     let socials = [];
//     let uuid = "";
//     //-------------------------------------------------
//     image = identifyer[i]._id;
//     name = identifyer[i]._id;
    
//     if ( brandCompany.length > 0 ) {
//       brandCompany = brandCompany[0];
//       country = brandCompany.country;
//       region  = brandCompany.region;
//       revenue = brandCompany.revenue;
//       uuid    = brandCompany.uuid;

//       if ( brandCompany.cat )   species.push("Cat");
//       if ( brandCompany.dog )   species.push("Dog");
//       if ( species.length > 1)  species = ["Both"];

//       if (brandCompany.mmi)       product = "MM-I";
//       else if (brandCompany.mmni) product = "MM-NI";
//       else if (brandCompany.cts)  product = "CTS";
//       else if (brandCompany.mtt)  product = "MTT";
//     } 

//     if ( trafficCompany.length > 0 ) { 
//       trafficCompany  = trafficCompany[0];
//       traffic         = trafficCompany.visits;
//       trafficDelta    = trafficCompany.change;
//       uuid            = trafficCompany.uuid;
//     }

//     if ( socialCompany.length > 0 ) {
//       socialCompany   = socialCompany[0];
//       followers       = socialCompany.currentTotalFollowers;
//       followersDelta  = socialCompany.followerChange;
//       uuid            = socialCompany.uuid;
//     }

//     socials = socials;
//     //-------------------------------------------------
//     let data = {
//       image:      `${image}`,
//       name:       `${name}`,
//       country:    `${country}`,
//       region:     `${region}`,
//       species:    `${species}`,
//       product:    `${product}`,
//       revenue:    `${revenue}M`,
//       traffic:    `${traffic}K`,
//       delta1:     `${trafficDelta}`,
//       followers:  `${followers}K`,
//       delta2:     `${followersDelta}`,
//       socials:    `${socials}`,
//       uuid:       `${uuid}`,
//     };
//     table.push(data);
//   }

//   //-------------------------------------------------------
//   setTableItems([...table]);
// }