export const allKPIList = [
    {
      name: "intrinsic",
      points: [
        {kpi: 'nsv', name: "NSV($)"}, 
        {kpi: 'gmac', name: "GMAC(%)"}, 
        {kpi: 'cac', name: "CAC($)"}, 
        {kpi: 'ltv', name: "LTV($)"}, 
        {kpi: 'ltvcac', name: "LTV/CAC(x:x)"},
        {kpi: 'ctr', name: "CTR(%)"},
        //{kpi: 'session', name: "Traffic/Sessions"},
        {kpi: 'bcr', name: "BCR(%)"},
        {kpi: 'aov', name: 'AOV($)'}
    ]
    },
    {
      name: "engagement",
      points: [
        {kpi: 'rp', name: "Repeat Purchasers(%)"},
        {kpi: 'tc', name: "Total Churn(%)"},
        {kpi: 'rr', name: "Referral Rate(%)"},
        {kpi: 'nps', name: "NPS(%)"}]
    },
    {
      name: "data",
      points: [
        {kpi: 'dvam', name: "Data value attribution model(t)"}, 
        {kpi: 'ppp', name: "Pet/Pet parent profiles(#)"}, 
        {kpi: 'cda', name: "Cost of data acquisition($)"}]
    },
    {
      name: "route to scale",
      points: [
        //{kpi: 'sim', name: "Scaleable in markets"}, 
        //{kpi: 'tam', name: "TAM/SAM/SOM($)"},
        // {kpi: 'markets', name: "Markets"},
        // {kpi: 'channels', name: "Channels"},
        // {kpi: 'brands', name: "Brands"},
        {kpi: 'tam', name: "TAM($)"},
        {kpi: 'sam', name: "SAM($)"},
        {kpi: 'som', name: "SOM($)"},
      ]
    },
  ]