import React from 'react';
import { CardBody } from 'reactstrap';
import LineChart from '../LineChart';

const LineChartLayoutWebTraffic = ({ data, title, width, height, sub, allBrands, webTraffic, buttons }) => {
  const [socialsFilter, setSocialsFilter] = React.useState({insta: true, facebook: true, tiktok: true, twitter: true})
  const [init, setInit] = React.useState(false)
  const [webTrafficData, setWebTrafficData] = React.useState({})

  const handleClick = (input) => {
    let obj = socialsFilter
    obj[input] = !obj[input]
    setSocialsFilter({...obj})
  }

  React.useEffect(() => {
    if(allBrands !== undefined)
    getTrafficData()
    // eslint-disable-next-line
  }, [webTraffic, allBrands]);

  React.useEffect(() => {
    if(!init && allBrands !== undefined){
      setInit(!init);
      getTrafficData();
    }
    // eslint-disable-next-line
  }, [init]);

  let cardWidth = width !== null ? width : '100%';
  let cardHeight = height !== null ? height : '100%';

  const getTrafficData = () => {
    let obj = {}
    let _data = {
      "_id":"AllWebTraffic",
      "visitsFive":0,
      "visitsFour":0,
      "visitsThree":0,
      "visitsTwo":0,
      "visitsOne":0,
      "visitsZero":0
    } 
    allBrands.forEach(brand => {
      obj = webTraffic.find(item => item.cid === brand.cid)
      if(obj !== undefined){
        if(obj.history.length> 0) {
          obj.history.forEach((historyItem, i) => {
            if(i<6){
              if(historyItem.uniqueVisititor !== null) _data[lookUp[i]] = (Number(_data[lookUp[i]]) + Number(historyItem.uniqueVisitor))
            }
          })
        }
      }
    })
    Object.keys(_data).map(point => {
     _data[point] = (_data[point]/1000).toFixed(2)
    })
    setWebTrafficData({..._data})
  }


  let data2 = {"_id":"AllWebTraffic","visitsFive":0,"visitsFour":0,"visitsThree":0,"visitsTwo":0,"visitsOne":9.651,"visitsZero":9.627}

  const lookUp = {
    0: "visitsZero",
    1: "visitsOne",
    2: "visitsTwo",
    3: "visitsThree",
    4: "visitsFour",
    5: "visitsFive"
  }


  return (
    <div class="card mb-3" style={{ width: `${cardWidth}`, height: `${cardHeight}` }}>
      <div class=" ">
        <div class="card-header pb-1 bg-light">
          <h6><div data-bs-toggle="tooltip" title="Combined # unique visitors">{title} </div></h6>
          <h6 class="mb-0 fs--2 text-500">{sub}</h6>
        </div>
      </div>
      <CardBody style={{ paddingTop: 0, marginTop: 0 }}>
        <div className="chart">
          <LineChart data={webTrafficData} title={title} />
        </div>
      </CardBody>
    </div>
  );
};
export default LineChartLayoutWebTraffic;
