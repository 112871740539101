import React from "react";
import PipelineShapeFundingCard from "./PipelineShapeFundingCard";
import PipelineShapeFTECard from "./PipelineShapeFTECard";
import PipelineShapeInitiativesCard from "./PipelineShapeInitiativesCard";
import * as api from "./../API/IndividualVpAPI";
export const PipelineShape = ({data, valueProps}) => {
  const [open, setOpen] = React.useState(true);
  const [init, setInit] = React.useState(false);

  // const [allVp, setAllVp] = React.useState([]);

  // console.log(`valueProps: ${JSON.stringify(valueProps)}`)

  // React.useEffect(() => {
  //   if(!init) {
  //     setInit(!init)
  //     api.getManyVp(setAllVp)
  //   }
  // }, [init]);

  // React.useEffect(() => {
  //   console.log(`allVp ${JSON.stringify(allVp)}`)
  // }, [allVp]);

  return(<>
    
    <div class="card h-100">
      <div class="card-header bg-light d-flex flex-between-center py-2">
        <div class="col-auto col-sm-6 col-lg-7 p-0">
          <h6 class="mb-0 mt-2 text-nowrap py-2 py-xl-0">Pipeline shape</h6>
          <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
            <i class="fas fa-info-circle mr-1"/>
              Quick guide
          </div>
        </div>
      </div>
      <div class="card-body px-0 py-0">
        <div class={`${open ? "collapse" : ""}`} id="collapsePotential">
          <div class="p-card rounded fs--2 mt-n3">
            <p class="fs--2">
              <ul>
                <li>Value propositions (#) → Number of value porpositions by stage</li>
                <li>Approval rate (%) → Average rate of success ('Progress') by stage</li>
                <li>Funding per VP → Average investment per VP by stage (includes, as far as possible, technical development, sales & marketing and overheads</li>
                <li>FTE per VP → Average number of FTE (Associates) per VP by stage</li>
                <li>Duration per VP → Average number of weeks a VP spends in each stage</li>
              </ul>
            </p>
          </div>
        </div>
        <div class="table-responsive scrollbar">
          <table class="table table-sm fs--2 mb-0 overflow-hidden">
            <thead class="bg-200 text-900">
              <tr>
                <th class="mr-1 align-top white-space-nowrap">Description</th>
                <th class="mr-1 align-top white-space-nowrap"><span style={{color: '#add136'}} class="mr-1 fas fa-circle fs--2"></span>Ideation</th>
                <th class="mr-1 align-top white-space-nowrap"><span style={{color: '#50C1EF'}} class="mr-1 fas fa-circle fs--2"></span>Customer<br/> <div class="ml-3">Validation</div> </th>
                <th class="mr-1 align-top white-space-nowrap"><span style={{color: '#FFC32D'}} class="mr-1 fas fa-circle fs--2"></span>MVP</th>
                <th class="mr-1 align-top white-space-nowrap"><span style={{color: '#00B050'}} class="mr-1 fas fa-circle fs--2"></span>Optimize</th>
                <th class="mr-1 align-top white-space-nowrap"><span style={{color: '#5F7D95'}} class="mr-1 fas fa-circle fs--2"></span>Grow</th>
                <th class="mr-1 align-top white-space-nowrap"><span style={{color: '#6F3996'}} class="mr-1 fas fa-circle fs--2"></span>Scale</th>
                <th class="px-1 align-top white-space-nowrap  border-start">Aggregate</th>
              </tr>
            </thead>
            <tbody class="list fs--2">
              <PipelineShapeInitiativesCard valueProps={valueProps}/>
              <tr class="btn-reveal-trigger">
                <td class="align-top">Approval rate<br/>(avg %)</td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                      <div class="progress-bar mars-bg-stage1" style={{width: "17%"}} role="progressbar" aria-valuenow="17" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>17%</span>
                </td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                    <div class="progress-bar mars-bg-stage2" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>25%</span>
                </td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                    <div class="progress-bar mars-bg-stage3" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>25%</span>
                </td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                    <div class="progress-bar mars-bg-stage4" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>25%</span>
                </td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                    <div class="progress-bar mars-bg-stage5" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>25%</span>
                </td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                    <div class="progress-bar mars-bg-stage6" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>25%</span>
                </td>
                <td align={"center"} class="align-top bg-200  px-2 border-start">
                  <div class="d-none progress progress-white mars-pb-table">
                    <div class="progress-bar mars-bg-blue" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>25%</span>
                </td>
              </tr>
              <PipelineShapeFundingCard valueProps={valueProps}/>
              <PipelineShapeFTECard valueProps={valueProps}/>

              {/* <tr class="btn-reveal-trigger">
                <td class="align-top">FTE per VP<br/>(avg #)</td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                      <div class="progress-bar mars-bg-stage1" style={{width: "17%"}} role="progressbar" aria-valuenow="17" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>8 fte</span>
                </td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                    <div class="progress-bar mars-bg-stage2" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>10 fte</span>
                </td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                    <div class="progress-bar mars-bg-stage3" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>20 fte</span>
                </td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                    <div class="progress-bar mars-bg-stage4" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>45 fte</span>
                </td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                    <div class="progress-bar mars-bg-stage5" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>64 fte</span>
                </td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                    <div class="progress-bar mars-bg-stage6" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>80 fte</span>
                </td>
                <td class="align-top bg-200 px-2 border-start">
                  <div class="progress progress-white mars-pb-table">
                    <div class="progress-bar mars-bg-blue" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>15 fte</span>
                </td>
              </tr> */}
              <tr class="btn-reveal-trigger">
                <td class="align-top">Duration per VP<br/>(avg #weeks)</td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                      <div class="progress-bar mars-bg-stage1" style={{width: "17%"}} role="progressbar" aria-valuenow="17" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>1 week</span>
                </td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                    <div class="progress-bar mars-bg-stage2" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>2 weeks</span>
                </td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                    <div class="progress-bar mars-bg-stage3" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>3 weeks</span>
                </td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                    <div class="progress-bar mars-bg-stage4" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>5 weeks</span>
                </td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                    <div class="progress-bar mars-bg-stage5" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>10 weeks</span>
                </td>
                <td class="align-top">
                  <div class="progress mars-pb-table">
                    <div class="progress-bar mars-bg-stage6" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>10 weeks</span>
                </td>
                <td align={"center"} class="align-top bg-200 px-2 border-start">
                  <div class="d-none progress progress-white mars-pb-table">
                    <div class="progress-bar mars-bg-blue" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <span>31 weeks</span>
                </td>
              </tr>
            </tbody>
            </table>
        </div>
      </div>
    </div>
  
          
  </>)}