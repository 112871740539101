import React from "react";
// import {
//   InputGroup,
//   InputGroupAddon,
//   InputGroupButtonDropdown,
//   Input,
//   Button,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem
//  } from 'reactstrap';
 
//  import pb1 from "./../../../assets/img/learn/PB1_Build_Box.png";
//  import pb2 from "./../../../assets/img/learn/PB2_Build_Wheel.png";
//  import pb3 from "./../../../assets/img/learn/PB3_Plug_Box.png";

 import { ResourcesLine } from "./ResourcesLine";
 import { UploadResourcesLine } from "./UploadResourcesLine";

export const Playbooks = ({learnFiles}) => {
  // const [open, setOpen] = React.useState(true);
  const [hide, setHide] = React.useState(false);
  
  // const openInNewTab = (url) => {
  //   const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  //   if (newWindow) newWindow.opener = null
  // }


  return(
    
    <div class="card mb-3" id="playbooks">
      <div class="card-header pb-1 bg-light">
        <div class="row col m-0 p-0">
          <span class="fas fa-book-open mr-2"/>
          <h6 class="mb-0">Playbooks & Guides</h6>
        </div>
        <div class="row col m-0 p-0">
          <div>
            
          </div>
          <div class="ml-auto">
            <div class="me-2 fs--2" onClick={e => setHide(!hide)} role="button">
              view / hide
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class={`${hide ? "collapse" : ""}`}>
          <div>
            <ResourcesLine 
              title="Building the Box" 
              img="PB1_Build_Box.png"
              icon=""
              version="Version 1.1" 
              description="To be used by the deployment team who is responsible for building the Box for a region."
              link="https://d2cx.ams3.digitaloceanspaces.com/learn/Playbook%201_Building%20The%20Box_FINAL.pdf"
            />
            <ResourcesLine 
              title="Building the Wheel" 
              img="PB2_Build_Wheel.png"
              icon=""
              version="Version 2.1" 
              description="To be used by the Wheel owners to understand the proposition development process."
              link="https://d2cx.ams3.digitaloceanspaces.com/learn/Playbook%202_How%20to%20Build%20The%20Wheel_FINAL%20V2.pdf"  
            />
            <ResourcesLine 
              title="Plug into the Box" 
              img="PB3_Plug_Box.png"
              icon=""
              version="Version 2.1" 
              description="To be used by the Wheel owners to understand how to use The Box to launch new D2C propositions."
              link="https://d2cx.ams3.digitaloceanspaces.com/learn/Playbook%203_How%20To%20Plug%20Into%20The%20Box_FINAL.pdf"  
            />
            {learnFiles.map((resource) => (
            resource.destination === "playbooks" && <UploadResourcesLine resource={resource}/>
            ))}
          </div>
        </div>
      </div>
    </div>
          
  )}