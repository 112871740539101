import React from "react";
import Select from 'react-select'
import * as api from "../../components/API/IndividualVpAPI";
//import { speciesList } from "../../Lists/speciesList";


export const InputFieldDiscoverProduct = ({brand, setBrand, handle}) => {

  const productList = [
    {
      label: "Main Meal - Individualized",
      value: "mmi",
    },
    {
      label: "Main Meal - Not Individualized",
      value: "mmni",
    },
    {
      label: "Care, Treats & Supplements",
      value: "cts"
    },
    {
      label: "Monthly Treats & Toys",
      value: "mtt"
    }
  ]

  const handleChange = (e) => {
    console.log(`e: ${JSON.stringify(e.value)}`)
    if(e.value === "mmi"){
      brand.mmi = true
      brand.mmni = false
      brand.cts = false
      brand.mtt = false
    }
    if(e.value === "mmni"){
      brand.mmi = false
      brand.mmni = true
      brand.cts = false
      brand.mtt = false
    }
    if(e.value === "cts"){
      brand.mmi = false
      brand.mmni = false
      brand.cts = true
      brand.mtt = false
    }
    if(e.value === "mtt"){
      brand.mmi = false
      brand.mmni = false
      brand.cts = false
      brand.mtt = true
    }
    setBrand({...brand});
    
  }
  
  // const handleChange = (vpHandler, e, handle) => {      
  //   let vpObject = vpHandler.get

  //   if(e.label === "Cat") {
  //     vpObject.species = ["Cat"]
  //     //vpObject.species.push([...species])
  //   }
  //   if(e.label === "Dog") {
  //     vpObject.species = ["Dog"]
  //     //vpObject.species.push([...species])
  //   }
  //   if(e.label === "Cat & Dog") {
  //     vpObject.species = ["Cat", "Dog"]
  //     //vpObject.species.push([...species])
  //   }
  //   //console.log(`species: ${JSON.stringify(vpObject.species)}`)

  //   vpHandler.set({...vpObject})
  // }

  const getValue = () => {
    let obj = {label: "", value: ""}
    if(brand.mmi) {
      obj.label = "Main Meal - Individualized"
      obj.value = "mmi"
    } 
    if(brand.mmni) {
      obj.label = "Main Meal - Not Individualized"
      obj.value = "mmni"
    }
    if(brand.cts) {
      obj.label = "Care, Treats, Supplements"
      obj.value = "cts"
    }
    if(brand.mtt) {
      obj.label = "Monthly Treats & Toys"
      obj.value = "mtt"
    }
    return obj

  }

  const styles = {
    control: (styles, { isDisabled }) => {
      return {
        ...styles,
        height: '28px',
        minHeight: '28px',
        backgroundColor: isDisabled ? '#fafafa' : 'white',
      }
    },
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  return( 
        <Select 
          className="fs--2" 
          //isMulti={isMulti}
          //isDisabled={api.checkDisabled(vpHandler)}
          isSearchable={false}
          options={productList}
          placeholder={`Select product`}
          autosize={false}
          clearable={true}
          onChange={(e) => handleChange(e)}
          value={getValue()}
          styles={styles}
          //styles={{control: customControlStyles}}
          theme={theme => ({
            ...theme,
            borderRadius: 3,
            colors: {
              ...theme.colors,
              primary: '#cccccc',
            },
          })}
        />
  )
}