import React, { useEffect, useState } from 'react';
import Geocode from "react-geocode";
import { compose, withProps } from "recompose";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

export const GoogleMaps = ({address}) => {
  const [init, setInit] = useState(false);
  // const [bool, setBool] = useState(false);
  const [coords, setCoords] = useState({ lat: 33, lng: -118 });

  Geocode.setApiKey('AIzaSyBluh4zmCqXMgFJqdrnZA4WDBa8NLz39wY');

  useEffect(() => {
    if (!init) {
      setInit(true);
      getCoords();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);
    
  useEffect(() => {
    getCoords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  
  const getCoords = () => {
    Geocode.fromAddress(address).then(
      (response) => {
        //const { lat, lng } = response.results[0].geometry.location;
        setCoords({ ...response.results[0].geometry.location })
      },
      error => {
        console.error(`error: ${error}`);
      }
    );
  };

  // const getLatLng = (address) => {
  //   Geocode.fromAddress(address).then(
  //     (response) => {
  //       const { lat, lng } = response.results[0].geometry.location;

  //       setCoords({...response.results[0].geometry.location})
  //     },
  //     (error) => {
  //       console.error(`error: ${error}`);
  //     }
  //   );
  // }
  

  const MyMapComponent = compose(
    withProps({
      googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAyQl7vonO763eavPWEAZlfXHTvfwrkTz0',
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `200px`, marginBottom: '10px' }} />,
      mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
  )(props => (
    <GoogleMap defaultZoom={10} defaultCenter={{ lat: coords.lat, lng: coords.lng }}>
      <Marker position={{ lat: coords.lat, lng: coords.lng }} />
    </GoogleMap>
  ));

  return (
    <div>
      <MyMapComponent key="map" coords={coords} />
    </div>
  );
};