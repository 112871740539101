//import Dashboard from "../pages/dashboards/Dashboard";
import BrandOverview from "../pages/dashboards/BrandOverview";
//import SocialAnalysis from "../pages/dashboards/SocialAnalysis";
//import SingleBrand from "../pages/dashboards/SingleBrand";
//import CorporateOverview from "../pages/dashboards/CorporateOverview";
//import PricingOverview from "../pages/dashboards/PricingOverview";
//import Landing from "../pages/dashboards/Landing";
import Learn from "../pages/dashboards/Learn";
//import QuickGuide from "../pages/dashboards/QuickGuide";
//import VPMonitor from "../pages/monitor/VPMonitor";
import M_Discover from "../pages/dashboards/M_Discover";
import Benchmark from "../pages/dashboards/Benchmark";
import Homepage from "../pages/dashboards/Homepage"
import Feedback from "../pages/dashboards/Feedback";

const routes = [
  {
    collapse: true,
    path: "/dashboard",
    layout: "/insight",
    component: M_Discover,
    name: "Control Tower",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    logo: "whiteBox",
    views: [
      {
        path: "/dashboard",
        name: "Competitive Analysis",
        miniName: "SA",
        component: M_Discover,
        layout: "/insight",
        //logo: "src/assets/img/icons/marsicons/instagram.png",
      },
      // {
      //   path: "/vpmonitor",
      //   name: "Competitive Analysis",
      //   miniName: "SA",
      //   component: VPMonitor,
      //   layout: "/monitor",
      //   //logo: "src/assets/img/icons/marsicons/instagram.png",
      // },
      // {
      //   path: "/pricingoverview",
      //   name: "Price Analysis",
      //   miniName: "PA",
      //   component: PricingOverview,
      //   layout: "/insight",
      //   //logo: "assets/img/icons/003-price-tag.png"

      // },
      {
        path: "/brandoverview/:brand",
        name: "Brand Overview",
        miniName: "BO",
        component: BrandOverview,
        layout: "/insight",
        //logo: "src/assets/img/icons/004-analysis.png"

      },
      // {
      //   path: "/corporateoverview",
      //   name: "Corporate Overview",
      //   miniName: "CO",
      //   component: CorporateOverview,
      //   layout: "/insight",
      //   display: false,
      //   //logo: "assets/img/icons/005-eye.png"
      // },
      // {
      //   path: "/company/:id",
      //   name: "Business Insight",
      //   miniName: "BI",
      //   component: SingleBrand,
      //   layout: "/insight",
      //   display: false,
      //   //logo: "assets/img/icons/005-eye.png"
      // },
      {
        path: "/SocialAnalysis",
        name: "Business Insight",
        miniName: "BI",
        component: Benchmark,
        layout: "/insight",
        display: false,
        //logo: "assets/img/icons/005-eye.png"
      },
      {
        path: "/landing",
        name: "Landing Page",
        miniName: "LP",
        component: Homepage,
        layout: "/home",
        display: false,
      },
      {
        path: "/learn",
        name: "Learning",
        miniName: "LP",
        component: Learn,
        layout: "/home",
        display: false,
      },
      {
        path: "/socialanalysis",
        name: "Learning",
        miniName: "LP",
        component: Benchmark,
        layout: "/insight",
        display: false,
      },
      {
        path: "/feedback",
        name: "Feedback",
        miniName: "LP",
        component: Feedback,
        layout: "/home",
        display: false,
      },
      // {
      //   path: "/quickguide",
      //   name: "Guide",
      //   miniName: "QG",
      //   component: QuickGuide,
      //   layout: "/insight",
      //   display: false,
      // },
    ],
  },
  {
    collapse: true,
    path: "/brandoverview",
    layout: "/insight",
    component: BrandOverview,
    name: "Business Insights",
    icon: "ni ni-folder-17 text-primary",
    state: "dashboardsCollapse",
    logo: "whiteBox",
    views: [],
  },
  {
    collapse: true,
    path: "/socialanalysis",
    layout: "/insight",
    component: Benchmark,
    name: "Social Analysis",
    icon: "ni ni-circle-08 text-primary",
    state: "dashboardsCollapse",
    logo: "whiteBox",
    views: [],
  },
  {
    collapse: true,
    path: "/feedback",
    layout: "/home",
    component: Feedback,
    name: "Social Analysis",
    icon: "ni ni-circle-08 text-primary",
    state: "dashboardsCollapse",
    logo: "whiteBox",
    views: [],
  },

  // {
  //   collapse: true,
  //   path: "/quickguide",
  //   layout: "/insight",
  //   component: QuickGuide,
  //   name: "Quick Guide",
  //   icon: "ni ni-circle-08 text-primary",
  //   state: "dashboardsCollapse",
  //   logo: "whiteBox",
  //   views: [],
  // },    
  
];

export default routes;
