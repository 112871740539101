import React from "react";
import axios from "axios";
import { docx } from './../../../assets/img/icons/docx.png'
import { pdf } from './../../../assets/img/icons/pdf.png'
import { pptx } from './../../../assets/img/icons/pptx.png'
import { jpg } from './../../../assets/img/icons/jpg.png'

import { useHistory } from 'react-router-dom';

// import edit from "./../../../assets/img/learn/edit-alt.svg";
//import cloud from "./../../assets/img/learn/cloud-download.svg";

export const UploadResourcesLine = ({resource, allBrands}) => {
  // let imgUrl = "PB1_Build_Box.png"
  const history = useHistory()

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const getImg = () => {
    if(resource.fileExt.includes("doc")) return "docx"
    if(resource.fileExt.includes("jp") || resource.fileExt.includes("png")) return "jpg"
    if(resource.fileExt.includes("pdf")) return "pdf"
    if(resource.fileExt.includes("ppt")) return "pptx"
    else return "file"
  }

  const GetDownloadLink = () => { 
    let fileId = resource.uuid
    let fileName = `${resource.name}.${resource.fileExt}`
    axios({
      url: process.env.REACT_APP_API,
      //url: "https://d2cx.dev/graphql",
      method: "post",
      data: {
        query: `query GetDownload($fileId: String, $fileName: String) {
          GetDownload(fileId: $fileId, fileName: $fileName)
        }`,
        variables: { fileId: fileId, fileName: fileName },
      },
    })
      .then((result) => {
        openInNewTab(result.data.data.GetDownload);
        //setLearnFiles( [...result.data.data.GetLearnFile] );
      })
      .catch((err) => console.log(err));
  };

  //stretched-link text-900 fw-semi-bold

  const openBrandPage = () => {

    if(allBrands !== undefined){
      history.push(`/insight/brandoverview/${allBrands.find(item => item.brandName === resource.brand).cid}`)
      // console.log(`resource: ${resource.brand}`)
      // console.log(allBrands.find(item => item.brandName === resource.brand).cid)
    }
  }

  return (<>
      <div class="d-flex mb-3 hover-actions-trigger align-items-center mt-3">      
      <div class="file-thumbnail">
        <img class="border h-100 w-100 fit-cover rounded-2" src={require(`./../../../assets/img/icons/${getImg()}.png`)} alt={resource.fileExt} /> 
        {/* src={require(`./../../../assets/img/learn/${"img"}`)} */}
      </div>
      <div class="ml-3 flex-shrink-1 flex-grow-1">
        <h6 class="mb-1">
          <div class="hoverhand" onClick={(e) => GetDownloadLink()}>  
            {resource.name}
          </div>
        </h6>
        <div class="fs--1">
          <span class="fw-semi-bold">
            {resource.version}
          </span>
          {resource.brand&&<span class="fw-semi-bold ml-2 hoverhand" style={{color: "#000096", cursor: 'pointer'}} onClick={()=>openBrandPage()}>
            #{resource.brand}
          </span>}
        </div>
        <div class="fs--1">
          <span class="fw-medium text-600">
            {resource.description}
          </span>
        </div>
        {// <div class="hover-actions end-0 top-50 translate-middle-y">
        //   <a class="btn btn-light border-300 btn-sm me-1 text-600" data-bs-toggle="tooltip" data-bs-placement="top" title="Download" href="assets/img/icons/cloud-download.svg" download="download">
        //     <img src="assets/img/icons/cloud-download.svg" alt="" width="15" />
        //   </a>
        //   <button class="btn btn-light border-300 btn-sm me-1 text-600 shadow-none" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
        //     <img src={edit} alt="" width="15" />
        //   </button>
        // </div>
        }
      </div>
    </div>
    <hr class="bg-200" />
  </>)}