import axios from "axios";
import { getSocials } from "./queries/GetSocials";
import { socialAnalysisBrands } from "./queries/SocialAnalysisBrands";
import { socialAnalysisData } from "./queries/SocialAnalysisData";
import { socialAnalysisWebTraffic } from "./queries/SocialAnalysisWebTraffic";

export const GetSocialAnalysisWebTraffic = (setWebTrafficArray) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: socialAnalysisWebTraffic,
    },
  })
    .then((result) => {
      //setWebTrafficObject({...result.data.data.WebGetVisit.WebTraffic});
      setWebTrafficArray([...result.data.data.WebGetVisit.WebTraffic]);
    })
    .catch((err) => console.log(err));
};

export const GetSocialAnalysisData = (setSocialWebData) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: socialAnalysisData,
    },
  })
    .then((result) => {
      setSocialWebData([...result.data.data.GetSocials.Socials]);
    })
    .catch((err) => console.log(err));
};

export const GetSocialAnalysisBrands = (setBrands) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: socialAnalysisBrands,
    },
  })
    .then((result) => {
      setBrands([...result.data.data.GetBrandsSorted.BrandsType]);
    })
    .catch((err) => console.log(err));
};

export const GetSocials = (setBrands) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: getSocials,
    },
  })
    .then((result) => {
      setBrands([...result.data.data.GetSocials]);
      console.log(`socials: ${result.data.data.GetSocials}`)
    })
    .catch((err) => console.log(err));
};
