export const getVPs = `
query GetVP($vp: ValuePropInputType) {
  GetManyVP(vp: $vp) {
    name
    valuePropositionName
    uuid
    archived
    active
    pluggedIntoBox
    pivot
    specialIcon
    perish
    geography
    countries
    channel
    division
    painPoint
    description
    strategy
    createdBy
    businessType
    investment
    currentStage
    nextStepsActionPlan
    nextStepsOutcomes
    nextStepsFunding
    nextStepsFundingAllocation
    species
    funding{
      customerVal
      grow
      ideation
      mvp
      optimize
      scale
    }
    url
    iconUrl
    address
    revenue
    brandStartDate
    facebookHandle
    twitterHandle
    instaHandle
    tiktokHandle
    pinterestHandle
    youtubeHandle
    mmni
    mmi
    cts
    mtt    
    showDiscover
    speciesArray

    facebook
    twitter
    instagram
    youtube
    country
    startData
    website
  }
}
`
