import React from 'react';
import { ProgressBar }  from 'reactstrap';
const PipelineShapeFTECard = ({data, valueProps}) => {

  let ideationFTE = 0
  let customerValFTE = 0
  let mvpFTE = 0
  let optimizeFTE = 0
  let growFTE = 0 
  let scaleFTE = 0
  let fteAggregate = 0

  let fteObject = {}
  let fte = 0

  valueProps.forEach(vp => {
    fte = 0
    vp.team.forEach(member => {
      fte = Number(fte) + Number(member.fte)
    })
    if(vp.currentStage === "ideation") ideationFTE = fte + ideationFTE
    if(vp.currentStage === "customerVal") customerValFTE = fte + customerValFTE
    if(vp.currentStage === "mvp") mvpFTE = fte + mvpFTE
    if(vp.currentStage === "optimize") optimizeFTE = fte + optimizeFTE
    if(vp.currentStage === "grow") growFTE = fte + growFTE
    if(vp.currentStage === "scale") scaleFTE = fte + scaleFTE
  })

  fteAggregate = ideationFTE + customerValFTE + mvpFTE + optimizeFTE + growFTE + scaleFTE

  const getWidth = (input) => {
    // console.log(`input: ${input.toFixed(1)}, total: ${fteAggregate.toFixed(1)}, %: ${input * 100 / fteAggregate}`)
    return (input * 100 / fteAggregate)/2
  }

  return (
    <tr class="btn-reveal-trigger">
      <td class="align-top">FTE per VP<br/>(avg #)</td>
      <td class="align-top">
        <div class="progress mars-pb-table">
            <div class="progress-bar mars-bg-stage1" style={{ width: getWidth(ideationFTE) }} role="progressbar" />
        </div>
        <span>{ideationFTE.toFixed(1)} fte</span>
      </td>
      <td class="align-top">
        <div class="progress mars-pb-table">
          <div class="progress-bar mars-bg-stage2" style={{ width: getWidth(customerValFTE) }} role="progressbar"/>
        </div>
        <span>{customerValFTE.toFixed(1)} fte</span>
      </td>
      <td class="align-top">
        <div class="progress mars-pb-table">
          <div class="progress-bar mars-bg-stage3" style={{ width: getWidth(mvpFTE) }} role="progressbar" />
        </div>
        <span>{mvpFTE.toFixed(1)} fte</span>
      </td>
      <td class="align-top">
        <div class="progress mars-pb-table">
          <div class="progress-bar mars-bg-stage4" style={{ width: getWidth(optimizeFTE) }} role="progressbar" />
        </div>
        <span>{optimizeFTE.toFixed(1)} fte</span>
      </td>
      <td class="align-top">
        <div class="progress mars-pb-table">
          <div class="progress-bar mars-bg-stage5" style={{ width: getWidth(growFTE) }} role="progressbar" />
        </div>
        <span>{growFTE.toFixed(1)} fte</span>
      </td>
      <td class="align-top">
        <div class="progress mars-pb-table">
          <div class="progress-bar mars-bg-stage6" style={{ width: getWidth(scaleFTE) }} role="progressbar" />
        </div>
        <span>{scaleFTE.toFixed(1)} fte</span>
      </td>
      <td align={"center"} class="align-top bg-200 px-2 border-start">
        {/* <div class="progress progress-white mars-pb-table">
          <div class="progress-bar mars-bg-blue" style={{width: "25%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax={fteAggregate.toFixed(1)}></div>
        </div> */}
        <span>{fteAggregate.toFixed(1)} fte</span>
      </td>
    </tr>
  );
};

export default PipelineShapeFTECard;
