import React from "react";
import Select from 'react-select'
import * as api from "../../components/API/IndividualVpAPI";
import { lookUpList } from "../Lists/lookUpList";

export const DropdownFieldShowDiscover = ({ handle, isMulti, vpHandler, list}) => {

  const handleChange = (vpHandler, e) => {      
    let vpObject = vpHandler.get
    if(e.value === "yes") vpObject.showDiscover = true
    else vpObject.showDiscover = false
    vpHandler.set({...vpObject})    
  }

  // const previewValue = () => {
  //   let preview = []
  //   let value = {}
  //   if(isMulti){
  //     if(vpHandler.get[handle] !== undefined ){
  //       vpHandler.get[handle].forEach(item => {
  //         value.label = item
  //         value.value = item
  //         preview.push({...value})
  //         })
  //       }
  //     }
  //   else if(vpHandler.get[handle] !== ""){
      
  //     value.label = lookUpList[vpHandler.get[handle]] || vpHandler.get[handle] //this is the display part
  //     value.value = vpHandler.get[handle] //this is the saved string
  //     preview.push({...value})
  //   }
  //   return preview
  // }

  const getValue = () => {
    let vpObject = vpHandler.get
    let obj = {}
    //console.log(`${handle}: ${vpObject[handle]}`)
    if(vpObject[handle] === "" || vpObject[handle] === undefined || vpObject[handle] === []) return null
    else {
      obj.label = lookUpList[vpObject[handle]] || vpHandler.get[handle]
      obj.value = vpObject[handle]
      return obj
    }
  }


  const styles = {
    control: (styles, { isDisabled }) => {
      return {
        ...styles,
        height: '28px',
        minHeight: '28px',
        backgroundColor: isDisabled ? '#fafafa' : 'white',
        color: isDisabled ? "red" : "white",
      }
    },
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

 
  return(  
        <Select 
          className="fs--2" 
          isMulti={isMulti}
          isDisabled={api.checkDisabled(vpHandler)}
          isSearchable={false}
          options={list}
          placeholder="Show in Discover?"
          autosize={false}
          clearable={true}
          onChange={(e) => handleChange(vpHandler, e , handle)}
          value={getValue()}
          styles={styles}
          //styles={{control: customControlStyles}}
          theme={theme => ({
            ...theme,
            borderRadius: 3,
            colors: {
              ...theme.colors,
              primary: '#cccccc',
            },
          })}
        />
  )
}