import React from 'react';
import { lookUpList } from '../Lists/lookUpList';
import moment from 'moment';

const PipelineProgressCardNonActive = ({item}) => {
    
  let color = "mars-cd";
  if (item.currentStage === "ideation") color = "mars-c1"
  if (item.currentStage === "customerVal") color = "mars-c2"
  if (item.currentStage === "mvp") color = "mars-c3"
  if (item.currentStage === "optimize") color = "mars-c4"
  if (item.currentStage === "growth") color = "mars-c5"
  if (item.currentStage === "scale") color = "mars-c6"

  let stageClass = `fas fa-circle fs--2 me-2 ${color}`;
  let pivot = "No";
  if (item.pivot) pivot = "Yes";

  const getWeeks = () => {
    if(getSubmittedDate()){
      let a = moment() //now
      let b = moment(getSubmittedDate(), "MMM Do YYYY") //submit
      let duration = moment.duration(b.diff(a));
      //console.log(duration.asWeeks())
      let weeks = ((Math.round(duration.asWeeks())) * -1) + 1
      if(weeks) return `${weeks} week(s)`
    }
    else return "N/A"
  }

  const getSubmittedDate = () => {
    let obj = item.eventHistory[item.eventHistory.length-1]
    if(!!obj) console.log(obj.date)
    if(!!obj) return obj.date
  }

  return (
      <tr class="btn-reveal-trigger">
          <td class="align-top name"><a class="text-primary fw-semi-bold" href={`/monitor/individual/view/${item.uuid}`}>{item.name}</a></td>
          <td class="align-top stage"><span class={stageClass}></span>{` ${lookUpList[item.currentStage]}`}</td>
          <td class="align-top weeks">{getWeeks()}</td>
          <td class="align-top pivot">{pivot}</td>
      </tr>
  )
}
export default PipelineProgressCardNonActive;