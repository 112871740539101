import React from 'react';
import TableCell from '@material-ui/core/TableCell';

const PipelineShapeInitiativesCard = ({data, valueProps}) => {

let ideation = 0;
let customerVal = 0;
let mvp = 0;
let optimize = 0;
let growth = 0;
let scale = 0;

valueProps.forEach(item => 
    item.currentStage === "ideation" ? ideation++
  : item.currentStage === "customerVal" ? customerVal++
  : item.currentStage === "mvp" ? mvp++
  : item.currentStage === "optimize" ? optimize++
  : item.currentStage === "grow" ? growth++
  : item.currentStage === "scale" ? scale++
  : ("")
  );

  return (
    <tr class="btn-reveal-trigger sticky-table">
      <td class="align-top">Value propositions (#)</td>
      <td align={"center"} >{ideation}</td>
      <td align={"center"} >{customerVal}</td>
      <td align={"center"} >{mvp}</td>
      <td align={"center"} >{optimize}</td>
      <td align={"center"} >{growth}</td>
      <td align={"center"} >{scale}</td>
      <td align={"center"} class="align-top bg-200 px-2 border-start">{valueProps.length}</td>
    </tr>
  );
}

export default PipelineShapeInitiativesCard;