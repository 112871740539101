import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';

import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import AdminLayout from "../layouts/Insight.js"
import VPAdminLayout from "../layouts/Monitor.js";
import AuthLayout from "../layouts/Auth.js";
import HomeLayout from "../layouts/Home.js";

const DynamicRouter = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  const oktaAuth = new OktaAuth({
    issuer: 'https://mars-group.okta.com',
    clientId: '0oac71a37jlJvTiop357',
    redirectUri: window.location.origin + '/login/callback',
    scopes:['profile', 'openid', 'groups']
  });

  return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <Route path='/login/callback' component={LoginCallback} />
          <SecureRoute path="/home" render={(props) => <HomeLayout {...props} />} />

          <SecureRoute path="/insight" render={(props) => <AdminLayout {...props} />} sensitive={true} />

          <SecureRoute path="/monitor" render={(props) => <VPAdminLayout {...props} />} />

          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          {/*<Route path="/" render={(props) => <IndexView {...props} />} />
          <SecureRoute path='/protected' component={Protected} />*/}
          <Redirect from="*" to="/home/landing" />
        </Switch>
      </Security>
  );
}

export default DynamicRouter;
