import React from 'react';
import axios from 'axios';
import Iframe from 'react-iframe'

// import { useHistory } from 'react-router-dom';
// import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { LearnHeader } from '../../components/Cards/LearnCards/LearnHeader';
import { Other } from '../../components/Cards/LearnCards/Other';
import { Playbooks } from '../../components/Cards/LearnCards/Playbooks';
import { Tools } from '../../components/Cards/LearnCards/Tools';
import { AddNewPlaybookBar } from '../../components/Cards/VPCards/AddNewPlaybookBar';

import PageTitle from "../../components/header/PageTitle";
import * as api from "./../../components/API/BrandsTableAPI"

//import $scriptjs from 'scriptjs';


const Learn = () => {
  
  const [init, setInit] = React.useState(false)
  const [learnFiles, setLearnFiles] = React.useState([])
  const [allBrands, setAllBrands] = React.useState([])

  // React.componentDidUpdate(() => {
  //   //script loader
  //   setTimeout(function(){
  //     $scriptjs('https://c64.assets-yammer.com/assets/platform_embed.js', function(){
  //     $('#embed-feed').remove();
  //     window.yam.connect.embedFeed(YammerHelper.loadComments(_self.props.myNewsStory.href));
  //     });
  //   }, 1000);
  // });

  //import { useEffect } from 'react';


  React.useEffect(() => {
    
  }, []);

  React.useEffect(() => {
    if(!init) {
      setInit(!init)
      GetLearnFile()
      api.GetManyBrand(setAllBrands);


      const script = document.createElement('script');

      script.src = "https://c64.assets-yammer.com/assets/platform_embed.js"
      script.async = true;

      document.body.appendChild(script);
      console.log(script)

      return () => {
        document.body.removeChild(script);
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);

  

  const GetLearnFile = () => {
    axios({
      url: process.env.REACT_APP_API,
      //url: "https://d2cx.dev/graphql",
      method: "post",
      data: {
        query: `query GetLearnFile{
          GetLearnFile{
            mime
            name
            fileExt
            version
            description
            destination
            brand
            uuid
          }
        }`,
      },
    })
      .then((result) => {
        //console.log(JSON.stringify(result.data.data.GetLearnFile));
        setLearnFiles( [...result.data.data.GetLearnFile] );
      })
      .catch((err) => console.log(err));
  };
  

  return (
    <div class="pt-3">
      <PageTitle title="D2C Accelerator" subtitle="Find the materials you need" />
      <LearnHeader/>
      <AddNewPlaybookBar getLearnFile={GetLearnFile} allBrands={allBrands}/>
      <Playbooks learnFiles={learnFiles}  allBrands={allBrands}/>
      <Tools learnFiles={learnFiles}  allBrands={allBrands}/>
      <Other learnFiles={learnFiles}  allBrands={allBrands}/>

      <div class="card mb-3" id="other">
        <div class="card-header pb-1 bg-light">
          <div class="row col m-0 p-0">
            <span class="fas fa-book-open mr-2"/>
            <h6 class="mb-0">Embedded Yammer Feed</h6>
          </div>
          <div class="row col m-0 p-0">
            <div>
              
            </div>
            <div class="ml-auto">
              <div class="me-2 fs--2" onClick={e => console.log("!hide")} role="button">
                <a href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI5OTcyODEwIn0/all">
                  log in to yammer
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <Iframe 
            url="https://web.yammer.com/embed/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI5OTcyODEwIn0?header=false&footer=true&theme=light&includeFeedInformation=true"
            name="embed-feed"
            title="yammer"
            width="100%"
            height="750px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
          />
        </div>
      </div>
      
    </div>
  );
};

export default Learn;
