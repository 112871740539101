export const countryList = [
  [
    { 
      "Name": "Multi Country", 
      "Code": "MC", 
      "Region": "Multiple" 
    },{
      "Name": "TestCountry",
      "Code": "TC",
      "Region": "Test"
    },
    {
      "Name": "Multi Country",
      "Code": "MC",
      "Region": "Multiple"
    },
    {
    "Name": "Afghanistan",
    "Code": "AF",
    "alpha-3": "AFG",
    "country-code": "004",
    "iso_3166-2": "ISO 3166-2:AF",
    "Region": "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": ""
  }, {
    "Name": "Åland Islands",
    "Code": "AX",
    "alpha-3": "ALA",
    "country-code": "248",
    "iso_3166-2": "ISO 3166-2:AX",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": ""
  }, {
    "Name": "Albania",
    "Code": "AL",
    "alpha-3": "ALB",
    "country-code": "008",
    "iso_3166-2": "ISO 3166-2:AL",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Algeria",
    "Code": "DZ",
    "alpha-3": "DZA",
    "country-code": "012",
    "iso_3166-2": "ISO 3166-2:DZ",
    "Region": "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": ""
  }, {
    "Name": "American Samoa",
    "Code": "AS",
    "alpha-3": "ASM",
    "country-code": "016",
    "iso_3166-2": "ISO 3166-2:AS",
    "Region": "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": ""
  }, {
    "Name": "Andorra",
    "Code": "AD",
    "alpha-3": "AND",
    "country-code": "020",
    "iso_3166-2": "ISO 3166-2:AD",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Angola",
    "Code": "AO",
    "alpha-3": "AGO",
    "country-code": "024",
    "iso_3166-2": "ISO 3166-2:AO",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017"
  }, {
    "Name": "Anguilla",
    "Code": "AI",
    "alpha-3": "AIA",
    "country-code": "660",
    "iso_3166-2": "ISO 3166-2:AI",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Antarctica",
    "Code": "AQ",
    "alpha-3": "ATA",
    "country-code": "010",
    "iso_3166-2": "ISO 3166-2:AQ",
    "Region": "",
    "sub-region": "",
    "intermediate-region": "",
    "region-code": "",
    "sub-region-code": "",
    "intermediate-region-code": ""
  }, {
    "Name": "Antigua and Barbuda",
    "Code": "AG",
    "alpha-3": "ATG",
    "country-code": "028",
    "iso_3166-2": "ISO 3166-2:AG",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Argentina",
    "Code": "AR",
    "alpha-3": "ARG",
    "country-code": "032",
    "iso_3166-2": "ISO 3166-2:AR",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "Armenia",
    "Code": "AM",
    "alpha-3": "ARM",
    "country-code": "051",
    "iso_3166-2": "ISO 3166-2:AM",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Aruba",
    "Code": "AW",
    "alpha-3": "ABW",
    "country-code": "533",
    "iso_3166-2": "ISO 3166-2:AW",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Australia",
    "Code": "AU",
    "alpha-3": "AUS",
    "country-code": "036",
    "iso_3166-2": "ISO 3166-2:AU",
    "Region": "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": ""
  }, {
    "Name": "Austria",
    "Code": "AT",
    "alpha-3": "AUT",
    "country-code": "040",
    "iso_3166-2": "ISO 3166-2:AT",
    "Region": "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": ""
  }, {
    "Name": "Azerbaijan",
    "Code": "AZ",
    "alpha-3": "AZE",
    "country-code": "031",
    "iso_3166-2": "ISO 3166-2:AZ",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Bahamas",
    "Code": "BS",
    "alpha-3": "BHS",
    "country-code": "044",
    "iso_3166-2": "ISO 3166-2:BS",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Bahrain",
    "Code": "BH",
    "alpha-3": "BHR",
    "country-code": "048",
    "iso_3166-2": "ISO 3166-2:BH",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Bangladesh",
    "Code": "BD",
    "alpha-3": "BGD",
    "country-code": "050",
    "iso_3166-2": "ISO 3166-2:BD",
    "Region": "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": ""
  }, {
    "Name": "Barbados",
    "Code": "BB",
    "alpha-3": "BRB",
    "country-code": "052",
    "iso_3166-2": "ISO 3166-2:BB",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Belarus",
    "Code": "BY",
    "alpha-3": "BLR",
    "country-code": "112",
    "iso_3166-2": "ISO 3166-2:BY",
    "Region": "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": ""
  }, {
    "Name": "Belgium",
    "Code": "BE",
    "alpha-3": "BEL",
    "country-code": "056",
    "iso_3166-2": "ISO 3166-2:BE",
    "Region": "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": ""
  }, {
    "Name": "Belize",
    "Code": "BZ",
    "alpha-3": "BLZ",
    "country-code": "084",
    "iso_3166-2": "ISO 3166-2:BZ",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013"
  }, {
    "Name": "Benin",
    "Code": "BJ",
    "alpha-3": "BEN",
    "country-code": "204",
    "iso_3166-2": "ISO 3166-2:BJ",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Bermuda",
    "Code": "BM",
    "alpha-3": "BMU",
    "country-code": "060",
    "iso_3166-2": "ISO 3166-2:BM",
    "Region": "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": ""
  }, {
    "Name": "Bhutan",
    "Code": "BT",
    "alpha-3": "BTN",
    "country-code": "064",
    "iso_3166-2": "ISO 3166-2:BT",
    "Region": "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": ""
  }, {
    "Name": "Bolivia (Plurinational State of)",
    "Code": "BO",
    "alpha-3": "BOL",
    "country-code": "068",
    "iso_3166-2": "ISO 3166-2:BO",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "Bonaire, Sint Eustatius and Saba",
    "Code": "BQ",
    "alpha-3": "BES",
    "country-code": "535",
    "iso_3166-2": "ISO 3166-2:BQ",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Bosnia and Herzegovina",
    "Code": "BA",
    "alpha-3": "BIH",
    "country-code": "070",
    "iso_3166-2": "ISO 3166-2:BA",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Botswana",
    "Code": "BW",
    "alpha-3": "BWA",
    "country-code": "072",
    "iso_3166-2": "ISO 3166-2:BW",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018"
  }, {
    "Name": "Bouvet Island",
    "Code": "BV",
    "alpha-3": "BVT",
    "country-code": "074",
    "iso_3166-2": "ISO 3166-2:BV",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "Brazil",
    "Code": "BR",
    "alpha-3": "BRA",
    "country-code": "076",
    "iso_3166-2": "ISO 3166-2:BR",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "British Indian Ocean Territory",
    "Code": "IO",
    "alpha-3": "IOT",
    "country-code": "086",
    "iso_3166-2": "ISO 3166-2:IO",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Brunei Darussalam",
    "Code": "BN",
    "alpha-3": "BRN",
    "country-code": "096",
    "iso_3166-2": "ISO 3166-2:BN",
    "Region": "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": ""
  }, {
    "Name": "Bulgaria",
    "Code": "BG",
    "alpha-3": "BGR",
    "country-code": "100",
    "iso_3166-2": "ISO 3166-2:BG",
    "Region": "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": ""
  }, {
    "Name": "Burkina Faso",
    "Code": "BF",
    "alpha-3": "BFA",
    "country-code": "854",
    "iso_3166-2": "ISO 3166-2:BF",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Burundi",
    "Code": "BI",
    "alpha-3": "BDI",
    "country-code": "108",
    "iso_3166-2": "ISO 3166-2:BI",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Cabo Verde",
    "Code": "CV",
    "alpha-3": "CPV",
    "country-code": "132",
    "iso_3166-2": "ISO 3166-2:CV",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Cambodia",
    "Code": "KH",
    "alpha-3": "KHM",
    "country-code": "116",
    "iso_3166-2": "ISO 3166-2:KH",
    "Region": "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": ""
  }, {
    "Name": "Cameroon",
    "Code": "CM",
    "alpha-3": "CMR",
    "country-code": "120",
    "iso_3166-2": "ISO 3166-2:CM",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017"
  }, {
    "Name": "Canada",
    "Code": "CA",
    "alpha-3": "CAN",
    "country-code": "124",
    "iso_3166-2": "ISO 3166-2:CA",
    "Region": "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": ""
  }, {
    "Name": "Cayman Islands",
    "Code": "KY",
    "alpha-3": "CYM",
    "country-code": "136",
    "iso_3166-2": "ISO 3166-2:KY",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Central African Republic",
    "Code": "CF",
    "alpha-3": "CAF",
    "country-code": "140",
    "iso_3166-2": "ISO 3166-2:CF",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017"
  }, {
    "Name": "Chad",
    "Code": "TD",
    "alpha-3": "TCD",
    "country-code": "148",
    "iso_3166-2": "ISO 3166-2:TD",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017"
  }, {
    "Name": "Chile",
    "Code": "CL",
    "alpha-3": "CHL",
    "country-code": "152",
    "iso_3166-2": "ISO 3166-2:CL",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "China",
    "Code": "CN",
    "alpha-3": "CHN",
    "country-code": "156",
    "iso_3166-2": "ISO 3166-2:CN",
    "Region": "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": ""
  }, {
    "Name": "Christmas Island",
    "Code": "CX",
    "alpha-3": "CXR",
    "country-code": "162",
    "iso_3166-2": "ISO 3166-2:CX",
    "Region": "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": ""
  }, {
    "Name": "Cocos (Keeling) Islands",
    "Code": "CC",
    "alpha-3": "CCK",
    "country-code": "166",
    "iso_3166-2": "ISO 3166-2:CC",
    "Region": "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": ""
  }, {
    "Name": "Colombia",
    "Code": "CO",
    "alpha-3": "COL",
    "country-code": "170",
    "iso_3166-2": "ISO 3166-2:CO",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "Comoros",
    "Code": "KM",
    "alpha-3": "COM",
    "country-code": "174",
    "iso_3166-2": "ISO 3166-2:KM",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Congo",
    "Code": "CG",
    "alpha-3": "COG",
    "country-code": "178",
    "iso_3166-2": "ISO 3166-2:CG",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017"
  }, {
    "Name": "Congo, Democratic Republic of the",
    "Code": "CD",
    "alpha-3": "COD",
    "country-code": "180",
    "iso_3166-2": "ISO 3166-2:CD",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017"
  }, {
    "Name": "Cook Islands",
    "Code": "CK",
    "alpha-3": "COK",
    "country-code": "184",
    "iso_3166-2": "ISO 3166-2:CK",
    "Region": "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": ""
  }, {
    "Name": "Costa Rica",
    "Code": "CR",
    "alpha-3": "CRI",
    "country-code": "188",
    "iso_3166-2": "ISO 3166-2:CR",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013"
  }, {
    "Name": "Côte d'Ivoire",
    "Code": "CI",
    "alpha-3": "CIV",
    "country-code": "384",
    "iso_3166-2": "ISO 3166-2:CI",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Croatia",
    "Code": "HR",
    "alpha-3": "HRV",
    "country-code": "191",
    "iso_3166-2": "ISO 3166-2:HR",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Cuba",
    "Code": "CU",
    "alpha-3": "CUB",
    "country-code": "192",
    "iso_3166-2": "ISO 3166-2:CU",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Curaçao",
    "Code": "CW",
    "alpha-3": "CUW",
    "country-code": "531",
    "iso_3166-2": "ISO 3166-2:CW",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Cyprus",
    "Code": "CY",
    "alpha-3": "CYP",
    "country-code": "196",
    "iso_3166-2": "ISO 3166-2:CY",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Czechia",
    "Code": "CZ",
    "alpha-3": "CZE",
    "country-code": "203",
    "iso_3166-2": "ISO 3166-2:CZ",
    "Region": "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": ""
  }, {
    "Name": "Denmark",
    "Code": "DK",
    "alpha-3": "DNK",
    "country-code": "208",
    "iso_3166-2": "ISO 3166-2:DK",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": ""
  }, {
    "Name": "Djibouti",
    "Code": "DJ",
    "alpha-3": "DJI",
    "country-code": "262",
    "iso_3166-2": "ISO 3166-2:DJ",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Dominica",
    "Code": "DM",
    "alpha-3": "DMA",
    "country-code": "212",
    "iso_3166-2": "ISO 3166-2:DM",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Dominican Republic",
    "Code": "DO",
    "alpha-3": "DOM",
    "country-code": "214",
    "iso_3166-2": "ISO 3166-2:DO",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Ecuador",
    "Code": "EC",
    "alpha-3": "ECU",
    "country-code": "218",
    "iso_3166-2": "ISO 3166-2:EC",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "Egypt",
    "Code": "EG",
    "alpha-3": "EGY",
    "country-code": "818",
    "iso_3166-2": "ISO 3166-2:EG",
    "Region": "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": ""
  }, {
    "Name": "El Salvador",
    "Code": "SV",
    "alpha-3": "SLV",
    "country-code": "222",
    "iso_3166-2": "ISO 3166-2:SV",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013"
  }, {
    "Name": "Equatorial Guinea",
    "Code": "GQ",
    "alpha-3": "GNQ",
    "country-code": "226",
    "iso_3166-2": "ISO 3166-2:GQ",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017"
  }, {
    "Name": "Eritrea",
    "Code": "ER",
    "alpha-3": "ERI",
    "country-code": "232",
    "iso_3166-2": "ISO 3166-2:ER",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Estonia",
    "Code": "EE",
    "alpha-3": "EST",
    "country-code": "233",
    "iso_3166-2": "ISO 3166-2:EE",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": ""
  }, {
    "Name": "Eswatini",
    "Code": "SZ",
    "alpha-3": "SWZ",
    "country-code": "748",
    "iso_3166-2": "ISO 3166-2:SZ",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018"
  }, {
    "Name": "Ethiopia",
    "Code": "ET",
    "alpha-3": "ETH",
    "country-code": "231",
    "iso_3166-2": "ISO 3166-2:ET",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Falkland Islands (Malvinas)",
    "Code": "FK",
    "alpha-3": "FLK",
    "country-code": "238",
    "iso_3166-2": "ISO 3166-2:FK",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "Faroe Islands",
    "Code": "FO",
    "alpha-3": "FRO",
    "country-code": "234",
    "iso_3166-2": "ISO 3166-2:FO",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": ""
  }, {
    "Name": "Fiji",
    "Code": "FJ",
    "alpha-3": "FJI",
    "country-code": "242",
    "iso_3166-2": "ISO 3166-2:FJ",
    "Region": "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": ""
  }, {
    "Name": "Finland",
    "Code": "FI",
    "alpha-3": "FIN",
    "country-code": "246",
    "iso_3166-2": "ISO 3166-2:FI",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": ""
  }, {
    "Name": "France",
    "Code": "FR",
    "alpha-3": "FRA",
    "country-code": "250",
    "iso_3166-2": "ISO 3166-2:FR",
    "Region": "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": ""
  }, {
    "Name": "French Guiana",
    "Code": "GF",
    "alpha-3": "GUF",
    "country-code": "254",
    "iso_3166-2": "ISO 3166-2:GF",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "French Polynesia",
    "Code": "PF",
    "alpha-3": "PYF",
    "country-code": "258",
    "iso_3166-2": "ISO 3166-2:PF",
    "Region": "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": ""
  }, {
    "Name": "French Southern Territories",
    "Code": "TF",
    "alpha-3": "ATF",
    "country-code": "260",
    "iso_3166-2": "ISO 3166-2:TF",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Gabon",
    "Code": "GA",
    "alpha-3": "GAB",
    "country-code": "266",
    "iso_3166-2": "ISO 3166-2:GA",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017"
  }, {
    "Name": "Gambia",
    "Code": "GM",
    "alpha-3": "GMB",
    "country-code": "270",
    "iso_3166-2": "ISO 3166-2:GM",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Georgia",
    "Code": "GE",
    "alpha-3": "GEO",
    "country-code": "268",
    "iso_3166-2": "ISO 3166-2:GE",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Germany",
    "Code": "DE",
    "alpha-3": "DEU",
    "country-code": "276",
    "iso_3166-2": "ISO 3166-2:DE",
    "Region": "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": ""
  }, {
    "Name": "Ghana",
    "Code": "GH",
    "alpha-3": "GHA",
    "country-code": "288",
    "iso_3166-2": "ISO 3166-2:GH",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Gibraltar",
    "Code": "GI",
    "alpha-3": "GIB",
    "country-code": "292",
    "iso_3166-2": "ISO 3166-2:GI",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Greece",
    "Code": "GR",
    "alpha-3": "GRC",
    "country-code": "300",
    "iso_3166-2": "ISO 3166-2:GR",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Greenland",
    "Code": "GL",
    "alpha-3": "GRL",
    "country-code": "304",
    "iso_3166-2": "ISO 3166-2:GL",
    "Region": "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": ""
  }, {
    "Name": "Grenada",
    "Code": "GD",
    "alpha-3": "GRD",
    "country-code": "308",
    "iso_3166-2": "ISO 3166-2:GD",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Guadeloupe",
    "Code": "GP",
    "alpha-3": "GLP",
    "country-code": "312",
    "iso_3166-2": "ISO 3166-2:GP",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Guam",
    "Code": "GU",
    "alpha-3": "GUM",
    "country-code": "316",
    "iso_3166-2": "ISO 3166-2:GU",
    "Region": "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": ""
  }, {
    "Name": "Guatemala",
    "Code": "GT",
    "alpha-3": "GTM",
    "country-code": "320",
    "iso_3166-2": "ISO 3166-2:GT",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013"
  }, {
    "Name": "Guernsey",
    "Code": "GG",
    "alpha-3": "GGY",
    "country-code": "831",
    "iso_3166-2": "ISO 3166-2:GG",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "Channel Islands",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "830"
  }, {
    "Name": "Guinea",
    "Code": "GN",
    "alpha-3": "GIN",
    "country-code": "324",
    "iso_3166-2": "ISO 3166-2:GN",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Guinea-Bissau",
    "Code": "GW",
    "alpha-3": "GNB",
    "country-code": "624",
    "iso_3166-2": "ISO 3166-2:GW",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Guyana",
    "Code": "GY",
    "alpha-3": "GUY",
    "country-code": "328",
    "iso_3166-2": "ISO 3166-2:GY",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "Haiti",
    "Code": "HT",
    "alpha-3": "HTI",
    "country-code": "332",
    "iso_3166-2": "ISO 3166-2:HT",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Heard Island and McDonald Islands",
    "Code": "HM",
    "alpha-3": "HMD",
    "country-code": "334",
    "iso_3166-2": "ISO 3166-2:HM",
    "Region": "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": ""
  }, {
    "Name": "Holy See",
    "Code": "VA",
    "alpha-3": "VAT",
    "country-code": "336",
    "iso_3166-2": "ISO 3166-2:VA",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Honduras",
    "Code": "HN",
    "alpha-3": "HND",
    "country-code": "340",
    "iso_3166-2": "ISO 3166-2:HN",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013"
  }, {
    "Name": "Hong Kong",
    "Code": "HK",
    "alpha-3": "HKG",
    "country-code": "344",
    "iso_3166-2": "ISO 3166-2:HK",
    "Region": "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": ""
  }, {
    "Name": "Hungary",
    "Code": "HU",
    "alpha-3": "HUN",
    "country-code": "348",
    "iso_3166-2": "ISO 3166-2:HU",
    "Region": "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": ""
  }, {
    "Name": "Iceland",
    "Code": "IS",
    "alpha-3": "ISL",
    "country-code": "352",
    "iso_3166-2": "ISO 3166-2:IS",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": ""
  }, {
    "Name": "India",
    "Code": "IN",
    "alpha-3": "IND",
    "country-code": "356",
    "iso_3166-2": "ISO 3166-2:IN",
    "Region": "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": ""
  }, {
    "Name": "Indonesia",
    "Code": "ID",
    "alpha-3": "IDN",
    "country-code": "360",
    "iso_3166-2": "ISO 3166-2:ID",
    "Region": "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": ""
  }, {
    "Name": "Iran (Islamic Republic of)",
    "Code": "IR",
    "alpha-3": "IRN",
    "country-code": "364",
    "iso_3166-2": "ISO 3166-2:IR",
    "Region": "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": ""
  }, {
    "Name": "Iraq",
    "Code": "IQ",
    "alpha-3": "IRQ",
    "country-code": "368",
    "iso_3166-2": "ISO 3166-2:IQ",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Ireland",
    "Code": "IE",
    "alpha-3": "IRL",
    "country-code": "372",
    "iso_3166-2": "ISO 3166-2:IE",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": ""
  }, {
    "Name": "Isle of Man",
    "Code": "IM",
    "alpha-3": "IMN",
    "country-code": "833",
    "iso_3166-2": "ISO 3166-2:IM",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": ""
  }, {
    "Name": "Israel",
    "Code": "IL",
    "alpha-3": "ISR",
    "country-code": "376",
    "iso_3166-2": "ISO 3166-2:IL",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Italy",
    "Code": "IT",
    "alpha-3": "ITA",
    "country-code": "380",
    "iso_3166-2": "ISO 3166-2:IT",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Jamaica",
    "Code": "JM",
    "alpha-3": "JAM",
    "country-code": "388",
    "iso_3166-2": "ISO 3166-2:JM",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Japan",
    "Code": "JP",
    "alpha-3": "JPN",
    "country-code": "392",
    "iso_3166-2": "ISO 3166-2:JP",
    "Region": "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": ""
  }, {
    "Name": "Jersey",
    "Code": "JE",
    "alpha-3": "JEY",
    "country-code": "832",
    "iso_3166-2": "ISO 3166-2:JE",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "Channel Islands",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": "830"
  }, {
    "Name": "Jordan",
    "Code": "JO",
    "alpha-3": "JOR",
    "country-code": "400",
    "iso_3166-2": "ISO 3166-2:JO",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Kazakhstan",
    "Code": "KZ",
    "alpha-3": "KAZ",
    "country-code": "398",
    "iso_3166-2": "ISO 3166-2:KZ",
    "Region": "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": ""
  }, {
    "Name": "Kenya",
    "Code": "KE",
    "alpha-3": "KEN",
    "country-code": "404",
    "iso_3166-2": "ISO 3166-2:KE",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Kiribati",
    "Code": "KI",
    "alpha-3": "KIR",
    "country-code": "296",
    "iso_3166-2": "ISO 3166-2:KI",
    "Region": "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": ""
  }, {
    "Name": "Korea (Democratic People's Republic of)",
    "Code": "KP",
    "alpha-3": "PRK",
    "country-code": "408",
    "iso_3166-2": "ISO 3166-2:KP",
    "Region": "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": ""
  }, {
    "Name": "Korea, Republic of",
    "Code": "KR",
    "alpha-3": "KOR",
    "country-code": "410",
    "iso_3166-2": "ISO 3166-2:KR",
    "Region": "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": ""
  }, {
    "Name": "Kuwait",
    "Code": "KW",
    "alpha-3": "KWT",
    "country-code": "414",
    "iso_3166-2": "ISO 3166-2:KW",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Kyrgyzstan",
    "Code": "KG",
    "alpha-3": "KGZ",
    "country-code": "417",
    "iso_3166-2": "ISO 3166-2:KG",
    "Region": "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": ""
  }, {
    "Name": "Lao People's Democratic Republic",
    "Code": "LA",
    "alpha-3": "LAO",
    "country-code": "418",
    "iso_3166-2": "ISO 3166-2:LA",
    "Region": "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": ""
  }, {
    "Name": "Latvia",
    "Code": "LV",
    "alpha-3": "LVA",
    "country-code": "428",
    "iso_3166-2": "ISO 3166-2:LV",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": ""
  }, {
    "Name": "Lebanon",
    "Code": "LB",
    "alpha-3": "LBN",
    "country-code": "422",
    "iso_3166-2": "ISO 3166-2:LB",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Lesotho",
    "Code": "LS",
    "alpha-3": "LSO",
    "country-code": "426",
    "iso_3166-2": "ISO 3166-2:LS",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018"
  }, {
    "Name": "Liberia",
    "Code": "LR",
    "alpha-3": "LBR",
    "country-code": "430",
    "iso_3166-2": "ISO 3166-2:LR",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Libya",
    "Code": "LY",
    "alpha-3": "LBY",
    "country-code": "434",
    "iso_3166-2": "ISO 3166-2:LY",
    "Region": "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": ""
  }, {
    "Name": "Liechtenstein",
    "Code": "LI",
    "alpha-3": "LIE",
    "country-code": "438",
    "iso_3166-2": "ISO 3166-2:LI",
    "Region": "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": ""
  }, {
    "Name": "Lithuania",
    "Code": "LT",
    "alpha-3": "LTU",
    "country-code": "440",
    "iso_3166-2": "ISO 3166-2:LT",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": ""
  }, {
    "Name": "Luxembourg",
    "Code": "LU",
    "alpha-3": "LUX",
    "country-code": "442",
    "iso_3166-2": "ISO 3166-2:LU",
    "Region": "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": ""
  }, {
    "Name": "Macao",
    "Code": "MO",
    "alpha-3": "MAC",
    "country-code": "446",
    "iso_3166-2": "ISO 3166-2:MO",
    "Region": "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": ""
  }, {
    "Name": "Madagascar",
    "Code": "MG",
    "alpha-3": "MDG",
    "country-code": "450",
    "iso_3166-2": "ISO 3166-2:MG",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Malawi",
    "Code": "MW",
    "alpha-3": "MWI",
    "country-code": "454",
    "iso_3166-2": "ISO 3166-2:MW",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Malaysia",
    "Code": "MY",
    "alpha-3": "MYS",
    "country-code": "458",
    "iso_3166-2": "ISO 3166-2:MY",
    "Region": "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": ""
  }, {
    "Name": "Maldives",
    "Code": "MV",
    "alpha-3": "MDV",
    "country-code": "462",
    "iso_3166-2": "ISO 3166-2:MV",
    "Region": "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": ""
  }, {
    "Name": "Mali",
    "Code": "ML",
    "alpha-3": "MLI",
    "country-code": "466",
    "iso_3166-2": "ISO 3166-2:ML",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Malta",
    "Code": "MT",
    "alpha-3": "MLT",
    "country-code": "470",
    "iso_3166-2": "ISO 3166-2:MT",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Marshall Islands",
    "Code": "MH",
    "alpha-3": "MHL",
    "country-code": "584",
    "iso_3166-2": "ISO 3166-2:MH",
    "Region": "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": ""
  }, {
    "Name": "Martinique",
    "Code": "MQ",
    "alpha-3": "MTQ",
    "country-code": "474",
    "iso_3166-2": "ISO 3166-2:MQ",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Mauritania",
    "Code": "MR",
    "alpha-3": "MRT",
    "country-code": "478",
    "iso_3166-2": "ISO 3166-2:MR",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Mauritius",
    "Code": "MU",
    "alpha-3": "MUS",
    "country-code": "480",
    "iso_3166-2": "ISO 3166-2:MU",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Mayotte",
    "Code": "YT",
    "alpha-3": "MYT",
    "country-code": "175",
    "iso_3166-2": "ISO 3166-2:YT",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Mexico",
    "Code": "MX",
    "alpha-3": "MEX",
    "country-code": "484",
    "iso_3166-2": "ISO 3166-2:MX",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013"
  }, {
    "Name": "Micronesia (Federated States of)",
    "Code": "FM",
    "alpha-3": "FSM",
    "country-code": "583",
    "iso_3166-2": "ISO 3166-2:FM",
    "Region": "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": ""
  }, {
    "Name": "Moldova, Republic of",
    "Code": "MD",
    "alpha-3": "MDA",
    "country-code": "498",
    "iso_3166-2": "ISO 3166-2:MD",
    "Region": "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": ""
  }, {
    "Name": "Monaco",
    "Code": "MC",
    "alpha-3": "MCO",
    "country-code": "492",
    "iso_3166-2": "ISO 3166-2:MC",
    "Region": "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": ""
  }, {
    "Name": "Mongolia",
    "Code": "MN",
    "alpha-3": "MNG",
    "country-code": "496",
    "iso_3166-2": "ISO 3166-2:MN",
    "Region": "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": ""
  }, {
    "Name": "Montenegro",
    "Code": "ME",
    "alpha-3": "MNE",
    "country-code": "499",
    "iso_3166-2": "ISO 3166-2:ME",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Montserrat",
    "Code": "MS",
    "alpha-3": "MSR",
    "country-code": "500",
    "iso_3166-2": "ISO 3166-2:MS",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Morocco",
    "Code": "MA",
    "alpha-3": "MAR",
    "country-code": "504",
    "iso_3166-2": "ISO 3166-2:MA",
    "Region": "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": ""
  }, {
    "Name": "Mozambique",
    "Code": "MZ",
    "alpha-3": "MOZ",
    "country-code": "508",
    "iso_3166-2": "ISO 3166-2:MZ",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Myanmar",
    "Code": "MM",
    "alpha-3": "MMR",
    "country-code": "104",
    "iso_3166-2": "ISO 3166-2:MM",
    "Region": "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": ""
  }, {
    "Name": "Namibia",
    "Code": "NA",
    "alpha-3": "NAM",
    "country-code": "516",
    "iso_3166-2": "ISO 3166-2:NA",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018"
  }, {
    "Name": "Nauru",
    "Code": "NR",
    "alpha-3": "NRU",
    "country-code": "520",
    "iso_3166-2": "ISO 3166-2:NR",
    "Region": "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": ""
  }, {
    "Name": "Nepal",
    "Code": "NP",
    "alpha-3": "NPL",
    "country-code": "524",
    "iso_3166-2": "ISO 3166-2:NP",
    "Region": "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": ""
  }, {
    "Name": "Netherlands",
    "Code": "NL",
    "alpha-3": "NLD",
    "country-code": "528",
    "iso_3166-2": "ISO 3166-2:NL",
    "Region": "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": ""
  }, {
    "Name": "New Caledonia",
    "Code": "NC",
    "alpha-3": "NCL",
    "country-code": "540",
    "iso_3166-2": "ISO 3166-2:NC",
    "Region": "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": ""
  }, {
    "Name": "New Zealand",
    "Code": "NZ",
    "alpha-3": "NZL",
    "country-code": "554",
    "iso_3166-2": "ISO 3166-2:NZ",
    "Region": "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": ""
  }, {
    "Name": "Nicaragua",
    "Code": "NI",
    "alpha-3": "NIC",
    "country-code": "558",
    "iso_3166-2": "ISO 3166-2:NI",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013"
  }, {
    "Name": "Niger",
    "Code": "NE",
    "alpha-3": "NER",
    "country-code": "562",
    "iso_3166-2": "ISO 3166-2:NE",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Nigeria",
    "Code": "NG",
    "alpha-3": "NGA",
    "country-code": "566",
    "iso_3166-2": "ISO 3166-2:NG",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Niue",
    "Code": "NU",
    "alpha-3": "NIU",
    "country-code": "570",
    "iso_3166-2": "ISO 3166-2:NU",
    "Region": "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": ""
  }, {
    "Name": "Norfolk Island",
    "Code": "NF",
    "alpha-3": "NFK",
    "country-code": "574",
    "iso_3166-2": "ISO 3166-2:NF",
    "Region": "Oceania",
    "sub-region": "Australia and New Zealand",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "053",
    "intermediate-region-code": ""
  }, {
    "Name": "North Macedonia",
    "Code": "MK",
    "alpha-3": "MKD",
    "country-code": "807",
    "iso_3166-2": "ISO 3166-2:MK",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Northern Mariana Islands",
    "Code": "MP",
    "alpha-3": "MNP",
    "country-code": "580",
    "iso_3166-2": "ISO 3166-2:MP",
    "Region": "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": ""
  }, {
    "Name": "Norway",
    "Code": "NO",
    "alpha-3": "NOR",
    "country-code": "578",
    "iso_3166-2": "ISO 3166-2:NO",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": ""
  }, {
    "Name": "Oman",
    "Code": "OM",
    "alpha-3": "OMN",
    "country-code": "512",
    "iso_3166-2": "ISO 3166-2:OM",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Pakistan",
    "Code": "PK",
    "alpha-3": "PAK",
    "country-code": "586",
    "iso_3166-2": "ISO 3166-2:PK",
    "Region": "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": ""
  }, {
    "Name": "Palau",
    "Code": "PW",
    "alpha-3": "PLW",
    "country-code": "585",
    "iso_3166-2": "ISO 3166-2:PW",
    "Region": "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": ""
  }, {
    "Name": "Palestine, State of",
    "Code": "PS",
    "alpha-3": "PSE",
    "country-code": "275",
    "iso_3166-2": "ISO 3166-2:PS",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Panama",
    "Code": "PA",
    "alpha-3": "PAN",
    "country-code": "591",
    "iso_3166-2": "ISO 3166-2:PA",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Central America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "013"
  }, {
    "Name": "Papua New Guinea",
    "Code": "PG",
    "alpha-3": "PNG",
    "country-code": "598",
    "iso_3166-2": "ISO 3166-2:PG",
    "Region": "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": ""
  }, {
    "Name": "Paraguay",
    "Code": "PY",
    "alpha-3": "PRY",
    "country-code": "600",
    "iso_3166-2": "ISO 3166-2:PY",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "Peru",
    "Code": "PE",
    "alpha-3": "PER",
    "country-code": "604",
    "iso_3166-2": "ISO 3166-2:PE",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "Philippines",
    "Code": "PH",
    "alpha-3": "PHL",
    "country-code": "608",
    "iso_3166-2": "ISO 3166-2:PH",
    "Region": "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": ""
  }, {
    "Name": "Pitcairn",
    "Code": "PN",
    "alpha-3": "PCN",
    "country-code": "612",
    "iso_3166-2": "ISO 3166-2:PN",
    "Region": "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": ""
  }, {
    "Name": "Poland",
    "Code": "PL",
    "alpha-3": "POL",
    "country-code": "616",
    "iso_3166-2": "ISO 3166-2:PL",
    "Region": "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": ""
  }, {
    "Name": "Portugal",
    "Code": "PT",
    "alpha-3": "PRT",
    "country-code": "620",
    "iso_3166-2": "ISO 3166-2:PT",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Puerto Rico",
    "Code": "PR",
    "alpha-3": "PRI",
    "country-code": "630",
    "iso_3166-2": "ISO 3166-2:PR",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Qatar",
    "Code": "QA",
    "alpha-3": "QAT",
    "country-code": "634",
    "iso_3166-2": "ISO 3166-2:QA",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Réunion",
    "Code": "RE",
    "alpha-3": "REU",
    "country-code": "638",
    "iso_3166-2": "ISO 3166-2:RE",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Romania",
    "Code": "RO",
    "alpha-3": "ROU",
    "country-code": "642",
    "iso_3166-2": "ISO 3166-2:RO",
    "Region": "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": ""
  }, {
    "Name": "Russian Federation",
    "Code": "RU",
    "alpha-3": "RUS",
    "country-code": "643",
    "iso_3166-2": "ISO 3166-2:RU",
    "Region": "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": ""
  }, {
    "Name": "Rwanda",
    "Code": "RW",
    "alpha-3": "RWA",
    "country-code": "646",
    "iso_3166-2": "ISO 3166-2:RW",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Saint Barthélemy",
    "Code": "BL",
    "alpha-3": "BLM",
    "country-code": "652",
    "iso_3166-2": "ISO 3166-2:BL",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Saint Helena, Ascension and Tristan da Cunha",
    "Code": "SH",
    "alpha-3": "SHN",
    "country-code": "654",
    "iso_3166-2": "ISO 3166-2:SH",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Saint Kitts and Nevis",
    "Code": "KN",
    "alpha-3": "KNA",
    "country-code": "659",
    "iso_3166-2": "ISO 3166-2:KN",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Saint Lucia",
    "Code": "LC",
    "alpha-3": "LCA",
    "country-code": "662",
    "iso_3166-2": "ISO 3166-2:LC",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Saint Martin (French part)",
    "Code": "MF",
    "alpha-3": "MAF",
    "country-code": "663",
    "iso_3166-2": "ISO 3166-2:MF",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Saint Pierre and Miquelon",
    "Code": "PM",
    "alpha-3": "SPM",
    "country-code": "666",
    "iso_3166-2": "ISO 3166-2:PM",
    "Region": "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": ""
  }, {
    "Name": "Saint Vincent and the Grenadines",
    "Code": "VC",
    "alpha-3": "VCT",
    "country-code": "670",
    "iso_3166-2": "ISO 3166-2:VC",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Samoa",
    "Code": "WS",
    "alpha-3": "WSM",
    "country-code": "882",
    "iso_3166-2": "ISO 3166-2:WS",
    "Region": "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": ""
  }, {
    "Name": "San Marino",
    "Code": "SM",
    "alpha-3": "SMR",
    "country-code": "674",
    "iso_3166-2": "ISO 3166-2:SM",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Sao Tome and Principe",
    "Code": "ST",
    "alpha-3": "STP",
    "country-code": "678",
    "iso_3166-2": "ISO 3166-2:ST",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Middle Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "017"
  }, {
    "Name": "Saudi Arabia",
    "Code": "SA",
    "alpha-3": "SAU",
    "country-code": "682",
    "iso_3166-2": "ISO 3166-2:SA",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Senegal",
    "Code": "SN",
    "alpha-3": "SEN",
    "country-code": "686",
    "iso_3166-2": "ISO 3166-2:SN",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Serbia",
    "Code": "RS",
    "alpha-3": "SRB",
    "country-code": "688",
    "iso_3166-2": "ISO 3166-2:RS",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Seychelles",
    "Code": "SC",
    "alpha-3": "SYC",
    "country-code": "690",
    "iso_3166-2": "ISO 3166-2:SC",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Sierra Leone",
    "Code": "SL",
    "alpha-3": "SLE",
    "country-code": "694",
    "iso_3166-2": "ISO 3166-2:SL",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Singapore",
    "Code": "SG",
    "alpha-3": "SGP",
    "country-code": "702",
    "iso_3166-2": "ISO 3166-2:SG",
    "Region": "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": ""
  }, {
    "Name": "Sint Maarten (Dutch part)",
    "Code": "SX",
    "alpha-3": "SXM",
    "country-code": "534",
    "iso_3166-2": "ISO 3166-2:SX",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Slovakia",
    "Code": "SK",
    "alpha-3": "SVK",
    "country-code": "703",
    "iso_3166-2": "ISO 3166-2:SK",
    "Region": "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": ""
  }, {
    "Name": "Slovenia",
    "Code": "SI",
    "alpha-3": "SVN",
    "country-code": "705",
    "iso_3166-2": "ISO 3166-2:SI",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Solomon Islands",
    "Code": "SB",
    "alpha-3": "SLB",
    "country-code": "090",
    "iso_3166-2": "ISO 3166-2:SB",
    "Region": "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": ""
  }, {
    "Name": "Somalia",
    "Code": "SO",
    "alpha-3": "SOM",
    "country-code": "706",
    "iso_3166-2": "ISO 3166-2:SO",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "South Africa",
    "Code": "ZA",
    "alpha-3": "ZAF",
    "country-code": "710",
    "iso_3166-2": "ISO 3166-2:ZA",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Southern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "018"
  }, {
    "Name": "South Georgia and the South Sandwich Islands",
    "Code": "GS",
    "alpha-3": "SGS",
    "country-code": "239",
    "iso_3166-2": "ISO 3166-2:GS",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "South Sudan",
    "Code": "SS",
    "alpha-3": "SSD",
    "country-code": "728",
    "iso_3166-2": "ISO 3166-2:SS",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Spain",
    "Code": "ES",
    "alpha-3": "ESP",
    "country-code": "724",
    "iso_3166-2": "ISO 3166-2:ES",
    "Region": "Europe",
    "sub-region": "Southern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "039",
    "intermediate-region-code": ""
  }, {
    "Name": "Sri Lanka",
    "Code": "LK",
    "alpha-3": "LKA",
    "country-code": "144",
    "iso_3166-2": "ISO 3166-2:LK",
    "Region": "Asia",
    "sub-region": "Southern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "034",
    "intermediate-region-code": ""
  }, {
    "Name": "Sudan",
    "Code": "SD",
    "alpha-3": "SDN",
    "country-code": "729",
    "iso_3166-2": "ISO 3166-2:SD",
    "Region": "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": ""
  }, {
    "Name": "Suriname",
    "Code": "SR",
    "alpha-3": "SUR",
    "country-code": "740",
    "iso_3166-2": "ISO 3166-2:SR",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "Svalbard and Jan Mayen",
    "Code": "SJ",
    "alpha-3": "SJM",
    "country-code": "744",
    "iso_3166-2": "ISO 3166-2:SJ",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": ""
  }, {
    "Name": "Sweden",
    "Code": "SE",
    "alpha-3": "SWE",
    "country-code": "752",
    "iso_3166-2": "ISO 3166-2:SE",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": ""
  }, {
    "Name": "Switzerland",
    "Code": "CH",
    "alpha-3": "CHE",
    "country-code": "756",
    "iso_3166-2": "ISO 3166-2:CH",
    "Region": "Europe",
    "sub-region": "Western Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "155",
    "intermediate-region-code": ""
  }, {
    "Name": "Syrian Arab Republic",
    "Code": "SY",
    "alpha-3": "SYR",
    "country-code": "760",
    "iso_3166-2": "ISO 3166-2:SY",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Taiwan, Province of China",
    "Code": "TW",
    "alpha-3": "TWN",
    "country-code": "158",
    "iso_3166-2": "ISO 3166-2:TW",
    "Region": "Asia",
    "sub-region": "Eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "030",
    "intermediate-region-code": ""
  }, {
    "Name": "Tajikistan",
    "Code": "TJ",
    "alpha-3": "TJK",
    "country-code": "762",
    "iso_3166-2": "ISO 3166-2:TJ",
    "Region": "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": ""
  }, {
    "Name": "Tanzania, United Republic of",
    "Code": "TZ",
    "alpha-3": "TZA",
    "country-code": "834",
    "iso_3166-2": "ISO 3166-2:TZ",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Thailand",
    "Code": "TH",
    "alpha-3": "THA",
    "country-code": "764",
    "iso_3166-2": "ISO 3166-2:TH",
    "Region": "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": ""
  }, {
    "Name": "Timor-Leste",
    "Code": "TL",
    "alpha-3": "TLS",
    "country-code": "626",
    "iso_3166-2": "ISO 3166-2:TL",
    "Region": "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": ""
  }, {
    "Name": "Togo",
    "Code": "TG",
    "alpha-3": "TGO",
    "country-code": "768",
    "iso_3166-2": "ISO 3166-2:TG",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Western Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "011"
  }, {
    "Name": "Tokelau",
    "Code": "TK",
    "alpha-3": "TKL",
    "country-code": "772",
    "iso_3166-2": "ISO 3166-2:TK",
    "Region": "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": ""
  }, {
    "Name": "Tonga",
    "Code": "TO",
    "alpha-3": "TON",
    "country-code": "776",
    "iso_3166-2": "ISO 3166-2:TO",
    "Region": "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": ""
  }, {
    "Name": "Trinidad and Tobago",
    "Code": "TT",
    "alpha-3": "TTO",
    "country-code": "780",
    "iso_3166-2": "ISO 3166-2:TT",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Tunisia",
    "Code": "TN",
    "alpha-3": "TUN",
    "country-code": "788",
    "iso_3166-2": "ISO 3166-2:TN",
    "Region": "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": ""
  }, {
    "Name": "Turkey",
    "Code": "TR",
    "alpha-3": "TUR",
    "country-code": "792",
    "iso_3166-2": "ISO 3166-2:TR",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Turkmenistan",
    "Code": "TM",
    "alpha-3": "TKM",
    "country-code": "795",
    "iso_3166-2": "ISO 3166-2:TM",
    "Region": "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": ""
  }, {
    "Name": "Turks and Caicos Islands",
    "Code": "TC",
    "alpha-3": "TCA",
    "country-code": "796",
    "iso_3166-2": "ISO 3166-2:TC",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Tuvalu",
    "Code": "TV",
    "alpha-3": "TUV",
    "country-code": "798",
    "iso_3166-2": "ISO 3166-2:TV",
    "Region": "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": ""
  }, {
    "Name": "Uganda",
    "Code": "UG",
    "alpha-3": "UGA",
    "country-code": "800",
    "iso_3166-2": "ISO 3166-2:UG",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Ukraine",
    "Code": "UA",
    "alpha-3": "UKR",
    "country-code": "804",
    "iso_3166-2": "ISO 3166-2:UA",
    "Region": "Europe",
    "sub-region": "Eastern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "151",
    "intermediate-region-code": ""
  }, {
    "Name": "United Arab Emirates",
    "Code": "AE",
    "alpha-3": "ARE",
    "country-code": "784",
    "iso_3166-2": "ISO 3166-2:AE",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "United Kingdom",
    "Code": "GB",
    "alpha-3": "GBR",
    "country-code": "826",
    "iso_3166-2": "ISO 3166-2:GB",
    "Region": "Europe",
    "sub-region": "Northern Europe",
    "intermediate-region": "",
    "region-code": "150",
    "sub-region-code": "154",
    "intermediate-region-code": ""
  }, {
    "Name": "United States",
    "Code": "US",
    "alpha-3": "USA",
    "country-code": "840",
    "iso_3166-2": "ISO 3166-2:US",
    "Region": "Americas",
    "sub-region": "Northern America",
    "intermediate-region": "",
    "region-code": "019",
    "sub-region-code": "021",
    "intermediate-region-code": ""
  }, {
    "Name": "United States Minor Outlying Islands",
    "Code": "UM",
    "alpha-3": "UMI",
    "country-code": "581",
    "iso_3166-2": "ISO 3166-2:UM",
    "Region": "Oceania",
    "sub-region": "Micronesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "057",
    "intermediate-region-code": ""
  }, {
    "Name": "Uruguay",
    "Code": "UY",
    "alpha-3": "URY",
    "country-code": "858",
    "iso_3166-2": "ISO 3166-2:UY",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "Uzbekistan",
    "Code": "UZ",
    "alpha-3": "UZB",
    "country-code": "860",
    "iso_3166-2": "ISO 3166-2:UZ",
    "Region": "Asia",
    "sub-region": "Central Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "143",
    "intermediate-region-code": ""
  }, {
    "Name": "Vanuatu",
    "Code": "VU",
    "alpha-3": "VUT",
    "country-code": "548",
    "iso_3166-2": "ISO 3166-2:VU",
    "Region": "Oceania",
    "sub-region": "Melanesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "054",
    "intermediate-region-code": ""
  }, {
    "Name": "Venezuela (Bolivarian Republic of)",
    "Code": "VE",
    "alpha-3": "VEN",
    "country-code": "862",
    "iso_3166-2": "ISO 3166-2:VE",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "South America",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "005"
  }, {
    "Name": "Viet Nam",
    "Code": "VN",
    "alpha-3": "VNM",
    "country-code": "704",
    "iso_3166-2": "ISO 3166-2:VN",
    "Region": "Asia",
    "sub-region": "South-eastern Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "035",
    "intermediate-region-code": ""
  }, {
    "Name": "Virgin Islands (British)",
    "Code": "VG",
    "alpha-3": "VGB",
    "country-code": "092",
    "iso_3166-2": "ISO 3166-2:VG",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Virgin Islands (U.S.)",
    "Code": "VI",
    "alpha-3": "VIR",
    "country-code": "850",
    "iso_3166-2": "ISO 3166-2:VI",
    "Region": "Americas",
    "sub-region": "Latin America and the Caribbean",
    "intermediate-region": "Caribbean",
    "region-code": "019",
    "sub-region-code": "419",
    "intermediate-region-code": "029"
  }, {
    "Name": "Wallis and Futuna",
    "Code": "WF",
    "alpha-3": "WLF",
    "country-code": "876",
    "iso_3166-2": "ISO 3166-2:WF",
    "Region": "Oceania",
    "sub-region": "Polynesia",
    "intermediate-region": "",
    "region-code": "009",
    "sub-region-code": "061",
    "intermediate-region-code": ""
  }, {
    "Name": "Western Sahara",
    "Code": "EH",
    "alpha-3": "ESH",
    "country-code": "732",
    "iso_3166-2": "ISO 3166-2:EH",
    "Region": "Africa",
    "sub-region": "Northern Africa",
    "intermediate-region": "",
    "region-code": "002",
    "sub-region-code": "015",
    "intermediate-region-code": ""
  }, {
    "Name": "Yemen",
    "Code": "YE",
    "alpha-3": "YEM",
    "country-code": "887",
    "iso_3166-2": "ISO 3166-2:YE",
    "Region": "Asia",
    "sub-region": "Western Asia",
    "intermediate-region": "",
    "region-code": "142",
    "sub-region-code": "145",
    "intermediate-region-code": ""
  }, {
    "Name": "Zambia",
    "Code": "ZM",
    "alpha-3": "ZMB",
    "country-code": "894",
    "iso_3166-2": "ISO 3166-2:ZM",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }, {
    "Name": "Zimbabwe",
    "Code": "ZW",
    "alpha-3": "ZWE",
    "country-code": "716",
    "iso_3166-2": "ISO 3166-2:ZW",
    "Region": "Africa",
    "sub-region": "Sub-Saharan Africa",
    "intermediate-region": "Eastern Africa",
    "region-code": "002",
    "sub-region-code": "202",
    "intermediate-region-code": "014"
  }]
]