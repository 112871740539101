export const pipelineProgress = `
query PipelineProgress {
  PipelineProgress {
    Active {
      _id
      uuid
      stage
    }
    NonActive {
      _id
      uuid
      stage
      pivot
    }
  }
}
`;