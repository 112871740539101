import React from "react";

// import corner1 from './../../../assets/img/icons/spot-illustrations/corner-1.png';
// import corner2 from './../../../assets/img/icons/spot-illustrations/corner-2.png';
// import corner3 from './../../../assets/img/icons/spot-illustrations/corner-3.png';

export const PortfolioCard = (props) => {

  return(<>
    <div class="row pl-2 pr-2">
      <div class="col-md-6 p-2">
        <div class="card h-100">
          <div class="card-body p-0">
            <div class="card-header">
              <img 
                class="d-block mx-auto mb-3" 
                src={require(`../../../assets/img/undraw_Portfolio_update_re_jqnp.svg`)}
                alt="shield" width="69"
              />
              <div class="text-center">
                <h5>Value proposition portfolio</h5>
              </div>
              <div class="card-body mb-0">
                <p class="mb-1 fs--1">Get an overview of D2C value propositions across Mars Petcare to see the latest status and developments across the portfolio. Value Proposition Teams can manage their propositions, and D2C leadership can review and approve propositions through the delivery lifecycle.</p>
              </div>
              <div class="card-footer">
                <a class="btn btn-primary btn-sm mt-2 border-0 mars-bg-blue" href="/monitor/starter">Manage your D2C propositions<span class="ml-1 fas fa-chevron-right me-1 ms-2" data-fa-transform="shrink-4 down-1"></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 p-2">
        <div class="card h-100">
          <div class="card-body p-0">
            <div class="card-header">
              <img class="d-block mx-auto mb-3" 
                //src="assets/img/illustrations/undraw_geniuses_9h9g.svg" 
                src={require(`../../../assets/img/undraw_geniuses_9h9g.svg`)}
                alt="" width="75" 
              />
              <div class="text-center">
                <h5>Competitor intelligence</h5>
              </div>
              <div class="card-body">
                <p class="mb-1 fs--1">
                  Stay up to date on D2C propositions in the pet care market. Learn about competitor products, performance, social media activity, latest news, and more. Value Proposition Teams can use this resource to track and stay ahead of competition.
                </p>
                <br/>
              </div>
              <div class="card-footer">
                <a class="btn btn-primary btn-sm mt-2 mars-bg-blue border-0" href="/insight/dashboard">Explore your competition<span class=" ml-1 fas fa-chevron-right me-1 ms-2" data-fa-transform="shrink-4 down-1"></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )}