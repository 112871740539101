import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './helpers/initFA';

import { BrowserRouter as Router } from 'react-router-dom';

import DynamicRouter from './Routing/DynamicRouter';
//import FalconContextProvider from './context/Context'
import Main from './Main'

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/nucleo/css/nucleo.css";
// core styles
import "./assets/scss/theme.scss";
import "react-notification-alert/dist/animate.css";

//import reportWebVitals from './web_functionality/reportWebVitals';
let Hotjar = require('./util/hotjar.js');
Hotjar(2672486, 6);

// const value = {
//   setShowBurgerMenu: () => console.log('setting burger')
// }

ReactDOM.render(
  <React.Fragment>
      <Router>
        <Main>
        {/*    <FalconContextProvider.Provider value={value}>*/}
        <DynamicRouter />
    {/*  </FalconContextProvider.Provider>*/}
        </Main>
      </Router>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// <!-- Hotjar Tracking Code for d2cx.dev -->
// <script>
//     (function(h,o,t,j,a,r){
//         h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
//         h._hjSettings={hjid:2428799,hjsv:6};
//         a=o.getElementsByTagName('head')[0];
//         r=o.createElement('script');r.async=1;
//         r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
//         a.appendChild(r);
//     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
// </script>
