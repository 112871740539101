import React from "react";
import Select from 'react-select'

export const DropdownFieldBrands = ({title, list, setState, filterObject, setFilterObject}) => {

  const handleChange = (e) => {   
    let obj = filterObject
    obj[title] = e.value
    setFilterObject({...obj})
  }

  const getValue = (e) => {
    let obj = {}
    
    if(filterObject !== null) {  
      let item = list.find((brand) => brand.value === filterObject[title])
      if(filterObject[title] !== ""){  
        if(item !== undefined){
          obj = {
            label: item.label,
            value: filterObject[title]
          }
          return obj
        }
        else return null
      }
      else return null
    }
    else return null
  }

  const styles = {
    control: (base) => ({
      ...base,
      minHeight: 28,
      height: 28,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  return(  
    <div className="fs--2 ml-2 mt-1" style={{maxWidth: `15%`, minWidth: `140px`}}>
        <Select 
          isSearchable={false}
          className="" 
          options={list}
          placeholder={`${title}`}
          autosize={false}
          onChange={(e) => handleChange(e)}
          value={getValue()}
          styles={styles}
          theme={theme => ({
            ...theme,
            borderRadius: 3,
            colors: {
              ...theme.colors,
              primary: '#cccccc',
            },
          })}
        />
      </div>
  )
}