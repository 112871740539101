import React from 'react';

const PipelineMixSpeciesCard = ({specie, total}) => {
  
  let name = ""
  let amount = ""
  Object.keys(specie).map(item => {
    name = item
    amount = (specie[item] * 100 / total).toFixed(0)
  })

  const getIcon = () => {
    if(name === "Cat") return (
      <td class="align-top name">
          <span class={`fas fas fa-cat fs--1 text-600 me-1`} /> {name}
      </td>
    )
    if(name === "Dog") return (
      <td class="align-top name">
          <span class={`fas fas fa-dog fs--1 text-600 me-1`} /> {name}
      </td>
    )
    if(name === "Both") return (
      <td class="align-top name">
        <span class="fas fas fa-cat fs--1 text-600 me-1" />
        <span class="fas fas fa-dog fs--1 text-600 me-1" /> Both
      </td>)
    else return <td class="align-top name">Unspecified</td>
  }
 
  return (
    <tbody class="list fs--2">
      <tr class="btn-reveal-trigger">
        {getIcon()}
        <td class="align-top perc text-end">{amount} %</td>
      </tr>

      {/* <tr class="btn-reveal-trigger">
        <td class="align-top name">
          <span class="fas fas fa-dog fs--1 text-600 me-1" /> Dog
          <br />
        </td>
        <td class="align-top perc text-end">{dogOccurrence}</td>
      </tr>
      <tr class="btn-reveal-trigger">
        <td class="align-top name">
          <span class="fas fas fa-cat fs--1 text-600 me-1" />
          <span class="fas fas fa-dog fs--1 text-600 me-1" /> Both
          <br />
        </td>
        <td class="align-top perc text-end">{bothOccurrence}</td>
      </tr> */}
    </tbody>
  );
}

export default PipelineMixSpeciesCard;