import React from "react";
import Select from 'react-select'
import * as api from "../../components/API/IndividualVpAPI";
//import { speciesList } from "../../Lists/speciesList";


export const InputFieldDiscoverSpecies = ({brand, setBrand}) => {

  const speciesList = [
    {
      label: "Cat",
      value: "cat",
    },
    {
      label: "Dog",
      value: "dog"
    },
    {
      label: "Cat & Dog",
      value: "both"
    }
  ]
  
  const handleChange = (e) => {
    if(e.value === "cat"){
      brand.species = ["cat"]
    }
    if(e.value === "dog"){
      brand.species = ["dog"]
    }
    if(e.value === "both"){
      brand.species = ["cat", "dog"]
    }
    setBrand({...brand})
  }
  
  // const handleChange = (vpHandler, e, handle) => {      
  //   let vpObject = vpHandler.get

  //   if(e.label === "Cat") {
  //     vpObject.species = ["Cat"]
  //     //vpObject.species.push([...species])
  //   }
  //   if(e.label === "Dog") {
  //     vpObject.species = ["Dog"]
  //     //vpObject.species.push([...species])
  //   }
  //   if(e.label === "Cat & Dog") {
  //     vpObject.species = ["Cat", "Dog"]
  //     //vpObject.species.push([...species])
  //   }
  //   //console.log(`species: ${JSON.stringify(vpObject.species)}`)

  //   vpHandler.set({...vpObject})
  // }

  const getValue = () => {
    let obj = {}
    console.log(`species: ${JSON.stringify(brand.species)}`)
    if(brand.species.length === 1){
      if(brand.species.includes("cat")){
        obj.label = "Cat"
        obj.value = "cat"
        return obj
      }
      if(brand.species.includes("dog")){
        obj.label = "Dog"
        obj.value = "dog"
        return obj
      }
    }
    if(brand.species.length === 2){
      obj.label = "Cat & Dog"
      obj.value = "both"
      return obj
    }
    else return null
  }

  const styles = {
    control: (styles, { isDisabled }) => {
      return {
        ...styles,
        height: '28px',
        minHeight: '28px',
        backgroundColor: isDisabled ? '#fafafa' : 'white',
      }
    },
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  return( 
        <Select 
          className="fs--2" 
          //isMulti={isMulti}
          //isDisabled={api.checkDisabled(vpHandler)}
          isSearchable={false}
          options={speciesList}
          placeholder={`Select species`}
          autosize={false}
          clearable={true}
          onChange={(e) => handleChange(e)}
          value={getValue()}
          styles={styles}
          //styles={{control: customControlStyles}}
          theme={theme => ({
            ...theme,
            borderRadius: 3,
            colors: {
              ...theme.colors,
              primary: '#cccccc',
            },
          })}
        />
  )
}