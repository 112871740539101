import React from "react";
import Select from 'react-select'
import * as api from "../../components/API/IndividualVpAPI";

export const DropdownFieldValueModel = ({ isMulti, vpHandler, list, sov }) => {

  const [init, setInit] = React.useState(false)
  const [items, setItems] = React.useState([])

  React.useEffect(() => {
    if(!init){
     setInit(true)
     createList(sov)
    }
  }, [init]) // eslint-disable-line react-hooks/exhaustive-deps
  

  const addKPI = (e, kpi) => {
    //console.log(`e: ${JSON.stringify(e.value)}`) //kpi name
    let vpObject = vpHandler.get
    let metric = {}
    // let newKpi = {}
    //console.log(`metrics: ${JSON.stringify(vpObject.metrics)}`)
    metric = vpObject.metrics.find((item) => item.kpi === e.value)
    ///console.log(`metric: ${JSON.stringify(metric)}`)
    if(metric === undefined) {
      metric = {
        "sov": sov,
        "kpi": e.value,
        "unit": "-",
        "threshold": null,
        "y1": null,
        "y2": null,
        "y3": null
      }
      vpObject.metrics.push({...metric})
    }
    vpHandler.set({...vpObject})
    //console.log(`metric: ${JSON.stringify(vpObject.metrics)}`)
  }



  const createList = (sov) => {
    //console.log(`list: ${JSON.stringify(list)}`)
    let _object = {}
    let newList = []
    list.forEach(item => (
      item.name === sov.toLowerCase() &&
      item.points.forEach((kpi)=>{
        //console.log(`kpi: ${kpi.kpi}`)
        _object['label'] = kpi.name
        _object['value'] = kpi.kpi
        newList.push({..._object})
      })
    ))
    setItems([...newList])
    //console.log(`newlist ${JSON.stringify(newList)}`)

  }

  const styles = {
    control: (base) => ({
      ...base,
      minHeight: 28,
      height: 28,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  return(  
      <div className="w-50 fs--2 " >
        <Select 
          className="" 
          isMulti={isMulti}
          isDisabled={api.checkDisabled(vpHandler)}
          options={items}
          placeholder={`Add Kpi`}
          autosize={false}
          clearable={true}
          onChange={(e) => addKPI(e)}
          //defaultValue={previewValue()}
          styles={styles}
          //styles={{control: customControlStyles}}
          theme={theme => ({
            ...theme,
            borderRadius: 3,
            colors: {
              ...theme.colors,
              primary: '#cccccc',
            },
          })}
        />
      </div>
  )
}