export const lookUpKPI = {
    nsv: "intrinsic",
    gmac: "intrinsic",
    cac: "intrinsic",
    ltv: "intrinsic",
    ltvcac: "intrinsic",
    ctr: "intrinsic",
    session: "Sessions/User",
    bcr: "intrinsic",
    aov: "intrinsic",
    rp: "engagement",
    tc: "engagement",
    rr: "engagement",
    nps: "engagement",
    dvam: "data",
    ppp: "data",
    cda: "data",
    sim: "Scaleable in markets(#)",
    tam: "routetoscale",
    sam: "routetoscale",
    som: "routetoscale",
    markets: "Markets",
    channels: "Channels",
    brands: "Brands",
    session: "engagement",
    intrinsic: "Intrinsic",
    engagement: "Engagement",
    data: "Data",
    routetoscale: "Route To Scale",

  }