import React, { useState, useEffect } from 'react';
import { Button, Row } from 'reactstrap';
import axios from "axios";
import TableCell from '@material-ui/core/TableCell';

let testSocialPosts = [
  {
    "platform": "",
    "brandHandle": "",
    "url": "",
    "postTitle": "",
    "postBody": "",
    "postImage": "",
    "postLink": "",
    "reactions": "",
    "shares": "",
    "comments": ""
  }
]

const CompanySocialMedia = ({ social, brand, selectedBrand }) => {
  const [hide, setHide] = useState(false);
  const [news, setNews] = React.useState(null);
  const [posts, setPosts] = useState([...testSocialPosts]);
  const [init, setInit] = useState(false);
  const [newsIndex, setNewsIndex] = useState(0);
  const [postIndex, setPostIndex] = useState(0);
  // const [socialIndex, setSocialIndex] = useState(0);
  const [socialEngagement, setSocialEngagement] = useState(null)

  

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  useEffect(() => {
    if(!init){
      setInit(true);
      GetNews();
      GetPosts();
      GetSocialEngagement();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);

  useEffect(() => {
    GetNews();
    GetPosts();
    GetSocialEngagement();
    setNewsIndex(0)
    setPostIndex(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand]);

  // useEffect(() => {
  //   // console.log(`socials: f ${JSON.stringify(posts)}`)

  // }, [posts]);

  const GetPosts = () => {
    axios({
      url: process.env.REACT_APP_API,
      //url: "https://d2cx.dev/graphql",
      method: "post",
      data: {
        query: `query GetSocialPosts($cid: String) {
          GetSocialPosts(cid: $cid) {
            brandHandle
            platform
            postBody
            postTitle
            postImage
            postLink
            reactions
            shares
            comments
            url
          }
        }`,
        variables: {cid: selectedBrand },
      },
    })
      .then((result) => {
        // console.log(`socials: ${JSON.stringify(result.data.data.GetSocialPosts)}`)
        setPosts([...result.data.data.GetSocialPosts]);
      })
      .catch((err) => console.log(err));
  }

  const GetNews = () => {
    //console.log(`${brand.brandName}, ${selectedBrand}`)
    axios({
      url: process.env.REACT_APP_API,
      method: "post",
      data: {
        query: `query GetNewsRealtime($brandName: String) {
          GetNewsRealtime(brandName: $brandName) {
            news{
              title
              url
              source{
                name
              }
              published
            }
          }
        }`,
        variables: {brandName: brand.brandName },
      },
    })
      .then((result) => {
        //console.log(`${brand.brandName}: ${JSON.stringify(result.data.data.GetNewsRealtime)}`)
        setNews([...result.data.data.GetNewsRealtime.news]);
      })
      .catch((err) => console.log(err));

  }


   const GetSocialEngagement = () => {
    axios({
      url: process.env.REACT_APP_API,
      method: "post",
      data: {
        query: `
        query GetSocialEngagement($cid: String, $historyFrames:Int){
          GetSocialEngagement(cid:$cid, historyFrames:$historyFrames){
            cid
            facebook{
              likes
              talkingAbout
            }
            instagram{
              followers
              avgLikes
              avgComments
              posts
            }
            twitter{
              favorites
              followingDelta
            }
          }
        }
        `,
        variables: {cid: selectedBrand },
      },
    })
      .then((result) => {
        //console.log("Social API: " , result.data.data.GetSocialEngagement)
        setSocialEngagement({...result.data.data.GetSocialEngagement});
      })
      .catch((err) => console.log(err));
  };

  const newsLink = () => {
    if(news !== undefined && news !== null){
      return news[newsIndex].url
    }
  }

  const lowerNewsIndex = () => {
    if(newsIndex > 0 ) setNewsIndex(newsIndex-1)
    else setNewsIndex(news.length-1)
  }

  const increasePostIndex = () => {
    if(postIndex < posts.length-1 ) setPostIndex(postIndex+1)
    else setPostIndex(0)
  }

  const lowerPostIndex = () => {
    if(postIndex > 0 ) setPostIndex(postIndex-1)
    else setPostIndex(posts.length-1)
  }

  const increaseNewsIndex = () => {
    if(newsIndex < news.length-1 ) setNewsIndex(newsIndex+1)
    else setNewsIndex(0)
  }

  const newsBody = () => {
    // let body = ""
    if(news !== undefined && news !== null){
        if(news[newsIndex] !== undefined) return news[newsIndex].title
    }
  }

  
  const postTitle = () => {
    // let body = ""
    //console.log(posts)
    if(posts !== undefined && posts !== null && posts[postIndex]){
      if(posts[postIndex] !== undefined) return posts[postIndex].postTitle
    }
  }

  const postBody = () => {
    // let body = ""
    if(posts !== undefined && posts !== null && posts[postIndex]){
      if(posts[postIndex] !== undefined) return posts[postIndex].postBody
    }
  }
  const postLink = () => {
    if(posts !== undefined && posts !== null && posts[postIndex]){
      if(posts[postIndex] !== undefined) return posts[postIndex].url
    }
  }

  const postAuthor = () => {
    if(posts !== undefined && posts !== null && posts[postIndex]){
      return `${posts[postIndex].brandHandle} (${posts[postIndex].platform})`
    }
  }

  const newsAuthor = () => {
    if(news !== undefined && news !== null){
      if(news[newsIndex] !== undefined) return news[newsIndex].source.name
    }
  }

  const checkBrandName = () => {
    return (brand.brandName==undefined && brand.name==undefined)
  }

  const fixNumber = (input) => {
    let output = (input/1000).toFixed(1)
    output = output.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return output
  }


  return (
    <>
      {brand!==null&&
        !checkBrandName() &&
          <div class="card mb-3">
            <div
              class="card-header pb-1 bg-light"
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <h6 className="card-title mb-0 pb-0 pt-1">Social media and latest news</h6>

              <div>
                <div class="me-2 fs--2 mars-blue" onClick={() => setHide(!hide)} role="button">
                  view / hide
                </div>
              </div>
            </div>
            <div class={`${hide ? 'collapse' : ''} card-body`}>
              <div class="row">
                <div class="col-md-4">
                  <h6 class="fs--2">Followers</h6>
                  <table class="table table-sm fs--2 mb-0 ">
                    <thead class="bg-200 text-900">
                      <tr>
                        <TableCell align={"left"} style={{fontSize: '11px', fontWeight: '600'}}>
                          Platform
                        </TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px', fontWeight: '600'}}>
                          Followers
                        </TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px', fontWeight: '600'}}>
                          Likes
                        </TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px', fontWeight: '600'}}>
                          Interactions
                        </TableCell>
                      </tr>
                    </thead>
                    <tbody class="list fs--2">
                      <tr>
                        <TableCell align={"left"} style={{fontSize: '11px'}}>
                          <i class="fab fa-facebook" />
                        </TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px'}}>{social !== null && fixNumber(social.facebookFollowers*1000)}K</TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px'}}>{(socialEngagement !== null && socialEngagement.facebook) ? fixNumber(socialEngagement.facebook[0].likes) : "-"}K</TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px'}}>{(socialEngagement !== null && socialEngagement.facebook) ? fixNumber(socialEngagement.facebook[0].talkingAbout) : "-"}K</TableCell>
                      </tr>
                      <tr>
                        <TableCell align={"left"} style={{fontSize: '11px'}}>
                          <i class="fab fa-instagram" />
                        </TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px'}}>{social !== null && fixNumber(social.instagramFollowers*1000)}K</TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px'}}>{(socialEngagement !== null && socialEngagement.instagram) ? fixNumber(socialEngagement.instagram[0].avgLikes*socialEngagement.instagram[0].posts) : "-"}K</TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px'}}>{(socialEngagement !== null && socialEngagement.instagram) ? fixNumber(socialEngagement.instagram[0].avgComments*socialEngagement.instagram[0].posts) : "-"}K</TableCell>
                      </tr>
                      <tr>
                        <TableCell align={"left"} style={{fontSize: '11px'}}>
                          <i class="fab fa-twitter" />
                        </TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px'}}>{social !== null && fixNumber(social.twitterFollowers*1000)}K</TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px'}}>{(socialEngagement !== null && socialEngagement.twitter) ? fixNumber(socialEngagement.twitter[0].favorites) : "-"}K</TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px'}}>{(socialEngagement !== null && socialEngagement.twitter) ? fixNumber(socialEngagement.twitter[0].followingDelta) : "-"}K</TableCell>
                      </tr>
                      <tr>
                        <TableCell align={"left"} style={{fontSize: '11px'}}>
                          <i class="fab fa-tiktok" />
                        </TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px'}}>{social !== null && fixNumber(social.tiktokFollowers*1000)}K</TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px'}}>-K</TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px'}}>-K</TableCell>
                      </tr>
                      <tr>
                        <TableCell align={"left"} style={{fontSize: '11px'}}>
                          <i class="fab fa-youtube" />
                        </TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px'}}>{social !== null && social.youtubeFollowers}K</TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px'}}>-K</TableCell>
                        <TableCell align={"right"} style={{fontSize: '11px'}}>-K</TableCell>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="pt-2 col-md-4">
                  <h6 class="fs--2" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <p>Latest post </p>

                    <div onClick={e=>openInNewTab(postLink())} style={{ alignText: 'right' }} class="mars-blue hoverhand">
                      Go to post →
                    </div>
                  </h6>
                  <div class="text-normal pb-2" style={{ minHeight: '120px', overflow: 'hidden' }}>
                    <div className='font-weight-400'>{postTitle()}</div><br/>
                      {posts[postIndex]&&<>
                      <div className='row p-2'> 
                        <img
                          className='ml-auto mr-auto' 
                          style={{maxWidth: '250px'}} 
                          src={`https://d2cx.ams3.digitaloceanspaces.com/social-cdn/${posts[postIndex].postImage}`}
                          onError={e => {
                            e.target.src = require('../../assets/img/whiteBox.png');
                          }}
                        />
                      </div>
                      <div className='row px-3 pb-2'>
                        <div className='ml-auto row mr-2'>
                          {posts[postIndex].reactions > 0&&
                          <div className='row px-3 mr-1'>
                            <div className='pt-1 mr-1'>
                              {posts[postIndex].reactions}
                            </div>
                            <div className='' style={{width: '20px'}}>
                              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" /></svg>
                            </div>
                          </div>}
                          {posts[postIndex].comments > 0&&
                          <div className='row px-3 mr-1'>
                            <div className='pt-1 mr-1'>
                              {posts[postIndex].comments}
                            </div>
                            <div className='' style={{width: '20px'}}>
                              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" /></svg>                       
                            </div>
                          </div>}
                          {posts[postIndex].shares > 0 &&
                          <div className='row px-3 mr-1'>
                            <div className='pt-1 mr-1'>
                              {posts[postIndex].shares}
                            </div>
                            <div className='' style={{width: '20px'}}>
                              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" /></svg>
                            </div>
                          </div>}
                        </div>
                      </div>
                    </>}
                    {postBody()}<br/><br/>
                    {postAuthor()}

                  </div>
                  <Row>
                    <div class="pr-2 ml-3">
                      <Button onClick={e=>lowerPostIndex()} height="16px" style={{ minWidth: '80px', backgroundColor: '#000096', fontSize: '11px' }}>
                        Previous
                      </Button>
                    </div>
                    <Button onClick={e=>increasePostIndex()} height="16px" style={{ minWidth: '80px', backgroundColor: '#000096', fontSize: '11px' }}>
                      Next
                    </Button>
                  </Row>
                </div>
                <div class="pt-2 col-md-4">
                  <h6 class="fs--2" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <p>Latest news </p>
                    <div onClick={e=>openInNewTab(newsLink())} style={{ alignText: 'right' }} class="mars-blue hoverhand">
                      Go to news →
                    </div>
                  </h6>
                  <div class="text-normal" style={{ height: '120px', overflow: '' }}>
                    {newsBody()}<br/><br/>
                    - {newsAuthor()}
                  </div>
                  <div ></div>
                  <Row style={{position: 'absolute', bottom: 0}}> 
                    <div class="pr-2 ml-3">
                      <Button onClick={e=>lowerNewsIndex()} height="16px" style={{ minWidth: '80px', backgroundColor: '#000096', fontSize: '11px' }}>
                        Previous
                      </Button>
                    </div>
                    <Button onClick={e=>increaseNewsIndex()} height="16px" style={{ minWidth: '80px', backgroundColor: '#000096', fontSize: '11px' }}>
                      Next
                    </Button>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        } 
    </>
  );
};

export default CompanySocialMedia;
