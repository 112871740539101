import React from 'react';
import { CardBody } from 'reactstrap';
import { Months } from './../../lookupTables/Months';
import moment from 'moment';
import Scatter from '../Scatter';

const ScatterLayout = ({ data, yAxis, xAxis, title, width, height }) => {
  //console.log(`month; ${moment().subtract(1, 'months').format('MMM YYYY')}`)
  let cardWidth = width !== null ? width : '100%';
  let cardHeight = height !== null ? height : '100%';

  let currentMonth = new Date().getMonth()-1;
  let currentYear = new Date().getFullYear();
  let lastYear = null;
  let subtitle = null; 

  //console.log(`data: ${JSON.stringify(data)}`)

  if (currentMonth - 5 < 0) lastYear = currentYear - 1;
  else lastYear = currentYear;

  //subtitle = `${Months[(currentMonth - 5) % 11]} ${lastYear} - ${Months[currentMonth]} ${currentYear}`;
  subtitle = `${moment().subtract(6, 'months').format('MMM YYYY')} - ${moment().subtract(1, 'months').format('MMM YYYY')}`
  
  return (
    <div class="card mb-3" style={{ width: `${cardWidth}`, height: `${cardHeight}` }}>
    <div class="card-header pb-1 bg-light">
      <h6 className="card-title mb-0 pb-0 pt-1">{title}</h6>
      <h6 class="mb-0 mt-1 fs--2 text-500">{subtitle}</h6>
    </div>
      <div className="chart">
        <Scatter data={data} yAxis={yAxis} xAxis={xAxis} title={title} />
      </div>
    </div>
  );
};
export default ScatterLayout;
