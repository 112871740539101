import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PageTitle from '../../components/header/PageTitle';
import LineChartLayout from '../../components/ECharts/Layout/LineChartLayout';
import LineChartLayoutWebTraffic from '../../components/ECharts/Layout/LineChartLayoutWebTraffic';
import TreeMapLayout from '../../components/ECharts/Layout/TreeMapLayout';
//import ProductComparisonLayout from '../../components/ECharts/Layout/ProductComparisonLayout';
import * as api from '../../components/API/OverviewAPI';
import * as brandAPI from '../../components/API/BrandsTableAPI';
import BrandsTable from './BrandsTable';
import { FilterDeckDiscover } from "../../components/Cards/FilterDeckDiscover";
import { socialHistoryData } from '../../components/Lists/socialHistoryData';

const M_Discover = () => {
  const [init, setInit] = useState(false);
  const [allVPs, setAllVPs] = useState([]);
  const [combinedBrands, setCombinedBrands] = useState([]);
  const [socials, setSocials] = useState([]);
  const [webTraffic, setWebTraffic] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [allWebEngagement, setAllWebEngagement] = useState([]);
  const [string, setString] = React.useState("");
  const [minLengthCheck, setMinLengthCheck] = React.useState(100);

  useEffect(() => {
    if (!init) {
      setInit(true);
      api.GetAllSocials(setSocials);
      api.GetAllWebTraffic(setWebTraffic);
      api.GetManyWebEngagement(setAllWebEngagement);
      //api.GetProductComparison(setProductComparison);
      //api.GetRevenue(setRevenue);
      brandAPI.GetVPs(setAllVPs);
      brandAPI.GetManyBrand(setAllBrands);
      //brandAPI.GetSocialAnalysisData(setBrandTableSocials);
    }
  }, [init]);

  useEffect(() => {
    setMinLengthCheck(allBrands.length>0?allBrands.length:1000);
    MergeVPsWithBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBrands, allVPs]);

  const MergeVPsWithBrands = () => {
    let _brands = allBrands
    //console.log(allBrands)
    allVPs.forEach(vp => {
      if(vp.showDiscover) {
        _brands.push({...vp})
      }
    })

    setCombinedBrands([..._brands])
  }

  // useEffect(() => {
  //   console.log(`allBrands: ${JSON.stringify(allBrands)}`)
  // }, [allBrands]);

  // useEffect(() => {
  //   console.log(`webTraffic: ${JSON.stringify(webTraffic)}`)
  // }, [webTraffic]);
  
  const GetCompaniesQuery = `
  query GetCompanies {
    GetCompanies {
      companyName
      companyUrl
    }
  }
  `;

  const getCompanies = () => {
    axios
    .post(process.env.REACT_APP_API, {
      query: GetCompaniesQuery,
      variables: {}
      })
      .then(res => {
        //console.log(`newbrands: ${JSON.stringify(res.data.data.GetCompanies)}`)
        //setNewBrands([...res.data.data.GetCompanies])
      })
        .catch(err => console.log(err))
  }

  
  return (
    
      <div class="pt-3">
        <PageTitle title={'D2C Accelerator'} subtitle={'Find your competitor insights'} />
        <FilterDeckDiscover string={string} setString={setString} allBrands={combinedBrands} setAllBrands={setCombinedBrands} minLengthCheck={minLengthCheck}/>
        <BrandsTable string={string} setString={setString} data={combinedBrands} getCompanies={getCompanies}/>
        <TreeMapLayout data={combinedBrands} title='D2C competitors by revenue' sub="2020 or latest available, in $M" string={string} setString={setString} />
        <div class="row">
          <div class="col-md-6" >
            <LineChartLayoutWebTraffic webTraffic={allWebEngagement} data={webTraffic} allBrands={combinedBrands} title="Web Traffic" sub="Monthly unique visitors, in K" height={'350px'} />
          </div>
          <div class="col-md-6" >
            <LineChartLayout socialHistoryData={socialHistoryData} allBrands={combinedBrands} buttons={true} data={socials} title="Social Media Followers" sub="Sum of followers across all platforms, in K" height={'350px'} />
          </div>
        </div>
      </div>
  );
};

export default M_Discover;
