import React from 'react';
import axios from 'axios';
import { Input } from "reactstrap"
import { useOktaAuth } from "@okta/okta-react";
import NotificationAlert from 'react-notification-alert';

import * as api from "./../../components/API/IndividualVpAPI";
import {useDropzone} from 'react-dropzone';

import PageTitle from "../../components/header/PageTitle";

const Feedback = () => {
  const inputEl = React.useRef(null)

  const [init, setInit] = React.useState(false)
  const [learnFiles, setLearnFiles] = React.useState([])
  const [open, setOpen] = React.useState(true);
  const [fb, setFb] = React.useState("");
  const [imageLink, setImageLink] = React.useState("")
  const { authState, oktaAuth } = useOktaAuth();
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
  const [resourceObject, setResourceObject] = React.useState({name: "", version: "", description: "", destination: "playbooks", brand: ""});
  const [fileLink, setFileLink] = React.useState("")
  
  const handleChange = (e) => {
    let _fb = e.target.value
    setFb(_fb);
  }

  const SubmitFeedback = () => {
    axios({
      url: process.env.REACT_APP_API,
      method: "post",
      data: {
        query: `query SendEmail($senderEmail:String, $senderName:String, $suggestionText:String, $imageLink:String){
          SendEmail(senderEmail:$senderEmail, senderName:$senderName, suggestionText:$suggestionText, imageLink:$imageLink) 
        }`,
        variables: { senderEmail: authState.accessToken.claims.sub, suggestionText: fb, imageLink: fileLink },
      },
    })
      .then((result) => {
        api.notify("Your feedback has been submitted.", inputEl, "success" )
        //openInNewTab(result.data.data.GetDownload);
        //setLearnFiles( [...result.data.data.GetLearnFile] );
      })
      .catch((err) => console.log(err));
  };

  const submitFeedback = () => {
    if(fb !== "") {
      SubmitFeedback()
      setFb("")
    }
  }

  React.useEffect(() => {
    
    if(acceptedFiles.length > 0) {
      if(acceptedFiles[0].path !== "false"){
        //console.log(`file: ${acceptedFiles[0].path}`)
        uploadFeedbackFile(acceptedFiles[0] )
      }
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);


  const uploadFeedbackFile = () => {

    const _resourceObject = new FormData()
    _resourceObject.append("operations", JSON.stringify({ "query": "mutation UploadPublicFile($file:Upload!, $user:String) { UploadPublicFile(file: $file, user:$user)}", "variables": { "user": "testUser" } }));
    _resourceObject.append("map", JSON.stringify({"0": ["variables.file"]}));
    _resourceObject.append("0", acceptedFiles[0]);

    axios({
        url: process.env.REACT_APP_API,
        method: "post",
        data: _resourceObject
    })

    .then(async response => {
      // console.log(`response:${JSON.stringify(response.data.data.UploadPublicFile)}`)
      let _link = `https://d2cx.ams3.digitaloceanspaces.com/${response.data.data.UploadPublicFile}`
      console.log(_link)
      await sleep(1000)
      setFileLink(_link)

      //https://d2cx.ams3.digitaloceanspaces.com/a349b2e5-5209-491b-8590-0595a1dfef4a.png
      //GetDownloadLink(response.data.data.UploadPublicFile)
      api.notify("Your file has been uploaded.", inputEl, "success" )

      //getLearnFile()
      //notify("Upload Completed.", vpHandler.ref, "success")
      //getOneVp(getOneVpQuery, vpHandler.get.uuid, vpHandler)
    })
    .catch(error => {
      console.log(error)
      //notify("Upload Error.", vpHandler.ref, "danger")
    });
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const openInNewTab = (url) => {
    if(!!url) {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
    
  }

  
  const GetDownloadLink = (fileId ) => {
    console.log(`fileid: ${fileId}, fileName ${fileName}`)
    let fileName = `download.png`
    axios
      .post( process.env.REACT_APP_API, {
        query:`query GetDownload($fileId: String, $fileName: String){
          GetDownload(fileId:$fileId, fileName:$fileName)
        }`,
        variables: {
          fileId: fileId,
          fileName: fileName
        }
      })
    .then(res => {
      console.log(res)
      setFileLink(res.data.data.GetDownload)
      //openInNewTab(res.data.data.GetDownload)
    })
    .catch(err => console.log(err))
  }



  return (
    <>
      <NotificationAlert ref={inputEl} />
      {/* {console.log(`auth: ${JSON.stringify(authState.accessToken)}`)} */}
      <div class="px-3 pt-3">
        <PageTitle title="D2C Accelerator" subtitle="Manage value propositions" />
        <div class="card mb-3">
          <div class="card-header pb-1 bg-light">
            <div class="row flex-between-center">
              <div class="col-auto col-sm-6 col-lg-7">
                <h6 class="mb-0 text-nowrap py-2 py-xl-0">Your feedback</h6>
              </div>
            </div>
            </div>
            <div class="card-body" style={{ padding: 0, marginLeft: 0, marginRight: 0, marginTop: 0 }}>
              <div class={`${open ? 'collapse' : ''}`} id="collapseFilter">
                <div class="p-card rounded fs--2">
                   
                </div>
              </div>
              <div class="card-footer p-0">
                <div class="min-vh-30 ml-3 mt-2 fs--2">
                  <p>
                    The D2CX portal is a living thing and we count on your support for its continuous improvement. Please help us advance the portal together by reporting any issues or bugs you encounter, and by sharing any improvement ideas you may have.
                  </p>
                </div>
                <div class="w-75 ml-auto mr-auto mb-3">
                  <div class="fs--2" style={{fontWeight: '800', marginBottom: '-15px'}}>
                    <p>
                      User
                    </p>
                  </div>
                  <Input
                    className="ml-auto"
                    style={{fontSize: '12px'}}
                    type="text"
                    disabled={true}
                    rows="6"
                    value={authState.accessToken.claims.sub}
                    onChange={(e) => handleChange(e)}
                    placeholder="Name "
                  />
                </div>
                <div class="w-75 ml-auto mr-auto mb-3">
                  <div class="fs--2" style={{fontWeight: '800', marginBottom: '-12px'}}>
                    <p>
                      Feedback: 
                    </p>
                  </div>                  
                  <Input
                    className="ml-auto"
                    style={{fontSize: '12px'}}
                    type="textarea"
                    //disabled={api.checkDisabled(vpHandler)}
                    rows="6"
                    value={fb}
                    onChange={(e) => handleChange(e)}
                    placeholder="Your feedback.. "
                  />
                </div>
                <div class="w-100 row ml-3 mr-4">                  
                  <div class={` p-0 hoverhand`} data-dropzone="data-dropzone" data-options='{"url":"valid/url","maxFiles":1,"dictDefaultMessage":"Choose or Drop a file here"}'>
                    <div {...getRootProps({className: 'dropzone'})} class=" "  data-dz-message="data-dz-message">
                      <div class="">
                          <input {...getInputProps()} />
                          <div class="">
                            <button class={`btn mars-button btn-sm fs--2 mr-4 mb-3`} type="button" onClick={e=>submitFeedback()}>
                              <span class={"far fa-arrow-alt-circle-up mr-2"} />
                              {`${!fileLink? "Upload Attachment" : "Change Attachment" }`}
                            </button>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="ml-auto">
                    <button class={`btn mars-button btn-sm fs--2 mr-4 mb-3`} type="button" onClick={e=>submitFeedback()}>
                      <span class={"far fa-arrow-alt-circle-right mr-2"}/>
                      
                      {"Submit Feedback"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3">
          <div class="card-header pb-1 bg-light">
            <div class="row flex-between-center">
              <div class="col-auto col-sm-6 col-lg-7">
                <h6 class="mb-0 text-nowrap py-2 py-xl-0">Selected File</h6>
              </div>
            </div>
            </div>
            <div class="card-body" style={{ padding: 0, marginLeft: 0, marginRight: 0, marginTop: 0 }}>
              <div class={`${open ? 'collapse' : ''}`} id="collapseFilter">
                <div class="p-card rounded fs--2">
                   
                </div>
              </div>
              <div class="card-footer p-0">
                {fileLink ?
                  <div className='p-3'>
                      <img 
                        src={fileLink} 
                        className='img-fluid'
                        alt={"..."}
                      />
                  </div>
                  :
                  <div class="min-vh-30 ml-3 mt-2 fs--2">
                    <p>
                      No attachment has been selected
                    </p>
                  </div>
                }   
                </div>
            </div>
          </div>
        <div style={{height: '1000px'}}></div>

      </div>
    </>
  );
};

export default Feedback;
