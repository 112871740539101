import React from "react";
// import {
//   InputGroup,
//   InputGroupAddon,
//   InputGroupButtonDropdown,
//   Input,
//   Button,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem
//  } from 'reactstrap';
 
//  import pb1 from "./../../../assets/img/learn/PB1_Build_Box.png";
//  import pb2 from "./../../../assets/img/learn/PB2_Build_Wheel.png";
//  import pb3 from "./../../../assets/img/learn/PB3_Plug_Box.png";

import { UploadResourcesLine } from "./UploadResourcesLine";


export const Other = ({learnFiles, allBrands}) => {
  // const [open, setOpen] = React.useState(true);
  const [hide, setHide] = React.useState(false);
  


  return(
    
    <div class="card mb-3" id="other">
      <div class="card-header pb-1 bg-light">
        <div class="row col m-0 p-0">
          <span class="fas fa-book-open mr-2"/>
          <h6 class="mb-0">Other Resources</h6>
        </div>
        <div class="row col m-0 p-0">
          <div>
            
          </div>
          <div class="ml-auto">
            <div class="me-2 fs--2" onClick={e => setHide(!hide)} role="button">
              view / hide
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class={`${hide ? "collapse" : ""}`}>
          {learnFiles.map((resource) => (
            resource.destination === "other" && <UploadResourcesLine resource={resource}  allBrands={allBrands}/>
          ))}
        </div>   
      </div>
    </div>
          
  )}