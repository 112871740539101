import React from 'react';
import { CardBody } from 'reactstrap';
import LineChart from '../LineChart';

const LineChartLayout = ({ socialHistoryData, data, title, width, height, sub, allBrands, webTraffic, buttons }) => {
  const [socialsFilter, setSocialsFilter] = React.useState({insta: true, facebook: true, tiktok: true, twitter: true})
  const [init, setInit] = React.useState(false)
  const [socialData, setSocialData] = React.useState({});
  const handleClick = (input) => {
    let obj = socialsFilter
    obj[input] = !obj[input]
    setSocialsFilter({...obj})
  }

  React.useEffect(() => {
    if(allBrands !== undefined)
    getSocialData()
    // eslint-disable-next-line
  }, [socialHistoryData, allBrands, socialsFilter]);

  React.useEffect(() => {
    if(!init && allBrands !== undefined){
      setInit(!init);
      getSocialData();
    }
    // eslint-disable-next-line
  }, [init]);

  let cardWidth = width !== null ? width : '100%';
  let cardHeight = height !== null ? height : '100%';

  const getSocialData = () => {
    let obj = {}
    let _data = {
      "_id":"AllSocials",
      "totalFollowersFive":0,
      "totalFollowersFour":0,
      "totalFollowersThree":0,
      "totalFollowersTwo":0,
      "totalFollowersOne":0,
      "totalFollowersZero":0
    }

    allBrands.forEach(brand => {
      obj = socialHistoryData.find(item => item.cid === brand.cid)
      if(obj !== undefined){
        if(obj.facebook !== null && socialsFilter.facebook) {
          if(obj.facebook.data.length> 0) {
            obj.facebook.data.forEach((dataPoint, i) => {
              if(i<6){
                if(dataPoint.likes !== null) _data[lookUp[i]] = (Number(_data[lookUp[i]]) + Number(dataPoint.likes))
                //console.log(`datapoint: ${JSON.stringify(obj)}`)
              }
            })
          }
        }
        if(obj.twitter !== null && socialsFilter.twitter) {
          if(obj.twitter.data.length> 0) {
            obj.twitter.data.forEach((dataPoint, i) => {
              if(i<6){
                if(dataPoint.followers !== null) _data[lookUp[i]] = (Number(_data[lookUp[i]]) + Number(dataPoint.followers))
              }
            })
          }
        }
        if(obj.instagram !== null && socialsFilter.insta) {
          if(obj.instagram.data.length> 0) {
            obj.instagram.data.forEach((dataPoint, i) => {
              if(i<6){
                if(dataPoint.followers !== null) _data[lookUp[i]] = (Number(_data[lookUp[i]]) + Number(dataPoint.followers))
              }
            })
          }
        }
        if(obj.tiktok !== null && socialsFilter.tiktok) {
          if(obj.tiktok.data.length> 0) {
            obj.tiktok.data.forEach((dataPoint, i) => {
              if(i<6){
                if(dataPoint.followers !== null) _data[lookUp[i]] = (Number(_data[lookUp[i]]) + Number(dataPoint.followers))
              }
            })
          }
        }
      }
    })
    Object.keys(_data).map(point => {
     _data[point] = (_data[point]/1000).toFixed(2)
    })
    setSocialData({..._data})
  }

  const lookUp = {
    0: "totalFollowersZero",
    1: "totalFollowersOne",
    2: "totalFollowersTwo",
    3: "totalFollowersThree",
    4: "totalFollowersFour",
    5: "totalFollowersFive"
  }


  return (
    <div class="card mb-3" style={{ width: `${cardWidth}`, height: `${cardHeight}` }}>
      <div class="card-header pb-1 bg-light row pl-3">
        <div class="ml-3">
          <h6>{title}</h6>
          <h6 class="mb-0 fs--2 text-500">{sub}</h6>
        </div>
        <div class="ml-auto row mr-2 bg-light" style={{backgroundColor: '#sefioj'}}>
          <div class={`mr-2 socials-button text-center pt-1 ${!socialsFilter.insta ? "bg-gray" : ""}`} onClick={e=>handleClick("insta")}><div class={`fab fa-instagram ${socialsFilter.insta ? "insta-color" : "color-gray"}`} /></div>
          <div class={`mr-2 socials-button text-center pt-1 ${!socialsFilter.facebook ? "bg-gray" : ""}`} onClick={e=>handleClick("facebook")}><div class={`fab fa-facebook-f ${socialsFilter.facebook ? "fb-color" : "color-gray"}`}/></div>
          <div class={`mr-2 socials-button text-center pt-1 ${!socialsFilter.tiktok ? "bg-gray" : ""}`} onClick={e=>handleClick("tiktok")}><div class={`fab fa-tiktok ${socialsFilter.tiktok ? "tiktok-color" : "color-gray"}`}/></div>
          <div class={`mr-2 socials-button text-center pt-1 ${!socialsFilter.twitter ? "bg-gray" : ""}`} onClick={e=>handleClick("twitter")}><div class={`fab fa-twitter ${socialsFilter.twitter ? "twitter-color" : "color-gray"}`}/></div>
        </div>
      </div>
      <CardBody style={{ paddingTop: 0, marginTop: 0 }}>
        <div className="chart">
          <LineChart data={socialData} title={title} />
        </div>
      </CardBody>
    </div>
  );
};
export default LineChartLayout;
