export const getSpecificSocials = `
query GetSpecificSocials($cid: String) {
  GetSpecificSocials(cid: $cid) {
    _id
    cid
    uuid
    totalFollowersFive
    totalFollowersFour
    totalFollowersThree
    totalFollowersTwo
    totalFollowersOne
    totalFollowersZero

    facebookFollowers
    youtubeFollowers
    instagramFollowers
    twitterFollowers
    tiktokFollowers
  }
}
`;
