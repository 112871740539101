import React from "react";
import RaindropBarChartLayout from "../ECharts/Layout/RaindropBarChartLayout";

export const PipelinePotential = ({ data, valueProps }) => {
  const [open, setOpen] = React.useState(true);
  const [init, setInit] = React.useState(false);
  const [newData, setNewData ] = React.useState({"_id":"PipelinePotential","totalFunding":null,"totalMetrics":null})
  
  React.useEffect(() => {
    let met = 0
    let fun = 0
    if(valueProps.length>0) {
      valueProps.forEach(vp => {
        met = met + Number(vp.metrics.find(item => item.kpi === "nsv").y3)
        Object.keys(vp.funding).forEach(fundingItem => (
          fun = fun + vp.funding[fundingItem]
        ))
      })
      let obj = newData
      obj.totalMetrics = `${met == 0 ? null : (met/1000).toFixed(1)}`
      obj.totalFunding = `${fun == 0 ? null : (fun/1000).toFixed(1)}`
      setNewData({...obj})
    }   // eslint-disable-next-line
  }, [valueProps]);

  //data: {"_id":"PipelinePotential","totalFunding":503,"totalMetrics":11.6}


  return (
    <>
      <div class="card h-100">
        <div class="card-header bg-light d-flex flex-between-center py-2">
          <div class="col-auto col-sm-7 col-lg-8 pl-0">
            <h6 class="mb-0 mt-2 text-nowrap py-2 py-xl-0">Pipeline potential</h6>
            <div class=" fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
              <i class="fas fa-info-circle mr-1" />
              Quick guide
            </div>
          </div>
        </div>
        <div class="card-body p-0">
          <div class={`${open ? 'collapse' : ''}`} id="collapsePotential">
            <div class="p-card rounded fs--2 mt-n3">
              <p class="fs--2">
                <ul>
                  <li>NSV potential → Estimated NSV potential three years after launch</li><br/>
                  <li>Total investment → Total reported investments in all value propositions, including, as far as possible, technical development, sales & marketing and overheads. Excludes Box costs</li>
                </ul>
              </p>
            </div>
          </div>
          <RaindropBarChartLayout data={newData} />
        </div>
      </div>
    </>
  );
};