export const coreList = [
    {
        label: 'Core (red)',
        value: 'Core (red)'
    },
    {
        label: 'Beyond the Core (blue)',
        value: 'Beyond the Core (blue)'
    }


]