export const channelList = [
    {
        label: 'Online: Flagship store',
        value: 'Online: Flagship store'
    },
    {
        label: 'Online: Subscription',
        value: 'Online: Subscription'
    },
    {
        label: 'Online: Marketpace',
        value: 'Online: Marketpace'
    },
    {
        label: 'Online: Shop in shop',
        value: 'Online: Shop in shop'
    },
    {
        label: 'Online: Social media',
        value: 'Online: Social media'
    },
    {
        label: 'Online: Home delivery',
        value: 'Online: Home delivery'
    },
    {
        label: 'Offline: Flagship store',
        value: 'Offline: Flagship store'
    },
    {
        label: 'Offline: Shop in shop',
        value: 'Offline: Shop in shop'
    },
    {
        label: 'Offline: Pop up store',
        value: 'Offline: Pop up store'
    },
    {
        label: 'Online: Digital distributor',
        value: 'Online: Digital distributor'
    },


]