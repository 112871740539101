import React from 'react';
// Styling for this component: see "../../assets/scss/_user.scss"

const PageTitle = ({ title, subtitle, sticky }) => {
  return (
    <>
      <div 
        className="flex-start-center page-title-wrapper bg-white" 
        style={{ position: `${sticky ? "sticky" : ""}`, top: "15px", zIndex: "100" }} //old = 64px
      >
        <img className="" src={require('../../assets/img/mars/MARS_D2CX.svg')} alt="" width="125" />
        <div className="page-title">
          <div className="title-hr-wrapper">
            <span className="page-title-main mars-blue">{title}</span>
            <hr className="page-title-hr" />
          </div>
          <p className="page-subtitle">{subtitle}</p>
        </div>
      </div>
    </>
  );
};

export default PageTitle;
