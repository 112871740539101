import React from 'react';

const PipelineMixChannelCard = ({channel, total}) => {
  // let occurrence = Math.round((item.channelOccurrence / totalOccurrence) * 100 ) + "%";
  
  let channelIcon = "";
  let name = ""
  let amount = ""

  Object.keys(channel).map(item => {
    name = item
    amount = (channel[item] * 100 / total).toFixed(0)
  })


  if (name.includes("Online")) channelIcon = "fas fa-wifi fs--1 me-1";
  if (name.includes("Offline")) channelIcon = "fas fa-store fs--1 me-1";
  //if (item.channel === "Online platforms") channelIcon = "fas fa-wifi fs--1 me-1";

  
  return (
    <tr class="btn-reveal-trigger">
      <td class="align-top name">
        <span class={channelIcon} />{name}
      </td>
      <td class="align-top perc text-end">{amount} %</td>
    </tr>
  );
}

export default PipelineMixChannelCard;