export const sortBrandTable = (sortCategory, order, state, setState) => {
  
  let _data = [];
  
    
  const sortStringData = ( category ) => {
    let items = state.filter( item => item[category] !== "N/A")
    if (order === false)
      _data = items.sort(function(a, b) {
        if (a[category] < b[category]) return -1;
        if (a[category] > b[category]) return  1;
        else return null
      });
  
    else if (order === true)
      _data = items.sort(function(a, b) {
        if (a[category] > b[category]) return -1;
        if (a[category] < b[category]) return  1;
        else return null
      });
  
    else console.log('ERROR: wrong type of ordering in the brandtable: ', order);
  

  
    setState([..._data]);
  };
  
  const sortNumericData = (category) => {
    
    let items = state.filter( item => item[category] !== "N/A")

    if (order === false){
      _data = items.sort(function(a, b) {
        console.log(`${a.image} ${JSON.stringify(parseFloat(a[category]))}`)
        if (parseFloat(a[category] || 0) < parseFloat(b[category] || 0)) return -1;
        if (parseFloat(a[category] || 0) > parseFloat(b[category] || 0)) return  1;
        else return null
      })}


    else if (order === true){
      _data = items.sort(function(a, b) {
        if (parseFloat(a[category] || 0) > parseFloat(b[category] || 0)) return -1;
        if (parseFloat(a[category] || 0) < parseFloat(b[category] || 0)) return  1;
        else return null
      })}
    

    else console.log('ERROR: wrong type of ordering in the brandtable: ', order);


    setState([..._data]);
  };

  switch (sortCategory) {
    default: break;
    case 'name':
    case 'country':
    case 'region':
    case 'species':
    case 'product':
    case 'stage':
    case 'division':
    case 'channel':
    case 'business':
      sortStringData(sortCategory);
      break;

    case 'revenue':
    case 'traffic':
    case 'followers':
    case 'delta1':
    case 'delta2':
      sortNumericData(sortCategory);
      break;
  }

};
