import axios from "axios";
import { pipelineMix } from "./queries/PipelineMix";
import { pipelineProgress } from "./queries/PipelineProgress";
import { pipelineShape } from "./queries/PipelineShape";
import { pipelinePotential } from "./queries/PipelinePotential";
import { getValueProps } from "./queries/GetValueProps";

export const GetPipelineMix = (setState) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: pipelineMix,
    },
  })
    .then((result) => {
      setState( [...result.data.data.PipelineMix.PipelineMix] );
    })
    .catch((err) => console.log(err));
};

export const GetPipelineShape = (setState) => {
  axios({
    //url: "https://d2cx.dev/graphql",
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: pipelineShape,
    },
  })
    .then((result) => {
      setState({ ...result.data.data.PipelineShape });
    })
    .catch((err) => console.log(err));
};

export const GetPipelineProgress = (setActive, setNonActive) => {
  axios({
    url: process.env.REACT_APP_API,
    //url: "https://d2cx.dev/graphql",
    method: "post",
    data: {
      query: pipelineProgress,
    },
  })
    .then((result) => {
      setActive( [...result.data.data.PipelineProgress.Active]) ;
      setNonActive( [...result.data.data.PipelineProgress.NonActive] );
    })
    .catch((err) => console.log(err));
};

export const GetPipelinePotential = (setState) => {
  axios({
    url: process.env.REACT_APP_API,
    //url: "https://d2cx.dev/graphql",
    method: "post",
    data: {
      query: pipelinePotential,
    },
  })
    .then((result) => {
      setState({ ...result.data.data.PipelinePotential });
    })
    .catch((err) => console.log(err));
};

export const GetValueProps = (setState, setStateBackup) => {
  axios({
    url: process.env.REACT_APP_API,
    //url: "https://d2cx.dev/graphql",
    method: "post",
    data: {
      query: getValueProps,
    },
  })
    .then((result) => {
      setState( [...result.data.data.GetValueProps.ValueProposition] );
      setStateBackup( [...result.data.data.GetValueProps.ValueProposition] );
    })
    .catch((err) => console.log(err));
};
