import React from 'react';
import { lookUpList } from '../Lists/lookUpList';
const PipelineProgressCardActive = ({item}) => {

    let color = "mars-cd";
    if (item.currentStage === "ideation") color = "mars-c1"
    if (item.currentStage === "customerVal") color = "mars-c2"
    if (item.currentStage === "mvp") color = "mars-c3"
    if (item.currentStage === "optimize") color = "mars-c4"
    if (item.currentStage === "growth") color = "mars-c5"
    if (item.currentStage === "scale") color = "mars-c6"

    //console.log(item.eventHistory)


    const getSubmittedDate = () => {
      let obj = item.eventHistory[item.eventHistory.length-1]
      return obj.date
      console.log(obj)
    }

    let stageClass = `fas fa-circle fs--2 me-2 ${color}`;

    return (
        <tr class="btn-reveal-trigger">
            <td class="align-top name"><a class="text-primary fw-semi-bold" href={`/monitor/individual/view/${item.uuid}`}>{item.name}</a></td>
            <td class="align-top stage"><span class={stageClass}></span>{` ${lookUpList[item.currentStage]}`}</td>
            <td class="align-top submit">{getSubmittedDate()}</td>
        </tr>
    )
}
export default PipelineProgressCardActive;