export const stageList = [
    {
        label: '1. Ideation',
        value: 'ideation'
    },
    {
        label: '2. Customer validation',
        value: 'customerVal'
    },
    {
      label: '3. MVP',
      value: 'mvp'
    },
    {
      label: '4. Optimize',
      value: 'optimize'
    },
    {
      label: '5. Grow',
      value: 'grow'
    },
    {
      label: '6. Scale',
      value: 'scale'
    }


]