import React from "react";
import Select from 'react-select'
import * as api from "../../components/API/IndividualVpAPI";


export const DropdownFieldSpecies = ({title, handle, isMulti, vpHandler, list}) => {
 
  const handleChange = (vpHandler, e, handle) => {      
    let vpObject = vpHandler.get

    if(e.label === "Cat") {
      vpObject.species = ["Cat"]
      //vpObject.species.push([...species])
    }
    if(e.label === "Dog") {
      vpObject.species = ["Dog"]
      //vpObject.species.push([...species])
    }
    if(e.label === "Cat & Dog") {
      vpObject.species = ["Cat", "Dog"]
      //vpObject.species.push([...species])
    }
    //console.log(`species: ${JSON.stringify(vpObject.species)}`)

    vpHandler.set({...vpObject})
    
  }

  const getValue = () => {
    let vpObject = vpHandler.get
    let obj = {}
    if(vpObject.species.length === 1){
      if(vpObject.species.includes("Cat")){
        obj.label = "Cat"
        obj.value = "Cat"
        return obj
      }
      if(vpObject.species.includes("Dog")){
        obj.label = "Dog"
        obj.value = "Dog"
        return obj
      }
    }
    if(vpObject.species.length === 2){
      obj.label = "Cat & Dog"
      obj.value = "Cat & Dog"
      return obj
    }
    else return null
  }

  const styles = {
    control: (styles, { isDisabled }) => {
      return {
        ...styles,
        height: '28px',
        minHeight: '28px',
        backgroundColor: isDisabled ? '#fafafa' : 'white',
      }
    },
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  return( 
        <Select 
          className="fs--2" 
          isMulti={isMulti}
          isDisabled={api.checkDisabled(vpHandler)}
          isSearchable={false}
          options={list}
          placeholder={`Select ${title}`}
          autosize={false}
          clearable={true}
          onChange={(e) => handleChange(vpHandler, e , handle)}
          value={getValue()}
          styles={styles}
          //styles={{control: customControlStyles}}
          theme={theme => ({
            ...theme,
            borderRadius: 3,
            colors: {
              ...theme.colors,
              primary: '#cccccc',
            },
          })}
        />
  )
}