import React from "react";
import PipelineProgressCardActive from "./PipelineProgressCardActive";
import PipelineProgressCardNonActive from "./PipelineProgressCardNonActive";
import TablePagination from '@material-ui/core/TablePagination';
import { Button } from 'reactstrap';

// import { sortBrandTable } from "../Sort/SortBrandTable";

export const PipelineProgress = ({ valueProps, active, setActive, nonActive, setNonActive }) => {
  const [open, setOpen] = React.useState(true);
  const [sorting, setSorting] = React.useState(false);

  let activeData = Object.values(active);
  let nonActiveData = Object.values(nonActive);

  const [page1, setPage1] = React.useState(0);
  const [page2, setPage2] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(6);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(6);

  const lookUp = {
    "Ideation": "01. Ideation",
    "Cust Val": "02. Customer Validation",
    "MVP": "03. MVP",
    "Optimize": "04. Optimize",
    "Growth": "05. Grow",
    "Scale": "06. Scale"
  }

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage1 = event => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };

  const handleChangeRowsPerPage2 = event => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };

  const itemsOnThisPage = (rowsPerPage, page, list, active) => {
    let _list = list.filter(item => item.active === active)
    let y = rowsPerPage * (page + 1);
    let x = y - rowsPerPage;
    return _list.slice(x, y);
  };

  const getLength = (active) => {
    let _length = valueProps.filter(item => item.active === active).length
    return _length
    //console.log(_length)
  }
  

  const sortTable = ( category, order, state, setState ) => {
    let _data = []
    if (order === false)
      _data = state.sort(function(a, b) {
        if (a[category] < b[category]) return -1;
        if (a[category] > b[category]) return  1;
        else return null
      });
  
    else if (order === true)
      _data = state.sort(function(a, b) {
        if (a[category] > b[category]) return -1;
        if (a[category] < b[category]) return  1;
        else return null
      });

    setState([..._data]);

  };

  const sortStage = ( category, order, state, setState ) => {
    let _data = []

    if (order === false)
      _data = state.sort(function(a, b) {
        if (lookUp[a[category]] < lookUp[b[category]]) return -1;
        if (lookUp[a[category]] > lookUp[b[category]]) return  1;
        else return null
      });
  
    else if (order === true)
      _data = state.sort(function(a, b) {
        if (lookUp[a[category]] > lookUp[b[category]]) return -1;
        if (lookUp[a[category]] < lookUp[b[category]]) return  1;
        else return null
      });

    setState([..._data]);

  };

  return(<>
    
    <div class="card h-100">
      <div class="card-header bg-light d-flex flex-between-center py-2">
        <div class="col-auto col-sm-6 col-lg-7 pl-0">
          <h6 class="mb-0 text-nowrap py-2 py-xl-0 mt-2">Pipeline progress</h6>
          <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
            <i class="fas fa-info-circle mr-1"/>
              Quick guide
          </div>
        </div>
      </div>
      <div class="card-body px-0 py-0">
        <div class={`${open ? "collapse" : ""}`} id="collapsePotential">
          <div class="p-card rounded fs--2 mt-n3">
            <p class="fs--2">
              Overview of value propositions that have been submitted for approval the longest (top table), and that have been in their respective stages the longest (bottom table)
            </p>          
          </div>
        </div>
        <div class="text-center mb-2 mt-1 fs--2">Value propositions pending for review</div>
          <div id="valueProptable2" data-list='{"valueNames":["name", "stage", "submit"],"page":3,"pagination":true,"fallback":"pages-table-fallback"}'>
            <div class="table-responsive scrollbar">
              <table class="table table-sm fs--2 mb-0 overflow-hidden">
                <thead class="bg-200 text-900">
                  <tr>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="name">
                      <div class="row ml-1">
                        <div class="mr-1">
                          Name
                        </div>
                        <Button
                          color="none"
                          size="sm"
                          style={{ margin: 0, padding: 0, height: '16px', marginTop: '-2px' }}
                          onClick={() => {
                            sortTable("_id", sorting, active, setActive)
                            setSorting(!sorting)
                          }}
                        >
                          <div  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            <i className="fas fa-caret-up table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
                            <i className="fas fa-caret-down table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
                          </div>
                        </Button>
                      </div>
                    </th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="name">
                      <div class="row ml-1">
                        <div class="mr-1">
                          Stage
                        </div>
                        <Button
                          color="none"
                          size="sm"
                          style={{ margin: 0, padding: 0, height: '16px', marginTop: '-2px' }}
                          onClick={() => {
                            sortStage("stage", sorting, active, setActive)
                            setSorting(!sorting)
                          }}
                        >
                          <div  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            <i className="fas fa-caret-up table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
                            <i className="fas fa-caret-down table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
                          </div>
                        </Button>
                      </div>
                    </th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="name">
                      <div class="row ml-1">
                        <div class="mr-1">
                          Submitted
                        </div>
                        <Button
                          color="none"
                          size="sm"
                          style={{ margin: 0, padding: 0, height: '16px', marginTop: '-2px' }}
                          onClick={() => {
                            //sortBrandTable(column.id, sorting, tableItems, setTableItems)
                            //setSorting(!sorting)
                          }}
                        >
                          {// <div  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                          //   <i className="fas fa-caret-up table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
                          //   <i className="fas fa-caret-down table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
                          // </div>
                          }
                        </Button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody class="list fs--2">
                {itemsOnThisPage(rowsPerPage1, page1, valueProps, false).map((item) => <PipelineProgressCardActive item={item} key={item.uuid} /> )}
                </tbody>
              </table>
            </div>
            <div class="text-center d-none" id="pages-table-fallback">
              <p class="fw-bold fs-1 mt-3">No Page found</p>
            </div>
            <TablePagination
              rowsPerPageOptions={[3, 6, 12]}
              component="div"
              count={getLength(false)}
              rowsPerPage={rowsPerPage1}
              page={page1}
              onPageChange={handleChangePage1}
              onRowsPerPageChange={handleChangeRowsPerPage1}
              className="pagination-item"
              style={{ marginLeft: '15px', padding: 0, borderStyle: 'none', marginBottom: 0 }}
            />
            {// <div class="row align-items-center ms-1 me-1 mt-2">
            //   <div class="pagination d-none"></div>
            //   <div class="col">
            //     <p class="mb-0"><span class="d-none d-sm-inline-block me-2" data-list-info="data-list-info"></span></p>
            //   </div>
              // <div class="col-auto d-flex">
              //   <button class="btn btn-sm btn-primary fs--2" type="button" data-list-pagination="prev"><span>Previous</span></button>
              //   <button class="btn btn-sm btn-primary fs--2 px-4 ms-2" type="button" data-list-pagination="next"><span>Next</span></button>
              // </div>
              }
            </div>
          </div>
          <hr/>
          <div class="text-center mb-2 fs--2">Active value propositions</div>
          <div id="valueProptable2" data-list='{"valueNames":["name", "stage", "weeks", "pivot"],"page":3,"pagination":true,"fallback":"pages-table-fallback"}'>
            <div class="table-responsive scrollbar">
              <table class="table table-sm fs--2 mb-0 overflow-hidden">
                <thead class="bg-200 text-900">
                  <tr>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="name">
                      <div class="row ml-1">
                        <div class="mr-1">
                          Name
                        </div>
                        <Button
                          color="none"
                          size="sm"
                          style={{ margin: 0, padding: 0, height: '16px', marginTop: '-2px' }}
                          onClick={() => {
                            sortTable("_id", sorting, nonActive, setNonActive)
                            setSorting(!sorting)
                          }}
                        >
                          <div  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            <i className="fas fa-caret-up table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
                            <i className="fas fa-caret-down table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
                          </div>
                        </Button>
                      </div>
                    </th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="name">
                      <div class="row ml-1">
                        <div class="mr-1">
                          Stage
                        </div>
                        <Button
                          color="none"
                          size="sm"
                          style={{ margin: 0, padding: 0, height: '16px', marginTop: '-2px' }}
                          onClick={() => {
                            sortStage("stage", sorting, nonActive, setNonActive)
                            setSorting(!sorting)
                          }}
                        >
                          <div  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            <i className="fas fa-caret-up table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
                            <i className="fas fa-caret-down table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
                          </div>
                        </Button>
                      </div>
                    </th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="weeks">Weeks</th>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="pivot">Pivot</th>
                  </tr>
                </thead>
                <tbody class="list fs--2">
                {itemsOnThisPage(rowsPerPage2, page2, valueProps, true).map((item) => <PipelineProgressCardNonActive item={item} key={item.cid}/> )}
                </tbody>
              </table>
            </div>
            <TablePagination
              rowsPerPageOptions={[3, 6, 12]}
              labelRowsPerPage={"Items: "}
              component="div"
              count={getLength(true)}
              rowsPerPage={rowsPerPage2}
              page={page2}
              onPageChange={handleChangePage2}
              onRowsPerPageChange={handleChangeRowsPerPage2}
              className="pagination-item"
              style={{ marginLeft: '15px', padding: 0, borderStyle: 'none', marginBottom: 0 }}
            />
            <div class="text-center d-none" id="pages-table-fallback">
              <p class="fw-bold fs-1 mt-3">No Page found</p>
            </div>
          {// <div class="row align-items-center ms-1 me-1 mt-2">
          //     <div class="pagination d-none"></div>
          //     <div class="col">
          //       <p class="mb-0"><span class="d-none d-sm-inline-block me-2" data-list-info="data-list-info"></span></p>
          //     </div>
            // <div class="col-auto d-flex">
              //   <button class="btn btn-sm btn-primary fs--2" type="button" data-list-pagination="prev"><span>Previous</span></button>
              //   <button class="btn btn-sm btn-primary fs--2 px-4 ms-2" type="button" data-list-pagination="next"><span>Next</span></button>
              // </div>
              }
            </div>
          </div>

  
          
  </>)}