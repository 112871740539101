import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { countryList } from '../Lists/countryList';

const PipelineMixCountryCard = ({country, total}) => {
  //let occurrence = Math.round((item.countryOccurrence / totalOccurrence) * 100 ) + "%";
  let name = ""
  let amount = ""

  Object.keys(country).map(item => {
    name = item
    amount = (country[item] * 100 / total).toFixed(0)
  })

  let countryCode = "";
  if (name !== "Unspecified" && name !== ""){
    let obj = countryList.flat().find(arrayItem => arrayItem.Name === name)
    if(obj !== undefined) countryCode = obj.Code
  }
    //if (name !== "") countryCode = countryList.find(arrayItem => arrayItem.Name === name).Code;
  
  
  return (
    <tr class="btn-reveal-trigger">
      <td class="align-top name">
        {name === "Unspecified" ? "Unspecified" : 
        <ReactCountryFlag countryCode={countryCode} style={{ width: '20px' }} svg /> }
      </td>
      <td class="align-top perc text-end">{amount} %</td>
    </tr>
  );
}

export default PipelineMixCountryCard;