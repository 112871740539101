import React from "react";
import { Input } from "reactstrap";
//import 'assets/scss/custom/_individualVP.scss';
// import { hoverCopyList } from "../../components/Lists/hoverCopyList";
import * as api from "../../components/API/IndividualVpAPI";


export const InputField = ({e, title, vpHandler}) => {

  const handleChange = (e) => {
    let vpObject = vpHandler.get
    vpObject[title.toLowerCase()] = e.target.value
    vpHandler.set({...vpObject})
  }

  return(
    <Input 
      className="" 
      type="textfield" 
      name={title.toLowerCase()}
      style={{fontSize: '12px', height: '28px'}}
      onChange={e=>handleChange(e)}
      //onChange={e => api.createVPDetails(vpHandler, e)}
      placeholder={`Define ${title} ...`}
      value={(vpHandler.get[title.toLowerCase()])}
      disabled={api.checkDisabled(vpHandler)}
    />
  )
}