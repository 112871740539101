import React from "react";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import * as api from "./../../API/IndividualVpAPI";
import axios from 'axios'
import { useOktaAuth } from "@okta/okta-react";

export const HistoryOverview = ({vpHandler}) => {
  const { authState, oktaAuth } = useOktaAuth(); 
  //authState.accessToken.claims.sub
  const [open, setOpen] = React.useState(true);
  const [hide, setHide] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("preview");

  const getType = (type) => {
    if(type.includes("doc")) return "docx"
    if(type.includes("jp") || type.includes("png")) return "jpg"
    if(type.includes("pdf")) return "pdf"
    if(type.includes("ppt")) return "pptx"
    else return "file"
  }

  React.useEffect(() => {
    console.log(authState.idToken.claims.name)
}, [authState, oktaAuth]);

  const getDownloadLink = (item) => {
    //console.log(`item: ${JSON.stringify(item)}`)
    axios
      .post( process.env.REACT_APP_API, {
        query:`query GetDownload($fileId: String, $fileName: String){
          GetDownload(fileId:$fileId, fileName:$fileName)
        }`,
        variables: {
          fileId: item.fileUuid,
          fileName: `${item.fileName}.pdf`
        }
      })
    .then(res => {
      console.log(res.data.data.GetDownload)
      openInNewTab(res.data.data.GetDownload)
    })
    .catch(err => console.log(err))
  }
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const events = [
    {
      date: "01-01-2021",
      event: "Ideation -> Customer Validation",
      decision: "Persevere",
      document: "Ideation stage review",
      link: ""
    },
    {
      date: "01-02-2021",
      event: "Customer Validation -> MVP",
      decision: "Pivot",
      document: "Customer Validation stage review",
      link: ""
    },
    {
      date: "01-03-2021",
      event: "MVP -> Optimize",
      decision: "Persevere",
      document: "MVP stage review",
      link: ""
    },
  ] 

  return(
    
    <div class="card mb-3">
      <div class="card-header pb-1 bg-light">
        <h6 class="mb-0">Value proposition history overview</h6>
        <div class="row col m-0 p-0">
          <div>
            <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
              <i class="fas fa-info-circle mr-1"/>
              Quick help & legend
            </div>
          </div>
          <div class="ml-auto">
            <div class="me-2 fs--2 mars-blue" onClick={e => setHide(!hide)} role="button">
              view / hide
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class={`${open ? "collapse" : ""}`} id="collapseFilter">
          <div class="p-card rounded fs--2">
            <p>The fields and their explanation:
            </p>
          </div>
        </div>
        <div class={`${hide ? "collapse" : ""} card-body`}>
        <div class="mb-2">
          <div class="table-responsive scrollbar">
            <table class="table table-sm fs--2 mb-0 overflow-hidden">
              <thead class="bg-200 text-900">
                <tr>
                  <th class="align-middle white-space-nowrap" >Date</th>
                  <th class="align-middle white-space-nowrap" >Event</th>
                  <th class="align-middle white-space-nowrap" >Requested By</th>
                  <th class="align-middle white-space-nowrap">Decision</th>
                  <th class="align-middle white-space-nowrap">Document</th>
                  <th class="align-middle white-space-nowrap">Download</th>
                </tr>
              </thead>
              <tbody class="list fs--2">
              {!!vpHandler.get.eventHistory&& vpHandler.get.eventHistory.map((item, itt) => (
                <tr key={itt} class="">
                  <td class="">
                    {item.date}
                  </td>
                  <td class="">
                    {item.event}
                  </td>
                  <td class="">
                    {item.requester || "N/A"}
                  </td>
                  <td class="">
                    {item.decision}
                  </td>
                  <td class="">
                    {item.fileName}
                  </td>
                  <td class="">
                    {item.decision !== "N/A" &&<div onClick={e => getDownloadLink(item)}><span class="fas fa-cloud-download-alt fs--2 mars-blue hoverhand"></span></div>}
                  </td>
                </tr>
              ))}
              
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>      
  )}