import React from 'react';
import { CardBody, CardFooter, Row, Col, UncontrolledTooltip } from 'reactstrap';
import HorizontalBarChart from '../HorizontalBarChart';
import  {Months} from "./../../lookupTables/Months"
import moment from 'moment';

const HorizontalBarChartLayout = ({ data, title, subtitle, width, height }) => {
  let cardWidth = width !== null ? width : '100%';
  let cardHeight = height !== null ? height : '100%';

  let topQuartile = 0;
  let median = 0;
  let bottomQuartile = 0;
  let positionTop = 0;
  let positionMed = 0;
  let positionBot = 0;

  let currentMonth = new Date().getMonth()-1;
  let currentYear = new Date().getFullYear();
  let lastYear = null;
  let subtitleText = null; 

  if (currentMonth - 5 < 0) lastYear = currentYear - 1;
  else lastYear = currentYear;

  //subtitleText = `${Months[(currentMonth - 5) % 11]} ${lastYear} - ${Months[currentMonth]} ${currentYear}`;
  subtitleText = `${moment().subtract(6, 'months').format('MMM YYYY')} - ${moment().subtract(1, 'months').format('MMM YYYY')}`

  if ( data.length > 4 ) {
    let _data = data;
    
    _data = _data.sort(function(a,b){
      return (a.value) - (b.value);
    });

    positionTop = (0.75 * (_data.length + 1));
    positionMed = (0.50 * (_data.length + 1));
    positionBot = (0.25 * (_data.length + 1));

    if (positionTop % 1 !== 0) {
      let top = Math.round(positionTop);
      let bot = Math.round(positionTop) - 1;

      topQuartile = ((_data[top - 1].value + _data[bot - 1].value) / 2).toFixed(1);
    }
    else topQuartile = _data[positionTop].value;

    if (positionMed % 1 !== 0) {
      let top = Math.round(positionMed);
      let bot = Math.round(positionMed) - 1;

      median = ((_data[top - 1].value + _data[bot - 1].value) / 2).toFixed(1);
    }
    else median = _data[positionMed].value;

    if (positionBot % 1 !== 0) {
      let top = Math.round(positionBot);
      let bot = Math.round(positionBot) - 1;

      bottomQuartile = ((_data[top].value + _data[bot].value) / 2).toFixed(1);
    }
    else bottomQuartile = _data[positionBot].value;
  }

  return (
    <div class="card mb-3" style={{ width: `${cardWidth}`, height: `${cardHeight}` }}>
    <div class="card-header pb-1 bg-light">
      <h6 className="card-title fs--2 mb-0 pb-0 pt-1" style={{ cursor: "default"}}>{title}</h6>
      <h6 className="card-title fs--2 mb-0 pb-0 pt-1" style={{ cursor: "default"}} id={`${subtitle.includes('engagement') ? "subtitleTooltip" : ""}`}>{subtitle}</h6>
      <UncontrolledTooltip placement="right" target="subtitleTooltip">
        Reactions to social media posts (#) / Social media followers (#)
      </UncontrolledTooltip>
      <h6 class="mb-0 mt-1 fs--2 text-500">{subtitleText}</h6>
    </div>
      <CardBody style={{ paddingTop: 0, marginTop: 0 }}>
        <div className="chart">
          <HorizontalBarChart data={data} title={title} />
        </div>
      </CardBody>
      <CardFooter
        style={{backgroundColor: '#f9fafd', height: '50px', paddingTop: 10 }}
      >
        <Row style={{padding: 0, margin: 0}}>
          <Col
            xl="4"
            lg="4"
            sm="4"
            className="text-sans-serif"
            style={{ fontSize: '0.7rem', color: '#232e3c', padding: 0, margin: 0, textAlign: 'center' }}
          >
            <div>Top quartile</div>
            <div>{topQuartile}</div>
          </Col>
          <Col
            xl="4"
            lg="4"
            sm="4"
            className="text-sans-serif"
            style={{ fontSize: '0.7rem', color: '#232e3c', padding: 0, margin: 0, textAlign: 'center' }}
          >
            <div>Median</div>
            <div>{median}</div>
          </Col>
          <Col
            xl="4"
            lg="4"
            sm="4"
            className="text-sans-serif"
            style={{ fontSize: '0.7rem', color: '#232e3c', padding: 0, margin: 0, textAlign: 'center' }}
          >
            <div>Bottom quartile</div>
            <div>{bottomQuartile}</div>
          </Col>
        </Row>
      </CardFooter>
    </div>
  );
};
export default HorizontalBarChartLayout;
