export const socialHistoryData = [
  {
    "cid": "APupAbove",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 5870
        },
        {
          "date": "2021-10",
          "followers": 5661
        },
        {
          "date": "2021-09",
          "followers": 5465
        },
        {
          "date": "2021-08",
          "followers": 5262
        },
        {
          "date": "2021-07",
          "followers": 5129
        },
        {
          "date": "2021-06",
          "followers": 4944
        },
        {
          "date": "2021-05",
          "followers": 4898
        },
        {
          "date": "2021-04",
          "followers": 4717
        },
        {
          "date": "2021-03",
          "followers": 4611
        },
        {
          "date": "2021-02",
          "followers": 4610
        },
        {
          "date": "2021-01",
          "followers": 4580
        },
        {
          "date": "2020-12",
          "followers": 4562
        },
        {
          "date": "2020-08",
          "followers": 4437
        },
        {
          "date": "2020-07",
          "followers": 4441
        },
        {
          "date": "2020-06",
          "followers": 4443
        },
        {
          "date": "2020-05",
          "followers": 4459
        },
        {
          "date": "2020-04",
          "followers": 4465
        },
        {
          "date": "2020-03",
          "followers": 4468
        },
        {
          "date": "2020-02",
          "followers": 4475
        },
        {
          "date": "2020-01",
          "followers": 4473
        },
        {
          "date": "2019-12",
          "followers": 4493
        },
        {
          "date": "2019-11",
          "followers": 4490
        },
        {
          "date": "2019-10",
          "followers": 4460
        },
        {
          "date": "2019-09",
          "followers": 4466
        },
        {
          "date": "2019-08",
          "followers": 4497
        },
        {
          "date": "2019-07",
          "followers": 4502
        },
        {
          "date": "2019-06",
          "followers": 4454
        },
        {
          "date": "2019-05",
          "followers": 4448
        },
        {
          "date": "2019-04",
          "followers": 4421
        },
        {
          "date": "2019-03",
          "followers": 4316
        },
        {
          "date": "2019-02",
          "followers": 4316
        },
        {
          "date": "2019-01",
          "followers": 4269
        },
        {
          "date": "2018-12",
          "followers": 4279
        },
        {
          "date": "2018-11",
          "followers": 4314
        },
        {
          "date": "2018-10",
          "followers": 4239
        },
        {
          "date": "2018-09",
          "followers": 3979
        },
        {
          "date": "2018-08",
          "followers": 3511
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 3215
        },
        {
          "date": "2021-10",
          "likes": 3193
        },
        {
          "date": "2021-09",
          "likes": 3146
        },
        {
          "date": "2021-08",
          "likes": 3124
        },
        {
          "date": "2021-07",
          "likes": 3106
        },
        {
          "date": "2021-06",
          "likes": 3073
        },
        {
          "date": "2021-05",
          "likes": 3067
        },
        {
          "date": "2021-04",
          "likes": 3048
        },
        {
          "date": "2021-03",
          "likes": 3037
        },
        {
          "date": "2021-02",
          "likes": 3034
        },
        {
          "date": "2021-01",
          "likes": 3038
        },
        {
          "date": "2020-12",
          "likes": 3020
        },
        {
          "date": "2020-11",
          "likes": 3008
        },
        {
          "date": "2020-10",
          "likes": 2985
        },
        {
          "date": "2020-09",
          "likes": 2975
        },
        {
          "date": "2020-08",
          "likes": 2965
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "BalancedBlends",
    "instagram": null,
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 218
        },
        {
          "date": "2021-10",
          "followers": 218
        },
        {
          "date": "2021-09",
          "followers": 218
        },
        {
          "date": "2021-08",
          "followers": 218
        },
        {
          "date": "2021-07",
          "followers": 219
        },
        {
          "date": "2021-06",
          "followers": 219
        },
        {
          "date": "2021-05",
          "followers": 219
        },
        {
          "date": "2021-04",
          "followers": 220
        },
        {
          "date": "2021-03",
          "followers": 221
        },
        {
          "date": "2021-02",
          "followers": 221
        },
        {
          "date": "2021-01",
          "followers": 222
        },
        {
          "date": "2020-12",
          "followers": 224
        },
        {
          "date": "2020-11",
          "followers": 228
        },
        {
          "date": "2020-10",
          "followers": 228
        },
        {
          "date": "2020-09",
          "followers": 227
        },
        {
          "date": "2020-08",
          "followers": 227
        },
        {
          "date": "2020-07",
          "followers": 227
        },
        {
          "date": "2020-06",
          "followers": 226
        },
        {
          "date": "2020-05",
          "followers": 228
        },
        {
          "date": "2020-04",
          "followers": 229
        },
        {
          "date": "2020-03",
          "followers": 228
        },
        {
          "date": "2020-02",
          "followers": 227
        },
        {
          "date": "2020-01",
          "followers": 228
        },
        {
          "date": "2019-12",
          "followers": 228
        },
        {
          "date": "2019-11",
          "followers": 229
        },
        {
          "date": "2019-10",
          "followers": 225
        },
        {
          "date": "2019-09",
          "followers": 227
        },
        {
          "date": "2019-08",
          "followers": 228
        },
        {
          "date": "2019-07",
          "followers": 227
        },
        {
          "date": "2019-06",
          "followers": 226
        },
        {
          "date": "2019-05",
          "followers": 227
        },
        {
          "date": "2019-04",
          "followers": 226
        },
        {
          "date": "2019-03",
          "followers": 225
        },
        {
          "date": "2019-02",
          "followers": 229
        },
        {
          "date": "2019-01",
          "followers": 228
        },
        {
          "date": "2018-12",
          "followers": 229
        },
        {
          "date": "2018-11",
          "followers": 231
        },
        {
          "date": "2018-10",
          "followers": 235
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Barkbox",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 1786034
        },
        {
          "date": "2021-10",
          "followers": 1783640
        },
        {
          "date": "2021-09",
          "followers": 1779226
        },
        {
          "date": "2021-08",
          "followers": 1776579
        },
        {
          "date": "2021-07",
          "followers": 1772563
        },
        {
          "date": "2021-06",
          "followers": 1767428
        },
        {
          "date": "2021-05",
          "followers": 1765364
        },
        {
          "date": "2021-04",
          "followers": 1760935
        },
        {
          "date": "2021-03",
          "followers": 1753223
        },
        {
          "date": "2021-02",
          "followers": 1745596
        },
        {
          "date": "2021-01",
          "followers": 1736415
        },
        {
          "date": "2020-12",
          "followers": 1740070
        },
        {
          "date": "2020-11",
          "followers": 1724034
        },
        {
          "date": "2020-10",
          "followers": 1712401
        },
        {
          "date": "2020-09",
          "followers": 1701587
        },
        {
          "date": "2020-08",
          "followers": 1689540
        },
        {
          "date": "2020-07",
          "followers": 1676951
        },
        {
          "date": "2020-06",
          "followers": 1664577
        },
        {
          "date": "2020-05",
          "followers": 1656456
        },
        {
          "date": "2020-04",
          "followers": 1636545
        },
        {
          "date": "2020-03",
          "followers": 1619851
        },
        {
          "date": "2020-02",
          "followers": 1611627
        },
        {
          "date": "2020-01",
          "followers": 1606520
        },
        {
          "date": "2019-12",
          "followers": 1602183
        },
        {
          "date": "2019-11",
          "followers": 1599205
        },
        {
          "date": "2019-10",
          "followers": 1591304
        },
        {
          "date": "2019-09",
          "followers": 1580598
        },
        {
          "date": "2019-08",
          "followers": 1571691
        },
        {
          "date": "2019-07",
          "followers": 1562219
        },
        {
          "date": "2019-06",
          "followers": 1555083
        },
        {
          "date": "2019-05",
          "followers": 1548616
        },
        {
          "date": "2019-04",
          "followers": 1542194
        },
        {
          "date": "2019-03",
          "followers": 1533894
        },
        {
          "date": "2019-02",
          "followers": 1523238
        },
        {
          "date": "2019-01",
          "followers": 1509520
        },
        {
          "date": "2018-12",
          "followers": 1498605
        },
        {
          "date": "2018-11",
          "followers": 1487239
        },
        {
          "date": "2018-10",
          "followers": 1476523
        },
        {
          "date": "2018-09",
          "followers": 1469432
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 3029386
        },
        {
          "date": "2021-10",
          "likes": 3028860
        },
        {
          "date": "2021-09",
          "likes": 3028249
        },
        {
          "date": "2021-08",
          "likes": 3029237
        },
        {
          "date": "2021-07",
          "likes": 3029781
        },
        {
          "date": "2021-06",
          "likes": 3029907
        },
        {
          "date": "2021-05",
          "likes": 3031476
        },
        {
          "date": "2021-04",
          "likes": 3031038
        },
        {
          "date": "2021-03",
          "likes": 3030083
        },
        {
          "date": "2021-02",
          "likes": 3030048
        },
        {
          "date": "2021-01",
          "likes": 3033703
        },
        {
          "date": "2020-12",
          "likes": 3032522
        },
        {
          "date": "2020-11",
          "likes": 3030182
        },
        {
          "date": "2020-10",
          "likes": 3022827
        },
        {
          "date": "2020-09",
          "likes": 3022215
        },
        {
          "date": "2020-08",
          "likes": 3016938
        },
        {
          "date": "2020-07",
          "likes": 3008863
        },
        {
          "date": "2020-06",
          "likes": 3003875
        },
        {
          "date": "2020-05",
          "likes": 3004147
        },
        {
          "date": "2020-04",
          "likes": 2999672
        },
        {
          "date": "2020-03",
          "likes": 2995294
        },
        {
          "date": "2020-02",
          "likes": 2994473
        },
        {
          "date": "2020-01",
          "likes": 2996142
        },
        {
          "date": "2019-12",
          "likes": 2999920
        },
        {
          "date": "2019-11",
          "likes": 3000920
        },
        {
          "date": "2019-10",
          "likes": 2988149
        },
        {
          "date": "2019-09",
          "likes": 2989408
        },
        {
          "date": "2019-08",
          "likes": 2990865
        },
        {
          "date": "2019-07",
          "likes": 2991243
        },
        {
          "date": "2019-06",
          "likes": 2989690
        },
        {
          "date": "2019-05",
          "likes": 2990434
        },
        {
          "date": "2019-04",
          "likes": 2989996
        },
        {
          "date": "2019-03",
          "likes": 2989131
        },
        {
          "date": "2019-02",
          "likes": 2987245
        },
        {
          "date": "2019-01",
          "likes": 2983460
        },
        {
          "date": "2018-12",
          "likes": 2972675
        },
        {
          "date": "2018-11",
          "likes": 2957789
        },
        {
          "date": "2018-10",
          "likes": 2951270
        },
        {
          "date": "2018-09",
          "likes": 2951535
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 306820
        },
        {
          "date": "2021-10",
          "followers": 306814
        },
        {
          "date": "2021-09",
          "followers": 306760
        },
        {
          "date": "2021-08",
          "followers": 306848
        },
        {
          "date": "2021-07",
          "followers": 306835
        },
        {
          "date": "2021-06",
          "followers": 306908
        },
        {
          "date": "2021-05",
          "followers": 307402
        },
        {
          "date": "2021-04",
          "followers": 309217
        },
        {
          "date": "2021-03",
          "followers": 309223
        },
        {
          "date": "2021-02",
          "followers": 309192
        },
        {
          "date": "2021-01",
          "followers": 309078
        },
        {
          "date": "2020-12",
          "followers": 309593
        },
        {
          "date": "2020-11",
          "followers": 308960
        },
        {
          "date": "2020-10",
          "followers": 308054
        },
        {
          "date": "2020-09",
          "followers": 307656
        },
        {
          "date": "2020-08",
          "followers": 308447
        },
        {
          "date": "2020-07",
          "followers": 308825
        },
        {
          "date": "2020-06",
          "followers": 309133
        },
        {
          "date": "2020-05",
          "followers": 310633
        },
        {
          "date": "2020-04",
          "followers": 310823
        },
        {
          "date": "2020-03",
          "followers": 309920
        },
        {
          "date": "2020-02",
          "followers": 310883
        },
        {
          "date": "2020-01",
          "followers": 310690
        },
        {
          "date": "2019-12",
          "followers": 311121
        },
        {
          "date": "2019-11",
          "followers": 311468
        },
        {
          "date": "2019-10",
          "followers": 311271
        },
        {
          "date": "2019-09",
          "followers": 311577
        },
        {
          "date": "2019-08",
          "followers": 311883
        },
        {
          "date": "2019-07",
          "followers": 311046
        },
        {
          "date": "2019-06",
          "followers": 310428
        },
        {
          "date": "2019-05",
          "followers": 310112
        },
        {
          "date": "2019-04",
          "followers": 309144
        },
        {
          "date": "2019-03",
          "followers": 308388
        },
        {
          "date": "2019-02",
          "followers": 308597
        },
        {
          "date": "2019-01",
          "followers": 309065
        },
        {
          "date": "2018-12",
          "followers": 309899
        },
        {
          "date": "2018-11",
          "followers": 310696
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Barkyn",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 48383
        },
        {
          "date": "2021-10",
          "followers": 48170
        },
        {
          "date": "2021-09",
          "followers": 47745
        },
        {
          "date": "2021-08",
          "followers": 47013
        },
        {
          "date": "2021-07",
          "followers": 45826
        },
        {
          "date": "2021-06",
          "followers": 44816
        },
        {
          "date": "2021-05",
          "followers": 43383
        },
        {
          "date": "2021-04",
          "followers": 42366
        },
        {
          "date": "2021-03",
          "followers": 41680
        },
        {
          "date": "2021-02",
          "followers": 40446
        },
        {
          "date": "2021-01",
          "followers": 39864
        },
        {
          "date": "2020-12",
          "followers": 38865
        },
        {
          "date": "2020-11",
          "followers": 37157
        },
        {
          "date": "2020-10",
          "followers": 36115
        },
        {
          "date": "2020-09",
          "followers": 35167
        },
        {
          "date": "2020-08",
          "followers": 34252
        },
        {
          "date": "2020-07",
          "followers": 33130
        },
        {
          "date": "2020-06",
          "followers": 32225
        },
        {
          "date": "2020-05",
          "followers": 31232
        },
        {
          "date": "2020-04",
          "followers": 30261
        },
        {
          "date": "2020-03",
          "followers": 29308
        },
        {
          "date": "2020-02",
          "followers": 28615
        },
        {
          "date": "2020-01",
          "followers": 27976
        },
        {
          "date": "2019-12",
          "followers": 27329
        },
        {
          "date": "2019-11",
          "followers": 26732
        },
        {
          "date": "2019-10",
          "followers": 26339
        },
        {
          "date": "2019-09",
          "followers": 25839
        },
        {
          "date": "2019-08",
          "followers": 25386
        },
        {
          "date": "2019-02",
          "followers": 22825
        },
        {
          "date": "2019-01",
          "followers": 22283
        },
        {
          "date": "2018-12",
          "followers": 21695
        },
        {
          "date": "2018-11",
          "followers": 21296
        },
        {
          "date": "2018-10",
          "followers": 20852
        },
        {
          "date": "2018-09",
          "followers": 20374
        }
      ]
    },
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 113
        },
        {
          "date": "2021-10",
          "followers": 112
        },
        {
          "date": "2021-09",
          "followers": 110
        },
        {
          "date": "2021-08",
          "followers": 109
        },
        {
          "date": "2021-07",
          "followers": 107
        },
        {
          "date": "2021-06",
          "followers": 106
        },
        {
          "date": "2021-05",
          "followers": 105
        },
        {
          "date": "2021-04",
          "followers": 105
        },
        {
          "date": "2021-03",
          "followers": 103
        },
        {
          "date": "2021-02",
          "followers": 98
        },
        {
          "date": "2021-01",
          "followers": 98
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "BecauseAnimals",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 5710
        },
        {
          "date": "2021-10",
          "followers": 5646
        },
        {
          "date": "2021-09",
          "followers": 5609
        },
        {
          "date": "2021-08",
          "followers": 5408
        },
        {
          "date": "2021-07",
          "followers": 4887
        },
        {
          "date": "2021-06",
          "followers": 4865
        },
        {
          "date": "2021-05",
          "followers": 4761
        },
        {
          "date": "2021-04",
          "followers": 4645
        },
        {
          "date": "2021-03",
          "followers": 4568
        },
        {
          "date": "2021-02",
          "followers": 3898
        },
        {
          "date": "2021-01",
          "followers": 3845
        },
        {
          "date": "2020-11",
          "followers": 3859
        },
        {
          "date": "2020-10",
          "followers": 3824
        },
        {
          "date": "2020-09",
          "followers": 3632
        },
        {
          "date": "2020-08",
          "followers": 3578
        },
        {
          "date": "2020-07",
          "followers": 3507
        },
        {
          "date": "2020-06",
          "followers": 3432
        },
        {
          "date": "2020-05",
          "followers": 3406
        },
        {
          "date": "2020-04",
          "followers": 3367
        },
        {
          "date": "2020-03",
          "followers": 3355
        },
        {
          "date": "2020-02",
          "followers": 3322
        },
        {
          "date": "2020-01",
          "followers": 3191
        },
        {
          "date": "2019-12",
          "followers": 3191
        },
        {
          "date": "2019-11",
          "followers": 3103
        },
        {
          "date": "2019-10",
          "followers": 3022
        },
        {
          "date": "2019-09",
          "followers": 2798
        },
        {
          "date": "2019-08",
          "followers": 2770
        },
        {
          "date": "2019-07",
          "followers": 2779
        },
        {
          "date": "2019-06",
          "followers": 2795
        },
        {
          "date": "2019-05",
          "followers": 2804
        },
        {
          "date": "2019-04",
          "followers": 2757
        },
        {
          "date": "2019-03",
          "followers": 2642
        },
        {
          "date": "2019-02",
          "followers": 2152
        },
        {
          "date": "2019-01",
          "followers": 1770
        },
        {
          "date": "2018-12",
          "followers": 1282
        },
        {
          "date": "2018-11",
          "followers": 1276
        },
        {
          "date": "2018-10",
          "followers": 1240
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 8045
        },
        {
          "date": "2021-10",
          "likes": 8045
        },
        {
          "date": "2021-09",
          "likes": 8040
        },
        {
          "date": "2021-08",
          "likes": 8022
        },
        {
          "date": "2021-07",
          "likes": 7962
        },
        {
          "date": "2021-06",
          "likes": 7940
        },
        {
          "date": "2021-05",
          "likes": 7901
        },
        {
          "date": "2021-04",
          "likes": 7881
        },
        {
          "date": "2021-03",
          "likes": 7820
        },
        {
          "date": "2021-02",
          "likes": 7760
        },
        {
          "date": "2021-01",
          "likes": 7750
        },
        {
          "date": "2020-12",
          "likes": 7740
        },
        {
          "date": "2020-11",
          "likes": 7737
        },
        {
          "date": "2020-10",
          "likes": 7713
        },
        {
          "date": "2020-09",
          "likes": 7682
        },
        {
          "date": "2020-08",
          "likes": 7657
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 604
        },
        {
          "date": "2021-10",
          "followers": 599
        },
        {
          "date": "2021-09",
          "followers": 583
        },
        {
          "date": "2021-08",
          "followers": 568
        },
        {
          "date": "2021-07",
          "followers": 521
        },
        {
          "date": "2021-06",
          "followers": 515
        },
        {
          "date": "2021-05",
          "followers": 509
        },
        {
          "date": "2021-04",
          "followers": 500
        },
        {
          "date": "2021-03",
          "followers": 483
        },
        {
          "date": "2021-02",
          "followers": 474
        },
        {
          "date": "2021-01",
          "followers": 452
        },
        {
          "date": "2020-12",
          "followers": 435
        },
        {
          "date": "2020-11",
          "followers": 434
        },
        {
          "date": "2020-10",
          "followers": 428
        },
        {
          "date": "2020-09",
          "followers": 418
        },
        {
          "date": "2020-08",
          "followers": 410
        },
        {
          "date": "2020-07",
          "followers": 399
        },
        {
          "date": "2020-06",
          "followers": 387
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Bella&amp;Duke",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 23015
        },
        {
          "date": "2021-10",
          "followers": 22804
        },
        {
          "date": "2021-09",
          "followers": 22442
        },
        {
          "date": "2021-08",
          "followers": 21992
        },
        {
          "date": "2021-07",
          "followers": 21424
        },
        {
          "date": "2021-06",
          "followers": 20952
        },
        {
          "date": "2021-05",
          "followers": 20357
        },
        {
          "date": "2021-04",
          "followers": 19723
        },
        {
          "date": "2021-03",
          "followers": 19066
        },
        {
          "date": "2021-02",
          "followers": 18262
        },
        {
          "date": "2021-01",
          "followers": 17429
        },
        {
          "date": "2020-12",
          "followers": 16381
        },
        {
          "date": "2020-11",
          "followers": 15562
        },
        {
          "date": "2020-10",
          "followers": 14549
        },
        {
          "date": "2020-09",
          "followers": 13104
        },
        {
          "date": "2020-08",
          "followers": 12275
        },
        {
          "date": "2020-07",
          "followers": 11311
        },
        {
          "date": "2020-06",
          "followers": 10389
        },
        {
          "date": "2020-05",
          "followers": 8847
        },
        {
          "date": "2020-04",
          "followers": 7966
        },
        {
          "date": "2020-03",
          "followers": 7408
        },
        {
          "date": "2020-02",
          "followers": 7077
        },
        {
          "date": "2020-01",
          "followers": 6776
        },
        {
          "date": "2019-12",
          "followers": 6299
        },
        {
          "date": "2019-11",
          "followers": 5728
        },
        {
          "date": "2019-10",
          "followers": 5467
        },
        {
          "date": "2019-09",
          "followers": 5144
        },
        {
          "date": "2019-08",
          "followers": 4813
        },
        {
          "date": "2019-07",
          "followers": 4421
        },
        {
          "date": "2019-06",
          "followers": 3964
        },
        {
          "date": "2019-05",
          "followers": 3745
        },
        {
          "date": "2019-04",
          "followers": 3477
        },
        {
          "date": "2019-03",
          "followers": 3105
        },
        {
          "date": "2019-02",
          "followers": 2688
        },
        {
          "date": "2019-01",
          "followers": 2418
        },
        {
          "date": "2018-12",
          "followers": 2228
        },
        {
          "date": "2018-11",
          "followers": 2100
        },
        {
          "date": "2018-10",
          "followers": 1787
        },
        {
          "date": "2018-09",
          "followers": 1550
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 36618
        },
        {
          "date": "2021-10",
          "likes": 36513
        },
        {
          "date": "2021-09",
          "likes": 36152
        },
        {
          "date": "2021-08",
          "likes": 35711
        },
        {
          "date": "2021-07",
          "likes": 35201
        },
        {
          "date": "2021-06",
          "likes": 34640
        },
        {
          "date": "2021-05",
          "likes": 34050
        },
        {
          "date": "2021-04",
          "likes": 33347
        },
        {
          "date": "2021-03",
          "likes": 32334
        },
        {
          "date": "2021-02",
          "likes": 31259
        },
        {
          "date": "2021-01",
          "likes": 29909
        },
        {
          "date": "2020-12",
          "likes": 28117
        },
        {
          "date": "2020-11",
          "likes": 27082
        },
        {
          "date": "2020-10",
          "likes": 26149
        },
        {
          "date": "2020-09",
          "likes": 24537
        },
        {
          "date": "2020-08",
          "likes": 23640
        },
        {
          "date": "2020-07",
          "likes": 22460
        },
        {
          "date": "2020-06",
          "likes": 21445
        },
        {
          "date": "2020-05",
          "likes": 20171
        },
        {
          "date": "2020-04",
          "likes": 18980
        },
        {
          "date": "2020-03",
          "likes": 18013
        },
        {
          "date": "2020-02",
          "likes": 17619
        },
        {
          "date": "2020-01",
          "likes": 17204
        },
        {
          "date": "2019-12",
          "likes": 16632
        },
        {
          "date": "2019-11",
          "likes": 16300
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 1401
        },
        {
          "date": "2021-10",
          "followers": 1389
        },
        {
          "date": "2021-09",
          "followers": 1368
        },
        {
          "date": "2021-08",
          "followers": 1350
        },
        {
          "date": "2021-07",
          "followers": 1335
        },
        {
          "date": "2021-06",
          "followers": 1314
        },
        {
          "date": "2021-05",
          "followers": 1301
        },
        {
          "date": "2021-04",
          "followers": 1285
        },
        {
          "date": "2021-03",
          "followers": 1275
        },
        {
          "date": "2021-02",
          "followers": 1260
        },
        {
          "date": "2021-01",
          "followers": 1225
        },
        {
          "date": "2020-12",
          "followers": 1201
        },
        {
          "date": "2020-11",
          "followers": 1187
        },
        {
          "date": "2020-10",
          "followers": 1164
        },
        {
          "date": "2020-09",
          "followers": 1142
        },
        {
          "date": "2020-08",
          "followers": 1123
        },
        {
          "date": "2020-07",
          "followers": 1110
        },
        {
          "date": "2020-06",
          "followers": 1094
        },
        {
          "date": "2020-05",
          "followers": 1078
        },
        {
          "date": "2020-04",
          "followers": 1068
        },
        {
          "date": "2020-03",
          "followers": 1033
        },
        {
          "date": "2020-02",
          "followers": 1023
        },
        {
          "date": "2020-01",
          "followers": 1023
        },
        {
          "date": "2019-12",
          "followers": 1022
        },
        {
          "date": "2019-11",
          "followers": 1020
        },
        {
          "date": "2019-10",
          "followers": 1021
        },
        {
          "date": "2019-09",
          "followers": 1010
        },
        {
          "date": "2019-08",
          "followers": 1006
        },
        {
          "date": "2019-07",
          "followers": 992
        },
        {
          "date": "2019-06",
          "followers": 977
        },
        {
          "date": "2019-05",
          "followers": 967
        },
        {
          "date": "2019-04",
          "followers": 950
        },
        {
          "date": "2019-03",
          "followers": 937
        },
        {
          "date": "2019-02",
          "followers": 919
        },
        {
          "date": "2019-01",
          "followers": 911
        },
        {
          "date": "2018-12",
          "followers": 912
        },
        {
          "date": "2018-11",
          "followers": 912
        },
        {
          "date": "2018-10",
          "followers": 913
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Bibimpaws",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 4446
        },
        {
          "date": "2021-10",
          "followers": 4419
        },
        {
          "date": "2021-09",
          "followers": 4347
        },
        {
          "date": "2021-08",
          "followers": 4283
        },
        {
          "date": "2021-07",
          "followers": 4206
        },
        {
          "date": "2021-06",
          "followers": 4097
        },
        {
          "date": "2021-05",
          "followers": 4096
        },
        {
          "date": "2021-04",
          "followers": 4109
        },
        {
          "date": "2021-03",
          "followers": 4113
        },
        {
          "date": "2021-02",
          "followers": 4089
        },
        {
          "date": "2021-01",
          "followers": 4083
        },
        {
          "date": "2020-12",
          "followers": 4113
        },
        {
          "date": "2020-11",
          "followers": 4120
        },
        {
          "date": "2020-10",
          "followers": 4154
        },
        {
          "date": "2020-09",
          "followers": 4174
        },
        {
          "date": "2020-08",
          "followers": 4201
        },
        {
          "date": "2020-07",
          "followers": 4221
        },
        {
          "date": "2020-06",
          "followers": 4261
        },
        {
          "date": "2020-05",
          "followers": 4289
        },
        {
          "date": "2020-04",
          "followers": 4300
        },
        {
          "date": "2020-03",
          "followers": 4319
        },
        {
          "date": "2020-02",
          "followers": 4193
        },
        {
          "date": "2020-01",
          "followers": 4116
        },
        {
          "date": "2019-12",
          "followers": 4150
        },
        {
          "date": "2019-11",
          "followers": 4179
        },
        {
          "date": "2019-10",
          "followers": 4107
        },
        {
          "date": "2019-09",
          "followers": 3814
        },
        {
          "date": "2019-08",
          "followers": 3547
        },
        {
          "date": "2019-07",
          "followers": 3500
        },
        {
          "date": "2019-06",
          "followers": 3495
        },
        {
          "date": "2019-05",
          "followers": 3441
        },
        {
          "date": "2019-04",
          "followers": 3387
        },
        {
          "date": "2019-03",
          "followers": 3176
        },
        {
          "date": "2019-02",
          "followers": 2370
        },
        {
          "date": "2019-01",
          "followers": 2071
        },
        {
          "date": "2018-12",
          "followers": 1430
        },
        {
          "date": "2018-11",
          "followers": 1265
        },
        {
          "date": "2018-10",
          "followers": 1039
        },
        {
          "date": "2018-09",
          "followers": 852
        },
        {
          "date": "2018-08",
          "followers": 732
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Blink!",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 7067
        },
        {
          "date": "2021-10",
          "followers": 6976
        },
        {
          "date": "2021-09",
          "followers": 6761
        },
        {
          "date": "2021-08",
          "followers": 6630
        },
        {
          "date": "2021-07",
          "followers": 6550
        },
        {
          "date": "2021-06",
          "followers": 6333
        },
        {
          "date": "2021-05",
          "followers": 6217
        },
        {
          "date": "2021-04",
          "followers": 6081
        },
        {
          "date": "2021-03",
          "followers": 6008
        },
        {
          "date": "2021-02",
          "followers": 5889
        },
        {
          "date": "2021-01",
          "followers": 5778
        },
        {
          "date": "2020-12",
          "followers": 5623
        },
        {
          "date": "2020-11",
          "followers": 5111
        },
        {
          "date": "2020-10",
          "followers": 4962
        },
        {
          "date": "2020-06",
          "followers": 3961
        },
        {
          "date": "2020-05",
          "followers": 3892
        },
        {
          "date": "2020-04",
          "followers": 3664
        },
        {
          "date": "2020-03",
          "followers": 3429
        },
        {
          "date": "2020-02",
          "followers": 3258
        },
        {
          "date": "2020-01",
          "followers": 3155
        },
        {
          "date": "2019-12",
          "followers": 3177
        },
        {
          "date": "2019-11",
          "followers": 3166
        },
        {
          "date": "2019-10",
          "followers": 3111
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "BondPetFoods",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 2267
        },
        {
          "date": "2021-10",
          "followers": 2206
        },
        {
          "date": "2021-09",
          "followers": 2218
        },
        {
          "date": "2021-08",
          "followers": 2200
        },
        {
          "date": "2021-07",
          "followers": 2191
        },
        {
          "date": "2021-06",
          "followers": 2171
        },
        {
          "date": "2021-05",
          "followers": 2110
        },
        {
          "date": "2021-04",
          "followers": 2004
        },
        {
          "date": "2021-03",
          "followers": 1933
        },
        {
          "date": "2021-02",
          "followers": 1845
        },
        {
          "date": "2021-01",
          "followers": 1756
        },
        {
          "date": "2020-12",
          "followers": 1717
        },
        {
          "date": "2020-11",
          "followers": 1643
        },
        {
          "date": "2020-10",
          "followers": 1484
        },
        {
          "date": "2020-09",
          "followers": 1369
        },
        {
          "date": "2020-08",
          "followers": 1178
        },
        {
          "date": "2020-07",
          "followers": 956
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 10620
        },
        {
          "date": "2021-10",
          "likes": 10617
        },
        {
          "date": "2021-09",
          "likes": 10650
        },
        {
          "date": "2021-08",
          "likes": 10671
        },
        {
          "date": "2021-07",
          "likes": 10694
        },
        {
          "date": "2021-06",
          "likes": 10721
        },
        {
          "date": "2021-05",
          "likes": 10513
        },
        {
          "date": "2021-04",
          "likes": 9818
        },
        {
          "date": "2021-03",
          "likes": 9424
        },
        {
          "date": "2021-02",
          "likes": 8907
        },
        {
          "date": "2021-01",
          "likes": 8271
        },
        {
          "date": "2020-12",
          "likes": 7348
        },
        {
          "date": "2020-11",
          "likes": 6210
        },
        {
          "date": "2020-10",
          "likes": 5582
        },
        {
          "date": "2020-09",
          "likes": 4944
        },
        {
          "date": "2020-08",
          "likes": 4111
        },
        {
          "date": "2020-07",
          "likes": 2873
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "ButternutBox",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 56977
        },
        {
          "date": "2021-10",
          "followers": 55008
        },
        {
          "date": "2021-09",
          "followers": 51932
        },
        {
          "date": "2021-08",
          "followers": 50036
        },
        {
          "date": "2021-07",
          "followers": 49200
        },
        {
          "date": "2021-06",
          "followers": 48666
        },
        {
          "date": "2021-05",
          "followers": 47835
        },
        {
          "date": "2021-04",
          "followers": 46444
        },
        {
          "date": "2021-03",
          "followers": 45509
        },
        {
          "date": "2021-02",
          "followers": 44348
        },
        {
          "date": "2021-01",
          "followers": 43306
        },
        {
          "date": "2020-12",
          "followers": 42028
        },
        {
          "date": "2020-11",
          "followers": 41031
        },
        {
          "date": "2020-10",
          "followers": 39673
        },
        {
          "date": "2020-09",
          "followers": 38405
        },
        {
          "date": "2020-08",
          "followers": 37155
        },
        {
          "date": "2020-07",
          "followers": 36227
        },
        {
          "date": "2020-06",
          "followers": 35099
        },
        {
          "date": "2020-05",
          "followers": 33517
        },
        {
          "date": "2020-04",
          "followers": 31767
        },
        {
          "date": "2020-03",
          "followers": 30927
        },
        {
          "date": "2020-02",
          "followers": 29635
        },
        {
          "date": "2020-01",
          "followers": 27659
        },
        {
          "date": "2019-12",
          "followers": 25634
        },
        {
          "date": "2019-11",
          "followers": 22487
        },
        {
          "date": "2019-10",
          "followers": 21353
        },
        {
          "date": "2019-09",
          "followers": 20248
        },
        {
          "date": "2019-08",
          "followers": 19226
        },
        {
          "date": "2019-07",
          "followers": 17684
        },
        {
          "date": "2019-06",
          "followers": 16811
        },
        {
          "date": "2019-05",
          "followers": 16477
        },
        {
          "date": "2019-04",
          "followers": 15527
        },
        {
          "date": "2019-03",
          "followers": 14975
        },
        {
          "date": "2019-02",
          "followers": 14379
        },
        {
          "date": "2019-01",
          "followers": 13638
        },
        {
          "date": "2018-12",
          "followers": 12809
        },
        {
          "date": "2018-11",
          "followers": 12467
        },
        {
          "date": "2018-10",
          "followers": 11872
        },
        {
          "date": "2018-09",
          "followers": 11269
        },
        {
          "date": "2018-08",
          "followers": 10798
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 33999
        },
        {
          "date": "2021-10",
          "likes": 33149
        },
        {
          "date": "2021-09",
          "likes": 31466
        },
        {
          "date": "2021-08",
          "likes": 30398
        },
        {
          "date": "2021-07",
          "likes": 30020
        },
        {
          "date": "2021-06",
          "likes": 29692
        },
        {
          "date": "2021-05",
          "likes": 29230
        },
        {
          "date": "2021-04",
          "likes": 28227
        },
        {
          "date": "2021-03",
          "likes": 27641
        },
        {
          "date": "2021-02",
          "likes": 26897
        },
        {
          "date": "2021-01",
          "likes": 26373
        },
        {
          "date": "2020-12",
          "likes": 25793
        },
        {
          "date": "2020-11",
          "likes": 25299
        },
        {
          "date": "2020-10",
          "likes": 24852
        },
        {
          "date": "2020-09",
          "likes": 24155
        },
        {
          "date": "2020-08",
          "likes": 23337
        },
        {
          "date": "2020-07",
          "likes": 22715
        },
        {
          "date": "2020-06",
          "likes": 22156
        },
        {
          "date": "2020-05",
          "likes": 21209
        },
        {
          "date": "2020-04",
          "likes": 20262
        },
        {
          "date": "2020-03",
          "likes": 19696
        },
        {
          "date": "2020-02",
          "likes": 18865
        },
        {
          "date": "2020-01",
          "likes": 17885
        },
        {
          "date": "2019-12",
          "likes": 16914
        },
        {
          "date": "2019-11",
          "likes": 16276
        },
        {
          "date": "2019-10",
          "likes": 15552
        },
        {
          "date": "2019-09",
          "likes": 14896
        },
        {
          "date": "2019-08",
          "likes": 14227
        },
        {
          "date": "2019-07",
          "likes": 13318
        },
        {
          "date": "2019-06",
          "likes": 12680
        },
        {
          "date": "2019-05",
          "likes": 12459
        },
        {
          "date": "2019-04",
          "likes": 12001
        },
        {
          "date": "2019-03",
          "likes": 11537
        },
        {
          "date": "2019-02",
          "likes": 11115
        },
        {
          "date": "2019-01",
          "likes": 10656
        },
        {
          "date": "2018-12",
          "likes": 10075
        },
        {
          "date": "2018-11",
          "likes": 9725
        },
        {
          "date": "2018-10",
          "likes": 9218
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 4896
        },
        {
          "date": "2021-10",
          "followers": 4466
        },
        {
          "date": "2021-09",
          "followers": 3529
        },
        {
          "date": "2021-08",
          "followers": 3016
        },
        {
          "date": "2021-07",
          "followers": 2964
        },
        {
          "date": "2021-06",
          "followers": 2934
        },
        {
          "date": "2021-05",
          "followers": 2896
        },
        {
          "date": "2021-04",
          "followers": 2833
        },
        {
          "date": "2021-03",
          "followers": 2791
        },
        {
          "date": "2021-02",
          "followers": 2754
        },
        {
          "date": "2020-06",
          "followers": 2336
        },
        {
          "date": "2020-05",
          "followers": 2320
        },
        {
          "date": "2020-04",
          "followers": 2272
        },
        {
          "date": "2020-03",
          "followers": 2201
        },
        {
          "date": "2020-02",
          "followers": 2122
        },
        {
          "date": "2020-01",
          "followers": 2046
        },
        {
          "date": "2019-12",
          "followers": 1973
        },
        {
          "date": "2019-11",
          "followers": 1938
        },
        {
          "date": "2019-10",
          "followers": 1882
        },
        {
          "date": "2019-09",
          "followers": 1824
        },
        {
          "date": "2019-08",
          "followers": 1754
        },
        {
          "date": "2019-07",
          "followers": 1708
        },
        {
          "date": "2019-06",
          "followers": 1668
        },
        {
          "date": "2019-05",
          "followers": 1634
        },
        {
          "date": "2019-04",
          "followers": 1584
        },
        {
          "date": "2019-03",
          "followers": 1485
        },
        {
          "date": "2019-02",
          "followers": 1423
        },
        {
          "date": "2019-01",
          "followers": 1368
        },
        {
          "date": "2018-12",
          "followers": 1311
        },
        {
          "date": "2018-11",
          "followers": 1286
        },
        {
          "date": "2018-10",
          "followers": 1253
        },
        {
          "date": "2018-09",
          "followers": 1211
        },
        {
          "date": "2018-08",
          "followers": 1176
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "CaliRaw",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 11646
        },
        {
          "date": "2021-10",
          "followers": 11655
        },
        {
          "date": "2021-09",
          "followers": 11514
        },
        {
          "date": "2021-08",
          "followers": 11472
        },
        {
          "date": "2021-07",
          "followers": 11432
        },
        {
          "date": "2021-06",
          "followers": 11265
        },
        {
          "date": "2021-05",
          "followers": 11073
        },
        {
          "date": "2021-04",
          "followers": 10971
        },
        {
          "date": "2021-03",
          "followers": 10848
        },
        {
          "date": "2021-02",
          "followers": 10762
        },
        {
          "date": "2021-01",
          "followers": 10540
        },
        {
          "date": "2020-12",
          "followers": 10374
        },
        {
          "date": "2020-11",
          "followers": 10154
        },
        {
          "date": "2020-10",
          "followers": 9978
        },
        {
          "date": "2020-09",
          "followers": 9722
        },
        {
          "date": "2020-08",
          "followers": 9429
        },
        {
          "date": "2020-07",
          "followers": 9112
        },
        {
          "date": "2020-06",
          "followers": 8624
        },
        {
          "date": "2020-05",
          "followers": 8322
        },
        {
          "date": "2020-04",
          "followers": 7885
        },
        {
          "date": "2020-03",
          "followers": 7427
        },
        {
          "date": "2020-02",
          "followers": 6802
        },
        {
          "date": "2020-01",
          "followers": 6359
        },
        {
          "date": "2019-12",
          "followers": 5986
        },
        {
          "date": "2019-11",
          "followers": 5651
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "CatPerson",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 33328
        },
        {
          "date": "2021-10",
          "followers": 32651
        },
        {
          "date": "2021-09",
          "followers": 31441
        },
        {
          "date": "2021-08",
          "followers": 30465
        },
        {
          "date": "2021-07",
          "followers": 29817
        },
        {
          "date": "2021-06",
          "followers": 28915
        },
        {
          "date": "2021-05",
          "followers": 27577
        },
        {
          "date": "2021-04",
          "followers": 25923
        },
        {
          "date": "2021-03",
          "followers": 24393
        },
        {
          "date": "2021-02",
          "followers": 22837
        },
        {
          "date": "2021-01",
          "followers": 20772
        },
        {
          "date": "2020-12",
          "followers": 18729
        },
        {
          "date": "2020-11",
          "followers": 17897
        },
        {
          "date": "2020-10",
          "followers": 16957
        },
        {
          "date": "2020-09",
          "followers": 15672
        },
        {
          "date": "2020-08",
          "followers": 13720
        },
        {
          "date": "2020-07",
          "followers": 12032
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "ChiDog",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 3586
        },
        {
          "date": "2021-10",
          "followers": 3593
        },
        {
          "date": "2021-09",
          "followers": 3581
        },
        {
          "date": "2021-08",
          "followers": 3565
        },
        {
          "date": "2021-07",
          "followers": 3561
        },
        {
          "date": "2021-06",
          "followers": 3563
        },
        {
          "date": "2021-05",
          "followers": 3567
        },
        {
          "date": "2021-04",
          "followers": 3579
        },
        {
          "date": "2021-03",
          "followers": 3587
        },
        {
          "date": "2021-02",
          "followers": 3596
        },
        {
          "date": "2021-01",
          "followers": 3600
        },
        {
          "date": "2020-12",
          "followers": 3613
        },
        {
          "date": "2020-11",
          "followers": 3631
        },
        {
          "date": "2020-10",
          "followers": 3620
        },
        {
          "date": "2020-09",
          "followers": 3641
        },
        {
          "date": "2020-08",
          "followers": 3656
        },
        {
          "date": "2020-07",
          "followers": 3668
        },
        {
          "date": "2020-06",
          "followers": 3663
        },
        {
          "date": "2020-05",
          "followers": 3686
        },
        {
          "date": "2020-04",
          "followers": 3710
        },
        {
          "date": "2020-03",
          "followers": 3750
        },
        {
          "date": "2020-02",
          "followers": 3765
        },
        {
          "date": "2020-01",
          "followers": 3772
        },
        {
          "date": "2019-12",
          "followers": 3800
        },
        {
          "date": "2019-11",
          "followers": 3822
        },
        {
          "date": "2019-10",
          "followers": 3820
        },
        {
          "date": "2019-09",
          "followers": 3665
        },
        {
          "date": "2019-08",
          "followers": 3611
        },
        {
          "date": "2019-07",
          "followers": 3531
        },
        {
          "date": "2019-06",
          "followers": 3504
        },
        {
          "date": "2019-05",
          "followers": 3568
        },
        {
          "date": "2019-04",
          "followers": 2668
        },
        {
          "date": "2019-03",
          "followers": 2123
        },
        {
          "date": "2019-02",
          "followers": 1561
        },
        {
          "date": "2019-01",
          "followers": 1147
        },
        {
          "date": "2018-12",
          "followers": 951
        },
        {
          "date": "2018-11",
          "followers": 866
        },
        {
          "date": "2018-10",
          "followers": 643
        },
        {
          "date": "2018-09",
          "followers": 433
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Chippin",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 23574
        },
        {
          "date": "2021-10",
          "followers": 23417
        },
        {
          "date": "2021-09",
          "followers": 23253
        },
        {
          "date": "2021-08",
          "followers": 23139
        },
        {
          "date": "2021-07",
          "followers": 23117
        },
        {
          "date": "2021-06",
          "followers": 22893
        },
        {
          "date": "2021-05",
          "followers": 22214
        },
        {
          "date": "2021-04",
          "followers": 21350
        },
        {
          "date": "2021-03",
          "followers": 20367
        },
        {
          "date": "2021-02",
          "followers": 19785
        },
        {
          "date": "2021-01",
          "followers": 19163
        },
        {
          "date": "2020-12",
          "followers": 18670
        },
        {
          "date": "2020-11",
          "followers": 17592
        },
        {
          "date": "2020-10",
          "followers": 16938
        },
        {
          "date": "2020-09",
          "followers": 16208
        },
        {
          "date": "2020-08",
          "followers": 15549
        },
        {
          "date": "2020-07",
          "followers": 14695
        },
        {
          "date": "2020-06",
          "followers": 13210
        },
        {
          "date": "2020-05",
          "followers": 11945
        },
        {
          "date": "2020-04",
          "followers": 10961
        },
        {
          "date": "2020-03",
          "followers": 9419
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Dandy",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 13997
        },
        {
          "date": "2021-10",
          "followers": 13813
        },
        {
          "date": "2021-09",
          "followers": 13177
        },
        {
          "date": "2021-08",
          "followers": 12696
        },
        {
          "date": "2021-07",
          "followers": 11672
        },
        {
          "date": "2021-06",
          "followers": 11334
        },
        {
          "date": "2021-05",
          "followers": 10780
        },
        {
          "date": "2021-04",
          "followers": 7502
        },
        {
          "date": "2021-03",
          "followers": 6640
        },
        {
          "date": "2021-02",
          "followers": 6366
        },
        {
          "date": "2021-01",
          "followers": 6307
        },
        {
          "date": "2020-12",
          "followers": 6062
        },
        {
          "date": "2020-11",
          "followers": 5789
        },
        {
          "date": "2020-10",
          "followers": 5515
        },
        {
          "date": "2020-09",
          "followers": 5275
        },
        {
          "date": "2020-08",
          "followers": 5166
        },
        {
          "date": "2020-07",
          "followers": 4269
        },
        {
          "date": "2020-06",
          "followers": 3933
        },
        {
          "date": "2020-05",
          "followers": 3841
        },
        {
          "date": "2020-04",
          "followers": 3253
        },
        {
          "date": "2020-03",
          "followers": 3072
        },
        {
          "date": "2020-02",
          "followers": 2857
        },
        {
          "date": "2020-01",
          "followers": 2721
        },
        {
          "date": "2019-12",
          "followers": 2530
        },
        {
          "date": "2019-11",
          "followers": 2302
        },
        {
          "date": "2019-10",
          "followers": 1746
        },
        {
          "date": "2019-09",
          "followers": 1632
        },
        {
          "date": "2019-08",
          "followers": 1614
        },
        {
          "date": "2019-07",
          "followers": 1533
        },
        {
          "date": "2019-06",
          "followers": 1387
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 866
        },
        {
          "date": "2021-10",
          "likes": 850
        },
        {
          "date": "2021-09",
          "likes": 794
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Darwin&#039;s",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 32681
        },
        {
          "date": "2021-10",
          "followers": 32507
        },
        {
          "date": "2021-09",
          "followers": 32053
        },
        {
          "date": "2021-08",
          "followers": 31000
        },
        {
          "date": "2021-07",
          "followers": 30232
        },
        {
          "date": "2021-06",
          "followers": 29207
        },
        {
          "date": "2021-05",
          "followers": 28319
        },
        {
          "date": "2021-04",
          "followers": 27379
        },
        {
          "date": "2021-03",
          "followers": 26642
        },
        {
          "date": "2021-02",
          "followers": 25627
        },
        {
          "date": "2021-01",
          "followers": 24668
        },
        {
          "date": "2020-12",
          "followers": 23823
        },
        {
          "date": "2020-11",
          "followers": 22924
        },
        {
          "date": "2020-10",
          "followers": 22452
        },
        {
          "date": "2020-09",
          "followers": 21875
        },
        {
          "date": "2020-08",
          "followers": 20993
        },
        {
          "date": "2020-07",
          "followers": 20072
        },
        {
          "date": "2020-06",
          "followers": 19067
        },
        {
          "date": "2020-05",
          "followers": 18344
        },
        {
          "date": "2020-04",
          "followers": 17324
        },
        {
          "date": "2020-03",
          "followers": 16470
        },
        {
          "date": "2020-02",
          "followers": 15884
        },
        {
          "date": "2020-01",
          "followers": 15062
        },
        {
          "date": "2019-12",
          "followers": 14481
        },
        {
          "date": "2019-11",
          "followers": 13978
        },
        {
          "date": "2019-10",
          "followers": 13379
        },
        {
          "date": "2019-09",
          "followers": 12748
        },
        {
          "date": "2019-08",
          "followers": 11991
        },
        {
          "date": "2019-07",
          "followers": 11420
        },
        {
          "date": "2019-06",
          "followers": 11133
        },
        {
          "date": "2019-05",
          "followers": 10424
        },
        {
          "date": "2019-04",
          "followers": 9867
        },
        {
          "date": "2019-03",
          "followers": 9566
        },
        {
          "date": "2019-02",
          "followers": 9205
        },
        {
          "date": "2019-01",
          "followers": 8726
        },
        {
          "date": "2018-12",
          "followers": 8397
        },
        {
          "date": "2018-11",
          "followers": 8235
        },
        {
          "date": "2018-10",
          "followers": 8098
        },
        {
          "date": "2018-09",
          "followers": 7935
        }
      ]
    },
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 4876
        },
        {
          "date": "2021-10",
          "followers": 4883
        },
        {
          "date": "2021-09",
          "followers": 4888
        },
        {
          "date": "2021-08",
          "followers": 4884
        },
        {
          "date": "2021-07",
          "followers": 4886
        },
        {
          "date": "2021-06",
          "followers": 4883
        },
        {
          "date": "2021-05",
          "followers": 4896
        },
        {
          "date": "2021-04",
          "followers": 4959
        },
        {
          "date": "2021-03",
          "followers": 4965
        },
        {
          "date": "2021-02",
          "followers": 4947
        },
        {
          "date": "2021-01",
          "followers": 4951
        },
        {
          "date": "2020-12",
          "followers": 4989
        },
        {
          "date": "2020-11",
          "followers": 4990
        },
        {
          "date": "2020-10",
          "followers": 4997
        },
        {
          "date": "2020-09",
          "followers": 5005
        },
        {
          "date": "2020-08",
          "followers": 5002
        },
        {
          "date": "2020-07",
          "followers": 5011
        },
        {
          "date": "2020-06",
          "followers": 5025
        },
        {
          "date": "2020-05",
          "followers": 5060
        },
        {
          "date": "2020-04",
          "followers": 5080
        },
        {
          "date": "2020-03",
          "followers": 5092
        },
        {
          "date": "2020-02",
          "followers": 5093
        },
        {
          "date": "2020-01",
          "followers": 5093
        },
        {
          "date": "2019-12",
          "followers": 5095
        },
        {
          "date": "2019-11",
          "followers": 5108
        },
        {
          "date": "2019-10",
          "followers": 5123
        },
        {
          "date": "2019-09",
          "followers": 5130
        },
        {
          "date": "2019-08",
          "followers": 5134
        },
        {
          "date": "2019-07",
          "followers": 5151
        },
        {
          "date": "2019-06",
          "followers": 5151
        },
        {
          "date": "2019-05",
          "followers": 5155
        },
        {
          "date": "2019-04",
          "followers": 5172
        },
        {
          "date": "2019-03",
          "followers": 5170
        },
        {
          "date": "2019-02",
          "followers": 5182
        },
        {
          "date": "2019-01",
          "followers": 5204
        },
        {
          "date": "2018-12",
          "followers": 5220
        },
        {
          "date": "2018-11",
          "followers": 5219
        },
        {
          "date": "2018-10",
          "followers": 5300
        },
        {
          "date": "2018-09",
          "followers": 5218
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "DifferentDog",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 5459
        },
        {
          "date": "2021-10",
          "followers": 5206
        },
        {
          "date": "2021-09",
          "followers": 4617
        },
        {
          "date": "2021-08",
          "followers": 4317
        },
        {
          "date": "2021-07",
          "followers": 4107
        },
        {
          "date": "2021-06",
          "followers": 4015
        },
        {
          "date": "2021-05",
          "followers": 3891
        },
        {
          "date": "2021-04",
          "followers": 3692
        },
        {
          "date": "2021-03",
          "followers": 3608
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 5721
        },
        {
          "date": "2021-10",
          "likes": 5647
        },
        {
          "date": "2021-09",
          "likes": 5527
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 568
        },
        {
          "date": "2021-10",
          "followers": 568
        },
        {
          "date": "2021-09",
          "followers": 571
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Dog&#039;sBistrot",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 2846
        },
        {
          "date": "2021-10",
          "followers": 2850
        },
        {
          "date": "2021-09",
          "followers": 2805
        },
        {
          "date": "2021-08",
          "followers": 2814
        },
        {
          "date": "2021-07",
          "followers": 2828
        },
        {
          "date": "2021-06",
          "followers": 2825
        },
        {
          "date": "2021-05",
          "followers": 2821
        },
        {
          "date": "2021-04",
          "followers": 2825
        },
        {
          "date": "2021-03",
          "followers": 2829
        },
        {
          "date": "2021-02",
          "followers": 2834
        },
        {
          "date": "2021-01",
          "followers": 2813
        },
        {
          "date": "2020-12",
          "followers": 2836
        },
        {
          "date": "2020-11",
          "followers": 2841
        },
        {
          "date": "2020-10",
          "followers": 2864
        },
        {
          "date": "2020-09",
          "followers": 2852
        },
        {
          "date": "2020-08",
          "followers": 2862
        },
        {
          "date": "2020-07",
          "followers": 2880
        },
        {
          "date": "2020-06",
          "followers": 2896
        },
        {
          "date": "2020-05",
          "followers": 2914
        },
        {
          "date": "2020-04",
          "followers": 2909
        },
        {
          "date": "2020-03",
          "followers": 2908
        },
        {
          "date": "2020-02",
          "followers": 2911
        },
        {
          "date": "2020-01",
          "followers": 2890
        },
        {
          "date": "2019-12",
          "followers": 2901
        },
        {
          "date": "2019-11",
          "followers": 2904
        },
        {
          "date": "2019-10",
          "followers": 2905
        },
        {
          "date": "2019-09",
          "followers": 2860
        },
        {
          "date": "2019-08",
          "followers": 2874
        },
        {
          "date": "2019-07",
          "followers": 2894
        },
        {
          "date": "2019-06",
          "followers": 2920
        },
        {
          "date": "2019-05",
          "followers": 2939
        },
        {
          "date": "2019-04",
          "followers": 2833
        },
        {
          "date": "2019-03",
          "followers": 2877
        },
        {
          "date": "2019-02",
          "followers": 2751
        },
        {
          "date": "2019-01",
          "followers": 2059
        },
        {
          "date": "2018-12",
          "followers": 2010
        },
        {
          "date": "2018-11",
          "followers": 2088
        },
        {
          "date": "2018-10",
          "followers": 1969
        },
        {
          "date": "2018-09",
          "followers": 1247
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "DogChef",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 8770
        },
        {
          "date": "2021-10",
          "followers": 8628
        },
        {
          "date": "2021-09",
          "followers": 7831
        },
        {
          "date": "2021-08",
          "followers": 7338
        },
        {
          "date": "2021-07",
          "followers": 6674
        },
        {
          "date": "2021-06",
          "followers": 5650
        },
        {
          "date": "2021-05",
          "followers": 5123
        },
        {
          "date": "2021-04",
          "followers": 4749
        },
        {
          "date": "2021-03",
          "followers": 4122
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 16373
        },
        {
          "date": "2021-10",
          "likes": 16140
        },
        {
          "date": "2021-09",
          "likes": 15549
        },
        {
          "date": "2021-08",
          "likes": 14942
        },
        {
          "date": "2021-07",
          "likes": 14030
        },
        {
          "date": "2021-06",
          "likes": 12821
        },
        {
          "date": "2021-05",
          "likes": 11986
        },
        {
          "date": "2021-04",
          "likes": 11149
        },
        {
          "date": "2021-03",
          "likes": 10181
        },
        {
          "date": "2021-02",
          "likes": 8801
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "DoggieDabbas",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 24319
        },
        {
          "date": "2021-10",
          "followers": 24283
        },
        {
          "date": "2021-09",
          "followers": 24197
        },
        {
          "date": "2021-08",
          "followers": 23498
        },
        {
          "date": "2021-07",
          "followers": 22815
        },
        {
          "date": "2021-06",
          "followers": 21469
        },
        {
          "date": "2021-05",
          "followers": 20191
        },
        {
          "date": "2021-04",
          "followers": 18727
        },
        {
          "date": "2021-03",
          "followers": 17827
        },
        {
          "date": "2021-02",
          "followers": 17163
        },
        {
          "date": "2021-01",
          "followers": 15817
        },
        {
          "date": "2020-12",
          "followers": 15282
        },
        {
          "date": "2020-11",
          "followers": 14761
        },
        {
          "date": "2020-10",
          "followers": 14266
        },
        {
          "date": "2020-09",
          "followers": 13597
        },
        {
          "date": "2020-08",
          "followers": 12856
        },
        {
          "date": "2020-07",
          "followers": 12044
        },
        {
          "date": "2020-06",
          "followers": 10967
        },
        {
          "date": "2020-05",
          "followers": 10431
        },
        {
          "date": "2020-04",
          "followers": 10098
        },
        {
          "date": "2020-03",
          "followers": 9834
        },
        {
          "date": "2020-02",
          "followers": 9514
        },
        {
          "date": "2020-01",
          "followers": 9371
        },
        {
          "date": "2019-12",
          "followers": 8925
        },
        {
          "date": "2019-11",
          "followers": 8834
        },
        {
          "date": "2019-10",
          "followers": 8680
        },
        {
          "date": "2019-09",
          "followers": 8435
        },
        {
          "date": "2019-08",
          "followers": 8275
        },
        {
          "date": "2019-07",
          "followers": 8196
        },
        {
          "date": "2019-06",
          "followers": 8015
        },
        {
          "date": "2019-05",
          "followers": 7897
        },
        {
          "date": "2019-04",
          "followers": 7724
        },
        {
          "date": "2019-03",
          "followers": 7676
        },
        {
          "date": "2019-02",
          "followers": 7437
        },
        {
          "date": "2019-01",
          "followers": 7294
        },
        {
          "date": "2018-12",
          "followers": 7152
        },
        {
          "date": "2018-11",
          "followers": 6874
        },
        {
          "date": "2018-10",
          "followers": 6590
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 27342
        },
        {
          "date": "2021-10",
          "likes": 27360
        },
        {
          "date": "2021-09",
          "likes": 27385
        },
        {
          "date": "2021-08",
          "likes": 27414
        },
        {
          "date": "2021-07",
          "likes": 27510
        },
        {
          "date": "2021-06",
          "likes": 27570
        },
        {
          "date": "2021-05",
          "likes": 27630
        },
        {
          "date": "2021-04",
          "likes": 27663
        },
        {
          "date": "2021-03",
          "likes": 27690
        },
        {
          "date": "2021-02",
          "likes": 27745
        },
        {
          "date": "2021-01",
          "likes": 27793
        },
        {
          "date": "2020-12",
          "likes": 27832
        },
        {
          "date": "2020-11",
          "likes": 27875
        },
        {
          "date": "2020-10",
          "likes": 27898
        },
        {
          "date": "2020-09",
          "likes": 27921
        },
        {
          "date": "2020-08",
          "likes": 27947
        },
        {
          "date": "2020-07",
          "likes": 28022
        },
        {
          "date": "2020-06",
          "likes": 28088
        },
        {
          "date": "2020-05",
          "likes": 28162
        },
        {
          "date": "2020-04",
          "likes": 28193
        },
        {
          "date": "2020-03",
          "likes": 28254
        },
        {
          "date": "2020-02",
          "likes": 28267
        },
        {
          "date": "2020-01",
          "likes": 28302
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 471
        },
        {
          "date": "2021-10",
          "followers": 471
        },
        {
          "date": "2021-09",
          "followers": 472
        },
        {
          "date": "2021-08",
          "followers": 470
        },
        {
          "date": "2021-07",
          "followers": 470
        },
        {
          "date": "2021-06",
          "followers": 467
        },
        {
          "date": "2021-05",
          "followers": 469
        },
        {
          "date": "2021-04",
          "followers": 469
        },
        {
          "date": "2021-03",
          "followers": 471
        },
        {
          "date": "2021-02",
          "followers": 471
        },
        {
          "date": "2021-01",
          "followers": 472
        },
        {
          "date": "2020-12",
          "followers": 472
        },
        {
          "date": "2020-11",
          "followers": 472
        },
        {
          "date": "2020-10",
          "followers": 468
        },
        {
          "date": "2020-09",
          "followers": 468
        },
        {
          "date": "2020-08",
          "followers": 470
        },
        {
          "date": "2020-07",
          "followers": 470
        },
        {
          "date": "2020-06",
          "followers": 473
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Elmut",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 3513
        },
        {
          "date": "2021-10",
          "followers": 3474
        },
        {
          "date": "2021-09",
          "followers": 3387
        },
        {
          "date": "2021-08",
          "followers": 3285
        },
        {
          "date": "2021-07",
          "followers": 3167
        },
        {
          "date": "2021-06",
          "followers": 3064
        },
        {
          "date": "2021-05",
          "followers": 2998
        },
        {
          "date": "2021-04",
          "followers": 2956
        },
        {
          "date": "2021-03",
          "followers": 2859
        },
        {
          "date": "2021-02",
          "followers": 2572
        },
        {
          "date": "2021-01",
          "followers": 2077
        },
        {
          "date": "2020-12",
          "followers": 1901
        },
        {
          "date": "2020-11",
          "followers": 1646
        },
        {
          "date": "2020-10",
          "followers": 1513
        },
        {
          "date": "2020-09",
          "followers": 1320
        },
        {
          "date": "2020-08",
          "followers": 1264
        },
        {
          "date": "2020-07",
          "followers": 1269
        },
        {
          "date": "2020-06",
          "followers": 1269
        },
        {
          "date": "2020-05",
          "followers": 1278
        },
        {
          "date": "2020-04",
          "followers": 1277
        },
        {
          "date": "2020-03",
          "followers": 1279
        },
        {
          "date": "2020-02",
          "followers": 1290
        },
        {
          "date": "2020-01",
          "followers": 1294
        },
        {
          "date": "2019-12",
          "followers": 1302
        },
        {
          "date": "2019-11",
          "followers": 1312
        },
        {
          "date": "2019-10",
          "followers": 1322
        },
        {
          "date": "2019-09",
          "followers": 1319
        },
        {
          "date": "2019-08",
          "followers": 1296
        },
        {
          "date": "2019-07",
          "followers": 1298
        },
        {
          "date": "2019-06",
          "followers": 1271
        },
        {
          "date": "2019-05",
          "followers": 1264
        },
        {
          "date": "2019-04",
          "followers": 1250
        },
        {
          "date": "2019-03",
          "followers": 1240
        },
        {
          "date": "2019-01",
          "followers": 1164
        },
        {
          "date": "2018-12",
          "followers": 1150
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Evermore",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 5827
        },
        {
          "date": "2021-10",
          "followers": 5826
        },
        {
          "date": "2021-09",
          "followers": 5827
        },
        {
          "date": "2021-08",
          "followers": 5824
        },
        {
          "date": "2021-07",
          "followers": 5825
        },
        {
          "date": "2021-06",
          "followers": 5809
        },
        {
          "date": "2021-05",
          "followers": 5803
        },
        {
          "date": "2021-04",
          "followers": 5810
        },
        {
          "date": "2021-03",
          "followers": 5801
        },
        {
          "date": "2021-02",
          "followers": 5795
        },
        {
          "date": "2021-01",
          "followers": 5806
        },
        {
          "date": "2020-12",
          "followers": 5814
        },
        {
          "date": "2020-11",
          "followers": 5812
        },
        {
          "date": "2020-10",
          "followers": 5817
        },
        {
          "date": "2020-09",
          "followers": 5825
        },
        {
          "date": "2020-08",
          "followers": 5827
        },
        {
          "date": "2020-07",
          "followers": 5845
        },
        {
          "date": "2020-06",
          "followers": 5856
        },
        {
          "date": "2020-05",
          "followers": 5879
        },
        {
          "date": "2020-04",
          "followers": 5892
        },
        {
          "date": "2020-03",
          "followers": 5908
        },
        {
          "date": "2020-02",
          "followers": 5895
        },
        {
          "date": "2020-01",
          "followers": 5897
        },
        {
          "date": "2019-12",
          "followers": 5911
        },
        {
          "date": "2019-11",
          "followers": 5917
        },
        {
          "date": "2019-10",
          "followers": 5905
        },
        {
          "date": "2019-09",
          "followers": 5876
        },
        {
          "date": "2019-08",
          "followers": 5883
        },
        {
          "date": "2019-07",
          "followers": 5884
        },
        {
          "date": "2019-06",
          "followers": 5890
        },
        {
          "date": "2019-05",
          "followers": 5906
        },
        {
          "date": "2019-04",
          "followers": 5908
        },
        {
          "date": "2019-03",
          "followers": 5916
        },
        {
          "date": "2019-02",
          "followers": 5912
        },
        {
          "date": "2019-01",
          "followers": 5939
        },
        {
          "date": "2018-12",
          "followers": 5878
        },
        {
          "date": "2018-11",
          "followers": 5838
        },
        {
          "date": "2018-10",
          "followers": 5814
        },
        {
          "date": "2018-09",
          "followers": 5741
        },
        {
          "date": "2018-08",
          "followers": 5646
        }
      ]
    },
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 818
        },
        {
          "date": "2021-10",
          "followers": 819
        },
        {
          "date": "2021-09",
          "followers": 823
        },
        {
          "date": "2021-08",
          "followers": 824
        },
        {
          "date": "2021-07",
          "followers": 824
        },
        {
          "date": "2021-06",
          "followers": 824
        },
        {
          "date": "2021-05",
          "followers": 824
        },
        {
          "date": "2021-04",
          "followers": 833
        },
        {
          "date": "2021-03",
          "followers": 836
        },
        {
          "date": "2021-02",
          "followers": 836
        },
        {
          "date": "2021-01",
          "followers": 836
        },
        {
          "date": "2020-12",
          "followers": 840
        },
        {
          "date": "2020-11",
          "followers": 838
        },
        {
          "date": "2020-10",
          "followers": 838
        },
        {
          "date": "2020-09",
          "followers": 838
        },
        {
          "date": "2020-08",
          "followers": 837
        },
        {
          "date": "2020-07",
          "followers": 839
        },
        {
          "date": "2020-06",
          "followers": 841
        },
        {
          "date": "2020-05",
          "followers": 843
        },
        {
          "date": "2020-04",
          "followers": 842
        },
        {
          "date": "2020-03",
          "followers": 845
        },
        {
          "date": "2020-02",
          "followers": 849
        },
        {
          "date": "2020-01",
          "followers": 856
        },
        {
          "date": "2019-12",
          "followers": 860
        },
        {
          "date": "2019-11",
          "followers": 861
        },
        {
          "date": "2019-10",
          "followers": 861
        },
        {
          "date": "2019-09",
          "followers": 861
        },
        {
          "date": "2019-08",
          "followers": 860
        },
        {
          "date": "2019-07",
          "followers": 864
        },
        {
          "date": "2019-06",
          "followers": 864
        },
        {
          "date": "2019-05",
          "followers": 865
        },
        {
          "date": "2019-04",
          "followers": 866
        },
        {
          "date": "2019-03",
          "followers": 868
        },
        {
          "date": "2019-02",
          "followers": 865
        },
        {
          "date": "2019-01",
          "followers": 860
        },
        {
          "date": "2018-12",
          "followers": 860
        },
        {
          "date": "2018-11",
          "followers": 861
        },
        {
          "date": "2018-10",
          "followers": 865
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "FrankieTheKing",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 18526
        },
        {
          "date": "2021-10",
          "followers": 18384
        },
        {
          "date": "2021-09",
          "followers": 18021
        },
        {
          "date": "2021-08",
          "followers": 17675
        },
        {
          "date": "2021-07",
          "followers": 17302
        },
        {
          "date": "2021-06",
          "followers": 16902
        },
        {
          "date": "2021-05",
          "followers": 16675
        },
        {
          "date": "2021-04",
          "followers": 16173
        },
        {
          "date": "2021-03",
          "followers": 15843
        },
        {
          "date": "2021-02",
          "followers": 15525
        },
        {
          "date": "2021-01",
          "followers": 15005
        },
        {
          "date": "2020-12",
          "followers": 14486
        },
        {
          "date": "2020-11",
          "followers": 14345
        },
        {
          "date": "2020-10",
          "followers": 13734
        },
        {
          "date": "2020-09",
          "followers": 13093
        },
        {
          "date": "2020-08",
          "followers": 12236
        },
        {
          "date": "2020-07",
          "followers": 11369
        },
        {
          "date": "2020-06",
          "followers": 10601
        },
        {
          "date": "2020-05",
          "followers": 10233
        },
        {
          "date": "2020-04",
          "followers": 8783
        },
        {
          "date": "2020-03",
          "followers": 8424
        },
        {
          "date": "2020-02",
          "followers": 8075
        },
        {
          "date": "2020-01",
          "followers": 7749
        },
        {
          "date": "2019-12",
          "followers": 7583
        },
        {
          "date": "2019-11",
          "followers": 7296
        },
        {
          "date": "2019-10",
          "followers": 6849
        },
        {
          "date": "2019-09",
          "followers": 6302
        },
        {
          "date": "2019-08",
          "followers": 6010
        },
        {
          "date": "2019-07",
          "followers": 5884
        },
        {
          "date": "2019-06",
          "followers": 5623
        },
        {
          "date": "2019-05",
          "followers": 5533
        },
        {
          "date": "2019-04",
          "followers": 5354
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 3846
        },
        {
          "date": "2021-10",
          "likes": 3810
        },
        {
          "date": "2021-09",
          "likes": 3738
        },
        {
          "date": "2021-08",
          "likes": 3654
        },
        {
          "date": "2021-07",
          "likes": 3522
        },
        {
          "date": "2021-06",
          "likes": 3451
        },
        {
          "date": "2021-05",
          "likes": 3426
        },
        {
          "date": "2021-04",
          "likes": 3314
        },
        {
          "date": "2021-03",
          "likes": 3237
        },
        {
          "date": "2021-02",
          "likes": 3159
        },
        {
          "date": "2021-01",
          "likes": 3068
        },
        {
          "date": "2020-12",
          "likes": 2983
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Franklin",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 10813
        },
        {
          "date": "2021-10",
          "followers": 10813
        },
        {
          "date": "2021-09",
          "followers": 10746
        },
        {
          "date": "2021-08",
          "followers": 10721
        },
        {
          "date": "2021-07",
          "followers": 10219
        },
        {
          "date": "2021-06",
          "followers": 10045
        },
        {
          "date": "2021-05",
          "followers": 9636
        },
        {
          "date": "2021-04",
          "followers": 9636
        },
        {
          "date": "2021-03",
          "followers": 9504
        },
        {
          "date": "2021-02",
          "followers": 8861
        },
        {
          "date": "2021-01",
          "followers": 8680
        },
        {
          "date": "2020-12",
          "followers": 8589
        },
        {
          "date": "2020-11",
          "followers": 7378
        },
        {
          "date": "2020-10",
          "followers": 7046
        },
        {
          "date": "2020-09",
          "followers": 6604
        },
        {
          "date": "2020-08",
          "followers": 6617
        },
        {
          "date": "2020-07",
          "followers": 6559
        },
        {
          "date": "2020-06",
          "followers": 6451
        },
        {
          "date": "2020-05",
          "followers": 6204
        },
        {
          "date": "2020-04",
          "followers": 5810
        },
        {
          "date": "2020-03",
          "followers": 4727
        },
        {
          "date": "2020-02",
          "followers": 4636
        },
        {
          "date": "2020-01",
          "followers": 4642
        },
        {
          "date": "2019-12",
          "followers": 4629
        },
        {
          "date": "2019-11",
          "followers": 4655
        },
        {
          "date": "2019-10",
          "followers": 4685
        },
        {
          "date": "2019-09",
          "followers": 4612
        },
        {
          "date": "2019-08",
          "followers": 4453
        },
        {
          "date": "2019-07",
          "followers": 4222
        },
        {
          "date": "2019-06",
          "followers": 3982
        },
        {
          "date": "2019-05",
          "followers": 2677
        },
        {
          "date": "2019-04",
          "followers": 2231
        },
        {
          "date": "2019-03",
          "followers": 2207
        },
        {
          "date": "2019-02",
          "followers": 2191
        },
        {
          "date": "2019-01",
          "followers": 2184
        },
        {
          "date": "2018-12",
          "followers": 2198
        },
        {
          "date": "2018-11",
          "followers": 2087
        },
        {
          "date": "2018-10",
          "followers": 1928
        },
        {
          "date": "2018-09",
          "followers": 1813
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 6860
        },
        {
          "date": "2021-10",
          "likes": 6840
        },
        {
          "date": "2021-09",
          "likes": 6780
        },
        {
          "date": "2021-08",
          "likes": 6761
        },
        {
          "date": "2021-07",
          "likes": 6739
        },
        {
          "date": "2021-06",
          "likes": 6712
        },
        {
          "date": "2021-05",
          "likes": 6691
        },
        {
          "date": "2021-04",
          "likes": 6658
        },
        {
          "date": "2021-03",
          "likes": 6574
        },
        {
          "date": "2021-02",
          "likes": 6497
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Freely",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 2655
        },
        {
          "date": "2021-10",
          "followers": 2676
        },
        {
          "date": "2021-09",
          "followers": 2704
        },
        {
          "date": "2021-08",
          "followers": 2476
        },
        {
          "date": "2021-07",
          "followers": 2093
        },
        {
          "date": "2021-06",
          "followers": 2053
        },
        {
          "date": "2021-05",
          "followers": 2051
        },
        {
          "date": "2021-04",
          "followers": 2045
        },
        {
          "date": "2021-03",
          "followers": 1852
        },
        {
          "date": "2021-02",
          "followers": 1828
        },
        {
          "date": "2021-01",
          "followers": 1811
        }
      ]
    },
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 27
        },
        {
          "date": "2021-10",
          "followers": 27
        },
        {
          "date": "2021-09",
          "followers": 27
        },
        {
          "date": "2021-08",
          "followers": 27
        },
        {
          "date": "2021-07",
          "followers": 26
        },
        {
          "date": "2021-06",
          "followers": 25
        },
        {
          "date": "2021-05",
          "followers": 24
        },
        {
          "date": "2021-04",
          "followers": 24
        },
        {
          "date": "2021-03",
          "followers": 24
        },
        {
          "date": "2021-02",
          "followers": 22
        },
        {
          "date": "2021-01",
          "followers": 19
        },
        {
          "date": "2020-12",
          "followers": 19
        },
        {
          "date": "2020-11",
          "followers": 20
        },
        {
          "date": "2020-10",
          "followers": 21
        },
        {
          "date": "2020-09",
          "followers": 20
        },
        {
          "date": "2020-08",
          "followers": 19
        },
        {
          "date": "2020-07",
          "followers": 17
        },
        {
          "date": "2020-06",
          "followers": 15
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "FrontofthePack",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 18944
        },
        {
          "date": "2021-10",
          "followers": 18759
        },
        {
          "date": "2021-09",
          "followers": 18412
        },
        {
          "date": "2021-08",
          "followers": 15797
        },
        {
          "date": "2021-07",
          "followers": 10947
        },
        {
          "date": "2021-06",
          "followers": 10395
        },
        {
          "date": "2021-05",
          "followers": 9268
        },
        {
          "date": "2021-04",
          "followers": 7668
        },
        {
          "date": "2021-03",
          "followers": 6448
        },
        {
          "date": "2021-02",
          "followers": 3787
        },
        {
          "date": "2021-01",
          "followers": 2747
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 100505
        },
        {
          "date": "2021-10",
          "likes": 100554
        },
        {
          "date": "2021-09",
          "likes": 100553
        },
        {
          "date": "2021-08",
          "likes": 90463
        },
        {
          "date": "2021-07",
          "likes": 89458
        },
        {
          "date": "2021-06",
          "likes": 89335
        },
        {
          "date": "2021-05",
          "likes": 89097
        },
        {
          "date": "2021-04",
          "likes": 88902
        },
        {
          "date": "2021-03",
          "likes": 88605
        },
        {
          "date": "2021-02",
          "likes": 83438
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Futalis",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 4023
        },
        {
          "date": "2021-10",
          "followers": 4031
        },
        {
          "date": "2021-09",
          "followers": 3926
        },
        {
          "date": "2021-08",
          "followers": 3814
        },
        {
          "date": "2021-07",
          "followers": 3796
        },
        {
          "date": "2021-06",
          "followers": 3811
        },
        {
          "date": "2021-05",
          "followers": 3720
        },
        {
          "date": "2021-04",
          "followers": 3575
        },
        {
          "date": "2021-03",
          "followers": 3517
        },
        {
          "date": "2021-02",
          "followers": 3463
        },
        {
          "date": "2021-01",
          "followers": 3361
        },
        {
          "date": "2020-12",
          "followers": 3343
        },
        {
          "date": "2020-11",
          "followers": 3201
        },
        {
          "date": "2020-10",
          "followers": 3107
        },
        {
          "date": "2020-09",
          "followers": 3037
        },
        {
          "date": "2020-08",
          "followers": 3009
        },
        {
          "date": "2020-07",
          "followers": 2903
        },
        {
          "date": "2020-06",
          "followers": 2846
        },
        {
          "date": "2020-05",
          "followers": 2805
        },
        {
          "date": "2020-04",
          "followers": 2763
        },
        {
          "date": "2020-03",
          "followers": 2719
        },
        {
          "date": "2020-02",
          "followers": 2727
        },
        {
          "date": "2020-01",
          "followers": 2629
        },
        {
          "date": "2019-12",
          "followers": 2568
        },
        {
          "date": "2019-11",
          "followers": 2362
        },
        {
          "date": "2019-10",
          "followers": 1925
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Goodboy",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 5244
        },
        {
          "date": "2021-10",
          "followers": 5218
        },
        {
          "date": "2021-09",
          "followers": 5234
        },
        {
          "date": "2021-08",
          "followers": 5258
        },
        {
          "date": "2021-07",
          "followers": 4987
        },
        {
          "date": "2021-06",
          "followers": 4911
        },
        {
          "date": "2021-05",
          "followers": 4913
        },
        {
          "date": "2021-04",
          "followers": 4903
        },
        {
          "date": "2021-03",
          "followers": 4875
        },
        {
          "date": "2021-02",
          "followers": 4762
        },
        {
          "date": "2021-01",
          "followers": 4772
        },
        {
          "date": "2020-12",
          "followers": 4737
        },
        {
          "date": "2020-11",
          "followers": 4506
        },
        {
          "date": "2020-10",
          "followers": 4360
        },
        {
          "date": "2020-09",
          "followers": 4148
        },
        {
          "date": "2020-08",
          "followers": 3989
        },
        {
          "date": "2020-07",
          "followers": 3782
        },
        {
          "date": "2020-06",
          "followers": 3136
        },
        {
          "date": "2020-05",
          "followers": 2919
        },
        {
          "date": "2020-04",
          "followers": 2639
        },
        {
          "date": "2020-03",
          "followers": 2131
        },
        {
          "date": "2020-02",
          "followers": 1913
        },
        {
          "date": "2020-01",
          "followers": 1745
        },
        {
          "date": "2019-12",
          "followers": 1426
        },
        {
          "date": "2019-11",
          "followers": 1317
        },
        {
          "date": "2019-10",
          "followers": 1116
        },
        {
          "date": "2019-09",
          "followers": 613
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 523
        },
        {
          "date": "2021-10",
          "likes": 523
        },
        {
          "date": "2021-09",
          "likes": 520
        },
        {
          "date": "2021-08",
          "likes": 519
        },
        {
          "date": "2021-07",
          "likes": 519
        },
        {
          "date": "2021-06",
          "likes": 514
        },
        {
          "date": "2021-05",
          "likes": 508
        },
        {
          "date": "2021-04",
          "likes": 501
        },
        {
          "date": "2021-03",
          "likes": 499
        },
        {
          "date": "2021-02",
          "likes": 473
        },
        {
          "date": "2021-01",
          "likes": 470
        },
        {
          "date": "2020-12",
          "likes": 465
        },
        {
          "date": "2020-11",
          "likes": 458
        },
        {
          "date": "2020-10",
          "likes": 437
        },
        {
          "date": "2020-09",
          "likes": 424
        },
        {
          "date": "2020-08",
          "likes": 413
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "HectorKitchen",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 13938
        },
        {
          "date": "2021-10",
          "followers": 14014
        },
        {
          "date": "2021-09",
          "followers": 13278
        },
        {
          "date": "2021-08",
          "followers": 13279
        },
        {
          "date": "2021-07",
          "followers": 12818
        },
        {
          "date": "2021-06",
          "followers": 12808
        },
        {
          "date": "2021-05",
          "followers": 12815
        },
        {
          "date": "2021-04",
          "followers": 12753
        },
        {
          "date": "2021-03",
          "followers": 12544
        },
        {
          "date": "2021-02",
          "followers": 12384
        },
        {
          "date": "2021-01",
          "followers": 12286
        },
        {
          "date": "2020-12",
          "followers": 12074
        },
        {
          "date": "2020-11",
          "followers": 11992
        },
        {
          "date": "2020-10",
          "followers": 11837
        },
        {
          "date": "2020-09",
          "followers": 11706
        },
        {
          "date": "2020-08",
          "followers": 11563
        },
        {
          "date": "2020-07",
          "followers": 11481
        },
        {
          "date": "2020-06",
          "followers": 11417
        },
        {
          "date": "2020-05",
          "followers": 11337
        },
        {
          "date": "2020-04",
          "followers": 11093
        },
        {
          "date": "2020-03",
          "followers": 10656
        },
        {
          "date": "2020-02",
          "followers": 10638
        },
        {
          "date": "2020-01",
          "followers": 10638
        },
        {
          "date": "2019-12",
          "followers": 10710
        },
        {
          "date": "2019-11",
          "followers": 10775
        },
        {
          "date": "2019-10",
          "followers": 10835
        },
        {
          "date": "2019-09",
          "followers": 10905
        },
        {
          "date": "2019-08",
          "followers": 11003
        },
        {
          "date": "2019-07",
          "followers": 10806
        },
        {
          "date": "2019-06",
          "followers": 10569
        },
        {
          "date": "2019-05",
          "followers": 10271
        },
        {
          "date": "2019-04",
          "followers": 9915
        },
        {
          "date": "2019-03",
          "followers": 8613
        },
        {
          "date": "2019-02",
          "followers": 7938
        },
        {
          "date": "2019-01",
          "followers": 6504
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 14994
        },
        {
          "date": "2021-10",
          "likes": 15007
        },
        {
          "date": "2021-09",
          "likes": 15034
        },
        {
          "date": "2021-08",
          "likes": 15055
        },
        {
          "date": "2021-07",
          "likes": 15063
        },
        {
          "date": "2021-06",
          "likes": 15080
        },
        {
          "date": "2021-05",
          "likes": 15099
        },
        {
          "date": "2021-04",
          "likes": 15124
        },
        {
          "date": "2021-03",
          "likes": 15144
        },
        {
          "date": "2021-02",
          "likes": 15159
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "HoliChow",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 3897
        },
        {
          "date": "2021-10",
          "followers": 3649
        },
        {
          "date": "2021-09",
          "followers": 3157
        },
        {
          "date": "2021-08",
          "followers": 3079
        },
        {
          "date": "2021-07",
          "followers": 3034
        },
        {
          "date": "2021-06",
          "followers": 3033
        },
        {
          "date": "2021-05",
          "followers": 2849
        },
        {
          "date": "2021-04",
          "followers": 2809
        },
        {
          "date": "2021-03",
          "followers": 2718
        },
        {
          "date": "2021-02",
          "followers": 2662
        },
        {
          "date": "2020-11",
          "followers": 2526
        },
        {
          "date": "2020-10",
          "followers": 2474
        },
        {
          "date": "2020-09",
          "followers": 2338
        },
        {
          "date": "2020-08",
          "followers": 2147
        }
      ]
    },
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 11
        },
        {
          "date": "2021-10",
          "followers": 11
        },
        {
          "date": "2021-09",
          "followers": 11
        },
        {
          "date": "2021-08",
          "followers": 11
        },
        {
          "date": "2021-07",
          "followers": 11
        },
        {
          "date": "2021-06",
          "followers": 11
        },
        {
          "date": "2021-05",
          "followers": 11
        },
        {
          "date": "2021-04",
          "followers": 11
        },
        {
          "date": "2021-03",
          "followers": 11
        },
        {
          "date": "2021-02",
          "followers": 11
        },
        {
          "date": "2021-01",
          "followers": 11
        },
        {
          "date": "2020-12",
          "followers": 11
        },
        {
          "date": "2020-11",
          "followers": 11
        },
        {
          "date": "2020-10",
          "followers": 11
        },
        {
          "date": "2020-09",
          "followers": 11
        },
        {
          "date": "2020-08",
          "followers": 11
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "HOM",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 267
        },
        {
          "date": "2021-10",
          "followers": 267
        },
        {
          "date": "2021-09",
          "followers": 262
        },
        {
          "date": "2021-08",
          "followers": 260
        },
        {
          "date": "2021-07",
          "followers": 262
        },
        {
          "date": "2021-06",
          "followers": 263
        },
        {
          "date": "2021-05",
          "followers": 261
        },
        {
          "date": "2021-04",
          "followers": 260
        },
        {
          "date": "2021-03",
          "followers": 258
        },
        {
          "date": "2021-02",
          "followers": 258
        },
        {
          "date": "2021-01",
          "followers": 259
        },
        {
          "date": "2020-12",
          "followers": 260
        },
        {
          "date": "2020-11",
          "followers": 253
        },
        {
          "date": "2020-10",
          "followers": 250
        },
        {
          "date": "2020-09",
          "followers": 251
        },
        {
          "date": "2020-08",
          "followers": 252
        },
        {
          "date": "2020-07",
          "followers": 250
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "HonestKitchen",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 105745
        },
        {
          "date": "2021-10",
          "followers": 105345
        },
        {
          "date": "2021-09",
          "followers": 104531
        },
        {
          "date": "2021-08",
          "followers": 102211
        },
        {
          "date": "2021-07",
          "followers": 100954
        },
        {
          "date": "2021-06",
          "followers": 99584
        },
        {
          "date": "2021-05",
          "followers": 97445
        },
        {
          "date": "2021-04",
          "followers": 95322
        },
        {
          "date": "2021-03",
          "followers": 93383
        },
        {
          "date": "2021-02",
          "followers": 90950
        },
        {
          "date": "2021-01",
          "followers": 88912
        },
        {
          "date": "2020-12",
          "followers": 87340
        },
        {
          "date": "2020-11",
          "followers": 85324
        },
        {
          "date": "2020-10",
          "followers": 83866
        },
        {
          "date": "2020-09",
          "followers": 81883
        },
        {
          "date": "2020-08",
          "followers": 80116
        },
        {
          "date": "2020-07",
          "followers": 78584
        },
        {
          "date": "2020-06",
          "followers": 76784
        },
        {
          "date": "2020-05",
          "followers": 75319
        },
        {
          "date": "2020-04",
          "followers": 72782
        },
        {
          "date": "2020-03",
          "followers": 71487
        },
        {
          "date": "2020-02",
          "followers": 69833
        },
        {
          "date": "2020-01",
          "followers": 68649
        },
        {
          "date": "2019-12",
          "followers": 67695
        },
        {
          "date": "2019-11",
          "followers": 66656
        },
        {
          "date": "2019-10",
          "followers": 64315
        },
        {
          "date": "2019-09",
          "followers": 61891
        },
        {
          "date": "2019-08",
          "followers": 58439
        },
        {
          "date": "2019-07",
          "followers": 56138
        },
        {
          "date": "2019-06",
          "followers": 53994
        },
        {
          "date": "2019-05",
          "followers": 51639
        },
        {
          "date": "2019-04",
          "followers": 49394
        },
        {
          "date": "2019-03",
          "followers": 47690
        },
        {
          "date": "2019-02",
          "followers": 45629
        },
        {
          "date": "2019-01",
          "followers": 44118
        },
        {
          "date": "2018-12",
          "followers": 43372
        },
        {
          "date": "2018-11",
          "followers": 42843
        },
        {
          "date": "2018-10",
          "followers": 42186
        },
        {
          "date": "2018-09",
          "followers": 41401
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 284887
        },
        {
          "date": "2021-10",
          "likes": 284900
        },
        {
          "date": "2021-09",
          "likes": 284916
        },
        {
          "date": "2021-08",
          "likes": 284985
        },
        {
          "date": "2021-07",
          "likes": 284959
        },
        {
          "date": "2021-06",
          "likes": 285004
        },
        {
          "date": "2021-05",
          "likes": 284989
        },
        {
          "date": "2021-04",
          "likes": 284988
        },
        {
          "date": "2021-03",
          "likes": 284729
        },
        {
          "date": "2021-02",
          "likes": 284122
        },
        {
          "date": "2021-01",
          "likes": 284117
        },
        {
          "date": "2020-12",
          "likes": 283860
        },
        {
          "date": "2020-11",
          "likes": 283236
        },
        {
          "date": "2020-10",
          "likes": 282329
        },
        {
          "date": "2020-09",
          "likes": 281488
        },
        {
          "date": "2020-08",
          "likes": 280950
        },
        {
          "date": "2020-07",
          "likes": 280838
        },
        {
          "date": "2020-06",
          "likes": 280796
        },
        {
          "date": "2020-05",
          "likes": 280572
        },
        {
          "date": "2020-04",
          "likes": 280354
        },
        {
          "date": "2020-03",
          "likes": 280061
        },
        {
          "date": "2020-02",
          "likes": 279865
        },
        {
          "date": "2020-01",
          "likes": 279887
        },
        {
          "date": "2019-12",
          "likes": 279736
        },
        {
          "date": "2019-11",
          "likes": 279753
        },
        {
          "date": "2019-10",
          "likes": 279547
        },
        {
          "date": "2019-09",
          "likes": 279729
        },
        {
          "date": "2019-08",
          "likes": 279865
        },
        {
          "date": "2019-07",
          "likes": 279668
        },
        {
          "date": "2019-06",
          "likes": 279446
        },
        {
          "date": "2019-05",
          "likes": 279489
        },
        {
          "date": "2019-04",
          "likes": 279466
        },
        {
          "date": "2019-03",
          "likes": 279482
        },
        {
          "date": "2019-02",
          "likes": 279468
        },
        {
          "date": "2019-01",
          "likes": 279694
        },
        {
          "date": "2018-12",
          "likes": 280053
        },
        {
          "date": "2018-11",
          "likes": 280437
        },
        {
          "date": "2018-10",
          "likes": 280768
        },
        {
          "date": "2018-09",
          "likes": 280841
        },
        {
          "date": "2018-08",
          "likes": 280771
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 12191
        },
        {
          "date": "2021-10",
          "followers": 12187
        },
        {
          "date": "2021-09",
          "followers": 12182
        },
        {
          "date": "2021-08",
          "followers": 12165
        },
        {
          "date": "2021-07",
          "followers": 12166
        },
        {
          "date": "2021-06",
          "followers": 12156
        },
        {
          "date": "2021-05",
          "followers": 12178
        },
        {
          "date": "2021-04",
          "followers": 12301
        },
        {
          "date": "2021-03",
          "followers": 12307
        },
        {
          "date": "2021-02",
          "followers": 12290
        },
        {
          "date": "2021-01",
          "followers": 12284
        },
        {
          "date": "2020-12",
          "followers": 12400
        },
        {
          "date": "2020-11",
          "followers": 12404
        },
        {
          "date": "2020-10",
          "followers": 12417
        },
        {
          "date": "2020-09",
          "followers": 12425
        },
        {
          "date": "2020-08",
          "followers": 12413
        },
        {
          "date": "2020-07",
          "followers": 12443
        },
        {
          "date": "2020-06",
          "followers": 12436
        },
        {
          "date": "2020-05",
          "followers": 12515
        },
        {
          "date": "2020-04",
          "followers": 12568
        },
        {
          "date": "2020-03",
          "followers": 12586
        },
        {
          "date": "2020-02",
          "followers": 12608
        },
        {
          "date": "2020-01",
          "followers": 12604
        },
        {
          "date": "2019-12",
          "followers": 12595
        },
        {
          "date": "2019-11",
          "followers": 12605
        },
        {
          "date": "2019-10",
          "followers": 12334
        },
        {
          "date": "2019-09",
          "followers": 12318
        },
        {
          "date": "2019-08",
          "followers": 12322
        },
        {
          "date": "2019-07",
          "followers": 12289
        },
        {
          "date": "2019-06",
          "followers": 12258
        },
        {
          "date": "2019-05",
          "followers": 12245
        },
        {
          "date": "2019-04",
          "followers": 12243
        },
        {
          "date": "2019-03",
          "followers": 12226
        },
        {
          "date": "2019-02",
          "followers": 12217
        },
        {
          "date": "2019-01",
          "followers": 12225
        },
        {
          "date": "2018-12",
          "followers": 12223
        },
        {
          "date": "2018-11",
          "followers": 12209
        },
        {
          "date": "2018-10",
          "followers": 12251
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Japhy",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 84272
        },
        {
          "date": "2021-10",
          "followers": 84415
        },
        {
          "date": "2021-09",
          "followers": 70745
        },
        {
          "date": "2021-08",
          "followers": 67801
        },
        {
          "date": "2021-07",
          "followers": 51565
        },
        {
          "date": "2021-06",
          "followers": 45602
        },
        {
          "date": "2021-05",
          "followers": 41840
        },
        {
          "date": "2021-04",
          "followers": 40204
        },
        {
          "date": "2021-03",
          "followers": 37001
        },
        {
          "date": "2021-02",
          "followers": 35735
        },
        {
          "date": "2021-01",
          "followers": 35046
        },
        {
          "date": "2020-12",
          "followers": 34418
        },
        {
          "date": "2020-11",
          "followers": 34065
        },
        {
          "date": "2020-10",
          "followers": 33523
        },
        {
          "date": "2020-09",
          "followers": 33157
        },
        {
          "date": "2020-08",
          "followers": 31983
        },
        {
          "date": "2020-07",
          "followers": 31445
        },
        {
          "date": "2020-06",
          "followers": 30232
        },
        {
          "date": "2020-05",
          "followers": 28838
        },
        {
          "date": "2020-04",
          "followers": 28106
        },
        {
          "date": "2020-03",
          "followers": 26653
        },
        {
          "date": "2020-02",
          "followers": 24609
        },
        {
          "date": "2020-01",
          "followers": 23361
        },
        {
          "date": "2019-12",
          "followers": 22970
        },
        {
          "date": "2019-11",
          "followers": 22190
        },
        {
          "date": "2019-10",
          "followers": 21148
        },
        {
          "date": "2019-09",
          "followers": 20112
        },
        {
          "date": "2019-08",
          "followers": 19418
        },
        {
          "date": "2019-07",
          "followers": 18288
        },
        {
          "date": "2019-06",
          "followers": 16949
        },
        {
          "date": "2019-05",
          "followers": 15937
        },
        {
          "date": "2019-04",
          "followers": 14800
        },
        {
          "date": "2019-03",
          "followers": 14026
        },
        {
          "date": "2019-02",
          "followers": 12584
        },
        {
          "date": "2019-01",
          "followers": 11320
        },
        {
          "date": "2018-12",
          "followers": 9529
        },
        {
          "date": "2018-11",
          "followers": 7006
        },
        {
          "date": "2018-10",
          "followers": 5320
        },
        {
          "date": "2018-09",
          "followers": 4720
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 14490
        },
        {
          "date": "2021-10",
          "likes": 14417
        },
        {
          "date": "2021-09",
          "likes": 14336
        },
        {
          "date": "2021-08",
          "likes": 14188
        },
        {
          "date": "2021-07",
          "likes": 14071
        },
        {
          "date": "2021-06",
          "likes": 13935
        },
        {
          "date": "2021-05",
          "likes": 13805
        },
        {
          "date": "2021-04",
          "likes": 13524
        },
        {
          "date": "2021-03",
          "likes": 13349
        },
        {
          "date": "2021-02",
          "likes": 13142
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Jiminy&#039;s",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 30627
        },
        {
          "date": "2021-10",
          "followers": 30648
        },
        {
          "date": "2021-09",
          "followers": 30634
        },
        {
          "date": "2021-08",
          "followers": 30595
        },
        {
          "date": "2021-07",
          "followers": 30565
        },
        {
          "date": "2021-06",
          "followers": 30314
        },
        {
          "date": "2021-05",
          "followers": 30242
        },
        {
          "date": "2021-04",
          "followers": 30209
        },
        {
          "date": "2021-03",
          "followers": 29804
        },
        {
          "date": "2021-02",
          "followers": 29802
        },
        {
          "date": "2021-01",
          "followers": 29851
        },
        {
          "date": "2020-12",
          "followers": 29888
        },
        {
          "date": "2020-11",
          "followers": 29906
        },
        {
          "date": "2020-10",
          "followers": 29933
        },
        {
          "date": "2020-09",
          "followers": 30020
        },
        {
          "date": "2020-08",
          "followers": 30051
        },
        {
          "date": "2020-07",
          "followers": 30093
        },
        {
          "date": "2020-06",
          "followers": 30171
        },
        {
          "date": "2020-05",
          "followers": 30285
        },
        {
          "date": "2020-04",
          "followers": 30358
        },
        {
          "date": "2020-03",
          "followers": 30492
        },
        {
          "date": "2020-02",
          "followers": 30641
        },
        {
          "date": "2020-01",
          "followers": 30752
        },
        {
          "date": "2019-12",
          "followers": 30805
        },
        {
          "date": "2019-11",
          "followers": 30961
        },
        {
          "date": "2019-10",
          "followers": 31078
        },
        {
          "date": "2019-09",
          "followers": 31202
        },
        {
          "date": "2019-08",
          "followers": 31343
        },
        {
          "date": "2019-07",
          "followers": 31438
        },
        {
          "date": "2019-06",
          "followers": 31523
        },
        {
          "date": "2019-05",
          "followers": 31853
        },
        {
          "date": "2019-04",
          "followers": 30167
        },
        {
          "date": "2019-03",
          "followers": 28428
        },
        {
          "date": "2019-02",
          "followers": 26405
        },
        {
          "date": "2019-01",
          "followers": 25318
        },
        {
          "date": "2018-12",
          "followers": 24073
        },
        {
          "date": "2018-11",
          "followers": 22880
        },
        {
          "date": "2018-10",
          "followers": 21859
        },
        {
          "date": "2018-09",
          "followers": 19894
        }
      ]
    },
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 1048
        },
        {
          "date": "2021-10",
          "followers": 1052
        },
        {
          "date": "2021-09",
          "followers": 1050
        },
        {
          "date": "2021-08",
          "followers": 1039
        },
        {
          "date": "2021-07",
          "followers": 1039
        },
        {
          "date": "2021-06",
          "followers": 1023
        },
        {
          "date": "2021-05",
          "followers": 962
        },
        {
          "date": "2021-04",
          "followers": 952
        },
        {
          "date": "2021-03",
          "followers": 946
        },
        {
          "date": "2021-02",
          "followers": 939
        },
        {
          "date": "2021-01",
          "followers": 929
        },
        {
          "date": "2020-12",
          "followers": 868
        },
        {
          "date": "2020-11",
          "followers": 568
        },
        {
          "date": "2020-10",
          "followers": 560
        },
        {
          "date": "2020-09",
          "followers": 559
        },
        {
          "date": "2020-08",
          "followers": 555
        },
        {
          "date": "2020-07",
          "followers": 551
        },
        {
          "date": "2020-06",
          "followers": 546
        },
        {
          "date": "2020-05",
          "followers": 542
        },
        {
          "date": "2020-04",
          "followers": 537
        },
        {
          "date": "2020-03",
          "followers": 535
        },
        {
          "date": "2020-02",
          "followers": 537
        },
        {
          "date": "2020-01",
          "followers": 534
        },
        {
          "date": "2019-12",
          "followers": 538
        },
        {
          "date": "2019-11",
          "followers": 531
        },
        {
          "date": "2019-10",
          "followers": 528
        },
        {
          "date": "2019-09",
          "followers": 522
        },
        {
          "date": "2019-08",
          "followers": 516
        },
        {
          "date": "2019-07",
          "followers": 505
        },
        {
          "date": "2019-06",
          "followers": 499
        },
        {
          "date": "2019-05",
          "followers": 503
        },
        {
          "date": "2019-04",
          "followers": 490
        },
        {
          "date": "2019-03",
          "followers": 475
        },
        {
          "date": "2019-02",
          "followers": 469
        },
        {
          "date": "2019-01",
          "followers": 462
        },
        {
          "date": "2018-12",
          "followers": 386
        },
        {
          "date": "2018-11",
          "followers": 382
        },
        {
          "date": "2018-10",
          "followers": 375
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Jinx",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 12922
        },
        {
          "date": "2021-10",
          "followers": 12905
        },
        {
          "date": "2021-09",
          "followers": 12695
        },
        {
          "date": "2021-08",
          "followers": 12575
        },
        {
          "date": "2021-07",
          "followers": 12170
        },
        {
          "date": "2021-06",
          "followers": 11504
        },
        {
          "date": "2021-05",
          "followers": 10586
        },
        {
          "date": "2021-04",
          "followers": 9556
        },
        {
          "date": "2021-03",
          "followers": 8821
        },
        {
          "date": "2021-02",
          "followers": 7909
        },
        {
          "date": "2021-01",
          "followers": 7253
        },
        {
          "date": "2020-12",
          "followers": 6434
        },
        {
          "date": "2020-11",
          "followers": 5588
        },
        {
          "date": "2020-10",
          "followers": 5047
        },
        {
          "date": "2020-09",
          "followers": 4099
        },
        {
          "date": "2020-08",
          "followers": 3680
        },
        {
          "date": "2020-07",
          "followers": 3420
        },
        {
          "date": "2020-06",
          "followers": 3036
        },
        {
          "date": "2020-05",
          "followers": 2958
        },
        {
          "date": "2020-04",
          "followers": 2494
        },
        {
          "date": "2020-03",
          "followers": 2037
        },
        {
          "date": "2020-02",
          "followers": 1590
        },
        {
          "date": "2020-01",
          "followers": 881
        },
        {
          "date": "2019-12",
          "followers": 484
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 1573
        },
        {
          "date": "2021-10",
          "likes": 1566
        },
        {
          "date": "2021-09",
          "likes": 1542
        },
        {
          "date": "2021-08",
          "likes": 1529
        },
        {
          "date": "2021-07",
          "likes": 1511
        },
        {
          "date": "2021-06",
          "likes": 1478
        },
        {
          "date": "2021-05",
          "likes": 1373
        },
        {
          "date": "2021-04",
          "likes": 1236
        },
        {
          "date": "2021-03",
          "likes": 1056
        },
        {
          "date": "2021-02",
          "likes": 951
        },
        {
          "date": "2021-01",
          "likes": 902
        },
        {
          "date": "2020-12",
          "likes": 753
        },
        {
          "date": "2020-11",
          "likes": 727
        },
        {
          "date": "2020-10",
          "likes": 693
        },
        {
          "date": "2020-09",
          "likes": 667
        },
        {
          "date": "2020-08",
          "likes": 647
        },
        {
          "date": "2020-07",
          "likes": 619
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 163
        },
        {
          "date": "2021-10",
          "followers": 163
        },
        {
          "date": "2021-09",
          "followers": 149
        },
        {
          "date": "2021-08",
          "followers": 144
        },
        {
          "date": "2021-07",
          "followers": 137
        },
        {
          "date": "2021-06",
          "followers": 137
        },
        {
          "date": "2021-05",
          "followers": 129
        },
        {
          "date": "2021-04",
          "followers": 118
        },
        {
          "date": "2021-03",
          "followers": 116
        },
        {
          "date": "2021-02",
          "followers": 115
        },
        {
          "date": "2021-01",
          "followers": 114
        },
        {
          "date": "2020-12",
          "followers": 119
        },
        {
          "date": "2020-11",
          "followers": 113
        },
        {
          "date": "2020-10",
          "followers": 104
        },
        {
          "date": "2020-09",
          "followers": 91
        },
        {
          "date": "2020-08",
          "followers": 89
        },
        {
          "date": "2020-07",
          "followers": 90
        },
        {
          "date": "2020-06",
          "followers": 89
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "JustFoodforDogs",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 73096
        },
        {
          "date": "2021-10",
          "followers": 72290
        },
        {
          "date": "2021-09",
          "followers": 70623
        },
        {
          "date": "2021-08",
          "followers": 69693
        },
        {
          "date": "2021-07",
          "followers": 68317
        },
        {
          "date": "2021-06",
          "followers": 66066
        },
        {
          "date": "2021-05",
          "followers": 62706
        },
        {
          "date": "2021-04",
          "followers": 60275
        },
        {
          "date": "2021-03",
          "followers": 56411
        },
        {
          "date": "2021-02",
          "followers": 54699
        },
        {
          "date": "2021-01",
          "followers": 52397
        },
        {
          "date": "2020-12",
          "followers": 49732
        },
        {
          "date": "2020-11",
          "followers": 47437
        },
        {
          "date": "2020-10",
          "followers": 46094
        },
        {
          "date": "2020-09",
          "followers": 45016
        },
        {
          "date": "2020-08",
          "followers": 43643
        },
        {
          "date": "2020-07",
          "followers": 42672
        },
        {
          "date": "2020-06",
          "followers": 41778
        },
        {
          "date": "2020-05",
          "followers": 40857
        },
        {
          "date": "2020-04",
          "followers": 39106
        },
        {
          "date": "2020-03",
          "followers": 37926
        },
        {
          "date": "2020-02",
          "followers": 36974
        },
        {
          "date": "2020-01",
          "followers": 35951
        },
        {
          "date": "2019-12",
          "followers": 34487
        },
        {
          "date": "2019-11",
          "followers": 33576
        },
        {
          "date": "2019-10",
          "followers": 32179
        },
        {
          "date": "2019-09",
          "followers": 30534
        },
        {
          "date": "2019-08",
          "followers": 28784
        },
        {
          "date": "2019-07",
          "followers": 26992
        },
        {
          "date": "2019-06",
          "followers": 25952
        },
        {
          "date": "2019-05",
          "followers": 24552
        },
        {
          "date": "2019-04",
          "followers": 21778
        },
        {
          "date": "2019-03",
          "followers": 20723
        },
        {
          "date": "2019-02",
          "followers": 19066
        },
        {
          "date": "2019-01",
          "followers": 16999
        },
        {
          "date": "2018-12",
          "followers": 15238
        },
        {
          "date": "2018-11",
          "followers": 13746
        }
      ]
    },
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 5656
        },
        {
          "date": "2021-10",
          "followers": 5592
        },
        {
          "date": "2021-09",
          "followers": 5453
        },
        {
          "date": "2021-08",
          "followers": 5329
        },
        {
          "date": "2021-07",
          "followers": 5048
        },
        {
          "date": "2021-06",
          "followers": 4655
        },
        {
          "date": "2021-05",
          "followers": 4079
        },
        {
          "date": "2021-04",
          "followers": 3497
        },
        {
          "date": "2021-03",
          "followers": 1947
        },
        {
          "date": "2021-02",
          "followers": 1928
        },
        {
          "date": "2021-01",
          "followers": 1913
        },
        {
          "date": "2020-12",
          "followers": 1926
        },
        {
          "date": "2020-11",
          "followers": 1912
        },
        {
          "date": "2020-10",
          "followers": 1844
        },
        {
          "date": "2020-09",
          "followers": 1840
        },
        {
          "date": "2020-08",
          "followers": 1824
        },
        {
          "date": "2020-07",
          "followers": 1826
        },
        {
          "date": "2020-06",
          "followers": 1814
        },
        {
          "date": "2020-05",
          "followers": 1815
        },
        {
          "date": "2020-04",
          "followers": 1692
        },
        {
          "date": "2020-03",
          "followers": 1529
        },
        {
          "date": "2020-02",
          "followers": 1408
        },
        {
          "date": "2020-01",
          "followers": 1395
        },
        {
          "date": "2019-12",
          "followers": 1381
        },
        {
          "date": "2019-11",
          "followers": 1367
        },
        {
          "date": "2019-10",
          "followers": 1358
        },
        {
          "date": "2019-09",
          "followers": 1343
        },
        {
          "date": "2019-08",
          "followers": 1333
        },
        {
          "date": "2019-07",
          "followers": 1312
        },
        {
          "date": "2019-06",
          "followers": 1298
        },
        {
          "date": "2019-05",
          "followers": 1285
        },
        {
          "date": "2019-04",
          "followers": 1260
        },
        {
          "date": "2019-03",
          "followers": 1257
        },
        {
          "date": "2019-02",
          "followers": 1245
        },
        {
          "date": "2019-01",
          "followers": 1234
        },
        {
          "date": "2018-12",
          "followers": 1230
        },
        {
          "date": "2018-11",
          "followers": 1201
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "JustRight",
    "instagram": null,
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 665
        },
        {
          "date": "2021-10",
          "followers": 661
        },
        {
          "date": "2021-09",
          "followers": 647
        },
        {
          "date": "2021-08",
          "followers": 639
        },
        {
          "date": "2021-07",
          "followers": 626
        },
        {
          "date": "2021-06",
          "followers": 613
        },
        {
          "date": "2021-05",
          "followers": 603
        },
        {
          "date": "2021-04",
          "followers": 607
        },
        {
          "date": "2021-03",
          "followers": 606
        },
        {
          "date": "2021-02",
          "followers": 606
        },
        {
          "date": "2021-01",
          "followers": 604
        },
        {
          "date": "2020-12",
          "followers": 611
        },
        {
          "date": "2020-11",
          "followers": 609
        },
        {
          "date": "2020-10",
          "followers": 607
        },
        {
          "date": "2020-09",
          "followers": 613
        },
        {
          "date": "2020-08",
          "followers": 612
        },
        {
          "date": "2020-07",
          "followers": 616
        },
        {
          "date": "2020-06",
          "followers": 619
        },
        {
          "date": "2020-05",
          "followers": 618
        },
        {
          "date": "2020-04",
          "followers": 616
        },
        {
          "date": "2020-03",
          "followers": 617
        },
        {
          "date": "2020-02",
          "followers": 624
        },
        {
          "date": "2020-01",
          "followers": 628
        },
        {
          "date": "2019-12",
          "followers": 627
        },
        {
          "date": "2019-11",
          "followers": 632
        },
        {
          "date": "2019-10",
          "followers": 632
        },
        {
          "date": "2019-09",
          "followers": 631
        },
        {
          "date": "2019-08",
          "followers": 639
        },
        {
          "date": "2019-07",
          "followers": 639
        },
        {
          "date": "2019-06",
          "followers": 639
        },
        {
          "date": "2019-05",
          "followers": 638
        },
        {
          "date": "2019-04",
          "followers": 643
        },
        {
          "date": "2019-03",
          "followers": 645
        },
        {
          "date": "2019-02",
          "followers": 642
        },
        {
          "date": "2019-01",
          "followers": 644
        },
        {
          "date": "2018-12",
          "followers": 644
        },
        {
          "date": "2018-11",
          "followers": 644
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Kabo",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 7406
        },
        {
          "date": "2021-10",
          "followers": 7190
        },
        {
          "date": "2021-09",
          "followers": 6895
        },
        {
          "date": "2021-08",
          "followers": 6670
        },
        {
          "date": "2021-07",
          "followers": 6245
        },
        {
          "date": "2021-06",
          "followers": 6040
        },
        {
          "date": "2021-05",
          "followers": 5806
        },
        {
          "date": "2021-04",
          "followers": 5418
        },
        {
          "date": "2021-03",
          "followers": 4907
        },
        {
          "date": "2021-02",
          "followers": 4712
        },
        {
          "date": "2021-01",
          "followers": 4566
        },
        {
          "date": "2020-12",
          "followers": 4510
        },
        {
          "date": "2020-11",
          "followers": 4277
        },
        {
          "date": "2020-10",
          "followers": 3791
        },
        {
          "date": "2020-09",
          "followers": 3398
        },
        {
          "date": "2020-08",
          "followers": 3117
        },
        {
          "date": "2020-07",
          "followers": 3001
        },
        {
          "date": "2020-06",
          "followers": 2884
        },
        {
          "date": "2020-05",
          "followers": 2687
        },
        {
          "date": "2020-04",
          "followers": 2545
        },
        {
          "date": "2020-03",
          "followers": 2264
        },
        {
          "date": "2020-02",
          "followers": 2022
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Katkin",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 9793
        },
        {
          "date": "2021-10",
          "followers": 9491
        },
        {
          "date": "2021-09",
          "followers": 8690
        },
        {
          "date": "2021-08",
          "followers": 7661
        },
        {
          "date": "2021-07",
          "followers": 6992
        },
        {
          "date": "2021-06",
          "followers": 6337
        },
        {
          "date": "2021-05",
          "followers": 5988
        },
        {
          "date": "2021-04",
          "followers": 5484
        },
        {
          "date": "2021-03",
          "followers": 4910
        },
        {
          "date": "2021-02",
          "followers": 4371
        },
        {
          "date": "2021-01",
          "followers": 3914
        },
        {
          "date": "2020-12",
          "followers": 3141
        },
        {
          "date": "2020-11",
          "followers": 2702
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "LaFineGamelle",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 2401
        },
        {
          "date": "2021-10",
          "followers": 2391
        },
        {
          "date": "2021-09",
          "followers": 2394
        },
        {
          "date": "2021-08",
          "followers": 2405
        },
        {
          "date": "2021-07",
          "followers": 2396
        },
        {
          "date": "2021-06",
          "followers": 2406
        },
        {
          "date": "2021-05",
          "followers": 2410
        },
        {
          "date": "2021-04",
          "followers": 2392
        },
        {
          "date": "2021-03",
          "followers": 2347
        },
        {
          "date": "2021-02",
          "followers": 2339
        },
        {
          "date": "2021-01",
          "followers": 2327
        },
        {
          "date": "2020-12",
          "followers": 2324
        },
        {
          "date": "2020-11",
          "followers": 2319
        },
        {
          "date": "2020-10",
          "followers": 2301
        },
        {
          "date": "2020-09",
          "followers": 2278
        },
        {
          "date": "2020-08",
          "followers": 2242
        },
        {
          "date": "2020-07",
          "followers": 2250
        },
        {
          "date": "2020-06",
          "followers": 2276
        },
        {
          "date": "2020-05",
          "followers": 2250
        },
        {
          "date": "2020-04",
          "followers": 2236
        },
        {
          "date": "2020-03",
          "followers": 2160
        },
        {
          "date": "2020-02",
          "followers": 2000
        },
        {
          "date": "2020-01",
          "followers": 1944
        },
        {
          "date": "2019-12",
          "followers": 1907
        },
        {
          "date": "2019-11",
          "followers": 1835
        },
        {
          "date": "2019-10",
          "followers": 1450
        },
        {
          "date": "2019-09",
          "followers": 1424
        },
        {
          "date": "2019-08",
          "followers": 1363
        },
        {
          "date": "2019-07",
          "followers": 1317
        },
        {
          "date": "2019-06",
          "followers": 1300
        },
        {
          "date": "2019-05",
          "followers": 1291
        },
        {
          "date": "2019-04",
          "followers": 1221
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "LaPetCuisine",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 28654
        },
        {
          "date": "2021-10",
          "followers": 28667
        },
        {
          "date": "2021-09",
          "followers": 28711
        },
        {
          "date": "2021-08",
          "followers": 28759
        },
        {
          "date": "2021-07",
          "followers": 28751
        },
        {
          "date": "2021-06",
          "followers": 28796
        },
        {
          "date": "2021-05",
          "followers": 28870
        },
        {
          "date": "2021-04",
          "followers": 28780
        },
        {
          "date": "2021-03",
          "followers": 28896
        },
        {
          "date": "2021-02",
          "followers": 28777
        },
        {
          "date": "2021-01",
          "followers": 27737
        },
        {
          "date": "2020-12",
          "followers": 27612
        },
        {
          "date": "2020-11",
          "followers": 27482
        },
        {
          "date": "2020-10",
          "followers": 27445
        },
        {
          "date": "2020-09",
          "followers": 27238
        },
        {
          "date": "2020-08",
          "followers": 26994
        },
        {
          "date": "2020-07",
          "followers": 26986
        },
        {
          "date": "2020-06",
          "followers": 27091
        },
        {
          "date": "2020-05",
          "followers": 26993
        },
        {
          "date": "2020-04",
          "followers": 26944
        },
        {
          "date": "2020-03",
          "followers": 26978
        },
        {
          "date": "2020-02",
          "followers": 26982
        },
        {
          "date": "2020-01",
          "followers": 27016
        },
        {
          "date": "2019-12",
          "followers": 27067
        },
        {
          "date": "2019-11",
          "followers": 27107
        },
        {
          "date": "2019-10",
          "followers": 27111
        },
        {
          "date": "2019-09",
          "followers": 26948
        },
        {
          "date": "2019-08",
          "followers": 26865
        },
        {
          "date": "2019-07",
          "followers": 26598
        },
        {
          "date": "2019-06",
          "followers": 26526
        },
        {
          "date": "2019-05",
          "followers": 26232
        },
        {
          "date": "2019-04",
          "followers": 26076
        },
        {
          "date": "2019-03",
          "followers": 25900
        },
        {
          "date": "2019-02",
          "followers": 25410
        },
        {
          "date": "2019-01",
          "followers": 24379
        },
        {
          "date": "2018-12",
          "followers": 23667
        },
        {
          "date": "2018-11",
          "followers": 22205
        },
        {
          "date": "2018-10",
          "followers": 20865
        },
        {
          "date": "2018-09",
          "followers": 19300
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Lyka",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 15585
        },
        {
          "date": "2021-10",
          "followers": 15189
        },
        {
          "date": "2021-09",
          "followers": 14520
        },
        {
          "date": "2021-08",
          "followers": 13890
        },
        {
          "date": "2021-07",
          "followers": 13033
        },
        {
          "date": "2021-06",
          "followers": 12297
        },
        {
          "date": "2021-05",
          "followers": 11635
        },
        {
          "date": "2021-04",
          "followers": 10011
        },
        {
          "date": "2021-03",
          "followers": 9385
        },
        {
          "date": "2021-02",
          "followers": 8570
        },
        {
          "date": "2021-01",
          "followers": 7703
        },
        {
          "date": "2020-12",
          "followers": 7252
        },
        {
          "date": "2020-11",
          "followers": 6956
        },
        {
          "date": "2020-10",
          "followers": 6203
        },
        {
          "date": "2020-09",
          "followers": 5685
        },
        {
          "date": "2020-08",
          "followers": 5459
        },
        {
          "date": "2020-07",
          "followers": 5137
        },
        {
          "date": "2020-06",
          "followers": 4789
        },
        {
          "date": "2020-05",
          "followers": 4486
        },
        {
          "date": "2020-04",
          "followers": 3914
        },
        {
          "date": "2020-03",
          "followers": 3627
        },
        {
          "date": "2020-02",
          "followers": 3460
        },
        {
          "date": "2020-01",
          "followers": 3209
        },
        {
          "date": "2019-12",
          "followers": 3043
        },
        {
          "date": "2019-11",
          "followers": 2944
        },
        {
          "date": "2019-10",
          "followers": 2759
        },
        {
          "date": "2019-09",
          "followers": 2517
        },
        {
          "date": "2019-08",
          "followers": 2405
        },
        {
          "date": "2019-07",
          "followers": 2293
        },
        {
          "date": "2019-06",
          "followers": 2296
        },
        {
          "date": "2019-05",
          "followers": 2299
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 7780
        },
        {
          "date": "2021-10",
          "likes": 7577
        },
        {
          "date": "2021-09",
          "likes": 7137
        },
        {
          "date": "2021-08",
          "likes": 6649
        },
        {
          "date": "2021-07",
          "likes": 6414
        },
        {
          "date": "2021-06",
          "likes": 5943
        },
        {
          "date": "2021-05",
          "likes": 5539
        },
        {
          "date": "2021-04",
          "likes": 4416
        },
        {
          "date": "2021-03",
          "likes": 4014
        },
        {
          "date": "2021-02",
          "likes": 3665
        },
        {
          "date": "2021-01",
          "likes": 3179
        },
        {
          "date": "2020-12",
          "likes": 2940
        },
        {
          "date": "2020-11",
          "likes": 2780
        },
        {
          "date": "2020-10",
          "likes": 2415
        },
        {
          "date": "2020-09",
          "likes": 2169
        },
        {
          "date": "2020-08",
          "likes": 1941
        },
        {
          "date": "2020-07",
          "likes": 1788
        },
        {
          "date": "2020-06",
          "likes": 1613
        },
        {
          "date": "2020-05",
          "likes": 1238
        },
        {
          "date": "2020-04",
          "likes": 945
        },
        {
          "date": "2020-03",
          "likes": 839
        },
        {
          "date": "2020-02",
          "likes": 777
        },
        {
          "date": "2020-01",
          "likes": 681
        },
        {
          "date": "2019-12",
          "likes": 635
        },
        {
          "date": "2019-11",
          "likes": 598
        },
        {
          "date": "2019-10",
          "likes": 546
        },
        {
          "date": "2019-09",
          "likes": 520
        },
        {
          "date": "2019-08",
          "likes": 511
        },
        {
          "date": "2019-07",
          "likes": 469
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "MaCroquetteàmoi",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 508
        },
        {
          "date": "2021-10",
          "followers": 507
        },
        {
          "date": "2021-09",
          "followers": 498
        },
        {
          "date": "2021-08",
          "followers": 489
        },
        {
          "date": "2021-07",
          "followers": 488
        },
        {
          "date": "2021-06",
          "followers": 490
        },
        {
          "date": "2021-05",
          "followers": 490
        },
        {
          "date": "2021-04",
          "followers": 495
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Meowbox",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 740139
        },
        {
          "date": "2021-10",
          "followers": 740484
        },
        {
          "date": "2021-09",
          "followers": 740862
        },
        {
          "date": "2021-08",
          "followers": 741375
        },
        {
          "date": "2021-07",
          "followers": 741373
        },
        {
          "date": "2021-06",
          "followers": 740993
        },
        {
          "date": "2021-05",
          "followers": 737588
        },
        {
          "date": "2021-04",
          "followers": 732702
        },
        {
          "date": "2021-03",
          "followers": 728988
        },
        {
          "date": "2021-02",
          "followers": 728485
        },
        {
          "date": "2021-01",
          "followers": 725180
        },
        {
          "date": "2020-12",
          "followers": 723555
        },
        {
          "date": "2020-11",
          "followers": 723047
        },
        {
          "date": "2020-10",
          "followers": 725004
        },
        {
          "date": "2020-09",
          "followers": 726297
        },
        {
          "date": "2020-08",
          "followers": 727822
        },
        {
          "date": "2020-07",
          "followers": 729748
        },
        {
          "date": "2020-06",
          "followers": 731698
        },
        {
          "date": "2020-05",
          "followers": 733992
        },
        {
          "date": "2020-04",
          "followers": 736256
        },
        {
          "date": "2020-03",
          "followers": 736511
        },
        {
          "date": "2020-02",
          "followers": 736406
        },
        {
          "date": "2020-01",
          "followers": 734565
        },
        {
          "date": "2019-12",
          "followers": 731994
        },
        {
          "date": "2019-11",
          "followers": 729995
        },
        {
          "date": "2019-10",
          "followers": 726260
        },
        {
          "date": "2019-09",
          "followers": 719977
        },
        {
          "date": "2019-08",
          "followers": 713058
        },
        {
          "date": "2019-07",
          "followers": 710550
        },
        {
          "date": "2019-06",
          "followers": 709301
        },
        {
          "date": "2019-05",
          "followers": 704903
        },
        {
          "date": "2019-04",
          "followers": 701252
        },
        {
          "date": "2019-03",
          "followers": 695915
        },
        {
          "date": "2019-02",
          "followers": 694349
        },
        {
          "date": "2019-01",
          "followers": 691236
        },
        {
          "date": "2018-12",
          "followers": 684914
        },
        {
          "date": "2018-11",
          "followers": 680547
        },
        {
          "date": "2018-10",
          "followers": 677800
        },
        {
          "date": "2018-09",
          "followers": 673945
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 191936
        },
        {
          "date": "2021-10",
          "likes": 191974
        },
        {
          "date": "2021-09",
          "likes": 191253
        },
        {
          "date": "2021-08",
          "likes": 191406
        },
        {
          "date": "2021-07",
          "likes": 191479
        },
        {
          "date": "2021-06",
          "likes": 191647
        },
        {
          "date": "2021-05",
          "likes": 191843
        },
        {
          "date": "2021-04",
          "likes": 191947
        },
        {
          "date": "2021-03",
          "likes": 192066
        },
        {
          "date": "2021-02",
          "likes": 192188
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 6755
        },
        {
          "date": "2021-10",
          "followers": 6749
        },
        {
          "date": "2021-09",
          "followers": 6742
        },
        {
          "date": "2021-08",
          "followers": 6749
        },
        {
          "date": "2021-07",
          "followers": 6715
        },
        {
          "date": "2021-06",
          "followers": 6720
        },
        {
          "date": "2021-05",
          "followers": 6736
        },
        {
          "date": "2021-04",
          "followers": 6775
        },
        {
          "date": "2021-03",
          "followers": 6749
        },
        {
          "date": "2021-02",
          "followers": 6758
        },
        {
          "date": "2021-01",
          "followers": 6772
        },
        {
          "date": "2020-12",
          "followers": 6784
        },
        {
          "date": "2020-11",
          "followers": 6791
        },
        {
          "date": "2020-10",
          "followers": 6794
        },
        {
          "date": "2020-09",
          "followers": 6791
        },
        {
          "date": "2020-08",
          "followers": 6798
        },
        {
          "date": "2020-07",
          "followers": 6815
        },
        {
          "date": "2020-06",
          "followers": 6817
        },
        {
          "date": "2020-05",
          "followers": 6859
        },
        {
          "date": "2020-04",
          "followers": 6883
        },
        {
          "date": "2020-03",
          "followers": 6897
        },
        {
          "date": "2020-02",
          "followers": 6900
        },
        {
          "date": "2020-01",
          "followers": 6885
        },
        {
          "date": "2019-12",
          "followers": 6886
        },
        {
          "date": "2019-11",
          "followers": 6887
        },
        {
          "date": "2019-10",
          "followers": 6885
        },
        {
          "date": "2019-09",
          "followers": 6853
        },
        {
          "date": "2019-08",
          "followers": 6869
        },
        {
          "date": "2019-07",
          "followers": 6864
        },
        {
          "date": "2019-06",
          "followers": 6862
        },
        {
          "date": "2019-05",
          "followers": 6839
        },
        {
          "date": "2019-04",
          "followers": 6817
        },
        {
          "date": "2019-03",
          "followers": 6769
        },
        {
          "date": "2019-02",
          "followers": 6758
        },
        {
          "date": "2019-01",
          "followers": 6729
        },
        {
          "date": "2018-12",
          "followers": 6751
        },
        {
          "date": "2018-11",
          "followers": 6742
        },
        {
          "date": "2018-10",
          "followers": 6766
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "NandiPets",
    "instagram": null,
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 11466
        },
        {
          "date": "2021-10",
          "likes": 11464
        },
        {
          "date": "2021-09",
          "likes": 11470
        },
        {
          "date": "2021-08",
          "likes": 11457
        },
        {
          "date": "2021-07",
          "likes": 11465
        },
        {
          "date": "2021-06",
          "likes": 11453
        },
        {
          "date": "2021-05",
          "likes": 11466
        },
        {
          "date": "2021-04",
          "likes": 11470
        },
        {
          "date": "2021-03",
          "likes": 11492
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "NativePet",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 5082
        },
        {
          "date": "2021-10",
          "followers": 4917
        },
        {
          "date": "2021-09",
          "followers": 4545
        },
        {
          "date": "2021-08",
          "followers": 4223
        },
        {
          "date": "2021-07",
          "followers": 3364
        },
        {
          "date": "2021-06",
          "followers": 2828
        },
        {
          "date": "2021-05",
          "followers": 2576
        },
        {
          "date": "2021-04",
          "followers": 1938
        },
        {
          "date": "2021-03",
          "followers": 1664
        },
        {
          "date": "2021-02",
          "followers": 1489
        },
        {
          "date": "2021-01",
          "followers": 1344
        },
        {
          "date": "2020-12",
          "followers": 1246
        },
        {
          "date": "2020-11",
          "followers": 1175
        },
        {
          "date": "2020-10",
          "followers": 1099
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 672
        },
        {
          "date": "2021-10",
          "likes": 669
        },
        {
          "date": "2021-09",
          "likes": 658
        },
        {
          "date": "2021-08",
          "likes": 652
        },
        {
          "date": "2021-07",
          "likes": 644
        },
        {
          "date": "2021-06",
          "likes": 635
        },
        {
          "date": "2021-05",
          "likes": 624
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "NewRoadFoods",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 2559
        },
        {
          "date": "2021-10",
          "followers": 2568
        },
        {
          "date": "2021-09",
          "followers": 2578
        },
        {
          "date": "2021-08",
          "followers": 2587
        },
        {
          "date": "2021-07",
          "followers": 2603
        },
        {
          "date": "2021-06",
          "followers": 2600
        },
        {
          "date": "2021-05",
          "followers": 2606
        },
        {
          "date": "2021-04",
          "followers": 2595
        },
        {
          "date": "2021-03",
          "followers": 2604
        },
        {
          "date": "2021-02",
          "followers": 2622
        },
        {
          "date": "2021-01",
          "followers": 2643
        },
        {
          "date": "2020-12",
          "followers": 2669
        },
        {
          "date": "2020-11",
          "followers": 2376
        },
        {
          "date": "2020-10",
          "followers": 2378
        },
        {
          "date": "2020-09",
          "followers": 2393
        },
        {
          "date": "2020-08",
          "followers": 2396
        },
        {
          "date": "2020-07",
          "followers": 2335
        },
        {
          "date": "2020-06",
          "followers": 2289
        },
        {
          "date": "2020-05",
          "followers": 2316
        },
        {
          "date": "2020-04",
          "followers": 2330
        },
        {
          "date": "2020-03",
          "followers": 2324
        },
        {
          "date": "2020-02",
          "followers": 1966
        }
      ]
    },
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 167
        },
        {
          "date": "2021-10",
          "followers": 167
        },
        {
          "date": "2021-09",
          "followers": 166
        },
        {
          "date": "2021-08",
          "followers": 166
        },
        {
          "date": "2021-07",
          "followers": 166
        },
        {
          "date": "2021-06",
          "followers": 166
        },
        {
          "date": "2021-05",
          "followers": 167
        },
        {
          "date": "2021-04",
          "followers": 167
        },
        {
          "date": "2021-03",
          "followers": 168
        },
        {
          "date": "2021-02",
          "followers": 171
        },
        {
          "date": "2021-01",
          "followers": 175
        },
        {
          "date": "2020-12",
          "followers": 179
        },
        {
          "date": "2020-11",
          "followers": 180
        },
        {
          "date": "2020-10",
          "followers": 183
        },
        {
          "date": "2020-09",
          "followers": 184
        },
        {
          "date": "2020-08",
          "followers": 184
        },
        {
          "date": "2020-07",
          "followers": 187
        },
        {
          "date": "2020-06",
          "followers": 186
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "NomNomNow",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 39569
        },
        {
          "date": "2021-10",
          "followers": 39299
        },
        {
          "date": "2021-09",
          "followers": 38878
        },
        {
          "date": "2021-08",
          "followers": 38544
        },
        {
          "date": "2021-07",
          "followers": 37693
        },
        {
          "date": "2021-06",
          "followers": 35786
        },
        {
          "date": "2021-05",
          "followers": 34850
        },
        {
          "date": "2021-04",
          "followers": 33678
        },
        {
          "date": "2021-03",
          "followers": 31784
        },
        {
          "date": "2021-02",
          "followers": 30585
        },
        {
          "date": "2021-01",
          "followers": 29336
        },
        {
          "date": "2020-12",
          "followers": 28171
        },
        {
          "date": "2020-11",
          "followers": 27941
        },
        {
          "date": "2020-10",
          "followers": 27803
        },
        {
          "date": "2020-09",
          "followers": 27428
        },
        {
          "date": "2020-08",
          "followers": 27064
        },
        {
          "date": "2020-07",
          "followers": 26370
        },
        {
          "date": "2020-06",
          "followers": 25590
        },
        {
          "date": "2020-05",
          "followers": 24812
        },
        {
          "date": "2020-02",
          "followers": 20822
        },
        {
          "date": "2020-01",
          "followers": 20655
        },
        {
          "date": "2019-12",
          "followers": 19351
        },
        {
          "date": "2019-11",
          "followers": 18719
        },
        {
          "date": "2019-10",
          "followers": 17998
        },
        {
          "date": "2019-09",
          "followers": 17344
        },
        {
          "date": "2019-08",
          "followers": 16796
        },
        {
          "date": "2019-07",
          "followers": 16185
        },
        {
          "date": "2019-06",
          "followers": 15472
        },
        {
          "date": "2019-05",
          "followers": 14833
        },
        {
          "date": "2019-04",
          "followers": 13875
        },
        {
          "date": "2019-03",
          "followers": 13231
        },
        {
          "date": "2019-02",
          "followers": 12478
        },
        {
          "date": "2019-01",
          "followers": 12177
        },
        {
          "date": "2018-12",
          "followers": 11480
        },
        {
          "date": "2018-11",
          "followers": 10831
        },
        {
          "date": "2018-10",
          "followers": 9923
        },
        {
          "date": "2018-09",
          "followers": 9436
        },
        {
          "date": "2018-08",
          "followers": 9065
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 26989
        },
        {
          "date": "2021-10",
          "likes": 26772
        },
        {
          "date": "2021-09",
          "likes": 26141
        },
        {
          "date": "2021-08",
          "likes": 25719
        },
        {
          "date": "2021-07",
          "likes": 24974
        },
        {
          "date": "2021-06",
          "likes": 24102
        },
        {
          "date": "2021-05",
          "likes": 23534
        },
        {
          "date": "2021-04",
          "likes": 23185
        },
        {
          "date": "2021-03",
          "likes": 22590
        },
        {
          "date": "2021-02",
          "likes": 22084
        },
        {
          "date": "2021-01",
          "likes": 21550
        },
        {
          "date": "2020-12",
          "likes": 20831
        },
        {
          "date": "2020-11",
          "likes": 20370
        },
        {
          "date": "2020-10",
          "likes": 19945
        },
        {
          "date": "2020-09",
          "likes": 19513
        },
        {
          "date": "2020-08",
          "likes": 18632
        },
        {
          "date": "2020-07",
          "likes": 18115
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 2182
        },
        {
          "date": "2021-10",
          "followers": 2189
        },
        {
          "date": "2021-09",
          "followers": 2186
        },
        {
          "date": "2021-08",
          "followers": 2191
        },
        {
          "date": "2021-07",
          "followers": 2194
        },
        {
          "date": "2021-06",
          "followers": 2199
        },
        {
          "date": "2021-05",
          "followers": 2202
        },
        {
          "date": "2021-04",
          "followers": 2220
        },
        {
          "date": "2021-03",
          "followers": 2210
        },
        {
          "date": "2021-02",
          "followers": 2218
        },
        {
          "date": "2021-01",
          "followers": 2228
        },
        {
          "date": "2020-12",
          "followers": 2266
        },
        {
          "date": "2020-11",
          "followers": 2288
        },
        {
          "date": "2020-10",
          "followers": 2300
        },
        {
          "date": "2020-09",
          "followers": 2313
        },
        {
          "date": "2020-08",
          "followers": 2320
        },
        {
          "date": "2020-07",
          "followers": 2259
        },
        {
          "date": "2020-06",
          "followers": 2148
        },
        {
          "date": "2020-05",
          "followers": 2015
        },
        {
          "date": "2020-04",
          "followers": 1609
        },
        {
          "date": "2020-03",
          "followers": 1616
        },
        {
          "date": "2020-02",
          "followers": 1230
        },
        {
          "date": "2020-01",
          "followers": 1229
        },
        {
          "date": "2019-12",
          "followers": 1220
        },
        {
          "date": "2019-11",
          "followers": 1212
        },
        {
          "date": "2019-10",
          "followers": 1212
        },
        {
          "date": "2019-09",
          "followers": 1202
        },
        {
          "date": "2019-08",
          "followers": 1194
        },
        {
          "date": "2019-07",
          "followers": 1183
        },
        {
          "date": "2019-06",
          "followers": 1172
        },
        {
          "date": "2019-05",
          "followers": 1160
        },
        {
          "date": "2019-04",
          "followers": 1147
        },
        {
          "date": "2019-03",
          "followers": 1131
        },
        {
          "date": "2019-02",
          "followers": 1130
        },
        {
          "date": "2019-01",
          "followers": 1108
        },
        {
          "date": "2018-12",
          "followers": 1098
        },
        {
          "date": "2018-11",
          "followers": 1079
        },
        {
          "date": "2018-10",
          "followers": 1075
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Ollie",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 69859
        },
        {
          "date": "2021-10",
          "followers": 69538
        },
        {
          "date": "2021-09",
          "followers": 68651
        },
        {
          "date": "2021-08",
          "followers": 67823
        },
        {
          "date": "2021-07",
          "followers": 67118
        },
        {
          "date": "2021-06",
          "followers": 66559
        },
        {
          "date": "2021-05",
          "followers": 66176
        },
        {
          "date": "2021-04",
          "followers": 65591
        },
        {
          "date": "2021-03",
          "followers": 64988
        },
        {
          "date": "2021-02",
          "followers": 64539
        },
        {
          "date": "2021-01",
          "followers": 63990
        },
        {
          "date": "2020-12",
          "followers": 63628
        },
        {
          "date": "2020-11",
          "followers": 63473
        },
        {
          "date": "2020-10",
          "followers": 63420
        },
        {
          "date": "2020-09",
          "followers": 63235
        },
        {
          "date": "2020-08",
          "followers": 63064
        },
        {
          "date": "2020-07",
          "followers": 62018
        },
        {
          "date": "2020-06",
          "followers": 61692
        },
        {
          "date": "2020-05",
          "followers": 61458
        },
        {
          "date": "2020-04",
          "followers": 60746
        },
        {
          "date": "2020-03",
          "followers": 60241
        },
        {
          "date": "2020-02",
          "followers": 59681
        },
        {
          "date": "2020-01",
          "followers": 58199
        },
        {
          "date": "2019-12",
          "followers": 58052
        },
        {
          "date": "2019-11",
          "followers": 58093
        },
        {
          "date": "2019-10",
          "followers": 57547
        },
        {
          "date": "2019-09",
          "followers": 57089
        },
        {
          "date": "2019-08",
          "followers": 56641
        },
        {
          "date": "2019-07",
          "followers": 55701
        },
        {
          "date": "2019-06",
          "followers": 54125
        },
        {
          "date": "2019-05",
          "followers": 53034
        },
        {
          "date": "2019-04",
          "followers": 50855
        },
        {
          "date": "2019-03",
          "followers": 50256
        },
        {
          "date": "2019-02",
          "followers": 48877
        },
        {
          "date": "2019-01",
          "followers": 47929
        },
        {
          "date": "2018-12",
          "followers": 46464
        },
        {
          "date": "2018-11",
          "followers": 45264
        },
        {
          "date": "2018-10",
          "followers": 43789
        },
        {
          "date": "2018-09",
          "followers": 41852
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 46726
        },
        {
          "date": "2021-10",
          "likes": 46686
        },
        {
          "date": "2021-09",
          "likes": 46432
        },
        {
          "date": "2021-08",
          "likes": 46192
        },
        {
          "date": "2021-07",
          "likes": 45966
        },
        {
          "date": "2021-06",
          "likes": 45789
        },
        {
          "date": "2021-05",
          "likes": 45624
        },
        {
          "date": "2021-04",
          "likes": 45374
        },
        {
          "date": "2021-03",
          "likes": 45047
        },
        {
          "date": "2021-02",
          "likes": 44721
        },
        {
          "date": "2021-01",
          "likes": 44501
        },
        {
          "date": "2020-12",
          "likes": 44156
        },
        {
          "date": "2020-11",
          "likes": 43954
        },
        {
          "date": "2020-10",
          "likes": 43771
        },
        {
          "date": "2020-09",
          "likes": 43645
        },
        {
          "date": "2020-08",
          "likes": 43419
        },
        {
          "date": "2020-07",
          "likes": 43166
        },
        {
          "date": "2020-06",
          "likes": 42955
        },
        {
          "date": "2020-05",
          "likes": 42696
        },
        {
          "date": "2020-04",
          "likes": 42286
        },
        {
          "date": "2020-03",
          "likes": 41042
        },
        {
          "date": "2020-02",
          "likes": 40259
        },
        {
          "date": "2020-01",
          "likes": 40157
        },
        {
          "date": "2019-12",
          "likes": 40015
        },
        {
          "date": "2019-11",
          "likes": 39838
        },
        {
          "date": "2019-10",
          "likes": 39559
        },
        {
          "date": "2019-09",
          "likes": 39307
        },
        {
          "date": "2019-08",
          "likes": 38921
        },
        {
          "date": "2019-07",
          "likes": 38418
        },
        {
          "date": "2019-06",
          "likes": 37929
        },
        {
          "date": "2019-05",
          "likes": 37360
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 1101
        },
        {
          "date": "2021-10",
          "followers": 1097
        },
        {
          "date": "2021-09",
          "followers": 1094
        },
        {
          "date": "2021-08",
          "followers": 1092
        },
        {
          "date": "2021-07",
          "followers": 1083
        },
        {
          "date": "2021-06",
          "followers": 1076
        },
        {
          "date": "2021-05",
          "followers": 1070
        },
        {
          "date": "2021-04",
          "followers": 1068
        },
        {
          "date": "2021-03",
          "followers": 1062
        },
        {
          "date": "2021-02",
          "followers": 1055
        },
        {
          "date": "2021-01",
          "followers": 1043
        },
        {
          "date": "2020-12",
          "followers": 1052
        },
        {
          "date": "2020-11",
          "followers": 1051
        },
        {
          "date": "2020-10",
          "followers": 1048
        },
        {
          "date": "2020-09",
          "followers": 1043
        },
        {
          "date": "2020-08",
          "followers": 1037
        },
        {
          "date": "2020-07",
          "followers": 1029
        },
        {
          "date": "2020-06",
          "followers": 1022
        },
        {
          "date": "2020-05",
          "followers": 1027
        },
        {
          "date": "2020-04",
          "followers": 1030
        },
        {
          "date": "2020-03",
          "followers": 1024
        },
        {
          "date": "2020-02",
          "followers": 1024
        },
        {
          "date": "2020-01",
          "followers": 1015
        },
        {
          "date": "2019-12",
          "followers": 1009
        },
        {
          "date": "2019-11",
          "followers": 1001
        },
        {
          "date": "2019-10",
          "followers": 998
        },
        {
          "date": "2019-09",
          "followers": 989
        },
        {
          "date": "2019-08",
          "followers": 985
        },
        {
          "date": "2019-07",
          "followers": 972
        },
        {
          "date": "2019-06",
          "followers": 962
        },
        {
          "date": "2019-05",
          "followers": 962
        },
        {
          "date": "2019-04",
          "followers": 959
        },
        {
          "date": "2019-03",
          "followers": 947
        },
        {
          "date": "2019-02",
          "followers": 934
        },
        {
          "date": "2019-01",
          "followers": 919
        },
        {
          "date": "2018-12",
          "followers": 903
        },
        {
          "date": "2018-11",
          "followers": 886
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "OpenFarm",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 73269
        },
        {
          "date": "2021-10",
          "followers": 72108
        },
        {
          "date": "2021-09",
          "followers": 69227
        },
        {
          "date": "2021-08",
          "followers": 67542
        },
        {
          "date": "2021-07",
          "followers": 65442
        },
        {
          "date": "2021-06",
          "followers": 62895
        },
        {
          "date": "2021-05",
          "followers": 61664
        },
        {
          "date": "2021-04",
          "followers": 60588
        },
        {
          "date": "2021-03",
          "followers": 59181
        },
        {
          "date": "2021-02",
          "followers": 57454
        },
        {
          "date": "2021-01",
          "followers": 56065
        },
        {
          "date": "2020-12",
          "followers": 54419
        },
        {
          "date": "2020-11",
          "followers": 50729
        },
        {
          "date": "2020-10",
          "followers": 49403
        },
        {
          "date": "2020-09",
          "followers": 47368
        },
        {
          "date": "2020-08",
          "followers": 46131
        },
        {
          "date": "2020-07",
          "followers": 43382
        },
        {
          "date": "2020-06",
          "followers": 42254
        },
        {
          "date": "2020-05",
          "followers": 41849
        },
        {
          "date": "2020-04",
          "followers": 39491
        },
        {
          "date": "2020-03",
          "followers": 36474
        },
        {
          "date": "2020-02",
          "followers": 35776
        },
        {
          "date": "2020-01",
          "followers": 34975
        },
        {
          "date": "2019-12",
          "followers": 34323
        },
        {
          "date": "2019-11",
          "followers": 33876
        },
        {
          "date": "2019-10",
          "followers": 32530
        },
        {
          "date": "2019-09",
          "followers": 31664
        },
        {
          "date": "2019-08",
          "followers": 30589
        },
        {
          "date": "2019-07",
          "followers": 29443
        },
        {
          "date": "2019-06",
          "followers": 28664
        },
        {
          "date": "2019-05",
          "followers": 27748
        },
        {
          "date": "2019-04",
          "followers": 26628
        },
        {
          "date": "2019-03",
          "followers": 25962
        },
        {
          "date": "2019-02",
          "followers": 25240
        },
        {
          "date": "2019-01",
          "followers": 24473
        },
        {
          "date": "2018-12",
          "followers": 23931
        },
        {
          "date": "2018-11",
          "followers": 23510
        },
        {
          "date": "2018-10",
          "followers": 22943
        },
        {
          "date": "2018-09",
          "followers": 22251
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 62597
        },
        {
          "date": "2021-10",
          "likes": 62543
        },
        {
          "date": "2021-09",
          "likes": 62413
        },
        {
          "date": "2021-08",
          "likes": 62256
        },
        {
          "date": "2021-07",
          "likes": 62109
        },
        {
          "date": "2021-06",
          "likes": 61977
        },
        {
          "date": "2021-05",
          "likes": 61897
        },
        {
          "date": "2021-04",
          "likes": 61782
        },
        {
          "date": "2021-03",
          "likes": 61216
        },
        {
          "date": "2021-02",
          "likes": 61030
        },
        {
          "date": "2021-01",
          "likes": 60912
        },
        {
          "date": "2020-12",
          "likes": 60731
        },
        {
          "date": "2020-11",
          "likes": 60596
        },
        {
          "date": "2020-10",
          "likes": 60480
        },
        {
          "date": "2020-09",
          "likes": 60349
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 911
        },
        {
          "date": "2021-10",
          "followers": 910
        },
        {
          "date": "2021-09",
          "followers": 907
        },
        {
          "date": "2021-08",
          "followers": 900
        },
        {
          "date": "2021-07",
          "followers": 898
        },
        {
          "date": "2021-06",
          "followers": 897
        },
        {
          "date": "2021-05",
          "followers": 899
        },
        {
          "date": "2021-04",
          "followers": 899
        },
        {
          "date": "2021-03",
          "followers": 888
        },
        {
          "date": "2021-02",
          "followers": 885
        },
        {
          "date": "2021-01",
          "followers": 877
        },
        {
          "date": "2020-12",
          "followers": 881
        },
        {
          "date": "2020-11",
          "followers": 878
        },
        {
          "date": "2020-10",
          "followers": 876
        },
        {
          "date": "2020-09",
          "followers": 868
        },
        {
          "date": "2020-08",
          "followers": 869
        },
        {
          "date": "2020-07",
          "followers": 858
        },
        {
          "date": "2020-06",
          "followers": 855
        },
        {
          "date": "2020-05",
          "followers": 858
        },
        {
          "date": "2020-04",
          "followers": 858
        },
        {
          "date": "2020-03",
          "followers": 851
        },
        {
          "date": "2020-02",
          "followers": 850
        },
        {
          "date": "2020-01",
          "followers": 846
        },
        {
          "date": "2019-12",
          "followers": 848
        },
        {
          "date": "2019-11",
          "followers": 845
        },
        {
          "date": "2019-10",
          "followers": 844
        },
        {
          "date": "2019-09",
          "followers": 845
        },
        {
          "date": "2019-08",
          "followers": 847
        },
        {
          "date": "2019-07",
          "followers": 839
        },
        {
          "date": "2019-06",
          "followers": 827
        },
        {
          "date": "2019-05",
          "followers": 829
        },
        {
          "date": "2019-04",
          "followers": 827
        },
        {
          "date": "2019-03",
          "followers": 820
        },
        {
          "date": "2019-02",
          "followers": 815
        },
        {
          "date": "2019-01",
          "followers": 803
        },
        {
          "date": "2018-12",
          "followers": 797
        },
        {
          "date": "2018-11",
          "followers": 795
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "PatasBox",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 141002
        },
        {
          "date": "2021-10",
          "followers": 140892
        },
        {
          "date": "2021-09",
          "followers": 140532
        },
        {
          "date": "2021-08",
          "followers": 139950
        },
        {
          "date": "2021-07",
          "followers": 139428
        },
        {
          "date": "2021-06",
          "followers": 139437
        },
        {
          "date": "2021-05",
          "followers": 134705
        },
        {
          "date": "2021-04",
          "followers": 132412
        },
        {
          "date": "2021-03",
          "followers": 132632
        },
        {
          "date": "2021-02",
          "followers": 131343
        },
        {
          "date": "2021-01",
          "followers": 129166
        },
        {
          "date": "2020-12",
          "followers": 129687
        },
        {
          "date": "2020-11",
          "followers": 122203
        },
        {
          "date": "2020-10",
          "followers": 117704
        },
        {
          "date": "2020-09",
          "followers": 116157
        },
        {
          "date": "2020-08",
          "followers": 115430
        },
        {
          "date": "2020-07",
          "followers": 115459
        },
        {
          "date": "2020-06",
          "followers": 114921
        },
        {
          "date": "2020-05",
          "followers": 115061
        },
        {
          "date": "2020-04",
          "followers": 113449
        },
        {
          "date": "2020-03",
          "followers": 108277
        },
        {
          "date": "2020-02",
          "followers": 101954
        },
        {
          "date": "2020-01",
          "followers": 101922
        },
        {
          "date": "2019-12",
          "followers": 100196
        },
        {
          "date": "2019-11",
          "followers": 98450
        },
        {
          "date": "2019-10",
          "followers": 89515
        },
        {
          "date": "2019-09",
          "followers": 87161
        },
        {
          "date": "2019-08",
          "followers": 86523
        },
        {
          "date": "2019-07",
          "followers": 78138
        },
        {
          "date": "2019-06",
          "followers": 77550
        },
        {
          "date": "2019-05",
          "followers": 75434
        },
        {
          "date": "2019-04",
          "followers": 74380
        },
        {
          "date": "2019-03",
          "followers": 73439
        },
        {
          "date": "2019-02",
          "followers": 73420
        },
        {
          "date": "2019-01",
          "followers": 71157
        },
        {
          "date": "2018-12",
          "followers": 72001
        },
        {
          "date": "2018-11",
          "followers": 69545
        },
        {
          "date": "2018-10",
          "followers": 52150
        },
        {
          "date": "2018-09",
          "followers": 50932
        },
        {
          "date": "2018-08",
          "followers": 49338
        },
        {
          "date": "2018-07",
          "followers": 49030
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 124118
        },
        {
          "date": "2021-10",
          "likes": 124158
        },
        {
          "date": "2021-09",
          "likes": 124211
        },
        {
          "date": "2021-08",
          "likes": 124245
        },
        {
          "date": "2021-07",
          "likes": 124247
        },
        {
          "date": "2021-06",
          "likes": 124284
        },
        {
          "date": "2021-05",
          "likes": 124356
        },
        {
          "date": "2021-04",
          "likes": 124398
        },
        {
          "date": "2021-03",
          "likes": 124495
        },
        {
          "date": "2021-02",
          "likes": 124539
        },
        {
          "date": "2021-01",
          "likes": 124655
        },
        {
          "date": "2020-12",
          "likes": 124724
        },
        {
          "date": "2020-11",
          "likes": 124712
        },
        {
          "date": "2020-10",
          "likes": 124664
        },
        {
          "date": "2020-09",
          "likes": 124749
        },
        {
          "date": "2020-08",
          "likes": 124793
        },
        {
          "date": "2020-07",
          "likes": 124876
        },
        {
          "date": "2020-06",
          "likes": 124892
        },
        {
          "date": "2020-05",
          "likes": 124573
        },
        {
          "date": "2020-04",
          "likes": 124599
        },
        {
          "date": "2020-03",
          "likes": 124440
        },
        {
          "date": "2020-02",
          "likes": 124261
        },
        {
          "date": "2020-01",
          "likes": 124333
        },
        {
          "date": "2019-12",
          "likes": 124403
        },
        {
          "date": "2019-11",
          "likes": 124371
        },
        {
          "date": "2019-10",
          "likes": 124160
        },
        {
          "date": "2019-09",
          "likes": 124121
        },
        {
          "date": "2019-08",
          "likes": 124187
        },
        {
          "date": "2019-07",
          "likes": 124242
        },
        {
          "date": "2019-06",
          "likes": 124249
        },
        {
          "date": "2019-05",
          "likes": 124058
        },
        {
          "date": "2019-04",
          "likes": 124002
        },
        {
          "date": "2019-03",
          "likes": 124008
        },
        {
          "date": "2019-02",
          "likes": 124084
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 1140
        },
        {
          "date": "2021-10",
          "followers": 1140
        },
        {
          "date": "2021-09",
          "followers": 1142
        },
        {
          "date": "2021-08",
          "followers": 1144
        },
        {
          "date": "2021-07",
          "followers": 1141
        },
        {
          "date": "2021-06",
          "followers": 1139
        },
        {
          "date": "2021-05",
          "followers": 1138
        },
        {
          "date": "2021-04",
          "followers": 1146
        },
        {
          "date": "2021-03",
          "followers": 1147
        },
        {
          "date": "2021-02",
          "followers": 1150
        },
        {
          "date": "2021-01",
          "followers": 1151
        },
        {
          "date": "2020-12",
          "followers": 1151
        },
        {
          "date": "2020-11",
          "followers": 1151
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Pawmeal",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 1340
        },
        {
          "date": "2021-10",
          "followers": 1341
        },
        {
          "date": "2021-09",
          "followers": 1333
        },
        {
          "date": "2021-08",
          "followers": 1327
        },
        {
          "date": "2021-07",
          "followers": 1299
        },
        {
          "date": "2021-06",
          "followers": 1295
        },
        {
          "date": "2021-05",
          "followers": 1295
        },
        {
          "date": "2021-04",
          "followers": 1282
        },
        {
          "date": "2021-03",
          "followers": 1265
        },
        {
          "date": "2021-02",
          "followers": 1243
        },
        {
          "date": "2021-01",
          "followers": 1229
        },
        {
          "date": "2020-12",
          "followers": 1205
        },
        {
          "date": "2020-11",
          "followers": 1203
        },
        {
          "date": "2020-10",
          "followers": 1191
        },
        {
          "date": "2020-09",
          "followers": 1176
        },
        {
          "date": "2020-08",
          "followers": 1143
        },
        {
          "date": "2020-07",
          "followers": 1114
        },
        {
          "date": "2020-06",
          "followers": 1095
        },
        {
          "date": "2020-05",
          "followers": 1085
        },
        {
          "date": "2020-04",
          "followers": 1036
        },
        {
          "date": "2020-03",
          "followers": 1019
        },
        {
          "date": "2020-02",
          "followers": 998
        },
        {
          "date": "2020-01",
          "followers": 982
        },
        {
          "date": "2019-12",
          "followers": 958
        },
        {
          "date": "2019-11",
          "followers": 942
        },
        {
          "date": "2019-10",
          "followers": 912
        },
        {
          "date": "2019-09",
          "followers": 903
        },
        {
          "date": "2019-08",
          "followers": 863
        },
        {
          "date": "2019-07",
          "followers": 842
        },
        {
          "date": "2019-06",
          "followers": 837
        },
        {
          "date": "2019-05",
          "followers": 817
        },
        {
          "date": "2019-04",
          "followers": 778
        },
        {
          "date": "2019-03",
          "followers": 744
        },
        {
          "date": "2019-02",
          "followers": 720
        },
        {
          "date": "2019-01",
          "followers": 716
        },
        {
          "date": "2018-12",
          "followers": 702
        },
        {
          "date": "2018-11",
          "followers": 649
        },
        {
          "date": "2018-10",
          "followers": 629
        },
        {
          "date": "2018-09",
          "followers": 591
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 551
        },
        {
          "date": "2021-10",
          "likes": 550
        },
        {
          "date": "2021-09",
          "likes": 549
        },
        {
          "date": "2021-08",
          "likes": 548
        },
        {
          "date": "2021-07",
          "likes": 544
        },
        {
          "date": "2021-06",
          "likes": 545
        },
        {
          "date": "2021-05",
          "likes": 543
        },
        {
          "date": "2021-04",
          "likes": 540
        },
        {
          "date": "2021-03",
          "likes": 537
        },
        {
          "date": "2021-02",
          "likes": 536
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Pepette",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 5433
        },
        {
          "date": "2021-10",
          "followers": 5273
        },
        {
          "date": "2021-09",
          "followers": 4870
        },
        {
          "date": "2021-08",
          "followers": 4305
        },
        {
          "date": "2021-07",
          "followers": 4046
        },
        {
          "date": "2021-06",
          "followers": 3731
        },
        {
          "date": "2021-05",
          "followers": 3591
        },
        {
          "date": "2021-04",
          "followers": 3430
        },
        {
          "date": "2021-03",
          "followers": 3200
        },
        {
          "date": "2021-02",
          "followers": 2936
        },
        {
          "date": "2021-01",
          "followers": 2764
        },
        {
          "date": "2020-12",
          "followers": 2665
        },
        {
          "date": "2020-11",
          "followers": 2609
        },
        {
          "date": "2020-10",
          "followers": 2380
        },
        {
          "date": "2020-09",
          "followers": 1989
        },
        {
          "date": "2020-08",
          "followers": 1319
        },
        {
          "date": "2020-07",
          "followers": 1303
        },
        {
          "date": "2020-06",
          "followers": 1225
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "PetTreater",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 12216
        },
        {
          "date": "2021-10",
          "followers": 12135
        },
        {
          "date": "2021-09",
          "followers": 12134
        },
        {
          "date": "2021-08",
          "followers": 12164
        },
        {
          "date": "2021-07",
          "followers": 12086
        },
        {
          "date": "2021-06",
          "followers": 12076
        },
        {
          "date": "2021-05",
          "followers": 12081
        },
        {
          "date": "2021-04",
          "followers": 12095
        },
        {
          "date": "2021-03",
          "followers": 12071
        },
        {
          "date": "2021-02",
          "followers": 12080
        },
        {
          "date": "2021-01",
          "followers": 11983
        },
        {
          "date": "2020-12",
          "followers": 12008
        },
        {
          "date": "2020-11",
          "followers": 11973
        },
        {
          "date": "2020-10",
          "followers": 11897
        },
        {
          "date": "2020-09",
          "followers": 11862
        },
        {
          "date": "2020-08",
          "followers": 11814
        },
        {
          "date": "2020-07",
          "followers": 11796
        },
        {
          "date": "2020-06",
          "followers": 11764
        },
        {
          "date": "2020-05",
          "followers": 11730
        },
        {
          "date": "2020-04",
          "followers": 11524
        },
        {
          "date": "2020-03",
          "followers": 11171
        },
        {
          "date": "2020-02",
          "followers": 10854
        },
        {
          "date": "2020-01",
          "followers": 10734
        },
        {
          "date": "2019-12",
          "followers": 10551
        },
        {
          "date": "2019-11",
          "followers": 10252
        },
        {
          "date": "2019-10",
          "followers": 9906
        },
        {
          "date": "2019-09",
          "followers": 9454
        },
        {
          "date": "2019-08",
          "followers": 9330
        },
        {
          "date": "2019-07",
          "followers": 8804
        },
        {
          "date": "2019-06",
          "followers": 8480
        },
        {
          "date": "2019-05",
          "followers": 7959
        },
        {
          "date": "2019-04",
          "followers": 7741
        },
        {
          "date": "2019-03",
          "followers": 7535
        },
        {
          "date": "2019-02",
          "followers": 7412
        },
        {
          "date": "2019-01",
          "followers": 7088
        },
        {
          "date": "2018-12",
          "followers": 6830
        },
        {
          "date": "2018-11",
          "followers": 6307
        },
        {
          "date": "2018-10",
          "followers": 5534
        },
        {
          "date": "2018-09",
          "followers": 4875
        },
        {
          "date": "2018-08",
          "followers": 4608
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "PetMio",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 5248
        },
        {
          "date": "2021-10",
          "followers": 5254
        },
        {
          "date": "2021-09",
          "followers": 5271
        },
        {
          "date": "2021-08",
          "followers": 5277
        },
        {
          "date": "2021-07",
          "followers": 5315
        },
        {
          "date": "2021-06",
          "followers": 5323
        },
        {
          "date": "2021-05",
          "followers": 5338
        },
        {
          "date": "2021-04",
          "followers": 5373
        },
        {
          "date": "2021-03",
          "followers": 5396
        },
        {
          "date": "2021-02",
          "followers": 5426
        },
        {
          "date": "2021-01",
          "followers": 5461
        },
        {
          "date": "2020-12",
          "followers": 5488
        },
        {
          "date": "2020-11",
          "followers": 5536
        },
        {
          "date": "2020-10",
          "followers": 5296
        },
        {
          "date": "2020-09",
          "followers": 4733
        },
        {
          "date": "2020-08",
          "followers": 4560
        },
        {
          "date": "2020-07",
          "followers": 4421
        },
        {
          "date": "2020-06",
          "followers": 4316
        },
        {
          "date": "2020-05",
          "followers": 4243
        },
        {
          "date": "2020-04",
          "followers": 4247
        },
        {
          "date": "2020-03",
          "followers": 4267
        },
        {
          "date": "2020-02",
          "followers": 4283
        },
        {
          "date": "2020-01",
          "followers": 4292
        },
        {
          "date": "2019-12",
          "followers": 4307
        },
        {
          "date": "2019-11",
          "followers": 4333
        },
        {
          "date": "2019-10",
          "followers": 4349
        },
        {
          "date": "2019-09",
          "followers": 4360
        },
        {
          "date": "2019-08",
          "followers": 4368
        },
        {
          "date": "2019-07",
          "followers": 4400
        },
        {
          "date": "2019-06",
          "followers": 4415
        },
        {
          "date": "2019-05",
          "followers": 4423
        },
        {
          "date": "2019-04",
          "followers": 4402
        },
        {
          "date": "2019-03",
          "followers": 4425
        },
        {
          "date": "2019-02",
          "followers": 4457
        },
        {
          "date": "2019-01",
          "followers": 4473
        },
        {
          "date": "2018-12",
          "followers": 4486
        },
        {
          "date": "2018-11",
          "followers": 4495
        },
        {
          "date": "2018-10",
          "followers": 4349
        },
        {
          "date": "2018-09",
          "followers": 3930
        }
      ]
    },
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 2096
        },
        {
          "date": "2021-10",
          "followers": 2096
        },
        {
          "date": "2021-09",
          "followers": 2099
        },
        {
          "date": "2021-08",
          "followers": 2102
        },
        {
          "date": "2021-07",
          "followers": 2106
        },
        {
          "date": "2021-06",
          "followers": 2109
        },
        {
          "date": "2021-05",
          "followers": 2114
        },
        {
          "date": "2021-04",
          "followers": 2125
        },
        {
          "date": "2021-03",
          "followers": 2132
        },
        {
          "date": "2021-02",
          "followers": 2142
        },
        {
          "date": "2021-01",
          "followers": 2148
        },
        {
          "date": "2020-12",
          "followers": 2180
        },
        {
          "date": "2020-11",
          "followers": 2188
        },
        {
          "date": "2020-10",
          "followers": 2199
        },
        {
          "date": "2020-09",
          "followers": 2200
        },
        {
          "date": "2020-08",
          "followers": 2206
        },
        {
          "date": "2020-07",
          "followers": 2210
        },
        {
          "date": "2020-06",
          "followers": 2219
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "PetokoFoods",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 31870
        },
        {
          "date": "2021-10",
          "followers": 31528
        },
        {
          "date": "2021-09",
          "followers": 30775
        },
        {
          "date": "2021-08",
          "followers": 30110
        },
        {
          "date": "2021-07",
          "followers": 29287
        },
        {
          "date": "2021-06",
          "followers": 27860
        },
        {
          "date": "2021-05",
          "followers": 26618
        },
        {
          "date": "2021-04",
          "followers": 24545
        },
        {
          "date": "2021-03",
          "followers": 23235
        },
        {
          "date": "2021-02",
          "followers": 21428
        },
        {
          "date": "2021-01",
          "followers": 19262
        },
        {
          "date": "2020-12",
          "followers": 17152
        },
        {
          "date": "2020-11",
          "followers": 13640
        },
        {
          "date": "2020-10",
          "followers": 12602
        },
        {
          "date": "2020-09",
          "followers": 11273
        },
        {
          "date": "2020-08",
          "followers": 10484
        },
        {
          "date": "2020-07",
          "followers": 9098
        },
        {
          "date": "2020-06",
          "followers": 8135
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Petplate",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 54716
        },
        {
          "date": "2021-10",
          "followers": 54576
        },
        {
          "date": "2021-09",
          "followers": 54237
        },
        {
          "date": "2021-08",
          "followers": 54373
        },
        {
          "date": "2021-07",
          "followers": 54335
        },
        {
          "date": "2021-06",
          "followers": 54259
        },
        {
          "date": "2021-05",
          "followers": 53754
        },
        {
          "date": "2021-04",
          "followers": 52937
        },
        {
          "date": "2021-03",
          "followers": 51888
        },
        {
          "date": "2021-02",
          "followers": 50707
        },
        {
          "date": "2021-01",
          "followers": 49686
        },
        {
          "date": "2020-12",
          "followers": 49373
        },
        {
          "date": "2020-11",
          "followers": 48734
        },
        {
          "date": "2020-10",
          "followers": 48658
        },
        {
          "date": "2020-09",
          "followers": 48427
        },
        {
          "date": "2020-08",
          "followers": 47825
        },
        {
          "date": "2020-07",
          "followers": 47175
        },
        {
          "date": "2020-06",
          "followers": 45446
        },
        {
          "date": "2020-05",
          "followers": 38912
        },
        {
          "date": "2020-04",
          "followers": 38094
        },
        {
          "date": "2020-03",
          "followers": 36807
        },
        {
          "date": "2020-02",
          "followers": 35242
        },
        {
          "date": "2020-01",
          "followers": 34647
        },
        {
          "date": "2019-12",
          "followers": 33741
        },
        {
          "date": "2019-11",
          "followers": 32874
        },
        {
          "date": "2019-10",
          "followers": 31873
        },
        {
          "date": "2019-09",
          "followers": 31248
        },
        {
          "date": "2019-08",
          "followers": 30590
        },
        {
          "date": "2019-07",
          "followers": 29940
        },
        {
          "date": "2019-06",
          "followers": 29500
        },
        {
          "date": "2019-05",
          "followers": 29028
        },
        {
          "date": "2019-04",
          "followers": 28267
        },
        {
          "date": "2019-03",
          "followers": 27341
        },
        {
          "date": "2019-02",
          "followers": 26632
        },
        {
          "date": "2019-01",
          "followers": 26174
        },
        {
          "date": "2018-12",
          "followers": 25707
        },
        {
          "date": "2018-11",
          "followers": 25274
        },
        {
          "date": "2018-10",
          "followers": 24894
        },
        {
          "date": "2018-09",
          "followers": 24558
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 29392
        },
        {
          "date": "2021-10",
          "likes": 29425
        },
        {
          "date": "2021-09",
          "likes": 29463
        },
        {
          "date": "2021-08",
          "likes": 29503
        },
        {
          "date": "2021-07",
          "likes": 29540
        },
        {
          "date": "2021-06",
          "likes": 29581
        },
        {
          "date": "2021-05",
          "likes": 29583
        },
        {
          "date": "2021-04",
          "likes": 29622
        },
        {
          "date": "2021-03",
          "likes": 29630
        },
        {
          "date": "2021-02",
          "likes": 29641
        },
        {
          "date": "2021-01",
          "likes": 29718
        },
        {
          "date": "2020-12",
          "likes": 29793
        },
        {
          "date": "2020-11",
          "likes": 29853
        },
        {
          "date": "2020-10",
          "likes": 29879
        },
        {
          "date": "2020-09",
          "likes": 29933
        },
        {
          "date": "2020-08",
          "likes": 29993
        },
        {
          "date": "2020-07",
          "likes": 30060
        },
        {
          "date": "2020-06",
          "likes": 30096
        },
        {
          "date": "2020-05",
          "likes": 30119
        },
        {
          "date": "2020-04",
          "likes": 30156
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 4193
        },
        {
          "date": "2021-10",
          "followers": 4192
        },
        {
          "date": "2021-09",
          "followers": 4205
        },
        {
          "date": "2021-08",
          "followers": 4207
        },
        {
          "date": "2021-07",
          "followers": 4212
        },
        {
          "date": "2021-06",
          "followers": 4210
        },
        {
          "date": "2021-05",
          "followers": 4213
        },
        {
          "date": "2021-04",
          "followers": 4264
        },
        {
          "date": "2021-03",
          "followers": 4288
        },
        {
          "date": "2021-02",
          "followers": 4292
        },
        {
          "date": "2021-01",
          "followers": 4296
        },
        {
          "date": "2020-12",
          "followers": 4370
        },
        {
          "date": "2020-11",
          "followers": 4368
        },
        {
          "date": "2020-10",
          "followers": 4331
        },
        {
          "date": "2020-09",
          "followers": 4340
        },
        {
          "date": "2020-08",
          "followers": 4340
        },
        {
          "date": "2020-07",
          "followers": 4364
        },
        {
          "date": "2020-06",
          "followers": 4383
        },
        {
          "date": "2020-05",
          "followers": 4276
        },
        {
          "date": "2020-04",
          "followers": 4297
        },
        {
          "date": "2020-03",
          "followers": 4297
        },
        {
          "date": "2020-02",
          "followers": 4316
        },
        {
          "date": "2020-01",
          "followers": 4292
        },
        {
          "date": "2019-12",
          "followers": 4301
        },
        {
          "date": "2019-11",
          "followers": 4309
        },
        {
          "date": "2019-10",
          "followers": 4323
        },
        {
          "date": "2019-09",
          "followers": 4332
        },
        {
          "date": "2019-08",
          "followers": 4388
        },
        {
          "date": "2019-07",
          "followers": 4402
        },
        {
          "date": "2019-06",
          "followers": 4411
        },
        {
          "date": "2019-05",
          "followers": 4415
        },
        {
          "date": "2019-04",
          "followers": 4423
        },
        {
          "date": "2019-03",
          "followers": 4422
        },
        {
          "date": "2019-02",
          "followers": 4427
        },
        {
          "date": "2019-01",
          "followers": 4435
        },
        {
          "date": "2018-12",
          "followers": 4440
        },
        {
          "date": "2018-11",
          "followers": 4449
        },
        {
          "date": "2018-10",
          "followers": 4457
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "PetsoChic",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 20791
        },
        {
          "date": "2021-10",
          "followers": 20808
        },
        {
          "date": "2021-09",
          "followers": 20766
        },
        {
          "date": "2021-08",
          "followers": 20801
        },
        {
          "date": "2021-07",
          "followers": 20820
        },
        {
          "date": "2021-06",
          "followers": 20845
        },
        {
          "date": "2021-05",
          "followers": 20876
        },
        {
          "date": "2021-04",
          "followers": 20917
        },
        {
          "date": "2021-03",
          "followers": 20985
        },
        {
          "date": "2021-02",
          "followers": 21011
        },
        {
          "date": "2021-01",
          "followers": 21086
        },
        {
          "date": "2020-12",
          "followers": 21114
        },
        {
          "date": "2020-11",
          "followers": 21067
        },
        {
          "date": "2020-10",
          "followers": 21127
        },
        {
          "date": "2020-09",
          "followers": 21096
        },
        {
          "date": "2020-08",
          "followers": 21148
        },
        {
          "date": "2020-07",
          "followers": 21199
        },
        {
          "date": "2020-06",
          "followers": 21197
        },
        {
          "date": "2020-05",
          "followers": 21030
        },
        {
          "date": "2020-04",
          "followers": 20939
        },
        {
          "date": "2020-03",
          "followers": 20964
        },
        {
          "date": "2020-02",
          "followers": 20831
        },
        {
          "date": "2020-01",
          "followers": 20821
        },
        {
          "date": "2019-12",
          "followers": 20849
        },
        {
          "date": "2019-11",
          "followers": 20649
        },
        {
          "date": "2019-10",
          "followers": 20503
        },
        {
          "date": "2019-09",
          "followers": 20204
        },
        {
          "date": "2019-08",
          "followers": 20095
        },
        {
          "date": "2019-07",
          "followers": 19545
        },
        {
          "date": "2019-06",
          "followers": 18313
        },
        {
          "date": "2019-05",
          "followers": 17039
        },
        {
          "date": "2019-04",
          "followers": 16813
        },
        {
          "date": "2019-03",
          "followers": 16745
        },
        {
          "date": "2019-02",
          "followers": 16777
        },
        {
          "date": "2019-01",
          "followers": 16867
        },
        {
          "date": "2018-12",
          "followers": 16909
        },
        {
          "date": "2018-11",
          "followers": 16896
        },
        {
          "date": "2018-10",
          "followers": 17007
        },
        {
          "date": "2018-09",
          "followers": 17087
        },
        {
          "date": "2018-08",
          "followers": 16856
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 3695
        },
        {
          "date": "2021-10",
          "likes": 3700
        },
        {
          "date": "2021-09",
          "likes": 3708
        },
        {
          "date": "2021-08",
          "likes": 3706
        },
        {
          "date": "2021-07",
          "likes": 3713
        },
        {
          "date": "2021-06",
          "likes": 3719
        },
        {
          "date": "2021-05",
          "likes": 3723
        },
        {
          "date": "2021-04",
          "likes": 3727
        },
        {
          "date": "2021-03",
          "likes": 3724
        },
        {
          "date": "2021-02",
          "likes": 3729
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Pettywell",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 12001
        },
        {
          "date": "2021-10",
          "followers": 11758
        },
        {
          "date": "2021-09",
          "followers": 11147
        },
        {
          "date": "2021-08",
          "followers": 10925
        },
        {
          "date": "2021-07",
          "followers": 10918
        },
        {
          "date": "2021-06",
          "followers": 10366
        },
        {
          "date": "2021-05",
          "followers": 10286
        },
        {
          "date": "2021-04",
          "followers": 10211
        },
        {
          "date": "2021-03",
          "followers": 9694
        },
        {
          "date": "2021-02",
          "followers": 9186
        },
        {
          "date": "2021-01",
          "followers": 8646
        },
        {
          "date": "2020-12",
          "followers": 8456
        },
        {
          "date": "2020-11",
          "followers": 7549
        },
        {
          "date": "2020-10",
          "followers": 6484
        },
        {
          "date": "2020-09",
          "followers": 5663
        },
        {
          "date": "2020-08",
          "followers": 5082
        },
        {
          "date": "2020-07",
          "followers": 4859
        },
        {
          "date": "2020-06",
          "followers": 4439
        },
        {
          "date": "2020-05",
          "followers": 3812
        },
        {
          "date": "2020-04",
          "followers": 3231
        },
        {
          "date": "2020-03",
          "followers": 2925
        },
        {
          "date": "2020-02",
          "followers": 2752
        },
        {
          "date": "2020-01",
          "followers": 2660
        },
        {
          "date": "2019-12",
          "followers": 2615
        },
        {
          "date": "2019-11",
          "followers": 2550
        },
        {
          "date": "2019-10",
          "followers": 2489
        },
        {
          "date": "2019-09",
          "followers": 2463
        },
        {
          "date": "2019-08",
          "followers": 2424
        },
        {
          "date": "2019-07",
          "followers": 2441
        },
        {
          "date": "2019-06",
          "followers": 2477
        },
        {
          "date": "2019-05",
          "followers": 2255
        },
        {
          "date": "2019-04",
          "followers": 2095
        },
        {
          "date": "2019-03",
          "followers": 1895
        },
        {
          "date": "2019-02",
          "followers": 1910
        },
        {
          "date": "2019-01",
          "followers": 1930
        },
        {
          "date": "2018-12",
          "followers": 1942
        },
        {
          "date": "2018-11",
          "followers": 1981
        },
        {
          "date": "2018-10",
          "followers": 1932
        },
        {
          "date": "2018-09",
          "followers": 1958
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 2106
        },
        {
          "date": "2021-10",
          "likes": 2094
        },
        {
          "date": "2021-09",
          "likes": 2072
        },
        {
          "date": "2021-08",
          "likes": 2050
        },
        {
          "date": "2021-07",
          "likes": 2035
        },
        {
          "date": "2021-06",
          "likes": 1953
        },
        {
          "date": "2021-05",
          "likes": 1913
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Poppy&#039;sPicnic",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 8317
        },
        {
          "date": "2021-10",
          "followers": 8300
        },
        {
          "date": "2021-09",
          "followers": 8284
        },
        {
          "date": "2021-08",
          "followers": 8226
        },
        {
          "date": "2021-07",
          "followers": 8028
        },
        {
          "date": "2021-06",
          "followers": 7929
        },
        {
          "date": "2021-05",
          "followers": 7782
        },
        {
          "date": "2021-04",
          "followers": 7580
        },
        {
          "date": "2021-03",
          "followers": 7311
        },
        {
          "date": "2021-02",
          "followers": 7013
        },
        {
          "date": "2021-01",
          "followers": 6865
        },
        {
          "date": "2020-12",
          "followers": 6721
        },
        {
          "date": "2020-11",
          "followers": 6662
        },
        {
          "date": "2020-10",
          "followers": 6520
        },
        {
          "date": "2020-09",
          "followers": 6387
        },
        {
          "date": "2020-08",
          "followers": 6326
        },
        {
          "date": "2020-07",
          "followers": 6230
        },
        {
          "date": "2020-06",
          "followers": 6077
        },
        {
          "date": "2020-05",
          "followers": 5944
        },
        {
          "date": "2020-04",
          "followers": 5772
        },
        {
          "date": "2020-03",
          "followers": 5564
        },
        {
          "date": "2020-02",
          "followers": 5415
        },
        {
          "date": "2020-01",
          "followers": 5343
        },
        {
          "date": "2019-12",
          "followers": 5241
        },
        {
          "date": "2019-11",
          "followers": 5157
        },
        {
          "date": "2019-10",
          "followers": 5091
        },
        {
          "date": "2019-09",
          "followers": 4988
        },
        {
          "date": "2019-08",
          "followers": 4886
        },
        {
          "date": "2019-07",
          "followers": 4764
        },
        {
          "date": "2019-06",
          "followers": 4707
        },
        {
          "date": "2019-05",
          "followers": 4611
        },
        {
          "date": "2019-04",
          "followers": 4572
        },
        {
          "date": "2019-03",
          "followers": 4526
        },
        {
          "date": "2019-02",
          "followers": 4447
        },
        {
          "date": "2019-01",
          "followers": 4378
        },
        {
          "date": "2018-12",
          "followers": 4347
        },
        {
          "date": "2018-11",
          "followers": 4335
        },
        {
          "date": "2018-10",
          "followers": 4322
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 17199
        },
        {
          "date": "2021-10",
          "likes": 17193
        },
        {
          "date": "2021-09",
          "likes": 17176
        },
        {
          "date": "2021-08",
          "likes": 16893
        },
        {
          "date": "2021-07",
          "likes": 16480
        },
        {
          "date": "2021-06",
          "likes": 16325
        },
        {
          "date": "2021-05",
          "likes": 16155
        },
        {
          "date": "2021-04",
          "likes": 15878
        },
        {
          "date": "2021-03",
          "likes": 15428
        },
        {
          "date": "2021-02",
          "likes": 14997
        },
        {
          "date": "2021-01",
          "likes": 14757
        },
        {
          "date": "2020-12",
          "likes": 14559
        },
        {
          "date": "2020-11",
          "likes": 14440
        },
        {
          "date": "2020-10",
          "likes": 14303
        },
        {
          "date": "2020-09",
          "likes": 14192
        },
        {
          "date": "2020-08",
          "likes": 14124
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Pupbox",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 355261
        },
        {
          "date": "2021-10",
          "followers": 354775
        },
        {
          "date": "2021-09",
          "followers": 353465
        },
        {
          "date": "2021-08",
          "followers": 351986
        },
        {
          "date": "2021-07",
          "followers": 348895
        },
        {
          "date": "2021-06",
          "followers": 347186
        },
        {
          "date": "2021-05",
          "followers": 345119
        },
        {
          "date": "2021-04",
          "followers": 340559
        },
        {
          "date": "2021-03",
          "followers": 338637
        },
        {
          "date": "2021-02",
          "followers": 334258
        },
        {
          "date": "2021-01",
          "followers": 328567
        },
        {
          "date": "2020-12",
          "followers": 321601
        },
        {
          "date": "2020-11",
          "followers": 314203
        },
        {
          "date": "2020-10",
          "followers": 311103
        },
        {
          "date": "2020-09",
          "followers": 304942
        },
        {
          "date": "2020-08",
          "followers": 298677
        },
        {
          "date": "2020-07",
          "followers": 288743
        },
        {
          "date": "2020-06",
          "followers": 277237
        },
        {
          "date": "2020-05",
          "followers": 267080
        },
        {
          "date": "2020-04",
          "followers": 253446
        },
        {
          "date": "2020-03",
          "followers": 241396
        },
        {
          "date": "2020-02",
          "followers": 233255
        },
        {
          "date": "2020-01",
          "followers": 227017
        },
        {
          "date": "2019-12",
          "followers": 219009
        },
        {
          "date": "2019-11",
          "followers": 212460
        },
        {
          "date": "2019-10",
          "followers": 205879
        },
        {
          "date": "2019-09",
          "followers": 198346
        },
        {
          "date": "2019-08",
          "followers": 192062
        },
        {
          "date": "2019-07",
          "followers": 182737
        },
        {
          "date": "2019-06",
          "followers": 172747
        },
        {
          "date": "2019-05",
          "followers": 166309
        },
        {
          "date": "2019-04",
          "followers": 157112
        },
        {
          "date": "2019-03",
          "followers": 149025
        },
        {
          "date": "2019-02",
          "followers": 139905
        },
        {
          "date": "2019-01",
          "followers": 133924
        },
        {
          "date": "2018-12",
          "followers": 130401
        },
        {
          "date": "2018-11",
          "followers": 129970
        },
        {
          "date": "2018-10",
          "followers": 126259
        },
        {
          "date": "2018-09",
          "followers": 122196
        },
        {
          "date": "2018-08",
          "followers": 118104
        }
      ]
    },
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 4160
        },
        {
          "date": "2021-10",
          "followers": 4164
        },
        {
          "date": "2021-09",
          "followers": 4164
        },
        {
          "date": "2021-08",
          "followers": 4152
        },
        {
          "date": "2021-07",
          "followers": 4151
        },
        {
          "date": "2021-06",
          "followers": 4151
        },
        {
          "date": "2021-05",
          "followers": 4157
        },
        {
          "date": "2021-04",
          "followers": 4181
        },
        {
          "date": "2021-03",
          "followers": 4187
        },
        {
          "date": "2021-02",
          "followers": 4184
        },
        {
          "date": "2021-01",
          "followers": 4186
        },
        {
          "date": "2020-12",
          "followers": 4211
        },
        {
          "date": "2020-11",
          "followers": 4228
        },
        {
          "date": "2020-10",
          "followers": 4238
        },
        {
          "date": "2020-09",
          "followers": 4251
        },
        {
          "date": "2020-08",
          "followers": 4246
        },
        {
          "date": "2020-07",
          "followers": 4251
        },
        {
          "date": "2020-06",
          "followers": 4250
        },
        {
          "date": "2020-05",
          "followers": 4275
        },
        {
          "date": "2020-04",
          "followers": 4287
        },
        {
          "date": "2020-03",
          "followers": 4279
        },
        {
          "date": "2020-02",
          "followers": 4294
        },
        {
          "date": "2020-01",
          "followers": 4297
        },
        {
          "date": "2019-12",
          "followers": 4297
        },
        {
          "date": "2019-11",
          "followers": 4306
        },
        {
          "date": "2019-10",
          "followers": 4304
        },
        {
          "date": "2019-09",
          "followers": 4303
        },
        {
          "date": "2019-08",
          "followers": 4302
        },
        {
          "date": "2019-07",
          "followers": 4300
        },
        {
          "date": "2019-06",
          "followers": 4290
        },
        {
          "date": "2019-05",
          "followers": 4286
        },
        {
          "date": "2019-04",
          "followers": 4283
        },
        {
          "date": "2019-03",
          "followers": 4274
        },
        {
          "date": "2019-02",
          "followers": 4267
        },
        {
          "date": "2019-01",
          "followers": 4279
        },
        {
          "date": "2018-12",
          "followers": 4301
        },
        {
          "date": "2018-11",
          "followers": 4304
        },
        {
          "date": "2018-10",
          "followers": 4306
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Pupjoy",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 26449
        },
        {
          "date": "2021-10",
          "followers": 26486
        },
        {
          "date": "2021-09",
          "followers": 26539
        },
        {
          "date": "2021-08",
          "followers": 26610
        },
        {
          "date": "2021-07",
          "followers": 26655
        },
        {
          "date": "2021-06",
          "followers": 26645
        },
        {
          "date": "2021-05",
          "followers": 26676
        },
        {
          "date": "2021-04",
          "followers": 26740
        },
        {
          "date": "2021-03",
          "followers": 26807
        },
        {
          "date": "2021-02",
          "followers": 26873
        },
        {
          "date": "2021-01",
          "followers": 26938
        },
        {
          "date": "2020-12",
          "followers": 27031
        },
        {
          "date": "2020-11",
          "followers": 27094
        },
        {
          "date": "2020-10",
          "followers": 27216
        },
        {
          "date": "2020-09",
          "followers": 27201
        },
        {
          "date": "2020-08",
          "followers": 27246
        },
        {
          "date": "2020-07",
          "followers": 27334
        },
        {
          "date": "2020-06",
          "followers": 27369
        },
        {
          "date": "2020-05",
          "followers": 27342
        },
        {
          "date": "2020-04",
          "followers": 27195
        },
        {
          "date": "2020-03",
          "followers": 27217
        },
        {
          "date": "2020-02",
          "followers": 27197
        },
        {
          "date": "2020-01",
          "followers": 27180
        },
        {
          "date": "2019-12",
          "followers": 27242
        },
        {
          "date": "2019-11",
          "followers": 26848
        },
        {
          "date": "2019-10",
          "followers": 26266
        },
        {
          "date": "2019-09",
          "followers": 26295
        },
        {
          "date": "2019-08",
          "followers": 26351
        },
        {
          "date": "2019-07",
          "followers": 26440
        },
        {
          "date": "2019-06",
          "followers": 26565
        },
        {
          "date": "2019-05",
          "followers": 26331
        },
        {
          "date": "2019-04",
          "followers": 24889
        },
        {
          "date": "2019-03",
          "followers": 23404
        },
        {
          "date": "2019-02",
          "followers": 22008
        },
        {
          "date": "2019-01",
          "followers": 20152
        },
        {
          "date": "2018-12",
          "followers": 17855
        },
        {
          "date": "2018-11",
          "followers": 16178
        },
        {
          "date": "2018-10",
          "followers": 14726
        },
        {
          "date": "2018-09",
          "followers": 14730
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 27579
        },
        {
          "date": "2021-10",
          "likes": 27589
        },
        {
          "date": "2021-09",
          "likes": 27627
        },
        {
          "date": "2021-08",
          "likes": 27666
        },
        {
          "date": "2021-07",
          "likes": 27701
        },
        {
          "date": "2021-06",
          "likes": 27723
        },
        {
          "date": "2021-05",
          "likes": 27753
        },
        {
          "date": "2021-04",
          "likes": 27788
        },
        {
          "date": "2021-03",
          "likes": 27794
        },
        {
          "date": "2021-02",
          "likes": 27829
        },
        {
          "date": "2021-01",
          "likes": 27896
        },
        {
          "date": "2020-12",
          "likes": 27927
        },
        {
          "date": "2020-11",
          "likes": 27968
        },
        {
          "date": "2020-10",
          "likes": 27977
        },
        {
          "date": "2020-09",
          "likes": 28026
        },
        {
          "date": "2020-08",
          "likes": 28056
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Pupums",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 5118
        },
        {
          "date": "2021-10",
          "followers": 5102
        },
        {
          "date": "2021-09",
          "followers": 5065
        },
        {
          "date": "2021-08",
          "followers": 5017
        },
        {
          "date": "2021-07",
          "followers": 4916
        },
        {
          "date": "2021-06",
          "followers": 4843
        },
        {
          "date": "2021-05",
          "followers": 4781
        },
        {
          "date": "2021-04",
          "followers": 4711
        },
        {
          "date": "2021-03",
          "followers": 4662
        },
        {
          "date": "2021-02",
          "followers": 4636
        },
        {
          "date": "2021-01",
          "followers": 4567
        },
        {
          "date": "2020-12",
          "followers": 4479
        },
        {
          "date": "2020-11",
          "followers": 4359
        },
        {
          "date": "2020-10",
          "followers": 4209
        },
        {
          "date": "2020-09",
          "followers": 4077
        },
        {
          "date": "2020-08",
          "followers": 3742
        },
        {
          "date": "2020-07",
          "followers": 3619
        },
        {
          "date": "2020-06",
          "followers": 3567
        },
        {
          "date": "2020-05",
          "followers": 3583
        },
        {
          "date": "2020-04",
          "followers": 3427
        },
        {
          "date": "2020-03",
          "followers": 3313
        },
        {
          "date": "2020-02",
          "followers": 3300
        },
        {
          "date": "2020-01",
          "followers": 3311
        },
        {
          "date": "2019-12",
          "followers": 3326
        },
        {
          "date": "2019-11",
          "followers": 3347
        },
        {
          "date": "2019-10",
          "followers": 3362
        },
        {
          "date": "2019-09",
          "followers": 3393
        },
        {
          "date": "2019-08",
          "followers": 3414
        },
        {
          "date": "2019-07",
          "followers": 3455
        },
        {
          "date": "2019-06",
          "followers": 3493
        },
        {
          "date": "2019-05",
          "followers": 3549
        },
        {
          "date": "2019-04",
          "followers": 3633
        },
        {
          "date": "2019-03",
          "followers": 3207
        },
        {
          "date": "2019-02",
          "followers": 2483
        },
        {
          "date": "2019-01",
          "followers": 2224
        },
        {
          "date": "2018-12",
          "followers": 2014
        },
        {
          "date": "2018-11",
          "followers": 1940
        },
        {
          "date": "2018-10",
          "followers": 1868
        },
        {
          "date": "2018-09",
          "followers": 1644
        },
        {
          "date": "2018-08",
          "followers": 1741
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "PurePetfood",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 25300
        },
        {
          "date": "2021-10",
          "followers": 25214
        },
        {
          "date": "2021-09",
          "followers": 25109
        },
        {
          "date": "2021-08",
          "followers": 24995
        },
        {
          "date": "2021-07",
          "followers": 24837
        },
        {
          "date": "2021-06",
          "followers": 24680
        },
        {
          "date": "2021-05",
          "followers": 24572
        },
        {
          "date": "2021-04",
          "followers": 24336
        },
        {
          "date": "2021-03",
          "followers": 24152
        },
        {
          "date": "2021-02",
          "followers": 24018
        },
        {
          "date": "2021-01",
          "followers": 23822
        },
        {
          "date": "2020-12",
          "followers": 23662
        },
        {
          "date": "2020-11",
          "followers": 23515
        },
        {
          "date": "2020-10",
          "followers": 23488
        },
        {
          "date": "2020-09",
          "followers": 23477
        },
        {
          "date": "2020-08",
          "followers": 23465
        },
        {
          "date": "2020-07",
          "followers": 23479
        },
        {
          "date": "2020-06",
          "followers": 23492
        },
        {
          "date": "2020-05",
          "followers": 23525
        },
        {
          "date": "2020-04",
          "followers": 23540
        },
        {
          "date": "2020-03",
          "followers": 23586
        },
        {
          "date": "2020-02",
          "followers": 23603
        },
        {
          "date": "2020-01",
          "followers": 23652
        },
        {
          "date": "2019-12",
          "followers": 23711
        },
        {
          "date": "2019-11",
          "followers": 23557
        },
        {
          "date": "2019-10",
          "followers": 23265
        },
        {
          "date": "2019-09",
          "followers": 23096
        },
        {
          "date": "2019-08",
          "followers": 22646
        },
        {
          "date": "2019-07",
          "followers": 22221
        },
        {
          "date": "2019-06",
          "followers": 22026
        },
        {
          "date": "2019-05",
          "followers": 21824
        },
        {
          "date": "2019-04",
          "followers": 21687
        },
        {
          "date": "2019-03",
          "followers": 21669
        },
        {
          "date": "2019-02",
          "followers": 21717
        },
        {
          "date": "2019-01",
          "followers": 21744
        },
        {
          "date": "2018-12",
          "followers": 21761
        },
        {
          "date": "2018-11",
          "followers": 21717
        },
        {
          "date": "2018-10",
          "followers": 21764
        },
        {
          "date": "2018-09",
          "followers": 21789
        }
      ]
    },
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 2350
        },
        {
          "date": "2021-10",
          "followers": 2347
        },
        {
          "date": "2021-09",
          "followers": 2345
        },
        {
          "date": "2021-08",
          "followers": 2344
        },
        {
          "date": "2021-07",
          "followers": 2333
        },
        {
          "date": "2021-06",
          "followers": 2329
        },
        {
          "date": "2021-05",
          "followers": 2333
        },
        {
          "date": "2021-04",
          "followers": 2337
        },
        {
          "date": "2021-03",
          "followers": 2337
        },
        {
          "date": "2021-02",
          "followers": 2330
        },
        {
          "date": "2021-01",
          "followers": 2332
        },
        {
          "date": "2020-12",
          "followers": 2331
        },
        {
          "date": "2020-11",
          "followers": 2332
        },
        {
          "date": "2020-10",
          "followers": 2334
        },
        {
          "date": "2020-09",
          "followers": 2329
        },
        {
          "date": "2020-08",
          "followers": 2330
        },
        {
          "date": "2020-07",
          "followers": 2339
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "RaisedRight",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 14063
        },
        {
          "date": "2021-10",
          "followers": 14071
        },
        {
          "date": "2021-09",
          "followers": 14102
        },
        {
          "date": "2021-08",
          "followers": 14120
        },
        {
          "date": "2021-07",
          "followers": 14133
        },
        {
          "date": "2021-06",
          "followers": 14141
        },
        {
          "date": "2021-05",
          "followers": 14155
        },
        {
          "date": "2021-04",
          "followers": 14183
        },
        {
          "date": "2021-03",
          "followers": 14193
        },
        {
          "date": "2021-02",
          "followers": 14226
        },
        {
          "date": "2021-01",
          "followers": 14288
        },
        {
          "date": "2020-12",
          "followers": 14327
        },
        {
          "date": "2020-11",
          "followers": 14365
        },
        {
          "date": "2020-10",
          "followers": 14446
        },
        {
          "date": "2020-09",
          "followers": 14496
        },
        {
          "date": "2020-08",
          "followers": 14573
        },
        {
          "date": "2020-07",
          "followers": 14661
        },
        {
          "date": "2020-06",
          "followers": 14734
        },
        {
          "date": "2020-05",
          "followers": 14832
        },
        {
          "date": "2020-04",
          "followers": 14945
        },
        {
          "date": "2020-03",
          "followers": 15038
        },
        {
          "date": "2020-02",
          "followers": 15107
        },
        {
          "date": "2020-01",
          "followers": 15159
        },
        {
          "date": "2019-12",
          "followers": 15233
        },
        {
          "date": "2019-11",
          "followers": 15291
        },
        {
          "date": "2019-10",
          "followers": 15332
        },
        {
          "date": "2019-09",
          "followers": 15316
        },
        {
          "date": "2019-08",
          "followers": 15299
        },
        {
          "date": "2019-07",
          "followers": 15385
        },
        {
          "date": "2019-06",
          "followers": 15542
        },
        {
          "date": "2019-05",
          "followers": 15135
        },
        {
          "date": "2019-04",
          "followers": 14506
        },
        {
          "date": "2019-03",
          "followers": 13451
        },
        {
          "date": "2019-02",
          "followers": 12749
        },
        {
          "date": "2019-01",
          "followers": 11581
        },
        {
          "date": "2018-12",
          "followers": 9879
        },
        {
          "date": "2018-11",
          "followers": 8917
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "RawWild",
    "instagram": null,
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 152
        },
        {
          "date": "2021-10",
          "followers": 152
        },
        {
          "date": "2021-09",
          "followers": 152
        },
        {
          "date": "2021-08",
          "followers": 154
        },
        {
          "date": "2021-07",
          "followers": 154
        },
        {
          "date": "2021-06",
          "followers": 153
        },
        {
          "date": "2021-05",
          "followers": 155
        },
        {
          "date": "2021-04",
          "followers": 155
        },
        {
          "date": "2021-03",
          "followers": 155
        },
        {
          "date": "2021-02",
          "followers": 155
        },
        {
          "date": "2021-01",
          "followers": 154
        },
        {
          "date": "2020-12",
          "followers": 158
        },
        {
          "date": "2020-11",
          "followers": 158
        },
        {
          "date": "2020-10",
          "followers": 158
        },
        {
          "date": "2020-09",
          "followers": 157
        },
        {
          "date": "2020-08",
          "followers": 155
        },
        {
          "date": "2020-07",
          "followers": 156
        },
        {
          "date": "2020-06",
          "followers": 158
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Rocketo",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 6431
        },
        {
          "date": "2021-10",
          "followers": 6393
        },
        {
          "date": "2021-09",
          "followers": 6117
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Scratch",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 23754
        },
        {
          "date": "2021-10",
          "followers": 23651
        },
        {
          "date": "2021-09",
          "followers": 23476
        },
        {
          "date": "2021-08",
          "followers": 23152
        },
        {
          "date": "2021-07",
          "followers": 22857
        },
        {
          "date": "2021-06",
          "followers": 22596
        },
        {
          "date": "2021-05",
          "followers": 21767
        },
        {
          "date": "2021-04",
          "followers": 20987
        },
        {
          "date": "2021-03",
          "followers": 20212
        },
        {
          "date": "2021-02",
          "followers": 19832
        },
        {
          "date": "2021-01",
          "followers": 19688
        },
        {
          "date": "2020-12",
          "followers": 19580
        },
        {
          "date": "2020-11",
          "followers": 19445
        },
        {
          "date": "2020-10",
          "followers": 19243
        },
        {
          "date": "2020-09",
          "followers": 18882
        },
        {
          "date": "2020-08",
          "followers": 18651
        },
        {
          "date": "2020-07",
          "followers": 18096
        },
        {
          "date": "2020-06",
          "followers": 16667
        },
        {
          "date": "2020-05",
          "followers": 16360
        },
        {
          "date": "2020-04",
          "followers": 16006
        },
        {
          "date": "2020-03",
          "followers": 15689
        },
        {
          "date": "2020-02",
          "followers": 15453
        },
        {
          "date": "2020-01",
          "followers": 15356
        },
        {
          "date": "2019-12",
          "followers": 13652
        },
        {
          "date": "2019-11",
          "followers": 13582
        },
        {
          "date": "2019-10",
          "followers": 13440
        },
        {
          "date": "2019-09",
          "followers": 13098
        },
        {
          "date": "2019-08",
          "followers": 12844
        },
        {
          "date": "2019-07",
          "followers": 12549
        },
        {
          "date": "2019-06",
          "followers": 12240
        },
        {
          "date": "2019-05",
          "followers": 11944
        },
        {
          "date": "2019-04",
          "followers": 10222
        },
        {
          "date": "2019-03",
          "followers": 8462
        },
        {
          "date": "2019-02",
          "followers": 6808
        },
        {
          "date": "2019-01",
          "followers": 5503
        },
        {
          "date": "2018-12",
          "followers": 3922
        },
        {
          "date": "2018-11",
          "followers": 2314
        },
        {
          "date": "2018-10",
          "followers": 732
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 6604
        },
        {
          "date": "2021-10",
          "likes": 6568
        },
        {
          "date": "2021-09",
          "likes": 6447
        },
        {
          "date": "2021-08",
          "likes": 6253
        },
        {
          "date": "2021-07",
          "likes": 6085
        },
        {
          "date": "2021-05",
          "likes": 5694
        },
        {
          "date": "2021-04",
          "likes": 5685
        },
        {
          "date": "2021-03",
          "likes": 5428
        },
        {
          "date": "2021-02",
          "likes": 5215
        },
        {
          "date": "2020-12",
          "likes": 4950
        },
        {
          "date": "2020-11",
          "likes": 4718
        },
        {
          "date": "2020-10",
          "likes": 4587
        },
        {
          "date": "2020-09",
          "likes": 4402
        },
        {
          "date": "2020-08",
          "likes": 4241
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 170
        },
        {
          "date": "2021-10",
          "followers": 170
        },
        {
          "date": "2021-09",
          "followers": 166
        },
        {
          "date": "2021-08",
          "followers": 167
        },
        {
          "date": "2021-07",
          "followers": 161
        },
        {
          "date": "2021-06",
          "followers": 154
        },
        {
          "date": "2021-05",
          "followers": 151
        },
        {
          "date": "2021-04",
          "followers": 151
        },
        {
          "date": "2021-03",
          "followers": 151
        },
        {
          "date": "2021-02",
          "followers": 149
        },
        {
          "date": "2021-01",
          "followers": 144
        },
        {
          "date": "2020-12",
          "followers": 141
        },
        {
          "date": "2020-11",
          "followers": 140
        },
        {
          "date": "2020-10",
          "followers": 139
        },
        {
          "date": "2020-09",
          "followers": 137
        },
        {
          "date": "2020-08",
          "followers": 136
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "ShamelessPets",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 9225
        },
        {
          "date": "2021-10",
          "followers": 9187
        },
        {
          "date": "2021-09",
          "followers": 9055
        },
        {
          "date": "2021-08",
          "followers": 8940
        },
        {
          "date": "2021-07",
          "followers": 8847
        },
        {
          "date": "2021-06",
          "followers": 8708
        },
        {
          "date": "2021-05",
          "followers": 8570
        },
        {
          "date": "2021-04",
          "followers": 8205
        },
        {
          "date": "2021-03",
          "followers": 7679
        },
        {
          "date": "2021-02",
          "followers": 7511
        },
        {
          "date": "2021-01",
          "followers": 7421
        },
        {
          "date": "2020-12",
          "followers": 7391
        },
        {
          "date": "2020-11",
          "followers": 7285
        },
        {
          "date": "2020-10",
          "followers": 7288
        },
        {
          "date": "2020-09",
          "followers": 7003
        },
        {
          "date": "2020-08",
          "followers": 6926
        },
        {
          "date": "2020-07",
          "followers": 6869
        },
        {
          "date": "2020-06",
          "followers": 6794
        },
        {
          "date": "2020-05",
          "followers": 6791
        },
        {
          "date": "2020-04",
          "followers": 6759
        },
        {
          "date": "2020-03",
          "followers": 6741
        },
        {
          "date": "2020-02",
          "followers": 6757
        },
        {
          "date": "2020-01",
          "followers": 6591
        },
        {
          "date": "2019-12",
          "followers": 6225
        },
        {
          "date": "2019-11",
          "followers": 6055
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "SidebySide",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 4090
        },
        {
          "date": "2021-10",
          "followers": 4080
        },
        {
          "date": "2021-09",
          "followers": 4081
        },
        {
          "date": "2021-08",
          "followers": 4088
        },
        {
          "date": "2021-07",
          "followers": 4081
        },
        {
          "date": "2021-06",
          "followers": 4077
        },
        {
          "date": "2021-05",
          "followers": 4067
        },
        {
          "date": "2021-04",
          "followers": 4062
        },
        {
          "date": "2021-03",
          "followers": 3993
        },
        {
          "date": "2021-02",
          "followers": 3993
        },
        {
          "date": "2021-01",
          "followers": 4008
        },
        {
          "date": "2020-12",
          "followers": 3997
        },
        {
          "date": "2020-11",
          "followers": 3990
        },
        {
          "date": "2020-10",
          "followers": 3998
        },
        {
          "date": "2020-09",
          "followers": 3996
        },
        {
          "date": "2020-08",
          "followers": 4010
        },
        {
          "date": "2020-07",
          "followers": 4024
        },
        {
          "date": "2020-06",
          "followers": 4037
        },
        {
          "date": "2020-05",
          "followers": 4053
        },
        {
          "date": "2020-04",
          "followers": 4089
        },
        {
          "date": "2020-03",
          "followers": 4111
        },
        {
          "date": "2020-02",
          "followers": 4129
        },
        {
          "date": "2020-01",
          "followers": 4153
        },
        {
          "date": "2019-12",
          "followers": 4165
        },
        {
          "date": "2019-11",
          "followers": 4179
        },
        {
          "date": "2019-10",
          "followers": 4173
        },
        {
          "date": "2019-09",
          "followers": 4186
        },
        {
          "date": "2019-08",
          "followers": 4165
        },
        {
          "date": "2019-07",
          "followers": 4163
        },
        {
          "date": "2019-06",
          "followers": 4177
        },
        {
          "date": "2019-05",
          "followers": 4199
        },
        {
          "date": "2019-04",
          "followers": 4202
        },
        {
          "date": "2019-03",
          "followers": 4117
        },
        {
          "date": "2019-02",
          "followers": 4087
        },
        {
          "date": "2019-01",
          "followers": 4102
        },
        {
          "date": "2018-12",
          "followers": 4038
        },
        {
          "date": "2018-11",
          "followers": 3762
        },
        {
          "date": "2018-10",
          "followers": 3759
        },
        {
          "date": "2018-09",
          "followers": 3785
        },
        {
          "date": "2018-08",
          "followers": 3823
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Smalls",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 42062
        },
        {
          "date": "2021-10",
          "followers": 41809
        },
        {
          "date": "2021-09",
          "followers": 40696
        },
        {
          "date": "2021-08",
          "followers": 39554
        },
        {
          "date": "2021-07",
          "followers": 38494
        },
        {
          "date": "2021-06",
          "followers": 38383
        },
        {
          "date": "2021-05",
          "followers": 37362
        },
        {
          "date": "2021-04",
          "followers": 35163
        },
        {
          "date": "2021-03",
          "followers": 31540
        },
        {
          "date": "2021-02",
          "followers": 29524
        },
        {
          "date": "2021-01",
          "followers": 28860
        },
        {
          "date": "2020-12",
          "followers": 27016
        },
        {
          "date": "2020-11",
          "followers": 26223
        },
        {
          "date": "2020-10",
          "followers": 25173
        },
        {
          "date": "2020-09",
          "followers": 23075
        },
        {
          "date": "2020-08",
          "followers": 21765
        },
        {
          "date": "2020-07",
          "followers": 21285
        },
        {
          "date": "2020-06",
          "followers": 20312
        },
        {
          "date": "2020-05",
          "followers": 19865
        },
        {
          "date": "2020-04",
          "followers": 18974
        },
        {
          "date": "2020-03",
          "followers": 18146
        },
        {
          "date": "2020-02",
          "followers": 17269
        },
        {
          "date": "2020-01",
          "followers": 16453
        },
        {
          "date": "2019-12",
          "followers": 15030
        },
        {
          "date": "2019-11",
          "followers": 13954
        },
        {
          "date": "2019-10",
          "followers": 12432
        },
        {
          "date": "2019-09",
          "followers": 11038
        },
        {
          "date": "2019-08",
          "followers": 10920
        },
        {
          "date": "2019-07",
          "followers": 10597
        },
        {
          "date": "2019-06",
          "followers": 8990
        },
        {
          "date": "2019-05",
          "followers": 8394
        },
        {
          "date": "2019-04",
          "followers": 7645
        },
        {
          "date": "2019-03",
          "followers": 7535
        },
        {
          "date": "2019-02",
          "followers": 6608
        },
        {
          "date": "2019-01",
          "followers": 5672
        },
        {
          "date": "2018-12",
          "followers": 4853
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 8467
        },
        {
          "date": "2021-10",
          "likes": 8331
        },
        {
          "date": "2021-09",
          "likes": 7972
        },
        {
          "date": "2021-08",
          "likes": 7705
        },
        {
          "date": "2021-07",
          "likes": 7569
        },
        {
          "date": "2021-06",
          "likes": 7551
        },
        {
          "date": "2021-05",
          "likes": 7428
        },
        {
          "date": "2021-04",
          "likes": 7186
        },
        {
          "date": "2021-03",
          "likes": 6834
        },
        {
          "date": "2021-02",
          "likes": 6584
        },
        {
          "date": "2021-01",
          "likes": 6516
        },
        {
          "date": "2020-12",
          "likes": 6190
        },
        {
          "date": "2020-11",
          "likes": 6090
        },
        {
          "date": "2020-10",
          "likes": 5915
        },
        {
          "date": "2020-09",
          "likes": 5523
        },
        {
          "date": "2020-08",
          "likes": 5251
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 395
        },
        {
          "date": "2021-10",
          "followers": 388
        },
        {
          "date": "2021-09",
          "followers": 384
        },
        {
          "date": "2021-08",
          "followers": 377
        },
        {
          "date": "2021-07",
          "followers": 379
        },
        {
          "date": "2021-06",
          "followers": 364
        },
        {
          "date": "2021-05",
          "followers": 350
        },
        {
          "date": "2021-04",
          "followers": 333
        },
        {
          "date": "2021-03",
          "followers": 324
        },
        {
          "date": "2021-02",
          "followers": 313
        },
        {
          "date": "2021-01",
          "followers": 298
        },
        {
          "date": "2020-12",
          "followers": 279
        },
        {
          "date": "2020-11",
          "followers": 269
        },
        {
          "date": "2020-10",
          "followers": 260
        },
        {
          "date": "2020-09",
          "followers": 244
        },
        {
          "date": "2020-08",
          "followers": 239
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Spot&amp;Tango",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 33292
        },
        {
          "date": "2021-10",
          "followers": 32439
        },
        {
          "date": "2021-09",
          "followers": 31447
        },
        {
          "date": "2021-08",
          "followers": 30593
        },
        {
          "date": "2021-07",
          "followers": 28876
        },
        {
          "date": "2021-06",
          "followers": 27456
        },
        {
          "date": "2021-05",
          "followers": 25389
        },
        {
          "date": "2021-04",
          "followers": 24286
        },
        {
          "date": "2021-03",
          "followers": 23258
        },
        {
          "date": "2021-02",
          "followers": 22696
        },
        {
          "date": "2021-01",
          "followers": 21888
        },
        {
          "date": "2020-12",
          "followers": 21460
        },
        {
          "date": "2020-11",
          "followers": 21079
        },
        {
          "date": "2020-10",
          "followers": 20140
        },
        {
          "date": "2020-09",
          "followers": 19009
        },
        {
          "date": "2020-08",
          "followers": 18714
        },
        {
          "date": "2020-07",
          "followers": 18592
        },
        {
          "date": "2020-06",
          "followers": 18464
        },
        {
          "date": "2019-02",
          "followers": 10558
        },
        {
          "date": "2019-01",
          "followers": 10594
        },
        {
          "date": "2018-12",
          "followers": 10646
        },
        {
          "date": "2018-11",
          "followers": 10695
        },
        {
          "date": "2018-10",
          "followers": 10754
        },
        {
          "date": "2018-09",
          "followers": 10813
        },
        {
          "date": "2018-08",
          "followers": 10888
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 5989
        },
        {
          "date": "2021-10",
          "likes": 5902
        },
        {
          "date": "2021-09",
          "likes": 5658
        },
        {
          "date": "2021-08",
          "likes": 5451
        },
        {
          "date": "2021-07",
          "likes": 5200
        },
        {
          "date": "2021-06",
          "likes": 4958
        },
        {
          "date": "2021-05",
          "likes": 4730
        },
        {
          "date": "2021-04",
          "likes": 4502
        },
        {
          "date": "2021-03",
          "likes": 4314
        },
        {
          "date": "2021-02",
          "likes": 4196
        },
        {
          "date": "2021-01",
          "likes": 4053
        },
        {
          "date": "2020-12",
          "likes": 3939
        },
        {
          "date": "2020-11",
          "likes": 3824
        },
        {
          "date": "2020-10",
          "likes": 3543
        },
        {
          "date": "2020-09",
          "likes": 3321
        },
        {
          "date": "2020-08",
          "likes": 3172
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 136
        },
        {
          "date": "2021-10",
          "followers": 134
        },
        {
          "date": "2021-09",
          "followers": 128
        },
        {
          "date": "2021-08",
          "followers": 128
        },
        {
          "date": "2021-07",
          "followers": 125
        },
        {
          "date": "2021-06",
          "followers": 122
        },
        {
          "date": "2021-05",
          "followers": 112
        },
        {
          "date": "2021-04",
          "followers": 108
        },
        {
          "date": "2021-03",
          "followers": 103
        },
        {
          "date": "2021-02",
          "followers": 102
        },
        {
          "date": "2021-01",
          "followers": 104
        },
        {
          "date": "2020-12",
          "followers": 104
        },
        {
          "date": "2020-11",
          "followers": 104
        },
        {
          "date": "2020-10",
          "followers": 100
        },
        {
          "date": "2020-09",
          "followers": 98
        },
        {
          "date": "2020-08",
          "followers": 95
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Sundays",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 9868
        },
        {
          "date": "2021-10",
          "followers": 9730
        },
        {
          "date": "2021-09",
          "followers": 9508
        },
        {
          "date": "2021-08",
          "followers": 9172
        },
        {
          "date": "2021-07",
          "followers": 9017
        },
        {
          "date": "2021-06",
          "followers": 8730
        },
        {
          "date": "2021-05",
          "followers": 8506
        },
        {
          "date": "2021-04",
          "followers": 8097
        },
        {
          "date": "2021-03",
          "followers": 7894
        },
        {
          "date": "2021-02",
          "followers": 7624
        },
        {
          "date": "2021-01",
          "followers": 7457
        },
        {
          "date": "2020-12",
          "followers": 7209
        },
        {
          "date": "2020-11",
          "followers": 6741
        },
        {
          "date": "2020-10",
          "followers": 6590
        },
        {
          "date": "2020-09",
          "followers": 6461
        },
        {
          "date": "2020-08",
          "followers": 6189
        },
        {
          "date": "2020-07",
          "followers": 5308
        },
        {
          "date": "2020-06",
          "followers": 4225
        }
      ]
    },
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 133
        },
        {
          "date": "2021-10",
          "followers": 131
        },
        {
          "date": "2021-09",
          "followers": 129
        },
        {
          "date": "2021-08",
          "followers": 130
        },
        {
          "date": "2021-07",
          "followers": 128
        },
        {
          "date": "2021-06",
          "followers": 125
        },
        {
          "date": "2021-05",
          "followers": 127
        },
        {
          "date": "2021-04",
          "followers": 117
        },
        {
          "date": "2021-03",
          "followers": 112
        },
        {
          "date": "2021-02",
          "followers": 108
        },
        {
          "date": "2021-01",
          "followers": 109
        },
        {
          "date": "2020-12",
          "followers": 110
        },
        {
          "date": "2020-11",
          "followers": 113
        },
        {
          "date": "2020-10",
          "followers": 111
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "TailoredPet",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 8296
        },
        {
          "date": "2021-10",
          "followers": 8247
        },
        {
          "date": "2021-09",
          "followers": 8117
        },
        {
          "date": "2021-08",
          "followers": 7913
        },
        {
          "date": "2021-07",
          "followers": 7577
        },
        {
          "date": "2021-06",
          "followers": 7455
        },
        {
          "date": "2021-05",
          "followers": 7051
        },
        {
          "date": "2021-04",
          "followers": 5798
        },
        {
          "date": "2021-03",
          "followers": 5374
        },
        {
          "date": "2021-02",
          "followers": 4968
        },
        {
          "date": "2021-01",
          "followers": 4354
        },
        {
          "date": "2020-12",
          "followers": 3943
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Tails",
    "instagram": null,
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 8890
        },
        {
          "date": "2021-10",
          "followers": 8880
        },
        {
          "date": "2021-09",
          "followers": 8853
        },
        {
          "date": "2021-08",
          "followers": 8813
        },
        {
          "date": "2021-07",
          "followers": 8732
        },
        {
          "date": "2021-06",
          "followers": 8683
        },
        {
          "date": "2021-05",
          "followers": 8661
        },
        {
          "date": "2021-04",
          "followers": 8693
        },
        {
          "date": "2021-03",
          "followers": 8648
        },
        {
          "date": "2021-02",
          "followers": 8589
        },
        {
          "date": "2021-01",
          "followers": 8529
        },
        {
          "date": "2020-12",
          "followers": 8470
        },
        {
          "date": "2020-11",
          "followers": 8431
        },
        {
          "date": "2020-10",
          "followers": 8337
        },
        {
          "date": "2020-09",
          "followers": 8293
        },
        {
          "date": "2020-08",
          "followers": 8258
        },
        {
          "date": "2020-07",
          "followers": 8218
        },
        {
          "date": "2020-06",
          "followers": 8181
        },
        {
          "date": "2020-05",
          "followers": 8160
        },
        {
          "date": "2020-04",
          "followers": 8112
        },
        {
          "date": "2020-03",
          "followers": 8045
        },
        {
          "date": "2020-02",
          "followers": 7995
        },
        {
          "date": "2020-01",
          "followers": 7982
        },
        {
          "date": "2019-12",
          "followers": 7955
        },
        {
          "date": "2019-11",
          "followers": 7949
        },
        {
          "date": "2019-10",
          "followers": 7928
        },
        {
          "date": "2019-09",
          "followers": 7900
        },
        {
          "date": "2019-08",
          "followers": 7880
        },
        {
          "date": "2019-07",
          "followers": 7843
        },
        {
          "date": "2019-06",
          "followers": 7783
        },
        {
          "date": "2019-05",
          "followers": 7751
        },
        {
          "date": "2019-04",
          "followers": 7699
        },
        {
          "date": "2019-03",
          "followers": 7668
        },
        {
          "date": "2019-02",
          "followers": 7612
        },
        {
          "date": "2019-01",
          "followers": 7552
        },
        {
          "date": "2018-12",
          "followers": 7507
        },
        {
          "date": "2018-11",
          "followers": 7444
        },
        {
          "date": "2018-10",
          "followers": 7429
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "TailsLife",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 937
        },
        {
          "date": "2021-10",
          "followers": 941
        },
        {
          "date": "2021-09",
          "followers": 943
        },
        {
          "date": "2021-08",
          "followers": 943
        },
        {
          "date": "2021-07",
          "followers": 946
        },
        {
          "date": "2021-06",
          "followers": 900
        },
        {
          "date": "2021-05",
          "followers": 885
        },
        {
          "date": "2021-04",
          "followers": 882
        },
        {
          "date": "2021-03",
          "followers": 870
        },
        {
          "date": "2021-02",
          "followers": 866
        },
        {
          "date": "2021-01",
          "followers": 865
        },
        {
          "date": "2020-12",
          "followers": 871
        },
        {
          "date": "2020-11",
          "followers": 867
        },
        {
          "date": "2020-10",
          "followers": 868
        },
        {
          "date": "2020-09",
          "followers": 865
        },
        {
          "date": "2020-08",
          "followers": 722
        },
        {
          "date": "2020-07",
          "followers": 638
        },
        {
          "date": "2020-06",
          "followers": 643
        },
        {
          "date": "2020-05",
          "followers": 651
        },
        {
          "date": "2020-04",
          "followers": 656
        },
        {
          "date": "2020-03",
          "followers": 661
        },
        {
          "date": "2020-02",
          "followers": 659
        },
        {
          "date": "2020-01",
          "followers": 664
        },
        {
          "date": "2019-12",
          "followers": 666
        },
        {
          "date": "2019-11",
          "followers": 671
        },
        {
          "date": "2019-10",
          "followers": 667
        },
        {
          "date": "2019-09",
          "followers": 656
        },
        {
          "date": "2019-08",
          "followers": 649
        },
        {
          "date": "2019-07",
          "followers": 618
        },
        {
          "date": "2019-06",
          "followers": 611
        },
        {
          "date": "2019-05",
          "followers": 613
        },
        {
          "date": "2019-04",
          "followers": 619
        },
        {
          "date": "2019-03",
          "followers": 618
        },
        {
          "date": "2019-02",
          "followers": 621
        },
        {
          "date": "2019-01",
          "followers": 627
        },
        {
          "date": "2018-12",
          "followers": 634
        },
        {
          "date": "2018-11",
          "followers": 639
        },
        {
          "date": "2018-10",
          "followers": 646
        },
        {
          "date": "2018-09",
          "followers": 632
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 10351
        },
        {
          "date": "2021-10",
          "likes": 10357
        },
        {
          "date": "2021-09",
          "likes": 10373
        },
        {
          "date": "2021-08",
          "likes": 10400
        },
        {
          "date": "2021-07",
          "likes": 10437
        },
        {
          "date": "2021-06",
          "likes": 10471
        },
        {
          "date": "2021-05",
          "likes": 10488
        },
        {
          "date": "2021-04",
          "likes": 10501
        },
        {
          "date": "2021-03",
          "likes": 10524
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 123
        },
        {
          "date": "2021-10",
          "followers": 123
        },
        {
          "date": "2021-09",
          "followers": 124
        },
        {
          "date": "2021-08",
          "followers": 124
        },
        {
          "date": "2021-07",
          "followers": 124
        },
        {
          "date": "2021-06",
          "followers": 126
        },
        {
          "date": "2021-05",
          "followers": 126
        },
        {
          "date": "2021-04",
          "followers": 128
        },
        {
          "date": "2021-03",
          "followers": 129
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "TheFarmer&#039;sdog",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 193666
        },
        {
          "date": "2021-10",
          "followers": 193057
        },
        {
          "date": "2021-09",
          "followers": 192246
        },
        {
          "date": "2021-08",
          "followers": 187583
        },
        {
          "date": "2021-07",
          "followers": 177807
        },
        {
          "date": "2021-06",
          "followers": 170557
        },
        {
          "date": "2021-05",
          "followers": 167944
        },
        {
          "date": "2021-04",
          "followers": 163888
        },
        {
          "date": "2021-03",
          "followers": 160038
        },
        {
          "date": "2021-02",
          "followers": 156052
        },
        {
          "date": "2021-01",
          "followers": 151198
        },
        {
          "date": "2020-12",
          "followers": 146116
        },
        {
          "date": "2020-11",
          "followers": 143755
        },
        {
          "date": "2020-10",
          "followers": 142310
        },
        {
          "date": "2020-09",
          "followers": 138651
        },
        {
          "date": "2020-08",
          "followers": 134428
        },
        {
          "date": "2020-07",
          "followers": 130935
        },
        {
          "date": "2020-06",
          "followers": 127454
        },
        {
          "date": "2020-05",
          "followers": 125067
        },
        {
          "date": "2020-04",
          "followers": 120365
        },
        {
          "date": "2020-03",
          "followers": 115524
        },
        {
          "date": "2020-02",
          "followers": 111705
        },
        {
          "date": "2020-01",
          "followers": 107693
        },
        {
          "date": "2019-12",
          "followers": 102977
        },
        {
          "date": "2019-11",
          "followers": 99809
        },
        {
          "date": "2019-10",
          "followers": 94899
        },
        {
          "date": "2019-09",
          "followers": 91129
        },
        {
          "date": "2019-08",
          "followers": 86337
        },
        {
          "date": "2019-07",
          "followers": 75640
        },
        {
          "date": "2019-06",
          "followers": 69408
        },
        {
          "date": "2019-05",
          "followers": 65868
        },
        {
          "date": "2019-04",
          "followers": 61574
        },
        {
          "date": "2019-03",
          "followers": 59394
        },
        {
          "date": "2019-02",
          "followers": 57254
        },
        {
          "date": "2019-01",
          "followers": 55598
        },
        {
          "date": "2018-12",
          "followers": 52429
        },
        {
          "date": "2018-11",
          "followers": 51084
        },
        {
          "date": "2018-10",
          "followers": 48335
        },
        {
          "date": "2018-09",
          "followers": 45468
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 89445
        },
        {
          "date": "2021-10",
          "likes": 89082
        },
        {
          "date": "2021-09",
          "likes": 88200
        },
        {
          "date": "2021-08",
          "likes": 86301
        },
        {
          "date": "2021-07",
          "likes": 82599
        },
        {
          "date": "2021-06",
          "likes": 80952
        },
        {
          "date": "2021-05",
          "likes": 79147
        },
        {
          "date": "2021-04",
          "likes": 78082
        },
        {
          "date": "2021-03",
          "likes": 76959
        },
        {
          "date": "2021-02",
          "likes": 75686
        },
        {
          "date": "2021-01",
          "likes": 74731
        },
        {
          "date": "2020-12",
          "likes": 73187
        },
        {
          "date": "2020-11",
          "likes": 72180
        },
        {
          "date": "2020-10",
          "likes": 71399
        },
        {
          "date": "2020-09",
          "likes": 70502
        },
        {
          "date": "2020-08",
          "likes": 69512
        },
        {
          "date": "2020-07",
          "likes": 68433
        },
        {
          "date": "2020-06",
          "likes": 67425
        },
        {
          "date": "2020-05",
          "likes": 66416
        },
        {
          "date": "2020-04",
          "likes": 65185
        },
        {
          "date": "2020-03",
          "likes": 63602
        },
        {
          "date": "2020-02",
          "likes": 62339
        },
        {
          "date": "2020-01",
          "likes": 61006
        },
        {
          "date": "2019-12",
          "likes": 59273
        },
        {
          "date": "2019-11",
          "likes": 57576
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 1818
        },
        {
          "date": "2021-10",
          "followers": 1806
        },
        {
          "date": "2021-09",
          "followers": 1780
        },
        {
          "date": "2021-08",
          "followers": 1749
        },
        {
          "date": "2021-07",
          "followers": 1693
        },
        {
          "date": "2021-06",
          "followers": 1655
        },
        {
          "date": "2021-05",
          "followers": 1623
        },
        {
          "date": "2021-04",
          "followers": 1604
        },
        {
          "date": "2021-03",
          "followers": 1581
        },
        {
          "date": "2021-02",
          "followers": 1552
        },
        {
          "date": "2021-01",
          "followers": 1382
        },
        {
          "date": "2020-12",
          "followers": 1347
        },
        {
          "date": "2020-11",
          "followers": 1324
        },
        {
          "date": "2020-10",
          "followers": 1288
        },
        {
          "date": "2020-09",
          "followers": 1261
        },
        {
          "date": "2020-08",
          "followers": 1239
        },
        {
          "date": "2020-07",
          "followers": 1199
        },
        {
          "date": "2020-06",
          "followers": 1148
        },
        {
          "date": "2020-05",
          "followers": 1121
        },
        {
          "date": "2020-04",
          "followers": 1105
        },
        {
          "date": "2020-03",
          "followers": 1087
        },
        {
          "date": "2020-02",
          "followers": 1076
        },
        {
          "date": "2020-01",
          "followers": 1053
        },
        {
          "date": "2019-12",
          "followers": 1032
        },
        {
          "date": "2019-11",
          "followers": 996
        },
        {
          "date": "2019-10",
          "followers": 972
        },
        {
          "date": "2019-09",
          "followers": 929
        },
        {
          "date": "2019-08",
          "followers": 885
        },
        {
          "date": "2019-07",
          "followers": 846
        },
        {
          "date": "2019-06",
          "followers": 815
        },
        {
          "date": "2019-05",
          "followers": 801
        },
        {
          "date": "2019-04",
          "followers": 788
        },
        {
          "date": "2019-03",
          "followers": 775
        },
        {
          "date": "2019-02",
          "followers": 753
        },
        {
          "date": "2019-01",
          "followers": 738
        },
        {
          "date": "2018-12",
          "followers": 727
        },
        {
          "date": "2018-11",
          "followers": 727
        },
        {
          "date": "2018-10",
          "followers": 940
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "TheGratefulDog",
    "instagram": null,
    "facebook": null,
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "Tom&amp;Sawyer",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 13035
        },
        {
          "date": "2021-10",
          "followers": 12998
        },
        {
          "date": "2021-09",
          "followers": 12956
        },
        {
          "date": "2021-08",
          "followers": 12907
        },
        {
          "date": "2021-07",
          "followers": 12860
        },
        {
          "date": "2021-06",
          "followers": 12791
        },
        {
          "date": "2021-05",
          "followers": 12802
        },
        {
          "date": "2021-04",
          "followers": 12754
        },
        {
          "date": "2021-03",
          "followers": 12747
        },
        {
          "date": "2021-02",
          "followers": 12665
        },
        {
          "date": "2021-01",
          "followers": 12575
        },
        {
          "date": "2020-12",
          "followers": 12424
        },
        {
          "date": "2020-11",
          "followers": 12367
        },
        {
          "date": "2020-10",
          "followers": 12332
        },
        {
          "date": "2020-09",
          "followers": 12317
        },
        {
          "date": "2020-08",
          "followers": 12300
        },
        {
          "date": "2020-07",
          "followers": 12285
        },
        {
          "date": "2020-06",
          "followers": 12187
        },
        {
          "date": "2020-05",
          "followers": 12145
        },
        {
          "date": "2020-04",
          "followers": 12086
        },
        {
          "date": "2020-03",
          "followers": 12046
        },
        {
          "date": "2020-02",
          "followers": 11983
        },
        {
          "date": "2020-01",
          "followers": 11925
        },
        {
          "date": "2019-12",
          "followers": 11827
        },
        {
          "date": "2019-11",
          "followers": 11732
        },
        {
          "date": "2019-10",
          "followers": 11651
        },
        {
          "date": "2019-09",
          "followers": 11597
        },
        {
          "date": "2019-08",
          "followers": 11484
        },
        {
          "date": "2019-07",
          "followers": 11358
        },
        {
          "date": "2019-06",
          "followers": 11141
        },
        {
          "date": "2019-05",
          "followers": 11022
        },
        {
          "date": "2019-04",
          "followers": 10933
        },
        {
          "date": "2019-03",
          "followers": 10879
        },
        {
          "date": "2019-02",
          "followers": 10816
        },
        {
          "date": "2019-01",
          "followers": 10813
        },
        {
          "date": "2018-12",
          "followers": 10697
        },
        {
          "date": "2018-11",
          "followers": 10658
        },
        {
          "date": "2018-10",
          "followers": 10460
        },
        {
          "date": "2018-09",
          "followers": 10148
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 2841
        },
        {
          "date": "2021-10",
          "likes": 2823
        },
        {
          "date": "2021-09",
          "likes": 2799
        },
        {
          "date": "2021-08",
          "likes": 2774
        },
        {
          "date": "2021-07",
          "likes": 2758
        },
        {
          "date": "2021-06",
          "likes": 2741
        },
        {
          "date": "2021-05",
          "likes": 2735
        },
        {
          "date": "2021-04",
          "likes": 2727
        },
        {
          "date": "2021-03",
          "likes": 2720
        },
        {
          "date": "2021-02",
          "likes": 2694
        },
        {
          "date": "2021-01",
          "likes": 2657
        },
        {
          "date": "2020-12",
          "likes": 2600
        },
        {
          "date": "2020-11",
          "likes": 2564
        },
        {
          "date": "2020-10",
          "likes": 2555
        },
        {
          "date": "2020-09",
          "likes": 2536
        },
        {
          "date": "2020-08",
          "likes": 2521
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 18594
        },
        {
          "date": "2021-10",
          "followers": 18616
        },
        {
          "date": "2021-09",
          "followers": 18655
        },
        {
          "date": "2021-08",
          "followers": 18714
        },
        {
          "date": "2021-07",
          "followers": 18772
        },
        {
          "date": "2021-06",
          "followers": 18838
        },
        {
          "date": "2021-05",
          "followers": 18914
        },
        {
          "date": "2021-04",
          "followers": 19053
        },
        {
          "date": "2021-03",
          "followers": 19106
        },
        {
          "date": "2021-02",
          "followers": 19187
        },
        {
          "date": "2021-01",
          "followers": 19254
        },
        {
          "date": "2020-12",
          "followers": 19422
        },
        {
          "date": "2020-11",
          "followers": 19519
        },
        {
          "date": "2020-10",
          "followers": 19602
        },
        {
          "date": "2020-09",
          "followers": 19724
        },
        {
          "date": "2020-08",
          "followers": 19822
        },
        {
          "date": "2020-07",
          "followers": 19961
        },
        {
          "date": "2020-06",
          "followers": 20084
        },
        {
          "date": "2020-05",
          "followers": 20242
        },
        {
          "date": "2020-04",
          "followers": 20428
        },
        {
          "date": "2020-03",
          "followers": 20559
        },
        {
          "date": "2020-02",
          "followers": 20727
        },
        {
          "date": "2020-01",
          "followers": 20814
        },
        {
          "date": "2019-12",
          "followers": 20942
        },
        {
          "date": "2019-11",
          "followers": 21055
        },
        {
          "date": "2019-10",
          "followers": 21177
        },
        {
          "date": "2019-09",
          "followers": 21275
        },
        {
          "date": "2019-08",
          "followers": 21363
        },
        {
          "date": "2019-07",
          "followers": 21490
        },
        {
          "date": "2019-06",
          "followers": 21606
        },
        {
          "date": "2019-05",
          "followers": 21665
        },
        {
          "date": "2019-04",
          "followers": 21734
        },
        {
          "date": "2019-03",
          "followers": 21874
        },
        {
          "date": "2019-02",
          "followers": 21981
        },
        {
          "date": "2019-01",
          "followers": 22122
        },
        {
          "date": "2018-12",
          "followers": 22328
        },
        {
          "date": "2018-11",
          "followers": 22498
        },
        {
          "date": "2018-10",
          "followers": 22734
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "TopDogDinners",
    "instagram": null,
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 651
        },
        {
          "date": "2021-10",
          "followers": 654
        },
        {
          "date": "2021-09",
          "followers": 656
        },
        {
          "date": "2021-08",
          "followers": 657
        },
        {
          "date": "2021-07",
          "followers": 658
        },
        {
          "date": "2021-06",
          "followers": 661
        },
        {
          "date": "2021-05",
          "followers": 661
        },
        {
          "date": "2021-04",
          "followers": 665
        },
        {
          "date": "2021-03",
          "followers": 665
        },
        {
          "date": "2021-02",
          "followers": 664
        },
        {
          "date": "2021-01",
          "followers": 664
        },
        {
          "date": "2020-12",
          "followers": 665
        },
        {
          "date": "2020-11",
          "followers": 672
        },
        {
          "date": "2020-10",
          "followers": 671
        },
        {
          "date": "2020-09",
          "followers": 673
        },
        {
          "date": "2020-08",
          "followers": 671
        },
        {
          "date": "2020-07",
          "followers": 671
        },
        {
          "date": "2020-06",
          "followers": 672
        },
        {
          "date": "2020-05",
          "followers": 678
        },
        {
          "date": "2020-04",
          "followers": 681
        },
        {
          "date": "2020-03",
          "followers": 683
        },
        {
          "date": "2020-02",
          "followers": 684
        },
        {
          "date": "2020-01",
          "followers": 684
        },
        {
          "date": "2019-12",
          "followers": 685
        },
        {
          "date": "2019-11",
          "followers": 687
        },
        {
          "date": "2019-10",
          "followers": 688
        },
        {
          "date": "2019-09",
          "followers": 690
        },
        {
          "date": "2019-08",
          "followers": 687
        },
        {
          "date": "2019-07",
          "followers": 696
        },
        {
          "date": "2019-06",
          "followers": 693
        },
        {
          "date": "2019-05",
          "followers": 698
        },
        {
          "date": "2019-04",
          "followers": 698
        },
        {
          "date": "2019-03",
          "followers": 699
        },
        {
          "date": "2019-02",
          "followers": 703
        },
        {
          "date": "2019-01",
          "followers": 706
        },
        {
          "date": "2018-12",
          "followers": 711
        },
        {
          "date": "2018-11",
          "followers": 710
        },
        {
          "date": "2018-10",
          "followers": 721
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Turbopup",
    "instagram": null,
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 2265
        },
        {
          "date": "2021-10",
          "followers": 2267
        },
        {
          "date": "2021-09",
          "followers": 2269
        },
        {
          "date": "2021-08",
          "followers": 2272
        },
        {
          "date": "2021-07",
          "followers": 2277
        },
        {
          "date": "2021-06",
          "followers": 2281
        },
        {
          "date": "2021-05",
          "followers": 2290
        },
        {
          "date": "2021-04",
          "followers": 2309
        },
        {
          "date": "2021-03",
          "followers": 2314
        },
        {
          "date": "2021-02",
          "followers": 2315
        },
        {
          "date": "2021-01",
          "followers": 2322
        },
        {
          "date": "2020-12",
          "followers": 2357
        },
        {
          "date": "2020-11",
          "followers": 2367
        },
        {
          "date": "2020-10",
          "followers": 2376
        },
        {
          "date": "2020-09",
          "followers": 2377
        },
        {
          "date": "2020-08",
          "followers": 2383
        },
        {
          "date": "2020-07",
          "followers": 2390
        },
        {
          "date": "2020-06",
          "followers": 2402
        },
        {
          "date": "2020-05",
          "followers": 2426
        },
        {
          "date": "2020-04",
          "followers": 2440
        },
        {
          "date": "2020-03",
          "followers": 2440
        },
        {
          "date": "2020-02",
          "followers": 2439
        },
        {
          "date": "2020-01",
          "followers": 2448
        },
        {
          "date": "2019-12",
          "followers": 2441
        },
        {
          "date": "2019-11",
          "followers": 2449
        },
        {
          "date": "2019-10",
          "followers": 2445
        },
        {
          "date": "2019-09",
          "followers": 2448
        },
        {
          "date": "2019-08",
          "followers": 2451
        },
        {
          "date": "2019-07",
          "followers": 2450
        },
        {
          "date": "2019-06",
          "followers": 2449
        },
        {
          "date": "2019-05",
          "followers": 2451
        },
        {
          "date": "2019-04",
          "followers": 2454
        },
        {
          "date": "2019-03",
          "followers": 2450
        },
        {
          "date": "2019-02",
          "followers": 2452
        },
        {
          "date": "2019-01",
          "followers": 2458
        },
        {
          "date": "2018-12",
          "followers": 2464
        },
        {
          "date": "2018-11",
          "followers": 2462
        },
        {
          "date": "2018-10",
          "followers": 2838
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "UltraPremiumDirect",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 101266
        },
        {
          "date": "2021-10",
          "followers": 96763
        },
        {
          "date": "2021-09",
          "followers": 93752
        },
        {
          "date": "2021-08",
          "followers": 91322
        },
        {
          "date": "2021-07",
          "followers": 85847
        },
        {
          "date": "2021-06",
          "followers": 81704
        },
        {
          "date": "2021-05",
          "followers": 78059
        },
        {
          "date": "2021-04",
          "followers": 76049
        },
        {
          "date": "2021-03",
          "followers": 70697
        },
        {
          "date": "2021-02",
          "followers": 67960
        },
        {
          "date": "2021-01",
          "followers": 65632
        },
        {
          "date": "2020-12",
          "followers": 62461
        },
        {
          "date": "2020-11",
          "followers": 59764
        },
        {
          "date": "2020-10",
          "followers": 56611
        },
        {
          "date": "2020-09",
          "followers": 50561
        },
        {
          "date": "2020-08",
          "followers": 48218
        },
        {
          "date": "2020-07",
          "followers": 45969
        },
        {
          "date": "2020-06",
          "followers": 40048
        },
        {
          "date": "2020-05",
          "followers": 38113
        },
        {
          "date": "2020-04",
          "followers": 33894
        },
        {
          "date": "2020-03",
          "followers": 30429
        },
        {
          "date": "2020-02",
          "followers": 25312
        },
        {
          "date": "2020-01",
          "followers": 19743
        },
        {
          "date": "2019-12",
          "followers": 15817
        },
        {
          "date": "2019-11",
          "followers": 14131
        },
        {
          "date": "2019-10",
          "followers": 10502
        },
        {
          "date": "2019-09",
          "followers": 8151
        },
        {
          "date": "2019-08",
          "followers": 7642
        },
        {
          "date": "2019-07",
          "followers": 7151
        },
        {
          "date": "2019-06",
          "followers": 6722
        },
        {
          "date": "2019-05",
          "followers": 6254
        },
        {
          "date": "2019-04",
          "followers": 5909
        },
        {
          "date": "2019-03",
          "followers": 5129
        },
        {
          "date": "2019-02",
          "followers": 4185
        },
        {
          "date": "2019-01",
          "followers": 3119
        },
        {
          "date": "2018-12",
          "followers": 2691
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 45257
        },
        {
          "date": "2021-10",
          "likes": 45173
        },
        {
          "date": "2021-09",
          "likes": 45018
        },
        {
          "date": "2021-08",
          "likes": 44771
        },
        {
          "date": "2021-07",
          "likes": 44457
        },
        {
          "date": "2021-06",
          "likes": 44302
        },
        {
          "date": "2021-05",
          "likes": 43848
        }
      ]
    },
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 232
        },
        {
          "date": "2021-10",
          "followers": 230
        },
        {
          "date": "2021-09",
          "followers": 228
        },
        {
          "date": "2021-08",
          "followers": 225
        },
        {
          "date": "2021-07",
          "followers": 220
        },
        {
          "date": "2021-06",
          "followers": 218
        },
        {
          "date": "2021-05",
          "followers": 214
        },
        {
          "date": "2021-04",
          "followers": 206
        },
        {
          "date": "2021-03",
          "followers": 171
        },
        {
          "date": "2021-02",
          "followers": 153
        },
        {
          "date": "2021-01",
          "followers": 132
        },
        {
          "date": "2020-12",
          "followers": 126
        },
        {
          "date": "2020-11",
          "followers": 125
        },
        {
          "date": "2020-10",
          "followers": 118
        },
        {
          "date": "2020-09",
          "followers": 117
        },
        {
          "date": "2020-08",
          "followers": 117
        },
        {
          "date": "2020-07",
          "followers": 117
        },
        {
          "date": "2020-06",
          "followers": 115
        },
        {
          "date": "2020-05",
          "followers": 115
        },
        {
          "date": "2020-04",
          "followers": 114
        },
        {
          "date": "2020-03",
          "followers": 112
        },
        {
          "date": "2020-02",
          "followers": 112
        },
        {
          "date": "2020-01",
          "followers": 108
        },
        {
          "date": "2019-12",
          "followers": 106
        },
        {
          "date": "2019-11",
          "followers": 105
        },
        {
          "date": "2019-10",
          "followers": 105
        },
        {
          "date": "2019-09",
          "followers": 104
        },
        {
          "date": "2019-08",
          "followers": 99
        },
        {
          "date": "2019-07",
          "followers": 96
        },
        {
          "date": "2019-06",
          "followers": 95
        },
        {
          "date": "2019-05",
          "followers": 92
        },
        {
          "date": "2019-04",
          "followers": 90
        },
        {
          "date": "2019-03",
          "followers": 88
        },
        {
          "date": "2019-02",
          "followers": 86
        },
        {
          "date": "2019-01",
          "followers": 83
        },
        {
          "date": "2018-12",
          "followers": 83
        },
        {
          "date": "2018-11",
          "followers": 83
        },
        {
          "date": "2018-10",
          "followers": 81
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Wilder&amp;Harrier",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 8361
        },
        {
          "date": "2021-10",
          "followers": 7753
        },
        {
          "date": "2021-09",
          "followers": 6780
        },
        {
          "date": "2021-08",
          "followers": 6412
        },
        {
          "date": "2021-07",
          "followers": 6239
        },
        {
          "date": "2021-06",
          "followers": 6145
        },
        {
          "date": "2021-05",
          "followers": 6096
        },
        {
          "date": "2021-04",
          "followers": 6029
        },
        {
          "date": "2021-03",
          "followers": 5826
        },
        {
          "date": "2021-02",
          "followers": 5706
        },
        {
          "date": "2021-01",
          "followers": 5473
        },
        {
          "date": "2020-12",
          "followers": 5484
        },
        {
          "date": "2020-11",
          "followers": 5443
        },
        {
          "date": "2020-10",
          "followers": 5424
        },
        {
          "date": "2020-09",
          "followers": 5411
        },
        {
          "date": "2020-08",
          "followers": 5331
        },
        {
          "date": "2020-07",
          "followers": 5280
        },
        {
          "date": "2020-06",
          "followers": 5183
        },
        {
          "date": "2020-05",
          "followers": 5097
        },
        {
          "date": "2020-04",
          "followers": 4938
        },
        {
          "date": "2020-03",
          "followers": 4724
        },
        {
          "date": "2020-02",
          "followers": 4635
        },
        {
          "date": "2020-01",
          "followers": 4596
        },
        {
          "date": "2019-12",
          "followers": 4573
        },
        {
          "date": "2019-11",
          "followers": 4545
        },
        {
          "date": "2019-10",
          "followers": 4512
        },
        {
          "date": "2019-09",
          "followers": 4440
        },
        {
          "date": "2019-08",
          "followers": 4362
        },
        {
          "date": "2019-07",
          "followers": 4329
        },
        {
          "date": "2019-06",
          "followers": 4297
        },
        {
          "date": "2019-05",
          "followers": 4283
        },
        {
          "date": "2019-04",
          "followers": 4268
        },
        {
          "date": "2019-03",
          "followers": 4194
        },
        {
          "date": "2019-02",
          "followers": 4151
        },
        {
          "date": "2019-01",
          "followers": 4128
        },
        {
          "date": "2018-12",
          "followers": 4095
        },
        {
          "date": "2018-11",
          "followers": 4079
        },
        {
          "date": "2018-10",
          "followers": 3981
        },
        {
          "date": "2018-09",
          "followers": 3966
        }
      ]
    },
    "facebook": null,
    "twitter": {
      "data": [
        {
          "date": "2021-11",
          "followers": 1010
        },
        {
          "date": "2021-10",
          "followers": 1009
        },
        {
          "date": "2021-09",
          "followers": 1008
        },
        {
          "date": "2021-08",
          "followers": 1008
        },
        {
          "date": "2021-07",
          "followers": 1006
        },
        {
          "date": "2021-06",
          "followers": 1005
        },
        {
          "date": "2021-05",
          "followers": 1011
        },
        {
          "date": "2021-04",
          "followers": 1020
        },
        {
          "date": "2021-03",
          "followers": 1023
        },
        {
          "date": "2021-02",
          "followers": 1024
        },
        {
          "date": "2021-01",
          "followers": 1025
        },
        {
          "date": "2020-12",
          "followers": 1022
        },
        {
          "date": "2020-11",
          "followers": 1025
        },
        {
          "date": "2020-10",
          "followers": 1034
        },
        {
          "date": "2020-09",
          "followers": 1034
        },
        {
          "date": "2020-08",
          "followers": 1034
        },
        {
          "date": "2020-07",
          "followers": 1039
        },
        {
          "date": "2020-06",
          "followers": 1042
        },
        {
          "date": "2020-05",
          "followers": 1050
        },
        {
          "date": "2020-04",
          "followers": 1058
        },
        {
          "date": "2020-03",
          "followers": 1059
        },
        {
          "date": "2020-02",
          "followers": 1068
        },
        {
          "date": "2020-01",
          "followers": 1076
        },
        {
          "date": "2019-12",
          "followers": 1082
        },
        {
          "date": "2019-11",
          "followers": 1085
        },
        {
          "date": "2019-10",
          "followers": 1088
        },
        {
          "date": "2019-09",
          "followers": 1088
        },
        {
          "date": "2019-08",
          "followers": 1093
        },
        {
          "date": "2019-07",
          "followers": 1096
        },
        {
          "date": "2019-06",
          "followers": 1101
        },
        {
          "date": "2019-05",
          "followers": 1107
        },
        {
          "date": "2019-04",
          "followers": 1105
        },
        {
          "date": "2019-03",
          "followers": 1107
        },
        {
          "date": "2019-02",
          "followers": 1111
        },
        {
          "date": "2019-01",
          "followers": 1117
        },
        {
          "date": "2018-12",
          "followers": 1128
        },
        {
          "date": "2018-11",
          "followers": 1132
        },
        {
          "date": "2018-10",
          "followers": 1142
        }
      ]
    },
    "tiktok": null
  },
  {
    "cid": "Wunschfutter",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 1892
        },
        {
          "date": "2021-10",
          "followers": 1698
        },
        {
          "date": "2021-09",
          "followers": 1653
        },
        {
          "date": "2021-08",
          "followers": 1654
        },
        {
          "date": "2021-07",
          "followers": 1576
        },
        {
          "date": "2021-06",
          "followers": 1564
        },
        {
          "date": "2021-05",
          "followers": 1563
        },
        {
          "date": "2021-04",
          "followers": 1564
        },
        {
          "date": "2021-03",
          "followers": 1556
        },
        {
          "date": "2021-02",
          "followers": 1522
        },
        {
          "date": "2021-01",
          "followers": 1513
        },
        {
          "date": "2020-12",
          "followers": 1413
        },
        {
          "date": "2020-11",
          "followers": 1346
        },
        {
          "date": "2020-10",
          "followers": 1149
        },
        {
          "date": "2020-09",
          "followers": 1118
        },
        {
          "date": "2020-08",
          "followers": 1049
        },
        {
          "date": "2020-07",
          "followers": 1023
        },
        {
          "date": "2020-06",
          "followers": 809
        },
        {
          "date": "2020-05",
          "followers": 781
        },
        {
          "date": "2020-04",
          "followers": 785
        },
        {
          "date": "2020-03",
          "followers": 768
        },
        {
          "date": "2020-02",
          "followers": 751
        }
      ]
    },
    "facebook": {
      "data": [
        {
          "date": "2021-11",
          "likes": 12713
        },
        {
          "date": "2021-10",
          "likes": 12722
        },
        {
          "date": "2021-09",
          "likes": 12731
        },
        {
          "date": "2021-08",
          "likes": 12734
        },
        {
          "date": "2021-07",
          "likes": 12734
        },
        {
          "date": "2021-06",
          "likes": 12719
        },
        {
          "date": "2021-05",
          "likes": 12732
        },
        {
          "date": "2021-04",
          "likes": 12733
        },
        {
          "date": "2021-03",
          "likes": 12706
        },
        {
          "date": "2021-02",
          "likes": 12688
        },
        {
          "date": "2021-01",
          "likes": 12667
        },
        {
          "date": "2020-12",
          "likes": 12654
        },
        {
          "date": "2020-11",
          "likes": 12539
        },
        {
          "date": "2020-10",
          "likes": 12358
        },
        {
          "date": "2020-09",
          "likes": 12308
        },
        {
          "date": "2020-08",
          "likes": 12310
        },
        {
          "date": "2020-07",
          "likes": 12309
        },
        {
          "date": "2020-06",
          "likes": 12295
        }
      ]
    },
    "twitter": null,
    "tiktok": null
  },
  {
    "cid": "ZiggyPetfood",
    "instagram": {
      "data": [
        {
          "date": "2021-11",
          "followers": 11064
        },
        {
          "date": "2021-10",
          "followers": 10470
        },
        {
          "date": "2021-09",
          "followers": 8956
        },
        {
          "date": "2021-08",
          "followers": 7962
        },
        {
          "date": "2021-07",
          "followers": 7321
        },
        {
          "date": "2021-06",
          "followers": 6043
        },
        {
          "date": "2021-05",
          "followers": 5882
        },
        {
          "date": "2021-04",
          "followers": 5420
        },
        {
          "date": "2021-03",
          "followers": 5046
        },
        {
          "date": "2021-02",
          "followers": 4194
        }
      ]
    },
    "facebook": null,
    "twitter": null,
    "tiktok": null
  }
]
