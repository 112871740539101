import React from "react";

// import edit from "./../../../assets/img/learn/edit-alt.svg";
//import cloud from "./../../assets/img/learn/cloud-download.svg";

export const ResourcesLine = ({img, title, version, description, icon, link}) => {
  // let imgUrl = "PB1_Build_Box.png"

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (<>

    <div 
      class="d-flex mb-3 hover-actions-trigger align-items-center mt-3"
      
      style={{cursor: 'pointer'}}
      >
      <div class="file-thumbnail">
        <img class="border h-100 w-100 fit-cover rounded-2" src={require(`./../../../assets/img/learn/${img}`)} alt="" />
      </div>
      <div class="ml-3 flex-shrink-1 flex-grow-1">
        <h6 class="mb-1">
          <div class="stretched-link text-900 fw-semi-bold" onClick={(e) => openInNewTab(link)} style={{cursor: 'pointer'}}>
            {title}
          </div>
        </h6>
        <div class="fs--1">
          <span class="fw-semi-bold">
            {icon !== "" && <span class={`${icon}`}/>} 
            {version}
          </span>
          
        </div>
        <div class="fs--1">
          <span class="fw-medium text-600">
            {description}
          </span>
        </div>
        {// <div class="hover-actions end-0 top-50 translate-middle-y">
        //   <a class="btn btn-light border-300 btn-sm me-1 text-600" data-bs-toggle="tooltip" data-bs-placement="top" title="Download" href="assets/img/icons/cloud-download.svg" download="download">
        //     <img src="assets/img/icons/cloud-download.svg" alt="" width="15" />
        //   </a>
        //   <button class="btn btn-light border-300 btn-sm me-1 text-600 shadow-none" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
        //     <img src={edit} alt="" width="15" />
        //   </button>
        // </div>
        }
      </div>
    </div>
    <hr class="bg-200" />
  </>)}