export const getAllSocials = `
query GetAllSocials {
    GetAllSocials {
      _id
      totalFollowersFive
      totalFollowersFour
      totalFollowersThree
      totalFollowersTwo
      totalFollowersOne
      totalFollowersZero
    }
  }`;
