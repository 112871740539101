export const pipelineMix = `
query PipelineMix {
  PipelineMix {
    PipelineMix {
      _id
			country    
      stage
      dog
      cat
      both
      channel
      division
    }
  }
}
`;
