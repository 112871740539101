import React, { useState } from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Table,
  Media,
  Spinner
} from "reactstrap";
import axios from 'axios';
import { InputFieldModal } from "../Input/InputFieldModal";

const FindCompany = ({companyName, handleUrl, setData, isOpen, setIsOpen}) => {
  //const [isOpen, setIsOpen] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const toggle = () => setIsOpen(!isOpen);
  const [customInput, setCustomInput] = React.useState({name: "", url: ""});

  const getList = () => {
    axios
      .get(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${companyName}`)
      .then(res => {
        setCompanyList([...res.data])
        toggle()
      })
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const SuggestionList = () => {    
    if(companyList.length === 0) return(
      <div>
        <tr key={'e'}>
          <td>
            <Spinner color="light" />
          </td>
          <td>
            <Spinner color="light" />
          </td>
          <td>
            <Spinner color="light" />
          </td>
        </tr>
      </div>
    )

    return companyList.map((item,i) => (
      <tr key={i}>
        <td class="fs--2 pl-0 pr-0">
          <Media className="align-items-center">
            <div
              className="avatar rounded-circle"
              onClick={(e) => e.preventDefault()}
            >
              <img
                alt="..."
                src={item.logo}
              />
            </div>
          </Media>
        </td>
        <td class="fs--2 pl-0 pr-0">
          <div style={{fontSize: '12px'}}>{item.name}</div>
          <br/>
          <div class="hoverhand" style={{fontSize: '11px'}} onClick={e=>openInNewTab(`$http://{item.domain}`)}>{item.domain}</div>
        </td>
        <td class="fs--2 pl-0 pr-0">
          <div
            class="ml-2"
            onClick={e => {
              //handleUrl(item.domain);
              setData(item.name, item.domain)
              toggle()
            }}>
            <i className="hoverhand ni ni-curved-next mars-blue"></i>
          </div>
          
        </td>
      </tr>
    ))
  }

  const checkUrlInput = () => {
    var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);
    var t = customInput.url;

    if (t.match(regex)) {
      //alert("Successful match");
      return true
    } else {
      alert("Input a valid website URL. (e.g. www.google.com)");
      return false
    }
  }

  return(
    <>
    <p>
      <div class="pr-2">
        <Button 
          onClick={e=>{
            getList()
          }} 
          height="16px" 
          style={{ minWidth: '80px', backgroundColor: '#000096', fontSize: '11px' }}
          >
          {`${!isOpen? "Search Company" : "Cancel"}`}
        </Button>
      </div>
    </p>
    <Collapse isOpen={isOpen}>
      <Card>
        <CardBody>
          <Table>
           <thead>
             <tr>
               <td class="fs--2 pl-0 pr-0">Logo</td>
               <td class="fs--2 pl-0 pr-0">Company Name</td>
               <td class="fs--2 pl-0 pr-0">Copy</td>
             </tr>
           </thead>
           <tbody>
            <SuggestionList />
            <tr>
              <td class="fs--2 pl-0 pr-0">
                {/* <Media className="align-items-center">
                  <div
                    className="avatar rounded-circle"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img
                      alt="..."
                      src={""}
                    />
                  </div>
                </Media> */}
              </td>
              <td class="fs--2 pl-0 pr-0">
                <div style={{fontSize: '12px'}}>
                  <InputFieldModal customInput={customInput} setCustomInput={setCustomInput} title={"Company Name"} field={"name"}/>
                </div>
                <br/>
                <div class="hoverhand" style={{fontSize: '11px'}}>
                  <InputFieldModal customInput={customInput} setCustomInput={setCustomInput} title={"Company Website"} field={"url"}/>
                </div>
              </td>
              <td class="fs--2 pl-0 pr-0">
                <div
                  class="ml-2"
                  onClick={e => {
                    //handleUrl(item.domain);
                    if(checkUrlInput()){
                      setData(customInput.name, customInput.url)
                      toggle()
                    }
                  }}>
                  <i className="hoverhand ni ni-curved-next mars-blue"></i>
                </div>
                
              </td>
            </tr>
           </tbody>
          </Table>
        </CardBody>
      </Card>
    </Collapse>
  </>
  )
}

export default FindCompany
