import React from 'react';
import { sortBrandTable } from '../Sort/SortBrandTable';
import ReactCountryFlag from 'react-country-flag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import SmallSearchBar from '../SearchBars/SmallSearchBar';

import NewCompany from '../add-company/NewCompany';

const PaginationTable = ({ getCompanies, page, setPage, setSelectedBrand, columns, itemsPerPage, tableItems, setTableItems, autofillList, string, setString }) => {
  // Columns = [ { string "columnName", string "field", bool isSortable, bool isNumeric }];
  // field refers to the name of the attribute which is used in the items array
  // itemsPerPage = number of items per page in the table
  // items = [ {itemAttribute 1, itemAttribute 2, etc. }];
  const [sorting, setSorting] = React.useState(false);
  //const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [open, setOpen] = React.useState(true);

  const catIcon = 'fas fa-cat fs--1 me-2 mr-1';
  const dogIcon = 'fas fas fa-dog fs--1 mr-1';
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage); 
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // This method is specific for handling the brands table on the M_Discover page
  //▼▲

  const fillCells = (item, columns) => {
    let cells = [];

    for (let column of columns) {
      let align = 'left';
      let value = '';
      let extra = "";
      let className = 'table-cell table-cell-body';
      
      if (column.id === 'revenue' || column.id === 'traffic' || column.id === 'followers' ) {
        align = 'center';
      }

      if (column.id === 'image') {
        value = (
          <img
            alt=""
            width="20"
            className="ml-3 mr-1"
            src={`https://d2cx.ams3.digitaloceanspaces.com/icons/${encodeURIComponent(item[column.id])}.png`}
            onError={e => {
              e.target.src = require('../../assets/img/whiteBox.png');
            }}
          />
        );
      } 
      else if (column.id === 'name') {
        extra = "sticky-table"
        value =
          setSelectedBrand === undefined ? (
            <Link
              to={`/insight/brandoverview/${encodeURIComponent(item.image)}`}
              className="mars-blue hoverhand"
              style={{ fontSize: '0.7rem', fontWeight: '500' }}
            >
              {item[column.id]}
            </Link>
          ) : (
            <div className="mars-blue hoverhand" style={{ fontSize: '0.7rem', fontWeight: '500' }}>
              {item[column.id]}
            </div>
          );
      } 
      else if (column.id === 'socials') {
        value = (
          <Button className="ml-1 mr-3 p-0" color="none" size="sm">
            <i className="fas fa-ellipsis-h" style={{ color: '#748194' }} />
          </Button>
        );
      } 
      else if (column.id.includes('delta')) {
        //console.log(`delta: ${item[column.id]}`)
        if(item[column.id] !== "-" && item[column.id] !== "N/A" && item[column.id] != "0" ){
          if(item[column.id] < 0){
            value = (
              <span className="badge badge-soft-warning rounded-pill fs--2" style={{ width: '52px' }}>
                {item[column.id] + "%"}
              </span>
            )
          }
          else {
            value = (
              <span className="badge badge-soft-success rounded-pill fs--2" style={{ width: '52px' }}>
                {item[column.id] + "%"}
              </span>
            )          
          }  
        }
        else {
          value = "N/A"
        }
        // let percentage = item[column.id];
        // if (percentage.includes('-')) {
        //   percentage = `${percentage}%`;
        //   value = (
        //     <span className="badge badge-soft-warning rounded-pill fs--2" style={{ width: '52px' }}>
        //       {percentage}
        //     </span>
        //   );
        // } else {
        //   percentage = `+${percentage}%`;
        //   value = (
        //     <span className="badge badge-soft-success rounded-pill fs--2" style={{ width: '52px' }}>
        //       {percentage}
        //     </span>
        //   );
        // }
      } 
      else if (column.id === 'species') {
        if(item.species == "dog" || item.species == "Dog") value = (
          <div>
            <div style={{ color: "#00d27a" }} class={dogIcon} /> 
            <div style={{ color: "#cccccc" }} class={catIcon} />
          </div>
        )
        if(item.species == "cat" || item.species == "Cat") value = (
          <div>
            <div style={{ color: "#cccccc" }} class={dogIcon} /> 
            <div style={{ color: "#00d27a" }} class={catIcon} />
          </div>
        )
        if(item.species === "Both") value = (
          <div>
            <div style={{ color: "#00d27a" }} class={dogIcon} /> 
            <div style={{ color: "#00d27a" }} class={catIcon} />
          </div>
        )
        if(item.species === undefined) value = (
        <div>
          <div style={{ color: "#ccc" }} class={dogIcon} /> 
          <div style={{ color: "#ccc" }} class={catIcon} />
        </div>
      )
        
      } 
      else if (column.id === 'country') {
        value = <ReactCountryFlag countryCode={`${item.country === "UK" ? "GB" : item.country}`} style={{ width: '20px' }} svg />;
      } 
      else {
        value = item[column.id];
      }
      cells.push(
        <TableCell align={align} className={className + " " + extra}>
          {value}
        </TableCell>
      );
    }

    return cells;
  };

  const formatColumnTitle = (column, key) => {
    let align = 'right';
    let sortIcon = '';
    let extra = ""

    if (column.id === 'name') extra = "sticky-header"

    if (column.id === 'image' || column.id === 'name') {
      align = 'right';
    }
    if (column.isSortable) {
      sortIcon = (
        <Button
          color="none"
          size="sm"
          style={{ margin: 0, padding: 0, height: '16px', marginTop: '-2px' }}
          onClick={() => {
            sortBrandTable(column.id, sorting, tableItems, setTableItems)
            setSorting(!sorting)
          }}
        >
          <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
            <i className="fas fa-caret-up table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
            <i className="fas fa-caret-down table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
          </span> 
        </Button>
      );
    }

    let justifyC = align !== 'left' ? 'flex-start' : 'flex-end';

    return (
      <TableCell key={key} className={`table-cell ${extra}`} align={align}> 
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: `${justifyC}` }}>
          <span>{column.columnName}</span>
          <span style={{ paddingLeft: '2px' }}>{sortIcon}</span>
        </div>
      </TableCell>
    );
  };

  // const testButtonPress = (sortCategory, order, state, setState) => {
  //   sortBrandTable(sortCategory, sorting, tableItems, setTableItems)
  //   setSorting(!sorting)
  // }

  // const getSortIcon = currentIcon => {
  //   let newIcon = '';

  //   // null = both up and down arrows
  //   if (currentIcon === null) {
  //     newIcon = (
  //       <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
  //         <i className="fas fa-caret-up table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
  //         <i className="fas fa-caret-down table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
  //       </span>
  //     );
  //   }
  //   // true = sortIconUp
  //   else if (currentIcon) {
  //     newIcon = (
  //       <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
  //         <i
  //           className="fas fa-caret-up table-sort-icon"
  //           style={{ fontSize: '0.8rem', lineHeight: '100%', textAlign: 'center' }}
  //         />
  //       </div>
  //     );
  //   }
  //   // false = sortIconDown
  //   else if (!currentIcon) {
  //     newIcon = (
  //       <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
  //         <i
  //           className="fas fa-caret-down table-sort-icon"
  //           style={{ fontSize: '0.8rem', lineHeight: '100%', textAlign: 'center' }}
  //         />
  //       </div>
  //     );
  //   }

  //   return newIcon;
  // };

  const itemsOnThisPage = (rowsPerPage, page, list) => {
    let y = rowsPerPage * (page + 1);
    let x = y - rowsPerPage;
    return list.slice(x, y);
  };

  return (
    <>
      <div class="card mb-3">
        <div class="card-header bg-light">
              <div class="row w-100">
                <div class={`${window.innerWidth>991?"col-1":"col-3"}`}><h6 className="mb-0 text-nowrap">Brands</h6></div>
                <div class="col-3 p-0"><NewCompany getCompanies={getCompanies} /></div>
                <div class="ml-auto col-6 pr-0" >
                  <SmallSearchBar list={autofillList} string={string} setString={setString}/>
                </div>
              </div>
              <div>
                <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
                  <i class="fas fa-info-circle mr-1" />
                  Quick guide & legend
                </div>
              </div>
        </div>
        <div class="card-body" style={{ padding: 0, marginLeft: 0, marginRight: 0, marginTop: 0 }}>
          <div class={`${open ? 'collapse' : ''}`} id="collapseFilter">
            <div class="p-card rounded fs--2">
              <ul>
                <li>Brand → Brand name of competitor</li>
                <li>Country & Region → Geographical coverage of competitor</li>
                <li>Species → Dog, cat of both</li>
                <li>Product → Main product type
                  <ul>
                    <li>MM-I → Main Meal - Individualized</li>
                    <li>MM-NI → Main Meal - Not individualized</li>
                    <li>CTS → Care + Treatments & Supplements</li>
                    <li>MTT → Monthly (boxes) Treats & Toys</li>
                  </ul>
                </li>
                <li>Revenue → Reported revenue where available, otherwise extrapolated based on validated formula, extrapolating from social media following.
                  All revenues for 2020 unless otherwise stated.</li>
                <li>Web traffic → Monthly unique visitors to websit e(in k) in latest month, month-over-month change</li>
                <li>Delta → Difference in web traffic between current and previous month, in percent. </li>
                <li>Followers → Sum of followers (in k) (Twitter, Facebook, Instagram and Youtube), latest month, month-over-month change</li>
                <li>Delta → Difference in followers between current and previous month, in percent. </li>

              </ul>
              
            </div>
          </div>

          <TableContainer style={{ marginTop: 0, paddingTop: 0 }}>
            <Table>
              <TableHead>
                <TableRow className="table-header">{columns.map((column, key) => formatColumnTitle(column, key))}</TableRow>
              </TableHead>
              <TableBody>
                {itemsOnThisPage(rowsPerPage, page, tableItems).map(item => (
                 
                  <TableRow
                    key={item.name}
                    onClick={e => setSelectedBrand !== undefined && setSelectedBrand(item.image)}
                  >
                    
                    {(fillCells(item, columns))}
                    {/* { console.log(item)} */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div class="card-footer p-0">
            <TablePagination
                rowsPerPageOptions={[4, 8, 16]}
                labelRowsPerPage={<div style={{fontSize: 14}}>{"Items: "}</div>}
                component="div"
                count={tableItems.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="pagination-item"
                style={{ marginLeft: '15px', padding: 0, borderStyle: 'none' }}
              />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaginationTable;
