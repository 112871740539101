import React from 'react';
import ReactEcharts from 'echarts-for-react';

const Scatter = ({ data, xAxis, yAxis, title }) => {
  //console.log(data)
  const option = {
    dataZoom: [
      {
        type: 'slider'
      },
      {
        type: 'inside'
      },
      {
        type: 'slider',
        yAxisIndex: 0,
        //zoomLock: true,
        //width: 10,
        //right: 10,
        //top: 70,
        //bottom: 20,
        //start: 95,
        //end: 100,
        //handleSize: 0,
        //showDetail: false,
    }, {
        type: 'inside',
        //id: 'insideY',
        //yAxisIndex: 0,
        //start: 95,
        // //end: 100,
        // zoomOnMouseWheel: false,
        // moveOnMouseMove: true,
        // moveOnMouseWheel: true
    }
    ],
    responsive: true,
    title: {
      show: true,
      text: title
    },
    tooltip: {
      show: true,
      //formatter: console.log(`idk `)
    },
    xAxis: {
      name: `${xAxis}`,
      nameLocation: 'center',
      nameGap: 25,

    },
    yAxis: {
      // axisLabel : {
      //     formatter: '{value} °C'
      //   },
      name: yAxis,
      nameLocation: 'center',
      nameGap: 35,
      // axisLabel: {
      //   interval: 1,
      //   rotate: 30 //If the label names are too long you can manage this by rotating the label.
      // }
    },
    // grid: {
    //   left: 30,
    //   right: 30,
    //   top: 30,
    //   bottom: 50
    // },
    series: [
      {
        symbolSize: 10,
        data: data,
        emphasis: {
          focus: 'self'
        },
        labelLayout(params) {
          return {
            x: '95px',
            align: 'right',
            moveOverlap: 'shiftY'
          };
        },
        // labelLine: {
        //   show: false,
        //   length2: 50,
        //   lineStyle: {
        //     color: '#9da9bb',
        //     opacity: '1'
        //   }
        // // },
        // label: {
        //   show: false,
        //   position: 'right',
        //   minMargin: 2,
        //   formatter: '{b}'
        // },
        color: '#000096',
        type: 'scatter'
      }
    ],
    color: ['#000096', '#003db0', '#0063b0', '#007cb0', '#008baf', '#00a1b0', '#08bcbf', '#10ccc9', '#20e6ce'],
    textStyle: {
      fontFamily: `'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default`,
      color: '#748194'
    }
  };

  return <ReactEcharts className="ml-auto" option={option} style={{ padding: 0, margin: 0, width: '95%', marginLeft: '10px' }} />;
};

export default Scatter;
