import React from "react";
// import {
//   InputGroup,
//   InputGroupAddon,
//   InputGroupButtonDropdown,
//   Input,
//   Button,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem
//  } from 'reactstrap';
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
// import * as api from "./../../API/IndividualVpAPI";


export const KeyLearnings = ({vpHandler}) => {
  const [open, setOpen] = React.useState(true);
  const [hide, setHide] = React.useState(false);

  const [selectedTab, setSelectedTab] = React.useState("preview");

  React.useEffect(() => {
    if(vpHandler.action === "view" || vpHandler.action === "review") setSelectedTab("preview")
    else setSelectedTab("write")
  }, [vpHandler.action]);
  
  const handleChange = (e, handle) => {
    let vpObject = vpHandler.get
    vpObject[handle] = e
    console.log(`painpoint: ${vpObject.painPoint}`)
    vpHandler.set({...vpObject})
  }

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
  });
  return(
    
    <div class="card mb-3">
      <div class="card-header pb-1 bg-light">
        <h6 class="mb-0">Key Learnings</h6>
        <div class="row col m-0 p-0">
          <div>
            <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
              <i class="fas fa-info-circle mr-1"/>
              Quick help & legend
            </div>
          </div>
          <div class="ml-auto">
            <div class="me-2 fs--2 mars-blue" onClick={e => setHide(!hide)} role="button">
              view / hide
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class={`${open ? "collapse" : ""}`} id="collapseFilter">
          <div class="p-card rounded fs--2">
            <p class="mb-0">
              Please use the box below to capture the learnings you have gained during this stage of the value proposition development.
              <br/>(to be completed at the end of the stage).            
            </p>
          </div>
        </div>
        <div class={`${hide ? "collapse" : ""} card-body`}>
          <div class="p-card rounded fs--2">
            <ReactMde
              disablePreview={true}
              value={vpHandler.get.keyLearnings}
              onChange={e => handleChange(e, "keyLearnings")}
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
              toolbarCommands={[["bold", "italic", "unordered-list"]]}
              generateMarkdownPreview={(markdown) =>
                Promise.resolve(converter.makeHtml(markdown))
              }
              childProps={{
                writeButton: {
                  tabIndex: -1
                }
              }}
            />
            {// <textarea id="KeyLearnings">
            //   This phase of work was led by the Mars LaunchPad team through a combination of qualitative interviews and iterative quantitative tests as “fake door” offers.  We ran paid social campaigns driving to specific landing page experiences that outlined the concepts and measured KPIs to indicate intent to buy.
            //   The winning proposition was the base case of a simplified shopping experience with an optional quiz.  Clarity of offer and simple user experience/UX drove the highest ad click through rate (CTR) of 0.8%, the highest intent click rate of 4.7%, and the lowest cost per intent click of $30.  The tests showed that a charitable or “giving” proposition could help in engagement (ad CTR) and recommend a further test during MVP.
            // </textarea>
            }
          </div>
        </div>
      </div>
    </div>      
  )}