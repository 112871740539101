export const stageGateCopyList = {
    'benchmark' : "In the Benchmark column, we're providing benchmark values for certain KPIs that teams can use as guidance to set their own stage gate targets",
    'target': "In the Target column, at the start of the stage, Teams can enter their targets which they want to achieve over the course of the stage. At the end of the stage, Teams can report back results in the Value column",
    'value': "In the Value column, at the end of the stage, Teams report back achieved results against the targets they set themselves in the Target column",
    'valueType': "Below, please select whether the value entered is an 'actual' value (i.e. observed and measured) or a 'modeled' value (i.e. estimated based on assumptions)",
    'trend': "Below, please select whether this KPI is trending in the right direction over time over the duration of the current stage",
    'notApplicable': 'Please unselect if this KPI is not applicable to your value proposition, and use the Comment column to explain why',
    'comment': "You can use the Comment column to provide additional information as required.",


    'ideation': {
        header: "",
        aName: "",
        aSub: "",
        bName: "",
        bSub: "",
        cName: "",
        cSub: ""
    },
    'customerVal': {
        'header': "This stage is all about building deep understanding of your target audience by capturing user feedback and quickly validating your idea/proposition with customers (be they consumers, vets, or other stakeholders). In most cases this will consist of testing how much traffic and clicks your proposition drives with a simple landing page and small-scale ad campaign (often without selling the actual product or service yet)",
        'aName': "A. Stage gate targets",
        'aSub': "Use the table below to set stage gate targets at the start of the Customer Discovery & Validation stage (‘Target’ column), then report back achieved actuals at the end of the stage (‘Value column’). You can use the benchmark values for guidance.",
        'bName': "B. Tracked/modelled KPIs (no targets)",
        'bSub': "In addition, we recommend tracking/modelling the following KPIs at the Customer Discovery & Validation stage, but without necessarily setting targets at the start of the stage – because setting targets at this stage is either not feasible or not necessary.",
        'cName': "C. Additional KPIs",
        'cSub': "Feel free to add any additional KPIs that the proposition team is tracking at this stage."
    },
    'mvp': {
        'header': "This stage is where a functioning solution, but in a minimum viable format, will be tested in the market. Customers will be able to purchase a product or service and will have that product or service delivered. The aim is to acquire first customers and find product-market fit. At the same time, at the end of this stage you should be able to build a first view on unit economics and first P&L lines, as well as baseline a set of KPIs you’d want to improve over time (e.g. repeat purchases, churn, CAC)",
        'aName': "A. Stage gate targets",
        'aSub': "Use the table below to set stage gate targets at the start of the Build & Test MVP stage (‘Target’ column), then report back achieved actuals at the end of the stage (‘Value column’). You can use the benchmark values for guidance.",
        'bName': "B. Tracked/modelled KPIs (no targets)",
        'bSub': "In addition, we recommend tracking/modelling the following KPIs at the Build & Test MVP stage, but without necessarily setting targets at the start of the stage – because setting targets at this stage is either not feasible or not necessary.",
        'cName': "C. Additional KPIs",
        'cSub': "Feel free to add any additional KPIs that the proposition team is tracking at this stage."
    },
    'optimize': {
        'header': "At this stage, you have collected insights and feedback from the MVP phase, and now turn your attention to rounding out and optimizing the proposition, the product or service, the business model, the go-to-market model and customer acquisition funnel. The focus shifts from testing core concepts of the proposition to continuous optimization. Finding the right business model fit is key for this stage, as you try to set the foundations for a sustainable business, ready for rapid growth.",
        'aName': "Stage gate targets",
        'aSub': "Use the table below to set stage gate targets at the start of the Optimize stage (‘Target’ column), then report back achieved actuals at the end of the stage (‘Value column’). You can use the benchmark values for guidance. ",
        'bName': "",
        'bSub': "",
        'cName': "Additional KPIs",
        'cSub': "Feel free to add any additional KPIs that the proposition team is tracking at this stage."
    },
    'grow': {
        'header': "At this stage, you have optimized all the low-hanging fruits for your proposition and set the foundations for growth in the selected pilot market(s). The Grow stage is all about growing the customer base, building executional excellence through incremental improvements (focus first on CAC, then LTV optimization) and starting to pivot toward a long-term profitable business path. At the end of this stage, you should be ready to scale operationally and have a validated scaling plan.",
        'aName': "Stage gate targets",
        'aSub': "Use the table below to set stage gate targets at the start of the Grow stage (‘Target’ column), then report back achieved actuals at the end of the stage (‘Value column’). You can use the benchmark values for guidance.",
        'bName': "",
        'bSub': "",
        'cName': "Additional KPIs",
        'cSub': "Feel free to add any additional KPIs that the proposition team is tracking at this stage."
    },
    'scale': {
        'header': "In the Scale phase the incremental optimization continues ahead (focus shifts more toward optimizing LTV, as CAC is largely optimized), while the proposition is scaled across markets, channels, brands or other pathways. At the end of this stage you want to see sizable, profitable revenues (intrinsic value) and/or intimacy at scale with target audience (engagement value) and/or quality data collection at scale (data value).",
        'aName': "Stage gate targets",
        'aSub': "Use the table below to set stage gate targets at the start of the Scale stage (‘Target’ column), then report back achieved actuals at the end of the stage (‘Value column’). You can use the benchmark values for guidance.",
        'bName': "",
        'bSub': "",
        'cName': "Additional KPIs",
        'cSub': "Feel free to add any additional KPIs that the proposition team is tracking at this stage."
    },
}
