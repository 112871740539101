export const speciesList = [
  {
    label: "Cat",
    value: "Cat",
  },
  {
    label: "Dog",
    value: "Dog"
  },
  {
    label: "Cat & Dog",
    value: "Cat & Dog"
  }
]