import React, { useEffect, useState } from "react";
import { DropdownFieldFilterDeck } from "../Input/DropdownFieldFilterDeck";
import { DropdownFieldBrands } from "../Input/DropdownFieldBrands";
import CsvDownload from 'react-json-to-csv'
import {countryList} from './../Lists/countryList'
import { regionList } from "../Lists/regionList";
export const FilterDeckDiscover = ({ allBrands, setAllBrands, string, setString, minLengthCheck }) => {
  const [init, setInit] = useState(false);
  const [init2, setInit2] = useState(false);

  const [dataInit, setDataInit] = useState(false);
  const [open, setOpen] = useState(true);

  const [data, setData] = useState([]);
  const [dataBackup, setDataBackup] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [speciesOptions, setSpeciesOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  const [filterObject, setFilterObject] = useState(null)

  useEffect(() => {
    if (!init2) {
      //console.log(`filterdeck init: ${localStorage.getItem("filterObject")}`)
      setInit2(true); 

      if(localStorage.getItem("filterObject") === "null") {
        //console.log(`FO is null`)
        let obj = {
          "Brand": "",
          "Country": "",
          "Region": "",
          "Species": "",
          "Product": "",
        }
        setFilterObject({...obj})
      }
      else {
        //console.log(`FO is NOT null: ${localStorage.getItem('filterObject')}`)
        let obj = localStorage.getItem("filterObject")
        setFilterObject(JSON.parse(obj))
        Filter()
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init2]);
  
  useEffect(() => {
    localStorage.setItem("filterObject", JSON.stringify(filterObject));
    Filter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObject]);

  useEffect(() => {

    if(localStorage.getItem("filterObject") === "null") {
      //console.log(`FO is null`)
      let obj = {
        "Brand": "",
        "Country": "",
        "Region": "",
        "Species": "",
        "Product": "",
      }
      setFilterObject({...obj})
    }

    Filter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [string]);


  useEffect(() => {
    //console.log(allBrands.length, minLengthCheck)
    if (allBrands !== undefined && allBrands.length > 130) {
      setInit(true); 
      setData([...allBrands]);
      if(allBrands.length > 130){
        setDataBackup([...allBrands])
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBrands]);  

  useEffect(() => {
    console.log(allBrands.length)
    // console.log(`allBrands: ${allBrands.length}, data: ${data.length}`)
    if (data.length > 0 && !dataInit) {
      CreateBrandOptions();
      CreateCountryOptions();
      CreateRegionOptions();
      CreateSpeciesOptions();
      CreateProductOptions();
      setDataInit(true);
      Filter()
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const sortArray = (array) => {
    array.sort(function(a, b){
      if(a < b) { return -1; }
      if(a > b) { return 1; }
      return 0;
    })
    return array
  }

  const CreateBrandOptions = () => {
    console.log(`create brand options`)
    let array = [];
    let obj = { value: "", label: "All" }
    array.push(obj)
    // data.forEach(item => array.push(item._id));
    // array = Array.from(new Set(array));
    // setBrandOptions(array);
    
    data.forEach((item) => {
      obj = {
        label: item.brandName || item.name,
        value: item.cid || item.name
      }
      array.push(obj)
    })
    array = Array.from(new Set(array))
    setBrandOptions(array)
  };

  // const CreateCountryOptions = () => {
  //   let array = [];
  //   data.forEach(item => (item.country !== '' && array.push(item.country)));
  //   array = Array.from(new Set(array));

  //   setCountryOptions(sortArray(array));
  // };

  const CreateCountryOptions = () => {
    let array = []
    let fullCountryName = ""
    let obj = {}
    data.forEach(item => {
      if(item.country !== ""){
        obj = countryList.flat().find(country => country.Code === item.country)
        if(obj!== undefined) {
          fullCountryName = obj.Name
          array.push(fullCountryName)
        }
      }
    })
    array = Array.from(new Set(array));
    setCountryOptions(sortArray(array))
  }

  const CreateRegionOptions = () => {
    let array = []
    let obj = {}
    data.forEach(item => {
      obj = countryList.flat().find(country => country.Code === item.country)
      if(obj!== undefined) {
        //console.log(`country: ${item.country}, region: ${obj.Region}}`)
        array.push(obj.Region)
      }
    })
    array = Array.from(new Set(array));
    setRegionOptions(sortArray(array))
  }


  // const CreateRegionOptions = () => {
  //   let array = [];
  //   data.forEach(item => (item.region !== '' ? array.push(item.region) : ''));
  //   array = Array.from(new Set(array));

  //   setRegionOptions(sortArray(array));
  // };

  const CreateSpeciesOptions = () => {
    let array = ["Cat", "Dog", "Both"];

    setSpeciesOptions(array);
  };

  const CreateProductOptions = () => {
    let array = ["MMI", "MMNI", "CTS", "MTT"];

    setProductOptions(sortArray(array));
  };

 
  const CheckSpecies = (item) => {
    console.log(`speciesArray: ${item.speciesArray}, species: ${item.species}`)
    if(item.speciesArray !== undefined){
      if (filterObject.Species === "Both") {
        if(item.speciesArray.length === 2) return true
      }
      if (filterObject.Species === "Cat" && item.speciesArray.length < 2)  if(item.speciesArray.includes("cat")) return true
      if (filterObject.Species === "Dog" && item.speciesArray.length < 2)  if(item.speciesArray.includes("dog")) return true
      return false;
    }
    else{
      if (filterObject.Species === "Both") {
        if(item.species.length === 2) return true
      }
      if (filterObject.Species === "Cat")  if(item.species.includes("cat")) return true
      if (filterObject.Species === "Dog")  if(item.species.includes("dog")) return true
      return false;
    }
  }

  const CheckCountry = (item) => {
    
    let obj = {}
    if(item.country !== null){
    obj = countryList.flat().find(country => country.Code === item.country)
      if(obj !== undefined) {
        //console.log(item.country + ", " + obj.Name)
        if(obj.Name === filterObject.Country) return true
        else return false
      }        
        //if(item.country === obj.Code) console.log('yay')
    }
  }

  const CheckRegion = (item) => {
    let obj = {}
    if(item.country !== null){
    obj = countryList.flat().find(country => country.Code === item.country)
      if(obj !== undefined) {
        console.log(item.country + ", " + obj.Name)
        if(obj.Region === filterObject.Region) return true
        else return false
      }        
        //if(item.country === obj.Code) console.log('yay')
    }
  }

  // const CheckSpecies = (item) => {
  //   console.log(`item: ${JSON.stringify(item)}`)
  //   if (filterObject.Species === "Both") {
  //     if(item.cat && item.dog) return true
  //   }
  //   if (filterObject.Species === "Cat")  if(item.cat && !item.dog) return true
  //   if (filterObject.Species === "Dog")  if(!item.cat && item.dog) return true
  //   return false;
  // }

  const CheckProduct = (item) => {
    //console.log(`item: ${JSON.stringify(item)}`)
    if (filterObject.Product === "MMI")  return item.mmi
    if (filterObject.Product === "MMNI")  return item.mmni
    if (filterObject.Product === "MTT")  return item.cts
    if (filterObject.Product === "CTS")  return item.mtt

    return false;
  }

  const ResetFilter = () => {
    setFilterObject({ 
      Brand: "",
      Country: "",
      Region: "",
      Species:"",
      Product: "",
    })
    setString("");
  }

  const CheckString = (item) => {
    //if(item.brandName.toLowerCase().includes(string.toLowerCase())) return true
    if(item.brandName !== undefined){
      if(item.brandName.toLowerCase().includes(string.toLowerCase())) return true
      else return false
    }
    else if(item.brandName === undefined){
      //console.log(item.name)
      if(item.name.toLowerCase().includes(string.toLowerCase())) return true
      else return false
    }
  }

  const Filter = () => {
    if(filterObject !== null) {
      let _data = dataBackup;
      if (filterObject.Brand    !== "")   _data = _data.filter(item => item.cid         === filterObject.Brand);
      if (filterObject.Country  !== "")   _data = _data.filter(item => CheckCountry(item));
      //if (filterObject.Region   !== "")   _data = _data.filter(item => item.region      === filterObject.Region);
      if (filterObject.Region   !== "")   _data = _data.filter(item => CheckRegion(item));
      if (filterObject.Species  !== "")   _data = _data.filter(item => CheckSpecies(item));
      if (filterObject.Product  !== "")   _data = _data.filter(item => CheckProduct(item));

      _data = _data.filter(item => CheckString(item));

      setAllBrands([..._data]);
    }
  };
  
  return (
    <div class="card mb-3">
      <div class="card-header pb-1 bg-light">
        <div class="row flex-between-center">
          <div class="col-auto col-sm-6 col-lg-7">
            <h6 class="mb-0 text-nowrap py-2 py-xl-0">Filter deck</h6>
            <div class="me-2 fs--2 mars-blue" onClick={() => setOpen(!open)} role="button">
              <i class="fas fa-info-circle mr-1" />
              Quick guide
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class={`${open ? 'collapse' : ''}`} id="collapseFilter">
          <div class="p-card rounded fs--2">
            <p>
              The filters you set here are applicable to all the elements on the rest of this page. Select one or more
              of the following optional filters:
            </p>
            <ul>
              <li>Country → Select one or more country you like to see in this Control Tower</li>
              <li>Division → Select Royal Canin or Mars Pet care</li>
              <li>
                Channel → Select one of the following channels
                <br />
                <div class="row">
                  <div class="col-lg-2">
                    <span class="text-decoration-underline">Online owned</span>
                    <br />
                    <span class="fas fa-wifi fs--2 me-1" />
                    flagship store
                    <br />
                    <span class="fas fa-wifi fs--2 me-1" />
                    subscription
                    <br />
                    <span class="fas fa-wifi fs--2 me-1" />
                    marketplace
                    <br />
                    <br />
                    <span class="text-decoration-underline">Online platforms</span>
                    <br />
                    <span class="fas fa-wifi fs--2 me-1" />
                    shop in shop
                    <br />
                    <span class="fas fa-wifi fs--2 me-1" />
                    social media
                    <br />
                    <span class="fas fa-wifi fs--2 me-1" />
                    home delivery
                    <br />
                    <br />
                  </div>
                  <div class="col-lg-3">
                    <span class="text-decoration-underline">Offline</span>
                    <br />
                    <span class="fas fa-store fs--1 me-1" />
                    flagship store
                    <br />
                    <span class="fas fa-store fs--2 me-1" />
                    shop in shop
                    <br />
                    <span class="fas fa-store fs--2 me-1" />
                    pop-up store
                    <br />
                    <br />
                    <span class="text-decoration-underline">Online owned (B2B)</span>
                    <br />
                    <span class="fas fa-wifi fs--2 me-1" />
                    digital distributor
                    <br />
                    <br />
                  </div>
                </div>
              </li>
              <li>
                Species → Select one
                <br />
                <div class="row">
                  <div class="col-lg-1">
                    <span class="fas fas fa-cat fs--1 text-600 me-1" />
                    Cat
                    <br />
                  </div>
                  <div class="col-lg-1">
                    <span class="fas fas fa-dog fs--1 text-600 me-1" />
                    Dog
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="btn-group" />
        <div class="row">
          <DropdownFieldBrands title="Brand"   list={brandOptions}    setState={setBrandOptions}   filterObject={filterObject} setFilterObject={setFilterObject}/>
          <DropdownFieldFilterDeck title="Country"  width={"10"} minWidth={"10"} list={countryOptions}  setState={setCountryOptions} filterObject={filterObject} setFilterObject={setFilterObject}/>
          <DropdownFieldFilterDeck title="Region"  width={"10"} minWidth={"10"}  list={regionOptions}   setState={setRegionOptions}  filterObject={filterObject} setFilterObject={setFilterObject}/>
          <DropdownFieldFilterDeck title="Species"  width={"10"} minWidth={"10"} list={speciesOptions}  setState={setSpeciesOptions} filterObject={filterObject} setFilterObject={setFilterObject}/>
          <DropdownFieldFilterDeck title="Product"  width={"10"} minWidth={"10"} list={productOptions}  setState={setProductOptions} filterObject={filterObject} setFilterObject={setFilterObject}/>
          <div onClick={() => ResetFilter() } class="hoverhand mars-blue fs--2 col-xl-1 col-md-2 col-sm-4 mr-2 pt-2" style={{fontWeight: '500'}}>reset all</div>
          
          <div class="col-xl-2 col-md-3 col-sm-5">
            <CsvDownload  
              data={allBrands}
              filename="Competitor_Data.csv"
              style={{ //pass other props, like styles
                //background:"linear-gradient(to bottom, #c123de 5%, #a20dbd 100%)",
                backgroundColor:"#fff",
                border:"0px solid #a511c0",
                }} 
                >
              <div class="hoverhand mars-blue fs--2 mr-2 pt-2" style={{fontWeight: '500'}}>download data</div>
            </CsvDownload>
          </div>
          {// <DropdownFieldFilterDeck title="Brand"    list={brandOptions}     setState={setBrandOptions}/>
          // <DropdownFieldFilterDeck title="Country"  list={countryOptions}   setState={setCountryOptions}/>
          // <DropdownFieldFilterDeck title="Region"   list={regionOptions}    setState={setRegionOptions}/>
          // <DropdownFieldFilterDeck title="Species"  list={speciesOptions}   setState={setSpeciesOptions}/>
          // <DropdownFieldFilterDeck title="Product"  list={productOptions}   setState={setProductOptions}/>
          // <div onClick={() => ResetFilter() } class="ml-auto mr-2 hoverhand mars-blue fs--2" style={{fontWeight: '600'}}>reset</div>
          }</div>
      </div>
    </div>
  );
};
