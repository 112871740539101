export const getValueProps = `
query GetValueProps {
    GetValueProps {
      ValueProposition {
        _id
        stage
        valuePropositionName
        country
        division
        species
        dog
        cat
        both
        channel
        business
        specialIcon
        pluggedIntoBox
        uuid
        createdBy
        active
        perish
        eventHistory{
          date
          event
          decision
          fileName
          fileUuid
          fileType
          requester
        }
      }
    }
  }`;
  