import React, {useState, useEffect} from 'react';
import NewBrandsPagination from '../../components/table/NewBrandsPagination';


const NewBrandsTable = ({ data, setSelectedBrand, getCompanies }) => {
  const [init, setInit] = useState(false);
  const [page, setPage] = useState(0);
  const [tableItems, setTableItems] = useState([]);

  const [brandTableBrands, setBrandTableBrands] = useState([]);

  const [autofillList, setAutofillList] = useState([]);

  const tableColumns = [
    
    { columnName: '', id: '', isSortable: false, defaultSort: true, isNumeric: false },
    { columnName: 'Brand', id: 'name', isSortable: false, isNumeric: false },
    { columnName: 'Website', id: 'url', isSortable: false, isNumeric: false },
    { columnName: 'Decision', id: 'decision', isSortable: false, isNumeric: false },
    { columnName: '', id: '', isSortable: false, isNumeric: false }

    // { columnName: 'Country', id: 'country', isSortable: false, isNumeric: false },
    // { columnName: 'Region', id: 'region', isSortable: false, isNumeric: false },
    // { columnName: 'Species', id: 'species', isSortable: false, isNumeric: false },
    // { columnName: 'Product', id: 'product', isSortable: false, isNumeric: false },

  ];

  useEffect(() => {
    if (!init) {
      setInit(true);
      //brandAPI.GetBrands(setBrandTableBrands);
      //brandAPI.GetSocialAnalysisData(setBrandTableSocials);
      //brandAPI.GetSocialAnalysisWebTraffic(setBrandTableTraffic);
    }
  }, [init]);

  useEffect(() => {
    //console.log(`data ${JSON.stringify(data)}`)
    setBrandTableBrands(data)
    setPage(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // useEffect(() => {
  //   console.log(`brandTableSocials: ${JSON.stringify(brandTableSocials)}`)
  // }, [brandTableSocials]);

  useEffect(() => {
    if (brandTableBrands.length > 0) 
      fillTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandTableBrands]);

  useEffect(() => {
    if (tableItems.length > 0 ) {
      createSearchBarList();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableItems]);

  // const getSpecies = (item) => {
  //   if(item.cat && item.dog) return "Both"
  //   if(item.cat && !item.dog) return "Cat"
  //   if(!item.cat && item.dog) return "Dog"
  // }
  // const getProduct = (item) => {
  //   if(item.mmi) return "MMI"
  //   if(item.mmni) return "MMNI"
  //   if(!item.cts) return "CTS"
  //   if(!item.mtt) return "MTT"
  // }

  const fillTable = () => {
    let table = [];
    let obj = {}

    data.forEach((item) => {
      if(item.archived === false) {
        obj = {
          companyUrl:        `${item.companyUrl}`,
          companyName:       `${item.companyName}`,
          archived:          `${item.archived}`,
          accepted:          `${item.accepted}`,
          uuid:              `${item.uuid}`
          // region:     `${item.region}`,
          // species:    getSpecies(item),
          // product:    getProduct(item),
          // revenue:    `${item.revenue}M`,
          // traffic:    getTraffic(item),
          // delta1:     `-`,
          // followers:  getSocial(item),
          // delta2:     `-`,
          // socials:    `-`,
        }
        table.push({...obj})
      }
    })
    setTableItems([...table]);
  }
  

  const createSearchBarList = () => {
    let _data = tableItems;
    let _list = [];

    _data.forEach((item) => {
      let object = {
        name: item.name,
        cid: item.image,
      };
      _list.push({ ...object });
    });
    setAutofillList([..._list]);
  };

  return ( 
    <>
    {//<FilterDeckDiscover tableItems={tableItems} setTableItems={setTableItems}/>
    }
    <NewBrandsPagination getCompanies={getCompanies} page={page} setPage={setPage} setSelectedBrand={setSelectedBrand} columns={tableColumns} tableItems={tableItems} itemsPerPage={4} setTableItems={setTableItems} autofillList={autofillList}/>
    </>
  )
};

export default NewBrandsTable;

// fill table stuff 
// const fillTable = () => {
//   let table = [];
//   // let identifyer = brandTableSocials;
//   // if (brandTableBrands.length > identifyer.length) identifyer = brandTableBrands;
//   // if (brandTableTraffic.length > identifyer.length) identifyer = brandTableTraffic;
  
//   let identifyer = brandTableBrands;

//   for (let i = 0; i < identifyer.length ; i++){
//     console.log(`identifyer: ${JSON.stringify(identifyer[i])}`)
//     let currentCompany = identifyer[i]._id;
    
//     let socialCompany = brandTableSocials.filter(item => item._id === currentCompany);
//     let brandCompany = brandTableBrands.filter(item => item._id === currentCompany);
//     let trafficCompany = brandTableTraffic.filter(item => item._id === currentCompany);

//     let image = "-";
//     let name = "-";
//     let country = "-";
//     let region = "-";
//     let species = [];
//     let product = "-";
//     let revenue = "0";
//     let traffic = "0";
//     let trafficDelta = "0";
//     let followers = "0";
//     let followersDelta = "0";
//     let socials = [];
//     let uuid = "";
//     //-------------------------------------------------
//     image = identifyer[i]._id;
//     name = identifyer[i]._id;
    
//     if ( brandCompany.length > 0 ) {
//       brandCompany = brandCompany[0];
//       country = brandCompany.country;
//       region  = brandCompany.region;
//       revenue = brandCompany.revenue;
//       uuid    = brandCompany.uuid;

//       if ( brandCompany.cat )   species.push("Cat");
//       if ( brandCompany.dog )   species.push("Dog");
//       if ( species.length > 1)  species = ["Both"];

//       if (brandCompany.mmi)       product = "MM-I";
//       else if (brandCompany.mmni) product = "MM-NI";
//       else if (brandCompany.cts)  product = "CTS";
//       else if (brandCompany.mtt)  product = "MTT";
//     } 

//     if ( trafficCompany.length > 0 ) { 
//       trafficCompany  = trafficCompany[0];
//       traffic         = trafficCompany.visits;
//       trafficDelta    = trafficCompany.change;
//       uuid            = trafficCompany.uuid;
//     }

//     if ( socialCompany.length > 0 ) {
//       socialCompany   = socialCompany[0];
//       followers       = socialCompany.currentTotalFollowers;
//       followersDelta  = socialCompany.followerChange;
//       uuid            = socialCompany.uuid;
//     }

//     socials = socials;
//     //-------------------------------------------------
//     let data = {
//       image:      `${image}`,
//       name:       `${name}`,
//       country:    `${country}`,
//       region:     `${region}`,
//       species:    `${species}`,
//       product:    `${product}`,
//       revenue:    `${revenue}M`,
//       traffic:    `${traffic}K`,
//       delta1:     `${trafficDelta}`,
//       followers:  `${followers}K`,
//       delta2:     `${followersDelta}`,
//       socials:    `${socials}`,
//       uuid:       `${uuid}`,
//     };
//     table.push(data);
//   }

//   //-------------------------------------------------------
//   setTableItems([...table]);
// }