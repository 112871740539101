import React from "react";
import { Input } from "reactstrap";
//import 'assets/scss/custom/_individualVP.scss';
// import { hoverCopyList } from "../../components/Lists/hoverCopyList";


export const InputFieldModal = ({customInput, setCustomInput, title, field}) => {

  const handleChange = (e) => {
    let _input = customInput
    _input[field] = e.target.value
    setCustomInput({..._input})
  }
 
  return(
    <Input 
      className="" 
      type="textfield" 
      name={title.toLowerCase()}
      style={{fontSize: '12px', height: '28px'}}
      onChange={e=>handleChange(e)}
      placeholder={`Define ${title} ...`}
      value={customInput[field]}
    />
  )
}