import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PageTitle from '../../components/header/PageTitle';
import * as api from '../../components/API/SocialAnalysisAPI';
import * as brandAPI from '../../components/API/BrandsTableAPI';
import { FilterDeckDiscover } from "../../components/Cards/FilterDeckDiscover";

import ScatterLayout from '../../components/ECharts/Layout/ScatterLayout';
import HorizontalBarChartLayout from '../../components/ECharts/Layout/HorizontalBarChartLayout';
import BrandsTable from './BrandsTable';


const Benchmark = () => {
  //const [autofillList, setAutofillList] = useState([]);
  const [allBrands, setAllBrands] = useState([]); 
  const [combinedBrands, setCombinedBrands] = useState([]);
  const [string, setString] = React.useState("");

  const [engagementGrowthObject, setEngagementGrowthObject] = useState([]);
  const [followersGrowthObject, setFollowersGrowthObject] = useState([]);
  const [trafficGrowthObject, setTrafficGrowthObject] = useState([]);
  const [postsPerMonth, setPostsPerMonth] = useState([]);
  const [engagementRate, setEngagementRate] = useState([]);
  //const [webTrafficObject, setWebTrafficObject] = useState(null);
  const [webTrafficArray, setWebTrafficArray] = useState([]);
  const [socialWebData, setSocialWebData] = useState([]);
  const [init, setInit] = React.useState(false);
  const [allVPs, setAllVPs] = React.useState([])
  const [minLengthCheck, setMinLengthCheck] = React.useState(null);
  //const [brands, setBrands] = useState(null);
  //const [brandTableSocials, setBrandTableSocials] = useState([]);

  useEffect(() => {
    if (!init) {
      setInit(true);
      api.GetSocialAnalysisWebTraffic(setWebTrafficArray); // createTrafficGrowthChart with [webTrafficObject as parameter]
      api.GetSocialAnalysisData(setSocialWebData); // createFollowersGrowthChart, createEngagementGrowthChart, createFollowersArrays with [socialWebData as parameter]
      //api.GetSocialAnalysisBrands(setBrands); // createAutofillList with [brands as parameter]
      
      brandAPI.GetVPs(setAllVPs);
      brandAPI.GetManyBrand(setAllBrands);
      
      //brandAPI.GetSocialAnalysisData(setBrandTableSocials);
    }
  }, [init]);

  useEffect(() => {
    console.log(`all brands: ${allBrands.length}`)
    setMinLengthCheck(allBrands.length>0?allBrands.length:1000);
    MergeVPsWithBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBrands, allVPs]);

  const MergeVPsWithBrands = () => {
    let _brands = allBrands
    allVPs.forEach(vp => {
      if(vp.showDiscover) {
        _brands.push({...vp})
      }
    })
    setCombinedBrands([..._brands])
  }

  useEffect(() => {
    if (webTrafficArray !== [] && allBrands !== []) {
      createTrafficGrowthChart();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webTrafficArray, allBrands]);

  useEffect(() => {
    if (socialWebData !== [] && allBrands !== []) {
      createFollowersGrowthChart();
      createEngagementGrowthChart();
      createPostsPerMonthChart();
      createEngagementRate();
      //console.log(`social web data: ${JSON.stringify(socialWebData)}`)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialWebData, allBrands]);

  useEffect(() => {
    if (socialWebData !== null && webTrafficArray !== null) {
      //socialWebData
      createFollowersGrowthChart();
      createEngagementGrowthChart();
      createPostsPerMonthChart();
      createEngagementRate();

      //webTrafficArr
      createTrafficGrowthChart();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedBrands]);

  const createTrafficGrowthChart = () => {
    //console.log(`web traffic: ${JSON.stringify(webTrafficArray)}`)
    //let webTrafficData = webTrafficArray;
    let _trafficGrowthObject = [];
    let brandObject = {}
    let data = {};

    combinedBrands.forEach(item => {
      //console.log(item.brandName)
      if(item.name != "Pepette"){
        brandObject = webTrafficArray.find(brand => brand.cid === item.cid)
        //console.log(brandObject)
        if(brandObject !== undefined){
          data = {
            name: item.brandName,
            value: [brandObject.visits, brandObject.change]
          };
          _trafficGrowthObject.push({ ...data });
        }
      }
    })

    // webTrafficArray.forEach(brand => {
    //   console.log(`brand: ${JSON.stringify(brand.cid)}`)
    //   data = {
    //     name: brand.cid,
    //     value: [brand.visits, brand.change]
    //   };
    //   _trafficGrowthObject.push({ ...data });
    // });

    setTrafficGrowthObject(...[_trafficGrowthObject]);
  };

  const getFollowerChange = (brand) => {
    let current = brand.previousInstagramFollowers
    let previous = brand.currentInstagramFollowers
    let change = 0

    change = (((previous * 100) / current ) - 100).toFixed(1)
    return change
    //if(change !== isNaN()) console.log(`change; ${change}`)
  }

  const createFollowersGrowthChart = () => {
    //console.log(`social web data: ${JSON.stringify(socialWebData)}`)
    //let _data = socialWebData;
    let _followersGrowthObject = [];
    let data = {};
    let brandObject = {}

    combinedBrands.forEach(item => {
      brandObject = socialWebData.find(brand => brand.cid === item.cid)
      if(brandObject !== undefined){
        data = {
          name: item.brandName,
          value: [brandObject.currentTotalFollowers, getFollowerChange(brandObject)]
        };
        _followersGrowthObject.push({ ...data });
      }
    })

    // socialWebData.forEach(brand => {
    //   //console.log(`brand: ${JSON.stringify(brand)}`)
    //   data = {
    //     name: brand.cid,
    //     value: [brand.currentTotalFollowers, getFollowerChange(brand)]
    //   };
    //   _followersGrowthObject.push({ ...data });
    // });
    
    setFollowersGrowthObject([..._followersGrowthObject]);
  };

  const createEngagementGrowthChart = () => {
    let _engagementGrowthObject = [];
    let data = {};
    let brandObject = {} 

    combinedBrands.forEach(item => {
      brandObject = socialWebData.find(brand => brand.cid === item.cid)
      
      if(brandObject !== undefined){
        data = {
          name: item.brandName,
          value: brandObject.interactionsPerPost
        };
        _engagementGrowthObject.push({ ...data });
      }
    })

    // socialWebData.forEach(brand => {
    //   data = {
    //     name: brand.cid,
    //     value: brand.interactionsPerPost
    //   };
    //   _engagementGrowthObject.push({ ...data });
    // });
    setEngagementGrowthObject(...[_engagementGrowthObject]);
  };

  const createPostsPerMonthChart = () => {
    //console.log(`posts per month: ${JSON.stringify(postsPerMonth)}`)
    let _postsPerMonth = [];
    let data = {};
    let brandObject = {} 

    combinedBrands.forEach(item => {
      brandObject = socialWebData.find(brand => brand.cid === item.cid)
      
      if(brandObject !== undefined){
        data = {
          name: item.brandName,
          value: brandObject.monthlyPosts
        };
        _postsPerMonth.push({ ...data });
      }
    })

    // socialWebData.forEach(brand => {
    //   data = {
    //     name: brand.cid,
    //     value: brand.monthlyPosts
    //   };
    //   _postsPerMonth.push({ ...data });
    // });
    setPostsPerMonth(...[_postsPerMonth]);
  };

  const createEngagementRate = () => {
    let _engagementRate = [];
    let data = {};
    let brandObject = {} 

    combinedBrands.forEach(item => {
      brandObject = socialWebData.find(brand => brand.cid === item.cid)
      
      if(brandObject !== undefined){
        data = {
          name: item.brandName,
          value: brandObject.engagementRate
        };
        _engagementRate.push({ ...data });
      }
    })
    // socialWebData.forEach(brand => {
    //   data = {
    //     name: brand.cid,
    //     value: brand.engagementRate
    //   };
    //   _engagementRate.push({ ...data });
    // });
    setEngagementRate(...[_engagementRate]);
  };

  // const CreateAutofillList = () => {
  //   let keys = [];

  //   brands.forEach(item => {
  //     keys.push(item.brandName);
  //   });

  //   setAutofillList([...keys]);
  // };


  useEffect(() => {
    //console.log(`allBrands: ${JSON.stringify(allBrands)}`)
  }, [allBrands]);

  const GetCompaniesQuery = `
  query GetCompanies {
    GetCompanies {
      companyName
      companyUrl
    }
  }
  `;

  const getCompanies = (setter) => {
    console.log(`getting companies`)
    axios
    .post(process.env.REACT_APP_API, {
      query: GetCompaniesQuery,
      variables: {}
      })
      .then(res => {
        //console.log(`newbrands: ${JSON.stringify(res.data.data.GetCompanies)}`)
        setter([...res.data.data.GetCompanies])
      })
        .catch(err => console.log(err))
  }

  return ( 
    <div class="pt-3">

      <PageTitle title={'D2C Accelerator'} subtitle={'Benchmark with the competition'} />
      <FilterDeckDiscover setString={setString} string={string} allBrands={combinedBrands} setAllBrands={setCombinedBrands} minLengthCheck={minLengthCheck}/>
      
      <BrandsTable setString={setString} string={string} data={combinedBrands} getCompanies={getCompanies}/>
      
      {/*<PaginationTable columns={tableColumns} items={tableItems} itemsPerPage={4} />*/}

      <div class="row">
        <div class="col-md-6">
          <ScatterLayout
            data={trafficGrowthObject}
            yAxis={'Web traffic growth (%)'}
            xAxis={'avg visitors (#K)'}
            title={'Web Traffic and Growth (%)'}
          />
        </div>
        <div class="col-md-6">
          <ScatterLayout
            data={followersGrowthObject}
            yAxis={'growth (%)'}
            xAxis={'followers (#K)'}
            title={'Social Media Followers and Growth (%)'}
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <HorizontalBarChartLayout data={postsPerMonth} title={'Social media activity:'} subtitle={'posts per month (#)'} height={'300px'} />
        </div>
        <div class="col-md-4">
          <HorizontalBarChartLayout data={engagementGrowthObject} title={'Social media interaction:'} subtitle={'reactions per post (#)'} height={'300px'} />
        </div>
        <div class="col-md-4">
          <HorizontalBarChartLayout data={engagementRate} title={'Social media engagement:'} subtitle={'engagement rate (%)'} height={'300px'} />
        </div>
      </div>
    </div>
  );
};

export default Benchmark;
