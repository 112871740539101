export const socialAnalysisData = `
query GetSocials {
  GetSocials {
    Socials {
      _id
      cid
      uuid

      currentTwitterFollowers
      previousTwitterFollowers
      previousPreviousTwitterFollowers
      tweets
      tweetsDelta
      favorites
      favoritesDelta

      currentTiktokFollowers
      previousTiktokFollowers
      previousPreviousTiktokFollowers
      likes
      likesDelta
      uploads
      uploadsDelta

      currentInstagramFollowers
      previousInstagramFollowers
      previousPreviousInstagramFollowers
      avgLikes
      avgComments

      currentFacebookLikes
      previousFacebookLikes
      previousPreviousFacebookLikes

      currentYoutubeLikes
      previousYoutubeLikes
      previousPreviousYoutubeLikes
      views
      viewsDelta
      subs
      subsDelta

      currentTotalFollowers
      previousTotalFollowers
      previousPreviousTotalFollowers
      currentEngagement
      previousEngagement
      previousPreviousEngagement
      
      followerChange
      engagementChange
      
      currentAveragePosts
      previousAveragePosts
      previousPreviousAveragePosts
      
      interactionsPerPost
      monthlyPosts
      engagementRate
    }
  }
}
`