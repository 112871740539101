import React, { useEffect, useState } from 'react';
import * as api from '../../components/API/BrandOverviewAPI';
import * as brandAPI from '../../components/API/BrandsTableAPI';

import BrandsTable from './BrandsTable';
import NewBrandsTable from './NewBrandsTable';

import axios from "axios";

import PageTitle from '../../components/header/PageTitle';
import CompanyProfile from './CompanyProfile';
import CompanySocialMedia from './CompanySocialMedia';
import { FilterDeckDiscover } from '../../components/Cards/FilterDeckDiscover';

const BrandOverview = (props) => {
  const [init, setInit] = useState(false);
  const [allBrands, setAllBrands] = useState([]);
  const [combinedBrands, setCombinedBrands] = useState([]);
  const [allVPs, setAllVPs] = useState([]);
  const [newBrands, setNewBrands] = useState([]);
  const [brand, setBrand] = useState(null);
  const [social, setSocial] = useState([]);
  const [traffic, setTraffic] = useState([]);
  const [string, setString] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [minLengthCheck, setMinLengthCheck] = React.useState(null);


  useEffect(() => {
    // if(!combinedBrands.find(obj => obj.name === decodeURIComponent(selectedBrand))){
    //   api.GetBrandSpecific(setBrand, selectedBrand);
    //   api.GetSocialSpecific(setSocial, selectedBrand);
    //   api.GetTrafficSpecific(setTraffic, selectedBrand);
    // }
    // else setBrand(combinedBrands.find(obj => obj.name === decodeURIComponent(selectedBrand)))
    // console.log(`selected brand has cvhanged to: ${selectedBrand}`)
    // console.log(`combinedBrands: ${combinedBrands.length} `)
    if(selectedBrand !== undefined){
      let _brand = {}
      _brand = combinedBrands.find(obj => obj.cid === selectedBrand) || combinedBrands.find(obj => obj.name === decodeURIComponent(selectedBrand))
      setBrand({..._brand})
      api.GetSocialSpecific(setSocial, selectedBrand);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrand, combinedBrands]);

  useEffect(() => {
    //console.log(`brand: ${props.match.params.brand}`)
    if (!init) {
      if(props.match.params.brand !== "all") setSelectedBrand(props.match.params.brand)

      setInit(true);      
      brandAPI.GetVPs(setAllVPs);
      brandAPI.GetManyBrand(setAllBrands); //GetBrands
      
      getCompanies()
      //MergeVPsWithBrands()
      //setSelectedBrand(props.match.params.brand)
      // api.GetBrandSpecific(setBrand, props.match.params.brand);
      // api.GetSocialSpecific(setSocial, props.match.params.brand);
      // api.GetTrafficSpecific(setTraffic, props.match.params.brand);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]);

  
  useEffect(() => {
    setMinLengthCheck(allBrands.length>0?allBrands.length:1000);
    MergeVPsWithBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBrands, allVPs]);

  const MergeVPsWithBrands = () => {
    let _brands = allBrands
    //console.log(allBrands)
    if(allVPs.length > 0 && allBrands.length > 0){
      allVPs.forEach(vp => {
        if(vp.showDiscover) {
          _brands.push({...vp})
        }
      })

      setCombinedBrands([..._brands])
    }
  }

  const GetCompaniesQuery = `
  query GetCompanies {
      GetCompanies {
        companyName
        companyUrl
        accepted
        archived
        uuid
      }
    }
  `;

  const getCompanies = (setter) => {
    axios
    .post(process.env.REACT_APP_API, {
      query: GetCompaniesQuery,
      variables: {}
      })
      .then(res => {
        //console.log(`newbrands: ${JSON.stringify(res.data.data.GetCompanies)}`)
        setNewBrands([...res.data.data.GetCompanies])
        if(setter) setter([...res.data.data.GetCompanies])
      })
        .catch(err => console.log(err))
  }

  const display = () => {    
    if(brand !== null) {
      if(brand.cid !== undefined || brand.name !== undefined) return true
    }
    else return false
  }

  // const checkAddresses = () => {
  //   console.log(`addresses: `)
  //   allBrands.forEach((brand) => (console.log(`addresses: ${JSON.stringify(brand)}`)))
  // }

  return (
    <div class="pt-3">
      <PageTitle title={'D2C Accelerator'} subtitle={'Find your competitor insights'} />
      <FilterDeckDiscover string={string} setString={setString} allBrands={combinedBrands} setAllBrands={setCombinedBrands} minLengthCheck={minLengthCheck}/>
      <BrandsTable string={string} setString={setString} data={combinedBrands} setSelectedBrand={setSelectedBrand} getCompanies={getCompanies}/>
      <NewBrandsTable data={newBrands} setSelectedBrand={setSelectedBrand} getCompanies={getCompanies}/>

      {display() === true &&
        <>
          <CompanyProfile     brand={brand} setBrand={setBrand}  social={social} traffic={traffic}  selectedBrand={selectedBrand} />
          
          <CompanySocialMedia brand={brand} setBrand={setBrand}  social={social} traffic={traffic}  selectedBrand={selectedBrand}/>
          
        </>
      }

      <div style={{height: '1000px'}}></div>

    </div>
  );
};

export default BrandOverview;
