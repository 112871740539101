import React from 'react';
import * as api from "../../components/API/IndividualVpAPI";
import moment from "moment"
import { useOktaAuth } from "@okta/okta-react";

import { useHistory } from 'react-router-dom';
// import { useLocation } from "react-router-dom";

import { Files } from "../../components/Cards/VPCards/Files";
import { Funding } from "../../components/Cards/VPCards/Funding";
import { KeyLearnings } from "../../components/Cards/VPCards/KeyLearnings";
import { NextSteps } from "../../components/Cards/VPCards/NextSteps";
import { Proposition } from "../../components/Cards/VPCards/Proposition";
import { SaveEditBar } from "../../components/Cards/VPCards/SaveEditBar";
import { ReviewBar } from "../../components/Cards/VPCards/ReviewBar";
import { StageGate } from "../../components/Cards/VPCards/StageGate";
import { TeamDetails } from "../../components/Cards/VPCards/TeamDetails";
import { ValueModel } from "../../components/Cards/VPCards/ValueModel";
import { ValuePropositionDetails } from "../../components/Cards/VPCards/ValuePropositionDetails";
import PageTitle from "../../components/header/PageTitle";
import { pageDataEmpty } from "../../components/Lists/pageDataEmpty";
import { getOneVpQuery } from '../../components/Lists/getOneVPQuery';
import NotificationAlert from 'react-notification-alert';
import { HistoryOverview } from '../../components/Cards/VPCards/HistoryOverview';

const ViewEditVP = (props) => {
  const { authState, oktaAuth } = useOktaAuth();

  const [init, setInit] = React.useState(false);
  const [hashInit, setHashInit] = React.useState(false);
  const [vpObject, setVPObject] = React.useState(null);
  const [hash, setHash] = React.useState("");
  let history = useHistory()
  

  var md5 = require('md5');

  const inputEl = React.useRef(null)
  
  const vpHandler = {
    set: setVPObject,
    get: vpObject,
    ref: inputEl,
    hash: hash,
    setHash: setHash,
    action: api.getAction(props.match.params),
    uuid: api.getUuid(props.match.params),
    history: history
  }

//console.log(vpHandler.get)

  const createHash = () => {
    if(vpObject!==null) setHash(md5(JSON.stringify(vpObject)))
  }

  React.useEffect(() => {
    if (!init) {
      setInit(true);
      if(vpHandler.action === "add") setVPObject({...pageDataEmpty})
      else api.getOneVp(getOneVpQuery, vpHandler.uuid, vpHandler)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init]); 

  React.useEffect(() => {
    if(vpObject !== null && !hashInit){
      console.log(`vpObject: ${JSON.stringify(vpHandler.get.archived)}`)
      setHashInit(true);
      createHash() 
  } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vpObject]); 

  React.useEffect(() => {
    if(vpHandler.action === "add") {
      let obj = {
        date: moment().format("MMM Do YYYY"), 
        event: `Value Proposition Created`, 
        decision: "N/A", 
        requester: authState.idToken.claims.name,
        fileName: `N/A`, 
        fileUuid: "N/A",
        fileType: "N/A"
      }
      if(pageDataEmpty.eventHistory.length < 1) pageDataEmpty.eventHistory.push(obj)
      setVPObject({...pageDataEmpty})
      
    }
    else api.getOneVp(getOneVpQuery, api.getUuid(props.match.params), vpHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vpHandler.action]); 

  
  return (
    <>
      {vpObject !== null &&<>
      <div class="pt-3 pl-3">
        <PageTitle title="D2C Accelerator" subtitle="Manage value propositions" sticky={false}/>
      </div>

      <div class={`${window.innerWidth>991?"ml-3 mr-3":""} px-3`} style={{
        backgroundColor: "white",
        position: "sticky", 
        top: `${window.innerWidth>991?"0":"57px"}`, 
        zIndex: "100",
        boxShadow: "0px 5px 10px #ccc"
      }}> 
        {vpHandler.action === "review" ?           
          <ReviewBar vpHandler={vpHandler} />
        : 
          <SaveEditBar vpHandler={vpHandler} />
        }
      </div>
        <div class="px-3">
          <NotificationAlert ref={inputEl} />
          
          <ValuePropositionDetails vpHandler={vpHandler}/> 
          <TeamDetails vpHandler={vpHandler}/>
          <Proposition vpHandler={vpHandler}/>
          <div class="row">
            <div class="col col-12 col-lg-3">
              <Files vpHandler={vpHandler}/>
            </div>
            <div class="col col-12 col-lg-9">
              <Funding vpHandler={vpHandler}/>
            </div>
          </div>
          <ValueModel vpHandler={vpHandler}/>
          <StageGate vpHandler={vpHandler}/>
          <KeyLearnings vpHandler={vpHandler}/>
          <NextSteps vpHandler={vpHandler}/>
          <HistoryOverview vpHandler={vpHandler}/>
        </div>
      </>}
    </>
  );
};

export default ViewEditVP;
