import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import RaindropBarChart from '../RaindropBarChart';

const RaindropBarChartLayout = ({ data }) => {
  return (
    <Row>
      <Col xl="12">
        <Card>
          <CardBody>
            <div className="chart">
              <RaindropBarChart data={data} />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
export default RaindropBarChartLayout;
