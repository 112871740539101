import React from "react";

import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import { lookUpList } from "../../Lists/lookUpList";
import * as api from "../../API/IndividualVpAPI";
import { defaultPosKpiList } from "../../Lists/defaultPosKpiList";
import { allKPIList } from "../../Lists/allKPIList";
import { DropdownFieldValueModel } from "../../Input/DropdownFieldValueModel";

export const ValueModel = ({vpHandler}) => {
  const [open, setOpen] = React.useState(true);
  const [hide, setHide] = React.useState(false);
  const [selected, setSelected] = React.useState("");
  const [selectedTab, setSelectedTab] = React.useState("preview");
    const [kpiObject, setKpiObject] = React.useState({...defaultPosKpiList});

  
  React.useEffect(() => {
    if(vpHandler.action === "view" || vpHandler.action === "review") setSelectedTab("preview")
    else setSelectedTab("write")
  }, [vpHandler.action]);

  const inputAffix = (kpi) => {
    if(kpi !== null){
      if(kpi.includes("%")) return "stage-procent"
      if(kpi.includes("$")) return "stage-dollar"
      if(kpi.includes(":")) return "stage-compare"
      if(kpi.includes("#")) return "stage-number"
      else return ""
    }
    else return ""
  }

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
  });
  
  const handleChange = (e, handle) => {
    let vpObject = vpHandler.get
    vpObject.sovDescription[handle] = e
    vpHandler.set({...vpObject})
  }

  const getValue = (handle) => {
    let vpObject = vpHandler.get
    return vpObject.sovDescription[handle]
  }

  const getSelected = (handle, field) => {
    let vpObject = vpHandler.get
    if(vpObject.valueModel[handle] === field) return true
    else return false
  }

  const changeRole = (e, handle) => {
    let vpObject = vpHandler.get
    vpObject.valueModel[handle] = e.target.value
    vpHandler.set({...vpObject})
  }

  const getKpiValue = (sov, kpi, year) => {
    let _object = vpHandler.get
    let _item = _object.metrics.find(item => item.kpi === kpi)
    if (_item !== undefined) return _item[year]
  }

  // const updateKpi = (e, sov, kpi, year) => {
  //   let vpObject = vpHandler.get
  //   vpObject.metrics[sov][kpi][year] = e.target.value
  //   vpHandler.set({...vpObject})
  // }

  const sovChange = (e, sov, kpi, year) => {
    let _object = vpHandler.get
    let _metric = _object.metrics.find(element => element.kpi === kpi) || {}
    _metric['sov'] = sov
    _metric['kpi'] = kpi
    _metric['unit'] = '-'
    _metric[year] = e.target.value
    if(!_object.metrics.find(element => element.kpi === kpi)) _object.metrics.push({..._metric})
    vpHandler.set({..._object})
  }

  const removeKPI = (sov, kpi) => {
    removeKpiFromSetup(sov, kpi)
    removeKpiFromObject(sov, kpi)
  }

  const removeKpiFromObject = (sov, kpi) => {
    let _object = vpHandler.get
    let index = _object.metrics.findIndex(element => element.kpi === kpi)
    if (index !== -1) {
      _object.metrics.splice(index, 1)
    }
    vpHandler.set({..._object})
  }

  const removeKpiFromSetup = (sov, kpi) => {
    let _kpi = kpiObject
    let index = _kpi[sov].findIndex(element => element === kpi)
    if (index !== -1) {
      _kpi[sov].splice(index, 1)
    }
    setKpiObject({..._kpi})
     api.notify(`Removed ${lookUpList[kpi]} from list.`, vpHandler.ref, "success")
  }

  // const addKPI = (kpiName, sov, notify) => {
  //   let _array = kpiObject
  //   let _selected = kpiName
  //   _array[sov] = kpiObject[sov] || []
  //   if (_array[sov].indexOf(_selected) > -1) {
  //     if(notify) api.notify(`${lookUpList[kpiName]} already in list.`, vpHandler.ref, "danger")
  //   } 
  //   else {
  //      _array[sov].push(_selected)
  //      if(notify) api.notify(`Added ${lookUpList[_selected]} to list.`, vpHandler.ref, "success")
  //   }
  //   setKpiObject({..._array})
  // }

  const checkDisplay = (kpi) => {
    if(!api.checkDisabled(vpHandler)){
      if(defaultPosKpiList.defaultKPI.includes(kpi)) return false
      else return true
    }
    else return false
  }

  return(
    
    <div class="card mb-3">
      <div class="card-header pb-1 bg-light">
        <h6 class="mb-0">Value Model and Picture of success</h6>
        <div class="row col m-0 p-0">
          <div>
            <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
              <i class="fas fa-info-circle mr-1"/>
              Quick help & legend
            </div>
          </div>
          <div class="ml-auto">
            <div class="me-2 fs--2 mars-blue" onClick={e => setHide(!hide)} role="button">
              view / hide
            </div>
          </div>
        </div>
      </div>
      <div class={`${open ? "collapse" : ""}`} id="collapseFilter">
        <div class="p-card rounded fs--2">
          <p>
            Use the entry fields below to describe the value model of this VP (intrinsic, engagement and data value), i.e. how will the proposition generate value for Mars. Use the table on the right to show the longer-term ambition of your value proposition.<br />
          </p>
          <strong>Value driver role</strong> → Select which value drivers are the most important to your proposition (primary driver, secondary or not relevant):
          <ul>
            <li>Intrinsic value: stand-alone, financial value</li>
            <li>Engagement value: value from 1-to-1 engagement with customers (pet parents, vets, or others)</li>
            <li>Data value: value from collection and monetization of data</li>
          </ul>          
          <strong>Value creation potential</strong> → Describe in words the proposition's value creation potential along these three dimensions (intrinsic, engagement, data)
          
          <strong>Long-term ambition and glidepath</strong> → Use the table to set your long-term ambition along some key target metrics (3 years after launch) from Ideation stage, add glidepath at later stages (usually MVP stage)
          Use the Route to Scale tab to describe how you plan to expand and scale the proposition. Include new markets, products, channels, brands or other relevant scaling paths. Provide the total addressable market size for your proposition.
          
          Select the tab for input on each respective source of value and route to scale.
          
          <hr/>
        </div>
      </div>
      <div class={`${hide ? "collapse" : ""} card-body`}>
        <div class="min-vh-30  fs--2">
          <p>Select the tab for input on each respective proposition part</p>
          <div class="tab">
            <button 
              onClick={e => setSelected("intrinsic")}
              style={{ backgroundColor: `${selected === "intrinsic" ? "#dddddd" : ""}` }} 
            >
              Intrinsic
            </button>
            <button 
              onClick={e => setSelected("engagement")}
              style={{ backgroundColor: `${selected === "engagement" ? "#dddddd" : ""}` }} 
            >
              Engagement
            </button>
            <button 
              onClick={e => setSelected("data")}
              style={{ backgroundColor: `${selected === "data" ? "#dddddd" : ""}` }} 
            >
              Data
            </button>
            <button 
              onClick={e => setSelected("routetoscale")}
              style={{ backgroundColor: `${selected === "routetoscale" ? "#dddddd" : ""}` }} 
            >
              Route to Scale
            </button>
          </div>
          <hr/>
          
          <div id="Intrinsic" class={`pt-2 ${selected === "intrinsic" ? "" : "collapse"}`}>
            <h6 class="text-center bg-200 p-2 fs--2">Intrinsic</h6>
            <div class="row fs--2 mx-1">
              
              <div class="col col-12 col-lg-2 mb-2">
                <h6 class="fs--2">Value driver role</h6>
                <select disabled={api.checkDisabled(vpHandler)} class="form-control form-select font-base" onChange={e=>changeRole(e, "intrinsic")}>
                  <option value="primary" selected={getSelected("intrinsic", "primary")}>Primary</option>
                  <option value="secondary" selected={getSelected("intrinsic", "secondary")}>Secondary</option>
                  <option value="relevant" selected={getSelected("intrinsic", "relevant")}>Not relevant</option>
                </select>
              </div>
              <div class="col col-12 col-lg-6 mb-2">
                <h6 class="fs--2">{`Description of Intrinsic`}</h6>
                <ReactMde
                  disablePreview={true}
                  value={getValue("intrinsicDescr")}
                  onChange={e => handleChange(e, "intrinsicDescr")}
                  selectedTab={selectedTab}
                  onTabChange={setSelectedTab}
                  toolbarCommands={[["bold", "italic", "unordered-list"]]}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(converter.makeHtml(markdown))
                  }
                  childProps={{
                    writeButton: {
                      tabIndex: -1
                    }
                  }}
                />
                {// <textarea id="valuePotIntrinsic">
                //   Modeling that was done with the strategy & finance teams indicated a US opportunity for Greenies proactive wellness of $8-9M direct revenue (RSV) by 2023 based on a 2021 launch. The revenue opportunity will be re-sized as an outcome of this phase of work.
                // </textarea>
                }
              </div>
              <div class="col col-12 col-lg-4 mb-2">
                <h6 class="fs--2">Long term ambition and glidepath</h6>
                <table class="table table-sm fs--2 mb-0 overflow-hidden">
                  <colgroup>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                  </colgroup>
                  <thead class="bg-200 text-900">
                    <tr>
                      <th class="sort align-middle white-space-nowrap">Target</th>
                      <th class="sort align-middle white-space-nowrap">Year 1</th>
                      <th class="sort align-middle white-space-nowrap">Year 2</th>
                      <th class="sort align-middle white-space-nowrap">Year 3</th>
                      <th class="sort align-middle white-space-nowrap"></th>
                    </tr>
                  </thead>
                  <tbody class="list fs--2">
                  {vpHandler.get.metrics.map((item)=>(
                    item.sov === "Intrinsic" && 
                    <tr class="btn-reveal-trigger">
                      <td class="align-top kpi ml-0 pl-0">{lookUpList[item.kpi]}</td>
                      <td class={`align-top y1 ${inputAffix(lookUpList[item.kpi])}`}>
                        <span>
                          <input  
                            disabled={api.checkDisabled(vpHandler)} 
                            class="form-control form-height font-base" 
                            type="number" 
                            onChange={e => sovChange(e, "Intrinsic", item.kpi, "y1")} 
                            value={getKpiValue("Intrinsic", item.kpi, "y1")}
                            placeholder="---"
                          />
                        </span>
                      </td>
                      <td class={`align-top y2 ${inputAffix(lookUpList[item.kpi])}`}>
                        <span>
                          <input 
                            disabled={api.checkDisabled(vpHandler)} 
                            class="form-control form-height font-base" 
                            type="number" 
                            onChange={e => sovChange(e, "Intrinsic", item.kpi, "y2")} 
                            value={getKpiValue("Intrinsic", item.kpi, "y2")}
                            placeholder="---"
                          />
                        </span>
                      </td>
                      <td class={`align-top y3 ${inputAffix(lookUpList[item.kpi])}`}>
                        <span>
                          <input 
                            disabled={api.checkDisabled(vpHandler)} 
                            class="form-control form-height font-base" 
                            type="number" 
                            onChange={e => sovChange(e, "Intrinsic", item.kpi, "y3")} 
                            value={getKpiValue("Intrinsic", item.kpi, "y3")}
                            placeholder="---"
                          />
                        </span>
                      </td>
                      <td class="align-top">
                        <span style={{cursor: 'pointer'}} class={`mars-blue fas far ${checkDisplay(item.kpi) ? "fa-trash-alt" : ""} fs--2`} onClick={e=>removeKPI("Intrinsic", item.kpi)}/>
                      </td>
                    </tr>
                  ))}          
                  </tbody>
                </table>

                <DropdownFieldValueModel vpHandler={vpHandler} list={allKPIList} sov="Intrinsic" kpiObject={kpiObject} setKpiObject={setKpiObject}/>

              </div>
            </div>
          </div>
          <div id="Engagement" class={`pt-2 ${selected === "engagement" ? "" : "collapse"}`}>
            <h6 class="text-center bg-200 p-2 fs--2">Engagement</h6>
            <div class="row fs--2 mx-1">
              <div class="col col-12 col-lg-2 mb-2">
                <h6 class="fs--2">Value driver role</h6>
                <select disabled={api.checkDisabled(vpHandler)} class="form-control form-select font-base" onChange={e=>changeRole(e, "engagement")}>
                  <option value="primary" selected={getSelected("engagement", "primary")}>Primary</option>
                  <option value="secondary" selected={getSelected("engagement", "secondary")}>Secondary</option>
                  <option value="relevant" selected={getSelected("engagement", "relevant")}>Not relevant</option>
                </select>
              </div>
              <div class="col col-12 col-lg-6 mb-2">
                <h6 class="fs--2">Description of Engagement Value</h6>
                <ReactMde
                  disablePreview={true}
                  value={getValue("engagementDescr")}
                  onChange={e => handleChange(e, "engagementDescr")}
                  selectedTab={selectedTab}
                  onTabChange={setSelectedTab}
                  toolbarCommands={[["bold", "italic", "unordered-list"]]}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(converter.makeHtml(markdown))
                  }
                  childProps={{
                    writeButton: {
                      tabIndex: -1
                    }
                  }}
                />
              {// <textarea id="valuePotEngagement">
                //     Engagement Value:  1:1 relationship platform that can be used to cross-promote brands/other ecosystem assets (Whistle) and unlock lifetime value.
                // </textarea>
              }
              </div>
              <div class="col col-12 col-lg-4 mb-2">
                <h6 class="fs--2">Long term ambition and glidepath</h6>
                <table class="table table-sm fs--2 mb-0 overflow-hidden">
                  <colgroup>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                  </colgroup>
                  <thead class="bg-200 text-900">
                    <tr>
                      <th class="sort pe-1 align-middle white-space-nowrap" data-sort="kpi">Target</th>
                      <th class="sort pe-1 align-middle white-space-nowrap" data-sort="y1">Year 1</th>
                      <th class="sort pe-1 align-middle white-space-nowrap" data-sort="y2">Year 2</th>
                      <th class="sort pe-1 align-middle white-space-nowrap" data-sort="y1">Year 3</th>
                      <th class="sort pe-1 align-middle white-space-nowrap" data-sort="action"></th>
                    </tr>
                  </thead>
                  <tbody class="list fs--2">
                  {vpHandler.get.metrics.map((item)=>(
                    item.sov === "Engagement" && 
                    <tr class="btn-reveal-trigger">
                      <td class="align-top kpi ml-0 pl-0">{lookUpList[item.kpi]}</td>
                      <td class={`align-top y1 ${inputAffix(lookUpList[item.kpi])}`}>
                        <input 
                          disabled={api.checkDisabled(vpHandler)} 
                          class="form-control form-height font-base" 
                          type="number" 
                          onChange={e => sovChange(e, "Engagement", item.kpi, "y1")} 
                          value={getKpiValue("Engagement", item.kpi, "y1")}
                          placeholder="---"
                        />
                      </td>
                      <td class={`align-top y2 ${inputAffix(lookUpList[item.kpi])}`}>
                        <input 
                          disabled={api.checkDisabled(vpHandler)} 
                          class="form-control form-height font-base" 
                          type="number" 
                          onChange={e => sovChange(e, "Engagement", item.kpi, "y2")} 
                          value={getKpiValue("Engagement", item.kpi, "y2")}
                          placeholder="---"
                        />
                      </td>
                      <td class={`align-top y3 ${inputAffix(lookUpList[item.kpi])}`}>
                        <input 
                          disabled={api.checkDisabled(vpHandler)} 
                          class="form-control form-height font-base" 
                          type="number" 
                          onChange={e => sovChange(e, "Engagement", item.kpi, "y3")} 
                          value={getKpiValue("Engagement", item.kpi, "y3")}
                          placeholder="---"
                        />
                      </td>
                      <td class="align-top">                
                        <span style={{cursor: 'pointer'}} class={`mars-blue fas far ${checkDisplay(item.kpi) ? "fa-trash-alt" : ""} fs--2`} onClick={e=>removeKPI("Engagement", item.kpi)}/>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                <DropdownFieldValueModel vpHandler={vpHandler} list={allKPIList} sov="Engagement" kpiObject={kpiObject} setKpiObject={setKpiObject}/>

              </div>
            </div>
          </div>
          <div id="Data" class={`pt-2 ${selected === "data" ? "" : "collapse"}`}>
            <h6 class="text-center bg-200 p-2 fs--2">Data </h6>
            <div class="row fs--2 mx-1">
              <div class="col col-12 col-lg-2 mb-2">
                <h6 class="fs--2">Value driver role</h6>
                <select disabled={api.checkDisabled(vpHandler)} class="form-control form-select font-base" onChange={e=>changeRole(e, "data")}>
                  <option value="primary" selected={getSelected("data", "primary")}>Primary</option>
                  <option value="secondary" selected={getSelected("data", "secondary")}>Secondary</option>
                  <option value="relevant" selected={getSelected("data", "relevant")}>Not relevant</option>
                </select>
              </div>
              <div class="col col-12 col-lg-6 mb-2">
                <h6 class="fs--2">Description of Data</h6>
                <ReactMde
                  disablePreview={true}
                  value={getValue("dataDescr")}
                  onChange={e => handleChange(e, "dataDescr")}
                  selectedTab={selectedTab}
                  onTabChange={setSelectedTab}
                  toolbarCommands={[["bold", "italic", "unordered-list"]]}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(converter.makeHtml(markdown))
                  }
                  childProps={{
                    writeButton: {
                      tabIndex: -1
                    }
                  }}
                />
                {// <textarea id="valuePotData">
                //   The primary value driver is Data Value – creating a closed loop system for ROI measurement through performance.
                //   Data Value:  1P data collection and closed loop ROI measurement to provide insights and performance-based learnings to brands & retailers/customers and inform brand innovation.
                //   Modeling that was done with the strategy & finance teams indicated a US opportunity for Greenies proactive wellness of 800K community size by 2023 based on a 2021 launch.
                // </textarea>
                }
              </div>
              <div class="col col-12 col-lg-4 mb-2">
                <h6 class="fs--2">Long term ambition and glidepath</h6>
                <table class="table table-sm fs--2 mb-0 overflow-hidden">
                  <colgroup>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                  </colgroup>
                  <thead class="bg-200 text-900">
                    <tr>
                      <th class="sort pe-1 align-middle white-space-nowrap">Target</th>
                      <th class="sort pe-1 align-middle white-space-nowrap">Year 1</th>
                      <th class="sort pe-1 align-middle white-space-nowrap">Year 2</th>
                      <th class="sort pe-1 align-middle white-space-nowrap">Year 3</th>
                      <th class="sort pe-1 align-middle white-space-nowrap"></th>
                    </tr>
                  </thead>
                  <tbody class="list fs--2">
                  {vpHandler.get.metrics.map((item)=>(
                    item.sov === "Data" && 
                    <tr class="btn-reveal-trigger">
                      <td class="align-top kpi ml-0 pl-0">{lookUpList[item.kpi]}</td>
                      <td class={`align-top y1 ${inputAffix(lookUpList[item.kpi])}`}>
                        <input 
                          disabled={api.checkDisabled(vpHandler)} 
                          class="form-control form-height font-base" 
                          type="number" 
                          onChange={e => sovChange(e, "Data", item.kpi, "y1")} 
                          value={getKpiValue("Data", item.kpi, "y1")}
                          placeholder="---"
                        />
                      </td>
                      <td class={`align-top y2 ${inputAffix(lookUpList[item.kpi])}`}>
                        <input 
                          disabled={api.checkDisabled(vpHandler)} 
                          class="form-control form-height font-base" 
                          type="number" 
                          onChange={e => sovChange(e, "Data", item.kpi, "y2")} 
                          value={getKpiValue("Data", item.kpi, "y2")}
                          placeholder="---"
                        />
                      </td>
                      <td class={`align-top y3 ${inputAffix(lookUpList[item.kpi])}`}>
                        <input 
                          disabled={api.checkDisabled(vpHandler)} 
                          class="form-control form-height font-base" 
                          type="number" 
                          onChange={e => sovChange(e, "Data", item.kpi, "y3")} 
                          value={getKpiValue("Data", item.kpi, "y3")}
                          placeholder="---"
                        />
                      </td>
                      <td class="align-top">
                       <span style={{cursor: 'pointer'}} class={`mars-blue fas far ${checkDisplay(item.kpi) ? "fa-trash-alt" : ""} fs--2`} onClick={e=>removeKPI("Data", item.kpi)}/>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                <DropdownFieldValueModel vpHandler={vpHandler} list={allKPIList} sov="Data" kpiObject={kpiObject} setKpiObject={setKpiObject}/>

              </div>
            </div>
          </div>
          <div id="Route" class={`pt-2 ${selected === "routetoscale" ? "" : "collapse"}`}>
            <h6 class="text-center bg-200 p-2 fs--2">Route to scale</h6>
            <div class="row fs--2 mx-1">
              <div class="col col-12 col-lg-2 mb-2 d-none">
                <h6 class="fs--2">Value driver role</h6>
                <select disabled={api.checkDisabled(vpHandler)} class="form-control form-select font-base" onChange={e=>changeRole(e, "routetoscale")}>
                  <option value="primary" selected={getSelected("routetoscale", "primary")}>Primary</option>
                  <option value="secondary" selected={getSelected("routetoscale", "secondary")}>Secondary</option>
                  <option value="relevant" selected={getSelected("routetoscale", "relevant")}>Not relevant</option>
                </select>
              </div>
              <div class="col col-12 col-lg-6 mb-2">
                <h6 class="fs--2">Description of Route to Scale</h6>
                <ReactMde
                  //readOnly={api.checkDisabled(vpHandler)} 
                  disablePreview={true}
                  value={getValue("routeDescr")}
                  onChange={e => handleChange(e, "routeDescr")}
                  selectedTab={selectedTab}
                  onTabChange={setSelectedTab}
                  toolbarCommands={[["bold", "italic", "unordered-list"]]}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(converter.makeHtml(markdown))
                  }
                  childProps={{
                    writeButton: {
                      tabIndex: -1
                    }
                  }}
                />
              </div>
              <div class="col col-12 col-lg-4 mb-2">
                <h6 class="fs--2">Long term ambition and glidepath</h6>
                <table class="table table-sm fs--2 mb-0 overflow-hidden" style={{width: '100%'}}>
                  <colgroup>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                    <col style={{width:"20%"}}/>
                  </colgroup>
                  <thead class="bg-200 text-900">
                    <tr>
                      <th class="sort pe-1 align-middle white-space-nowrap" data-sort="kpi"></th>
                      <th class="sort pe-1 align-middle white-space-nowrap" data-sort="y1">Value</th>
                      <th class="sort pe-1 align-middle white-space-nowrap" data-sort="action"></th>
                    </tr>
                  </thead>
                  <tbody class="list fs--2">
                  {vpHandler.get.metrics.map((item)=>(
                    item.sov === "Route To Scale" && 
                    <tr class="btn-reveal-trigger">
                      <td class="align-top kpi ml-0 pl-0">{lookUpList[item.kpi]}</td>
                      <td class={`align-top y1 ${inputAffix(lookUpList[item.kpi])}`}>
                        <input 
                          disabled={api.checkDisabled(vpHandler)} 
                          class="form-control form-height font-base" 
                          type="number" 
                          onChange={e => sovChange(e, "Route To Scale", item.kpi, "y1")} 
                          value={getKpiValue("Route To Scale", item.kpi, "y1")}
                          placeholder="---"
                        />
                      </td>
                      {// <td class="align-top y2">
                      // <input class="form-control form-height font-base" type="text" onChange={e => sovChange(e, "Data", item.kpi, "y2")} value={getKpiValue("Data", item.kpi, "y2")}/>
                      // </td>
                      // <td class="align-top y3">
                      // <input class="form-control form-height font-base" type="text" onChange={e => sovChange(e, "EngDataagement", item.kpi, "y3")} value={getKpiValue("Data", item.kpi, "y3")}/>
                      // </td>
                      }
                      <td class="align-top">
                        <span style={{cursor: 'pointer'}} class={`mars-blue fas far ${checkDisplay(item.kpi) ? "fa-trash-alt" : ""} fs--2`} onClick={e=>removeKPI("Route To Scale", item.kpi)}/>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
                <DropdownFieldValueModel vpHandler={vpHandler} list={allKPIList} sov="Route To Scale" kpiObject={kpiObject} setKpiObject={setKpiObject}/>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
  )}