import React from "react";
import {
  FormGroup,
  Form,
  Input
} from "reactstrap";

const CompanyForm = ({values, handleChange, handleFocus, isOpen}) => {
  //console.log(`values: ${JSON.stringify(values)}`)
  const companyForm = [
    {
      title:'Company name',
      name:'companyName',
      placeholder:'ACME Inc.',
      type:'text'
    },
    {
      title:'Url',
      name:'companyUrl',
      placeholder:'www.google.com',
      type:'text'
    }
  ]

  const checkDisabled = (item) => {
    if(item.name==="companyUrl" || isOpen) return true
    else return false
  }

  return (
    <>
      <Form>
        {companyForm.map((index, i) => (
          <FormGroup key={i}>
            <label
              className="fs--2"
            >
              {index.title}
            </label>
            <Input
              //onFocus={handleFocus}
              disabled={checkDisabled(index)}
              placeholder={index.placeholder}
              value={values[index.name]}
              id={index.name}
              name={index.name}
              type={index.type}
              onChange={handleChange}
            />
          </FormGroup>)
        )}
      </Form>
    </>
  );
}

export default CompanyForm;
