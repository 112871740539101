import React from 'react';
import ReactEcharts from 'echarts-for-react';

const HorizontalBarChart = ({data, title}) => {
  
let dataNames = [];
let dataValues = [];

for (let i = 0; i < data.length; i++){
  dataNames.push(data[i].name);
  dataValues.push(data[i].value);
}

  const option = {
    title: {
      show: false,
      text: title,
    },
    grid: {
      left: 0,
      right: 0,
      top: 20,
      bottom: 20,
        containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
          type: 'shadow'
      }
    },
    dataZoom: {
        show: true,
        width: '5%',
        slider: true,
        orient: 'vertical',
        start: '100',
        minSpan: 6,
        handleSize: 4,
        right: '0%', 
    },
    yAxis: {
      type: "category",
      data: dataNames,
      min: 5,
    },
    xAxis: {
      type: "value",
      show: false,
    },
    series: [
      {
        type: "bar",
        showBackground: true,
        backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
        },
        label: {
          show: true,
          position: "inside",
          color: '#FFFFFF'
        },
        data: dataValues,
        color: '#000096',
      },
    ],
    textStyle: {
      fontFamily: `'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default`,
      color: '#748194'
    },
    height: '150px'
  };

  return <ReactEcharts option={option}/>;
};
export default HorizontalBarChart;
