import React from 'react';
import { CardBody, UncontrolledTooltip } from 'reactstrap';
import TreeMap from '../TreeMap';

const TreeMapLayout = ({ data, title, sub, string, setString }) => {
  return (
    <div class="card mb-3">
      <div class="card-header pb-1 bg-light">
      <div class="row pl-3">
        <h6>
          {title}
        </h6>
        <i id="treemapTooltip" class="ml-1 fs--2 mars-blue fas fa-info-circle mr-1" />
        
        <UncontrolledTooltip  delay={0} target="treemapTooltip">
          Reported revenue where available, otherwise estimated based on validated formula, extrapolating from social media following
        </UncontrolledTooltip>
      </div>  
      <h6 class="mb-2 fs--2 text-500">{sub}</h6>
   </div>
      <CardBody style={{marginTop: 0, paddingTop: 0}}>
        <div className="chart">
          <TreeMap data={data} title={title} string={string} setString={setString}/>
        </div>
      </CardBody>
    </div>
  );
};
export default TreeMapLayout;
