import React from 'react';
// import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { Input } from 'reactstrap';

const SmallSearchBarVP = ({ list, string, setString }) => {

  // //console.log(`searhc: ${JSON.stringify(list)}`)

  // const handleOnSearch = (input) => {
  //   // onSearch will have as the first callback parameter
  //   // the string searched and for the second the results.
  //   console.log(`input: ${input}`);
  //   setString(input)
  // };

  // const handleOnHover = result => {
  //   // the item hovered
  //   //console.log(result);
  // };

  // const handleOnSelect = item => {
  //   window.location.href = `/insight/brandoverview/${encodeURIComponent(item.cid)}`;
  //   //console.log(item);
  // };


  return (
    <>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%'}}>
        <div style={{ width: '400px' }}>
          <Input
            //style={{height: '25px', boxShadow: '0'}}
            class="text-sans-serif fs--2 mars-blue"
            //items={list}
            //onSearch={handleOnSearch}
            //onClear={e=>setString("")}
            //value="test"
            //onHover={handleOnHover}
            //onSelect={handleOnSelect}
            //onFocus={handleOnFocus}
            placeholder="Search value propositions"
            onChange={e=>setString(e.target.value)}
            value={string}
            //showIcon={false}
            //maxResults={3}
            style={{
              borderRadius: '0.3rem 0rem 0rem 0.3rem',
              border: '1px solid rgb(210, 220, 240)',
              boxShadow: '0px 0px 0px #fff',
              shadowRadius: '0px',
              //zIndex: '5',
              height: '25px',
              fontSize: '11px',
              fontFamily: 'Poppins, sans-serif',
            }}
          />
        </div>

        <div
          style={{
            borderRadius: '0rem 0.3rem 0.3rem 0rem',
            border: '1px solid rgb(210, 220, 240)',
            height: '25px',
            width: '50px',
            marginLeft: '-1px',
            padding: 0,
            backgroundColor: '#F9FAFD',
          }}
        >
          <i
            class="fas fa-search"
            style={{ color: 'gray', fontSize: '12px', width: '100%', textAlign: 'center', verticalAlign: 'center' }}
          />
        </div>
      </div>
    </>
  );
};

export default SmallSearchBarVP;
