import React from "react";

import { ResourcesLine } from "./ResourcesLine";
import { UploadResourcesLine } from "./UploadResourcesLine";

export const Tools = ({learnFiles}) => {
  //const [open, setOpen] = React.useState(true);
  const [hide, setHide] = React.useState(false);
  
  return(
    
    <div class="card mb-3" id="tools">
      <div class="card-header pb-1 bg-light">
        <div class="row col m-0 p-0">
          <span class="fas fa-tools mr-2"/>
          <h6 class="mb-0">Tools & Templates</h6>
        </div>
        <div class="row col m-0 p-0">
          <div>
            
          </div>
          <div class="ml-auto">
            <div class="me-2 fs--2" onClick={e => setHide(!hide)} role="button">
              view / hide
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class={`${hide ? "collapse" : ""}`}>
          <ResourcesLine 
            title="Value Proposition Initiation - Idea Pitch Document" 
            img="DOC1_Initial_pitch.png"
            //icon="far fa-lightbulb"
            version="Ideation | Version 1.0" 
            description="Used to pitch to the NBD Governance Board and provide an initial idea of a value proposition’s potential."
            link='https://d2cx.ams3.digitaloceanspaces.com/learn/Playbook%202_Value%20Proposition%20Initial%20Idea%20Pitch%20Document_FINAL.docx'  
          />
          <ResourcesLine 
            title="Value Proposition Plan - Investment Request (Seed R1)" 
            img="DOC2_Seed1.png"
            //icon="far fa-lightbulb"
            version="Ideation | Version 1.0" 
            description="Summarises the first investment request to conduct consumer testing and validation of a concept."
            link='https://d2cx.ams3.digitaloceanspaces.com/learn/Playbook%202_Value%20Proposition%20Plan_Investment%20Document%20%28Seed%20R1%29_FINAL.docx'  
          />
          <ResourcesLine 
            title="Value Proposition Plan - Investment Request (Seed R2)" 
            img="DOC3_Seed2.png"
            //icon="fas fa-user-check"
            version="Customer Validation | Version 1.0" 
            description="Summarises the second investment request to build and test a value proposition."
            link='https://d2cx.ams3.digitaloceanspaces.com/learn/Playbook%202_Value%20Proposition%20Plan_Investment%20Document%20%28Seed%20R2%29_FINAL.docx'  
          />
          <ResourcesLine 
            title="Value Proposition Deck Templates" 
            img="buildTheWheel.PNG"
            //icon="fas fa-user-check"
            version="Customer Validation | Version 1.0" 
            description="A one page summary of the value proposition supported by details of the four components of The Wheel."
            link='https://d2cx.ams3.digitaloceanspaces.com/learn/Playbook%202_Value%20Proposition%20Deck_Wheel%20Templates_FINAL.pptx' 
          />
          <ResourcesLine 
            title="Box Service List" 
            img="DOC3_Seed2.png"
            //icon="fas fa-user-check"
            version="Customer Validation | Version 1.0" 
            description="Comprehensive list of Base and Advanced capabilities from The Box that can be plugged into."
            link='https://d2cx.ams3.digitaloceanspaces.com/learn/Playbook%203_The%20Box%20Service%20List%20FINAL%20V3.xlsx'
          />
          <ResourcesLine 
            title="Service Level Agreement" 
            img="DOC3_Seed2.png"
            //icon="fas fa-user-check"
            version="MVP | Version 1.0" 
            description="This contract between The Wheel and The Box team agrees the set of capabilities and services that need to be activated and built."
            link='https://d2cx.ams3.digitaloceanspaces.com/learn/Playbook%203_Service%20Level%20Agreement_FINAL.docx'
          />
          <ResourcesLine 
            title="Brand Asset Checklist" 
            img="DOC3_Seed2.png"
            //icon="fas fa-user-check"
            version="MVP | Version 1.0" 
            description="This is a checklist of assets that need to be provided to The Box team before a proposition can launch."
            link='https://d2cx.ams3.digitaloceanspaces.com/learn/Playbook%203_Service%20Level%20Agreement_FINAL.docx'
          />
          {learnFiles.map((resource) => (
            resource.destination === "tools" && <UploadResourcesLine resource={resource}/>
          ))}
        </div>
      </div>
    </div>
          
  )}