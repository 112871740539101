import React from "react";
import * as api from "../../API/IndividualVpAPI";
import moment from "moment"
import { useHistory } from 'react-router-dom';
import axios from 'axios'

export const ReviewBar = ({vpHandler}) => {
  const [selectedAction, setSelectedAction] = React.useState("")
  const history = useHistory()
  const select = (e) => {
    //console.log(`selected: ${e.target.value}`)
    setSelectedAction(e.target.value)
  }
  // active, perish, pivot
  //console.log(`vpObject: ${JSON.stringify(vpHandler.get)}`)

  let list = {
    "ideation": " 01. Ideation",
    "customerVal": "02. Customer Validation",
    "mvp": "03. MVP",
    "optimize": "04. Optimize",
    "scale": "05. Grow",
    "grow": "06. Scale",
    "persevere": "Progress",
    "perish": "Perish",
    "pivot": "Pivot"
  }

  const nextStage = () => {
    let current = vpHandler.get.currentStage
    if(current === "ideation") return "customerVal"
    if(current === "customerVal") return "mvp"
    if(current === "mvp") return "optimize"
    if(current === "optimize") return "grow"
    if(current === "grow") return "scale"
    else return "scale"
  }

  const sendEmail = () =>{
    let vpObject = vpHandler.get
    let obj = {"vpName": vpHandler.get.name, recipients: []}
    obj.vpName = vpObject.name
    obj.recipients = [...vpObject.team, ...vpObject.sponsors]
    SubmitFeedback(obj)
  }

  const SubmitFeedback = (emailObj) => {
    let testEmailObj = {"vpName":"Test VP Name","recipients":[{"name":"Douwe","email":"dafmulder@gmail.com", "fte": "1"}, {"name":"Rene","email":"wessels.rene@gmail.com"}]}
    //console.log(`emailObj: ${JSON.stringify(emailObj)}`)

    axios({
      url: process.env.REACT_APP_API,
      //url: "https://d2cx.dev/graphql",
      method: "post",
      data: {
        query: `query SendEmailConfirmation($vpApproval: VPApprovalEmailInputType) {
          SendEmailConfirmation(vpApproval: $vpApproval)
        }`,
        //variables: { email: _email, inviteName: vpHandler.get.name },
        variables: {vpApproval: { vpName: emailObj.vpName, recipients: emailObj.recipients }},
      },
    })
      .then((result) => {
        console.log("Email sent");
        //api.notify("Your feedback has been submitted.", inputEl, "success" )
        //openInNewTab(result.data.data.GetDownload);
        //setLearnFiles( [...result.data.data.GetLearnFile] );
      })
      .catch((err) => console.log(err));
  };



  const submitDecision = () => {
    //console.log(`selected action: ${selectedAction}`)
    let vpObject = vpHandler.get
    let action = selectedAction
    let obj = {date: moment().format("MMM Do YYYY") , event: `${list[vpHandler.get.currentStage]} => ${list[nextStage()]}`, decision: list[action], fileName: "", fileUuid: ""}
    console.log(`obj ${JSON.stringify(obj)}`)
    if(action === "") api.notify("Please select an option before submitting.", vpHandler.ref, "danger")
    
    if(action === "persevere"){
      vpObject.active = true
      vpObject.perish = false
      vpObject.pivot = false
      vpObject.currentStage = nextStage()
      api.notify("Decision Progress has been submitted.", vpHandler.ref, "success")
      vpHandler.get.eventHistory[vpHandler.get.eventHistory.length-1].decision = list[action]
      //vpObject.eventHistory.push(obj)
      sendEmail()
    }

    if(action === "pivot"){
      vpObject.active = true
      vpObject.perish = false
      vpObject.pivot = true
      vpHandler.get.eventHistory[vpHandler.get.eventHistory.length-1].decision = list[action]
      sendEmail()
      api.notify("Decision Pivot has been submitted.", vpHandler.ref, "success")
    }
    
    if(action === "perish"){
      vpObject.active = true
      vpObject.perish = true
      vpObject.pivot = false
      vpHandler.get.eventHistory[vpHandler.get.eventHistory.length-1].decision = list[action]
      api.notify("Decision Perish has been submitted.", vpHandler.ref, "success") 
      sendEmail()
    }
    
    vpHandler.set({...vpObject})
    api.updateVP(vpHandler)
    history.push('/monitor/searchreviewvp')
  }


  return(
    //localStorage.getItem("userGroup").includes("admin") &&
      <div 
        class="row mb-2 col p-0 m-0 bg-white"
        style={{ position: "sticky", top: "62px", zIndex: "100" }} //109 old
      >
      <div class="col">
        <div class="form-check form-check-inline">
          <input onClick={e=>select(e)} class="form-check-input"  type="radio" name="inlineRadioOptions" value="persevere" />
          <label class="form-check-label" >Progress</label>
        </div>
        <div class="form-check form-check-inline">
          <input onClick={e=>select(e)} class="form-check-input" type="radio" name="inlineRadioOptions" value="pivot" />
          <label class="form-check-label" >Pivot</label>
        </div>
        <div class="form-check form-check-inline">
          <input onClick={e=>select(e)} class="form-check-input" type="radio" name="inlineRadioOptions" value="perish" />
          <label class="form-check-label" >Perish</label>
        </div>
      </div>
        {vpHandler.action !== "add" &&
          <div class="ml-auto">
            <button class="btn mars-button btn-sm ml-1 mb-1 fs--2" type="button" onClick={e=>submitDecision()}>
              <span class="far fa-arrow-alt-circle-right mr-2"/>
              Submit decision
            </button>
          </div>
        }
      </div>
        
  )}