import React from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { kpiDictionary } from '../Lists/kpiDictionary';

import * as api from "./../API/IndividualVpAPI"

const KpiSearchBar = ({ setDictionaryList, dictionaryList, vpHandler }) => {
  const [init, setInit] = React.useState(false);
  const [autoFillList, setAutoFillList] = React.useState([]);
  
  React.useEffect(() => {
    if(!init){
      setInit(!init)
      createKpiList()
    }
  }, [init]);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results);
  };

  const handleOnHover = result => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (selected) => {
    let vpObject = vpHandler.get
    if(vpObject.dictMetrics === null) vpObject.dictMetrics = []
    
    if(selected.name === "Custom KPI") {
      if(vpObject.dictMetrics.find(item => item.kpi === selected.name) === undefined){
        let obj = kpiDictionary.find(item => item.kpi === selected.name)
          obj.min = String(obj.min)
          obj.max = String(obj.max)
          vpObject.dictMetrics.push({...obj})
        vpHandler.set({...vpObject})
      }
      else api.notify("Set Custom KPI name before adding a new Custom KPI.", vpHandler.ref, "danger")
    }
    else {
      if(vpObject.dictMetrics.find(item => item.kpi === selected.name) === undefined){
        let obj = kpiDictionary.find(item => item.kpi === selected.name)
          obj.min = String(obj.min)
          obj.max = String(obj.max)
          vpObject.dictMetrics.push({...obj})
        vpHandler.set({...vpObject})
      }
      else api.notify("This KPI is already selected.", vpHandler.ref, "danger")
    }

    // let list = dictionaryList
    // let listItem = kpiDictionary.find(item => item.kpi === selected.name)
    // list.push({...listItem})
    // setDictionaryList([...list])
  };

  const handleOnFocus = () => {
    console.log('Focused');
  };

  const createKpiList = () => {
    //console.log(`kpi: ${JSON.stringify(kpiDictionary)}`)
    let dictionary = kpiDictionary;
    let autoFillList = [];

    dictionary.forEach((item) => {
      let object = {
        name: item.kpi,
        uuid: item.kpi,
      };
      autoFillList.push({ ...object });
    });
    setAutoFillList([...autoFillList]);
  };

  return (
    <>
      <div class="row pl-3 pt-2">
        <div style={{ width: '60%', maxWidth: '400px' }}>
          <ReactSearchAutocomplete
            class="text-sans-serif"
            items={autoFillList}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            placeholder="Search KPI Library"
            //autoFocus
            showIcon={false}
            maxResults={3}
            styling={{
              borderRadius: '0.3rem 0rem 0rem 0.3rem',
              border: '1px solid rgb(210, 220, 240)',
              boxShadow: '0px',
              zIndex: '5',
              height: '23px',
              width: '400px',
              fontSize: '11px',
              fontFamily: 'Poppins, sans-serif',
            }}
          />
        </div>

        <div
          style={{
            borderRadius: '0rem 0.3rem 0.3rem 0rem',
            border: '1px solid rgb(210, 220, 240)',
            height: '25px',
            width: '50px',
            marginLeft: '-1px',
            padding: 0,
            backgroundColor: '#F9FAFD',
          }}
        >
          <i
            class="fas fa-search pt-1"
            style={{ color: 'gray', fontSize: '12px', width: '100%', textAlign: 'center', verticalAlign: 'center' }}
          />
        </div>
      </div>
    </>
  );
};

export default KpiSearchBar;
