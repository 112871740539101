import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { Months } from '../lookupTables/Months';
import moment from 'moment';

const LineChart = ({data, title}) => {
  let currentMonth = new Date().getMonth()-1;
  let currentYear = new Date().getFullYear();
  let lastYear = null;
  let subtitle = null; 
  let values = [];
  let monthData = [];
  let minValue = 0;
  let maxValue = 0;

  const fixNumber = (input) => {
    console.log(input.split('.')[0])
    let output = input.split('.')[0]
    output = output.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return output
  }


  // if (currentMonth - 5 < 0) lastYear = currentYear - 1;
  // else lastYear = currentYear;

  //subtitle = `${Months[(currentMonth - 5) % 11]} ${lastYear} - ${Months[currentMonth]} ${currentYear}`;
  subtitle = `${moment().subtract(6, 'months').format('MMM YYYY')} - ${moment().subtract(1, 'months').format('MMM YYYY')}`

  Object.values(data).forEach((item) => values.push(item));
  values = values.slice(1);

  minValue = Math.min(...values);
  maxValue = Math.max(...values);

  monthData = [moment().subtract(5, 'months').format('MMM'), moment().subtract(4, 'months').format('MMM'), moment().subtract(3, 'months').format('MMM'), moment().subtract(2, 'months').format('MMM'), moment().subtract(1, 'months').format('MMM') ];

  const option = {
    title: {
      show: false,
      text: title,
      subtext: subtitle,
    },
    grid: {
      left: '0%',
      right: '2%',
      bottom: '0%',
      top: "5%",
      containLabel: true
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      // formatter: '{b}: {c}'

      // formatter: function(d) {
      //   console.log(data)
      //   return fixNumber(d.data)
      // }
    },
    // dataZoom: {
    //     show: false,
    //     slider: true,
    //     orient: 'vertical',
    //     rangeMode: ['percent','percent'],
    //     start: ('100'),
    //     end: (minValue / maxValue * 100),
    // },
    xAxis: {
      type: 'category',
      data: monthData,
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: values,
        type: 'line',
        label: {
          formatter: function(d) {
            console.log(data)
            return fixNumber(d.data)
          }
        }
      }
    ],
    color: ['#000096', '#003db0', '#0063b0', '#007cb0', '#008baf', '#00a1b0', '#08bcbf', '#10ccc9', '#20e6ce'],
    textStyle: {
      fontFamily: `'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default`,
      color: '#748194'
    },
    height: '250px'
  };

  return <ReactEcharts option={option} style={{padding: 0, margin: 0}}/>;
};
export default LineChart;
