import React from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';

const WorldMap = ({ followingCompanies }) => {
  const geoUrl =
    'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json';

  const getColor = country => {
    if (followingCompanies[0].country) {
      let _baseColor = '#000096';
      let _color;

      let countryData = [];
      followingCompanies.map(company =>
        company.country.includes(country.properties.ISO_A2) ? countryData.push(company) : ''
      );

      if (countryData.length > 0) _color = _baseColor;
      else _color = '#C0C0C0';
      return _color;
    }
  };
  
  return (
    <>
      <ComposableMap data-tip="" projectionConfig={{ scale: 170}} height='520'>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map(geo => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                style={{
                  default: {
                    fill: getColor(geo),
                    stroke: '#ffffff'
                  },
                  hover: {
                    fill: getColor(geo),
                    outline: 'none'
                  },
                  pressed: {
                    fill: getColor(geo),
                    outline: 'none'
                  }
                }}
              />
            ))
          }
        </Geographies>
      </ComposableMap>
    </>
  );
};
export default WorldMap;
