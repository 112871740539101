import React from 'react';
import { sortBrandTable } from '../Sort/SortBrandTable';
import ReactCountryFlag from 'react-country-flag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import NotificationAlert from 'react-notification-alert';
import axios from 'axios';
import * as api from './../API/BrandsTableAPI'
// import SmallSearchBar from '../SearchBars/SmallSearchBar';

// import NewCompany from '../add-company/NewCompany';

let emptyCompanyObject = {
	"brandName": "",
	"activity": "ACTIVE",
	"region": "",
	"country": "",
	"url": "",
	"brandStartDate": "",
	"comment": "",
	"format": "",
	"dog": true,
	"catOnly": false,
	"healthy": true,
	"sickOnly": false,
	"countriesActive": [],
	"channels": [],
	"packaging": "",
	"special": "",
	"species": [],
	"health": "healthy",
	"technology": [],
	"webChatbot": false,
	"facebookHandle": "",
	"twitterHandle": "",
	"instaHandle": "",
	"tiktokHandle": "",
	"youtubeHandle": "",
	//"revenueType": "",
	"mmni": false,
	"mmi": false,
	"cts": false,
	"mtt": false,
	"clh": false,
	"clmc": false,
	"companyInfo": {
		"address": "",
		"funding": "",
		"revenue": ""
	},
	"fuelUp": null,
	"iconUrl": null,
	"pinterestHandle": null,
}


const NewBrandsPagination = ({ getCompanies, page, setPage, setSelectedBrand, columns, itemsPerPage, tableItems, setTableItems, autofillList }) => {
  // Columns = [ { string "columnName", string "field", bool isSortable, bool isNumeric }];
  // field refers to the name of the attribute which is used in the items array
  // itemsPerPage = number of items per page in the table
  // items = [ {itemAttribute 1, itemAttribute 2, etc. }];
  const [sorting, setSorting] = React.useState(false);
  const inputEl = React.useRef(null)

  //const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [open, setOpen] = React.useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openInNewTab = (url) => {
    url = `http://${url}`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  // This method is specific for handling the brands table on the M_Discover page
  //▼▲

  const notify = (msg, type) => {
    //console.log(`notify: ${JSON.stringify(msg)}, ${inputEl}, ${JSON.stringify(type)}`)
    let options = {
      place: 'tr',
      message: (
        <div className="alert-text fs--2">
          <span className="ml-2">
            {msg}
          </span>
        </div>
      ),
      type: type, //add custom type
      icon: "ni ni-bell-55 fs--2",
      autoDismiss: 4
    };
  
    if(inputEl.current !== null )inputEl.current.notificationAlert(options);
  };

  const updateCompany = (object) => {
    console.log(`object: ${JSON.stringify(object)}`)
    axios
      .post(process.env.REACT_APP_API, {
        query: `
        mutation UpdateCompany($company: CompanyInputType) {
          UpdateCompany(company: $company) {
            uuid
          }
        }`,
        variables: {'company': object }
    }) 
      .then(res => {
        console.log(`Company has been updated!`)
        getCompanies()

    })
      .catch(err => {
        console.log(err)
    })
  }

  const removeSuggestion = (name, decision, company) => {
    let _items = tableItems
    let item = {};

    //let filteredArray = _items.filter(item => item.name !== name)
    
    //setTableItems([...filteredArray])

    if(decision === "approve"){
      item = _items.find(item => item.companyName === name)
      item.archived = true
      item.accepted = true
      notify("Suggestion has been approved", "success")
      AddCompany(company)

    }
    if(decision === "remove"){
      item = _items.find(item => item.companyName === name)
      item.archived = true
      item.accepted = false
      notify("Suggestion has been removed", "danger")
    }
    console.log(_items)

    updateCompany(item)
    // console.log(_items)
    setTableItems([..._items])
  }

  const AddCompany = (company) => {
    let newCompany = emptyCompanyObject
    newCompany.brandName = company.companyName
    newCompany.url = company.companyUrl
    console.log(`add a company ${JSON.stringify(newCompany)}`)
    api.addBrand(newCompany)
  }

  const fillCells = (item, columns) => {
    let cells = [];
    //console.log(`item: ${JSON.stringify(item)}`)
    for (let column of columns) {
      let align = 'left';
      let value = '';
      let className = 'table-cell table-cell-body';
      
      if (column.id !== 'name' && column.id !== 'image') {
        align = 'right';
      }

      if (column.id === 'image') {
        value = (
          <img
            alt=""
            width="20"
            className="ml-3 mr-1"
            src={`https://d2cx.ams3.digitaloceanspaces.com/icons/${encodeURIComponent(item[column.id])}.png`}
            onError={e => {
              e.target.src = require('../../assets/img/whiteBox.png');
            }}
          />
        );
      } 

      else if (column.id === 'name') {
        value =
          <Link
            to={`/insight/brandoverview/${encodeURIComponent(item.name)}`}
            className="mars-blue hoverhand"
            style={{ fontSize: '0.7rem', fontWeight: '500' }}
          >
            {item.companyName}
          </Link>
      } 
      else if (column.id === 'url') {
        value =
          <div
            onClick={e=>openInNewTab(item.companyUrl)}
            className="mars-blue hoverhand"
            style={{ fontSize: '0.7rem'}}
          >
            {item.companyUrl}
          </div>
      } 
      else if (column.id === 'socials') {
        value = (
          <Button className="ml-1 mr-3 p-0" color="none" size="sm">
            <i className="fas fa-ellipsis-h" style={{ color: '#748194' }} />
          </Button>
        );
      } 
      else if (column.id.includes('delta')) {
        let percentage = item[column.id];
        if (percentage.includes('-')) {
          percentage = `${percentage}%`;
          value = (
            <span className="badge badge-soft-warning rounded-pill fs--2" style={{ width: '52px' }}>
              {percentage}
            </span>
          );
        } else {
          percentage = `+${percentage}%`;
          value = (
            <span className="badge badge-soft-success rounded-pill fs--2" style={{ width: '52px' }}>
              {percentage}
            </span>
          );
        }
      } 
      else if (column.id === 'decision') {
            value = (
              <div>
                <span class="fas fa-check fs--1 text-success me-2 mr-2 hoverhand" onClick={()=>removeSuggestion(item.companyName, "approve", item)}/>
                <span class="fas fas fa-ban fs--1 text-danger hoverhand" onClick={()=>removeSuggestion(item.companyName, "remove", item)}/>
              </div>
            );
      } 

      else if (column.id === 'country') {
        value = <ReactCountryFlag countryCode={`${item.country === "UK" ? "GB" : item.country}`} style={{ width: '20px' }} svg />;
      } 
      else {
        value = item[column.id];
      }
      cells.push(
        <TableCell align={align} className={className}>
          {value}
        </TableCell>
      );
    }

    return cells;
    //console.log(`cells: ${JSON.stringify(cells)}`)
  };

  const formatColumnTitle = column => {
    let align = 'right';
    let sortIcon = '';
    if (column.id === 'image' || column.id === 'name') {
      align = 'left';
    }
    if (column.isSortable) {
      sortIcon = (
        <Button
          color="none"
          size="sm"
          style={{ margin: 0, padding: 0, height: '16px', marginTop: '-2px' }}
          onClick={() => testButtonPress(column.id, 'decreasing', tableItems, setTableItems)}
        >
          {getSortIcon(null)}
        </Button>
      );
    }

    let justifyC = align === 'left' ? 'flex-start' : 'flex-end';

    return (
      <TableCell className="table-cell" align={align}> 
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: `${justifyC}` }}>
          <span>{column.columnName}</span>
          <span style={{ paddingLeft: '2px' }}>{sortIcon}</span>
        </div>
      </TableCell>
    );
  };

  const testButtonPress = (sortCategory, order, state, setState) => {
    sortBrandTable(sortCategory, sorting, state, setState)
    setSorting(!sorting)
  }

  const getSortIcon = currentIcon => {
    let newIcon = '';

    // null = both up and down arrows
    if (currentIcon === null) {
      newIcon = (
        <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
          <i className="fas fa-caret-up table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
          <i className="fas fa-caret-down table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
        </span>
      );
    }
    // true = sortIconUp
    else if (currentIcon) {
      newIcon = (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
          <i
            className="fas fa-caret-up table-sort-icon"
            style={{ fontSize: '0.8rem', lineHeight: '100%', textAlign: 'center' }}
          />
        </div>
      );
    }
    // false = sortIconDown
    else if (!currentIcon) {
      newIcon = (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
          <i
            className="fas fa-caret-down table-sort-icon"
            style={{ fontSize: '0.8rem', lineHeight: '100%', textAlign: 'center' }}
          />
        </div>
      );
    }

    return newIcon;
  };

  const itemsOnThisPage = (rowsPerPage, page, list) => {
    let y = rowsPerPage * (page + 1);
    let x = y - rowsPerPage;
    return list.slice(x, y);
  };

  return (
    <>
      {localStorage.getItem("customUserGroup").includes("admin") &&
      <div class="card mb-3">
        <NotificationAlert ref={inputEl} />
        <div class="card-header pb-1 bg-light">
          <div class="row flex-between-center">
            <div class="col-auto col-sm-6 col-lg-7">
              <div class="row ml-1">
                <h6 className="mb-0 text-nowrap py-2 py-xl-0 mb-2 mr-0">Suggested Brands</h6>
                {// <a class="ml-2 me-2 fs--2 mars-blue" onClick={e => NewCompany()} role="button">
                //   <i class="fas fa-plus mr-1" />
                //   Add New Brand
                // </a>
              }
                
              </div>
              <div class="me-2 fs--2 mars-blue d-none" onClick={e => setOpen(!open)} role="button">
                <i class="fas fa-info-circle mr-1" />
                Quick guide & legend
              </div>
            </div>
            
          </div>
        </div>
        <div class="card-body" style={{ padding: 0, marginLeft: 0, marginRight: 0, marginTop: 0 }}>
          
          <TableContainer style={{ marginTop: 0, paddingTop: 0 }}>
            <Table>
              <TableHead>
                <TableRow className="table-header">{columns.map(column => formatColumnTitle(column))}</TableRow>
              </TableHead>
              <TableBody>
                {itemsOnThisPage(rowsPerPage, page, tableItems).map(item => (
                  <TableRow
                    key={item.name}
                    onClick={e => setSelectedBrand !== undefined && setSelectedBrand(item.image)}
                  >
                    {(fillCells(item, columns))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[4, 8, 16]}
              component="div"
              count={tableItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className="pagination-item"
              style={{ marginLeft: '15px', padding: 0, borderStyle: 'none' }}
            />
          </TableContainer>
        </div>
      </div>
    }
    </>
  );
};

export default NewBrandsPagination;
