import React from "react";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import * as api from "./../../API/IndividualVpAPI";

export const NextSteps = ({vpHandler}) => {
  const [open, setOpen] = React.useState(true);
  const [hide, setHide] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("preview");

  React.useEffect(() => {
    if(vpHandler.action === "view" || vpHandler.action === "review") setSelectedTab("preview")
    else setSelectedTab("write")
  }, [vpHandler.action]);

  const handleChange = (e, handle) => {
    let vpObject = vpHandler.get
    vpObject[handle] = e
    //console.log(`painpoint: ${vpObject.painPoint}`)
    vpHandler.set({...vpObject})
  }

  const handleFunding = (e, handle) => {
    let vpObject = vpHandler.get
    vpObject[handle] = Number(e.target.value)
    if(vpObject[handle] === 0) vpObject[handle] = null
    else vpObject[handle] = Number(e.target.value)
    vpHandler.set({...vpObject})
  }

  const getFunding = (handle) => {
    let vpObject = vpHandler.get
    if(vpObject[handle] !== null && vpObject[handle] !== undefined) return vpObject[handle]
    else return null
  }

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
  });

  return(
    
    <div class="card mb-3">
      <div class="card-header pb-1 bg-light">
        <h6 class="mb-0">Next Steps</h6>
        <div class="row col m-0 p-0">
          <div>
            <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
              <i class="fas fa-info-circle mr-1"/>
              Quick help & legend
            </div>
          </div>
          <div class="ml-auto">
            <div class="me-2 fs--2 mars-blue" onClick={e => setHide(!hide)} role="button">
              view / hide
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div class={`${open ? "collapse" : ""}`} id="collapseFilter">
          <div class="p-card rounded fs--2">
            <p>The fields and their explanation:
            </p>
          </div>
        </div>
        <div class={`${hide ? "collapse" : ""} card-body`}>
        <div class="row fs--2">
          <div class="col-12 col-lg-6 mb-3">
            <h6 class="fs--2">Delivery plan</h6>
            <p>
              What is your action plan for the next stage of development of the VP? What are the key hypotheses you want to test and activities you want to deliver?
            </p>
            <div id="deliveryPlan">
              <ReactMde
                disablePreview={true}
                value={vpHandler.get.nextStepsActionPlan}
                onChange={e => handleChange(e, "nextStepsActionPlan")}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                toolbarCommands={[["bold", "italic", "unordered-list"]]}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(converter.makeHtml(markdown))
                }
                childProps={{
                  writeButton: {
                    tabIndex: -1
                  }
                }}
              />  
            {// Specific deliverables will include:
              // <ul>
              //   <li>Developing a robust learning agenda and executing close-loop marketing tests with Marketing Ops team</li>
              //   <li>Gathering 1P data and leveraging it to build 1:1 relationships and generate insights that are valuable internally and externally</li>
              //   <li>Optimize performance media mix and validate lead KPIs and measures of incrementality (new to brand / incremental audiences)</li>
              //   <li>Determine if there are additional test or optimizations needed to pilot</li>
              // </ul>
            }
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <h6 class="fs--2">Target outcomes</h6>
            <p>What outcomes are you targeting as a result of the next stage of development? What are the KPIs you will be tracking to measure these outcomes?</p>
            <div id="targetOutcomes">
              <ReactMde
                disablePreview={true}
                value={vpHandler.get.nextStepsOutcomes}
                onChange={e => handleChange(e, "nextStepsOutcomes")}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                toolbarCommands={[["bold", "italic", "unordered-list"]]}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(converter.makeHtml(markdown))
                }
                childProps={{
                  writeButton: {
                    tabIndex: -1
                  }
                }}
              />
              {// The outcome of the work will be to determine if this proposition is a viable and valuable DTC offering for GREENIES through a live in-market MVP.
              // <br/>
              // Specific KPIs will include:
              // <ul>
              //   <li>CAC:  $70-80</li>
              //   <li>Monthly Customers:  250-300</li>
              //   <li>% Subscribers:  35-50%</li>
              //   <li>Subscriber KPIs:
              //     <ul>
              //       <li>$40 AOV</li>
              //       <li>Monthly Cohort Retention:  60%-85% Mth 2 to ~25%-40% Mth 6</li>
              //     </ul>
              //   </li>
              //   <li>Non-Subscriber KPIs:
              //     <ul>
              //       <li>$65 AOV</li>
              //       <li>Monthly Retention:  25% by Mth 4</li>
              //     </ul>
              //   </li>
              //   <li>COGS
              //     <ul>
              //       <li>Pick/Pack/Ship:  $10-$13/order (~20-25%)</li>
              //     </ul>
              //   </li>
              //   <li>Audience Incrementality:
              //     <ul>
              //       <li>25% New to Brand</li>
              //       <li>% Younger Millennial and/or Gen Z</li>
              //     </ul>
              //   </li>
              // </ul>
              }
            </div>
          </div>
          
          <div class="col-12 col-lg-6 mb-3">
            <h6 class="fs--2">Required funding</h6>
            <p>How much incremental funding are you asking for / is required to deliver the plan and the outcomes described above? Include both one-off and incremental running costs for technical development, sales & marketing and overheads. Exclude Box costs.</p>
            <p>Incremental funding required for next stage ($):</p>
            <span class="input-dollar" >
              <input 
                disabled={api.checkDisabled(vpHandler)}  
                //class="form-control"
                style={{content: "test"}}
                type="number"
                placeholder="Funding ($)" 
                value={getFunding("nextStepsFunding")}
                onChange={e => handleFunding(e, "nextStepsFunding")}
              />            
            </span> 
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <h6 class="fs--2">Funding Allocation</h6>
            <p>Justification for the incremental funding</p> 
            <div id="targetOutcomes">
              <ReactMde
                disablePreview={true}
                value={vpHandler.get.nextStepsFundingAllocation}
                onChange={e => handleChange(e, "nextStepsFundingAllocation")}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                toolbarCommands={[["bold", "italic", "unordered-list"]]}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(converter.makeHtml(markdown))
                }
                childProps={{
                  writeButton: {
                    tabIndex: -1
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>      
  )}