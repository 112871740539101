export const socialAnalysisWebTraffic = `
query WebGetVisit {
  WebGetVisit {
    WebTraffic {
      _id
      uuid
      cid
      visits
      previousVisits
      change
    }
  }
}
`;
