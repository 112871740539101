import React from "react";
import { Input } from "reactstrap";
//import 'assets/scss/custom/_individualVP.scss';
// import { hoverCopyList } from "../../components/Lists/hoverCopyList";
import * as api from "../../components/API/IndividualVpAPI";


export const InputFieldCell = ({item, title, handle, vpHandler, team}) => {

  const handleChange = (e) => {
    let vpObject = vpHandler.get
    let index = vpObject[team].findIndex(obj => obj.name === item.name)

    if (index !== -1) {
      vpObject[team][index][handle] = e.target.value
    }
    vpHandler.set({...vpObject})
  }

  

  return(
    <div className="w-100 fs--2 ">
      <Input 
        className="h-50" 
        type="textfield" 
        name={handle}
        style={{fontSize: '12px', border: '0'}}
        onChange={e=>handleChange(e)}
        //onChange={e => api.createVPDetails(vpHandler, e)}
        placeholder={`${title}...`}
        value={item[title.toLowerCase()]}
        disabled={api.checkDisabled(vpHandler)}
      />
    </div>
  )
}