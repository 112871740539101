import React from 'react';

const PipelineMixDivisionCard = ({division, total}) => {
  //let occurrence = Math.round((item.divisionOccurrence / totalOccurrence) * 100 ) + "%";
  // let imageAlt = "";
  // let imageSource = "";

  // if (item.division === "Royal Canin") imageSource = RC;
  // if (item.division === "Pet Nutrition") imageSource = MP;
  // if (item.division === "Unspecified") imageAlt = "Unspecified";
  
  let name = ""
  let amount = ""

  Object.keys(division).map(item => {
    name = item
    amount = (division[item] * 100 / total).toFixed(0)
  })

  return (
    <tr class="btn-reveal-trigger">
      <td class="align-top name">
        {name}
      </td>
      <td class="align-top perc text-end">{amount} %</td>
    </tr>
  );
}

export default PipelineMixDivisionCard;