import React from "react";

import corner1 from './../../../assets/img/icons/spot-illustrations/corner-1.png';
import corner2 from './../../../assets/img/icons/spot-illustrations/corner-2.png';
import corner3 from './../../../assets/img/icons/spot-illustrations/corner-3.png';

export const LearnHeader = (props) => {

  return(
    
    <div class="row g-3 mb-3">
      <div class="col-sm-6 col-md-4 pb-2">
        <div class="card overflow-hidden" style={{minWidth: "12rem"}}>
          <div 
            class="bg-holder bg-card" 
            style={{backgroundImage: `url(${corner1})`}}
            >
          </div>

          <div class="card-body position-relative">
            <h6>Playbooks & guides</h6>
            <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-warning" data-countup='{"endValue":3,"decimalPlaces":0,"suffix":""}'>
              3
            </div>
            <a class="fw-semi-bold fs--1 text-nowrap" href="#playbooks">
              View them
              <span class="fas fa-angle-right ms-1 ml-1" data-fa-transform="down-1"/>
            </a>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 pb-2">
        <div class="card overflow-hidden" style={{minWidth: "12rem"}}>
          <div class="bg-holder bg-card" style={{backgroundImage: `url(${corner2})`}}>
          </div>

          <div class="card-body position-relative">
            <h6>Tools & templates</h6>
            <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info" data-countup='{"endValue":3,"decimalPlaces":0,"suffix":""}'>
              7
            </div>
            <a class="fw-semi-bold fs--1 text-nowrap" href="#tools">
              Use them
              <span class="fas fa-angle-right ms-1 ml-1" data-fa-transform="down-1"/>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-4 pb-2">
        <div class="card overflow-hidden" style={{minWidth: "12rem"}}>
          <div class="bg-holder bg-card" style={{backgroundImage: `url(${corner3})`}}>
          </div>

          <div class="card-body position-relative">
            <h6>Other Resources</h6>
            <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif" data-countup='{"endValue":0,"prefix":""}'>
              4
            </div>
            <a class="fw-semi-bold fs--1 text-nowrap" href="#other">
              Read them
              <span class="fas fa-angle-right ms-1 ml-1" data-fa-transform="down-1"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  )}