import React, { useEffect, useState } from "react";

import PageTitle from "./../../../components/header/PageTitle"
import { FilterDeck } from "../../../components/Cards/FilterDeck";
// import { ValuePropositions } from "../../../components/Cards/ValuePropositions";
import { PipelinePotential } from "../../../components/Cards/PipelinePotential";
import { PipelineShape } from "../../../components/Cards/PipelineShape";
import { PipelineMix } from "../../../components/Cards/PipelineMix";
import { PipelineProgress } from "../../../components/Cards/PipelineProgress";

import * as api from "../../../components/API/ControlTowerAPI";
import * as vpApi from "../../../components/API/IndividualVpAPI";

import ValuePropositionTable from "../../dashboards/ValuePropositionTable";

const VPControlTower = () => {
  const [init, setInit] = useState(false);
  //const [page, setPage] = React.useState(0);

  const [pipelineMix, setPipelineMix] = useState([]);
  const [pipelineMixData, setPipelineMixData] = useState([]);
  const [pipelineShape, setPipelineShape] = useState([]);
  const [activePipelineProgress, setActivePipelineProgress] = useState([]);
  const [nonActivePipelineProgress, setNonActivePipelineProgress] = useState([]);
  const [pipelinePotential, setPipelinePotential] = useState([]);
  const [valueProps, setValueProps] = useState([]);
  const [valuePropsBackup, setValuePropsBackup] = useState([]);
  //const [autofillList, setAutofillList] = useState([]);
  const [includePerished, setIncludePerished] = useState(false);
  const [string, setString] = useState("");
  const [allVp, setAllVp] = React.useState([]);
  const [allVpBackup, setAllVpBackup] = React.useState([]);

  // React.useEffect(() => {
  //   if(!init) {
  //     setInit(!init)
  //     api.getManyVp(setAllVp)
  //   }
  // }, [init]);

  React.useEffect(() => {
    //console.log(`allVp ${JSON.stringify(allVp)}`)
  }, [allVp]);

  useEffect(() => {
    if (!init) {
      setInit(true);
      vpApi.getManyVp(setAllVp, setAllVpBackup)
      api.GetPipelineMix(setPipelineMix); 
      api.GetPipelineShape(setPipelineShape);
      api.GetPipelineProgress(setActivePipelineProgress, setNonActivePipelineProgress);
      api.GetPipelinePotential(setPipelinePotential);
      api.GetValueProps(setValueProps, setValuePropsBackup);
    }
  }, [init]);

  useEffect(() => {
    if (pipelineMix.length > 0 ) {
      getPipelineMixPercentages();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineMix]);

  // useEffect(() => {
  //   if (valueProps.length > 0 ) {
  //     createSearchBarList();
  //     //console.log(`value props: ${JSON.stringify(valueProps)}`)
  //   }
  // }, [valueProps]);

  const getPipelineMixPercentages = () => {
    let divisions = [];
    let countries = [];
    let speciesS = []; 
    let channels = []; 

    let divisionData = [];
    let countyData = [];
    let speciesData = [];
    let channelsData = [];

    let _pipelineMixData = [];

 //-----------------------------------------   
    pipelineMix.forEach(item => divisions.push(item.division));
    pipelineMix.forEach(item => countries.push(item.country));
    pipelineMix.forEach(item => 
      item.both ? speciesS.push("Both") 
    : item.cat ? speciesS.push("Cat") 
    : item.dog ? speciesS.push("Dog")
    : (""));
    pipelineMix.forEach(item => channels.push(item.channel));

 //-----------------------------------------

    let distinctDivisions = Array.from(new Set(divisions));
    let distinctCountries = Array.from(new Set(countries));
    let distinctSpecies = Array.from(new Set(speciesS));
    let distinctChannels = Array.from(new Set(channels));
    
    for (let i = 0; i < distinctDivisions.length; i++){
      let data = {
        division: distinctDivisions[i],
        divisionOccurrence: divisions.reduce((n, x) => n + (x === distinctDivisions[i]), 0),
      }
      divisionData.push(data);
    }
    
    for (let i = 0; i < distinctCountries.length; i++){
      let data = {
        country: distinctCountries[i],
        countryOccurrence: countries.reduce((n, x) => n + (x === distinctCountries[i]), 0),
      }
      countyData.push(data);
    }
    
    for (let i = 0; i < distinctSpecies.length; i++){
      let data = {
        species: distinctSpecies[i],
        speciesOccurrence: speciesS.reduce((n, x) => n + (x === distinctSpecies[i]), 0),
      }
      speciesData.push(data);
    }
    
    for (let i = 0; i < distinctChannels.length; i++){
      let data = {
        channel: distinctChannels[i],
        channelOccurrence: channels.reduce((n, x) => n + (x === distinctChannels[i]), 0),
      }
      channelsData.push(data);
    }
    
    _pipelineMixData.push(divisionData);
    _pipelineMixData.push(countyData);
    _pipelineMixData.push(speciesData);
    _pipelineMixData.push(channelsData);
    _pipelineMixData.push({occurrences: pipelineMix.length})

    setPipelineMixData( [..._pipelineMixData] );
  }

  // const createSearchBarList = () => {
  //   let _data = valueProps;
  //   let _list = [];

  //   _data.forEach((item) => {
  //     let object = {
  //       name: item._id,
  //       uuid: item.uuid,
  //     };
  //     _list.push({ ...object });
  //   });
  //   setAutofillList([..._list]);
  // };
  
  return (<>
    {/* {valueProps.length > 0 ? console.log(valueProps.length) : console.log(valueProps.length)} */}
      <div class="px-3 pt-3">
        <PageTitle title="D2C Accelerator" subtitle="Manage value propositions" />
        <FilterDeck string={string} setString={setString} valueProps={allVp} setValueProps={setAllVp} valuePropsBackup={allVpBackup} includePerished={includePerished}/>
        <ValuePropositionTable 
          string={string}
          setString={setString}
          valueProps={allVp} 
          itemsPerPage={4} 
          includePerished={includePerished}
          setIncludePerished={setIncludePerished} 
        />
        <div class="row">
          <div class="col-md-3 mb-3">
            <PipelinePotential data={pipelinePotential} valueProps={allVp}/>
          </div>
          <div class="col-md-9 mb-3">
            <PipelineShape data={pipelineShape} valueProps={allVp}/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <PipelineMix data={pipelineMixData}  valueProps={allVp}/>
          </div>
          <div class="col-md-6 mb-3">
            <PipelineProgress 
              active={activePipelineProgress} 
              setActive={setActivePipelineProgress}
              nonActive={nonActivePipelineProgress}
              setNonActive={setNonActivePipelineProgress}
              valueProps={allVp}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VPControlTower;
