/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

function AdminFooter() {
  return (
    <>
      <div class="text-center"> 
        <div>
          <p class="fs--2 mb-0 text-600">2022 &copy; Mars v2022-Q2-41 | {process.env.REACT_APP_VERSION} </p> 
        </div>
        <div>
          <p class="fs--2 mb-0 text-600"></p>
        </div>
      </div>
    </>
  );
}

export default AdminFooter;

      // <Container fluid>
      //   <footer className="footer pt-0">
      //     <Row className="align-items-center justify-content-lg-between">
      //       <Col style={{margin: 0, padding: 0}}>
      //       <div class="row g-0 justify-content-between fs--1 mt-2 mb-3">
      //       <div class="text-center">
      //         <p class="mb-0 text-600">2021 &copy; <a className="mars-blue" href="#">Mars</a></p>
      //       </div>
      //       <div class="text-center">
      //         <p class="mb-0 text-600">v1.9</p>
      //       </div>
      //     </div>
      //       </Col>
            
      //     </Row>
      //   </footer>
      // </Container>