export const  stageGateKpiList = {
    'ideation': {
        'intrinsic':{
            a: [],
            b: [],
            c: []
        },
        'engagement':{
            a: [],
            b: [],
            c: []
        },
        'data':{
            a: [],
            b: [],
            c: []
        },
        // 'routetoscale':{
        //     a: [],
        //     b: [],
        //     c: []
        // },
    },

    'customerVal': {
        'intrinsic':{
            a: [
                {
                    name:"ctr", 
                    bench: '~1%', 
                    modelled: false,
                    copy: 'CTR benchmark here refers to click-through-rates from social media or Google Adword campaigns to a destination/landing page.'
                },  
                // {
                //     name:"session", 
                //     bench: '>1.3', 
                //     modelled: false,
                //     copy: 'Sessions per user per month is a good indicator for the relevance of your offering to the target user.'
                // }, 
                {
                    name:"bcr", 
                    bench: '5-8%', 
                    modelled: false,
                    copy: 'tbd'
                },
            ],
            b: [
                {
                    name:"cac", 
                    bench: '', 
                    modelled: true,
                    copy: 'tbd'
                },
            ],
            c: []
        },
        'engagement':{
            a: [],
            b: [],
            c: []
        },
        'data':{
            a: [],
            b: [],
            c: []
        },
        
    },

    'mvp':{
        'intrinsic':{
            a: [
                {
                    name:"ctr", 
                    bench: '1.5%', 
                    modelled: false,
                    copy: 'CTR benchmark here refers to click-through-rates from social media or Google Adword campaigns to a destination/landing page.'
                }, 
                // {
                //     name:"session", 
                //     bench: '>1.4', 
                //     modelled: false,
                //     copy: 'Sessions per user per month is a good indicator for the relevance of your offering to the target user. 1.3 makes you an average website, 1.6. puts you in the top 20%, 1.9 and above puts you int the top 10% of websites.'
                // },
                {
                    name:"bcr", 
                    bench: '0.75% | 2-5%', 
                    modelled: false,
                    copy: 'Conversation rate benchmark at this stage refers to conversion of customer to waiting list, or leaving email address, not product/service purchase'
                }, 
                {
                    name:"cac", 
                    bench: '', 
                    modelled: false,
                    copy: 'tbd'
                }, 
                {
                    name:"aov", 
                    bench: '', 
                    modelled: false,
                    copy: 'No benchmark, as AOV is highly dependent on value proposition.'
                }, 
                {
                    name:"nsv", 
                    bench: '', 
                    modelled: false,
                    copy: 'No benchmark, as NSV is highly dependent on value proposition.'
                }, 
                {
                    name:"gmac", 
                    bench: '', 
                    modelled: false,
                    copy: 'No benchmark, as GMAC is highly dependent on value proposition. As a reference value consider that overall RC GMAC margin is X%, and X% for PN.'
                }, 
            ],
            b: [
                {
                    name:"ltv", 
                    bench: '', 
                    modelled: true,
                    copy: 'tbd'
                },
                {
                    name:"ltvcac", 
                    bench: '1:1', 
                    modelled: true,
                    copy: 'At MVP stage, LTV:CAC ratio is ~1:1'
                },
            ],
            c: []
        },
        'engagement':{
            a: [
                {
                    name:"nps", 
                    bench: '>10%', 
                    modelled: false,
                    copy: 'tbd'
                },
            ],
            b: [
                {
                    name:"rp", 
                    bench: 'tbc', 
                    modelled: false,
                    copy: 'tbd'
                },
                {
                    name:"tc", 
                    bench: 'tbc', 
                    modelled: true,
                    copy: 'tbd'
                },
            ],
            c: []
        },
        'data':{ 
            a: [],
            b: [],
            c: []
        },
        
    },

    'optimize':{  
        'intrinsic':{ 
            a: [
                {
                    name:"ctr", 
                    bench: '~2%', 
                    modelled: false,
                    copy: 'CTR benchmark here refers to click-through-rates from social media or Google Adword campaigns to a destination/landing page.'
                }, 
                // {
                //     name:"session", 
                //     bench: '1.9', 
                //     modelled: false,
                //     copy: 'Sessions per user per month is a good indicator for the relevance of your offering to the target user. 1.3 makes you an average website, 1.6. puts you in the top 20%, 1.9 and above puts you int the top 10% of websites.'
                // },
                {
                    name:"bcr", 
                    bench: '>1%', 
                    modelled: false,
                    copy: 'tbd'
                },
                {
                    name:"cac", 
                    bench: '', 
                    modelled: false,
                    copy: 'tbd'
                }, 
                {
                    name:"ltv", 
                    bench: '', 
                    modelled: true,
                    copy: 'General LTV benchmarks are not very helpful. Optimal LTV only makes sense in relation to CAC, i.e. once you know what CAC is, then can start building a view on what LTV is required to build sustainable unit economics. For LTV:CAC benchmarks, see LTV:CAC KPI.'
                }, 
                {
                    name:"ltvcac", 
                    bench: '>1:1', 
                    modelled: true,
                    copy: 'With a focus on improving CAC, LTV:CAC ratio should start improving in Optimize stage, moving above 1:1 (with LTV still being modelled, rather than observed.'
                }, 
                {
                    name:"aov", 
                    bench: '', 
                    modelled: false,
                    copy: 'No benchmark, as AOV is highly dependent on value proposition.'
                }, 
                {
                    name:"nsv", 
                    bench: '', 
                    modelled: false,
                    copy: 'No benchmark, as NSV is highly dependent on value proposition.'
                }, 
                {
                    name:"gmac", 
                    bench: '', 
                    modelled: false,
                    copy: 'No benchmark, as GMAC is highly dependent on value proposition. As a reference value consider that overall RC GMAC margin is X%, and X% for PN.'
                },
            ],
            b: [],
            c: []
        },
        'engagement':{ 
            a: [
                {
                    name: "nps", 
                    bench: '>20%', 
                    modelled: false,
                    copy: 'tbd'
                },
                {
                    name:"rp", 
                    bench: 'tbc', 
                    modelled: false,
                    copy: 'tbd'
                },
                {
                    name:"tc", 
                    bench: 'tbc', 
                    modelled: false,
                    copy: 'tbd'
                },
                {
                    name:"rr", 
                    bench: '>1%', 
                    modelled: false,
                    copy: 'tbd'
                }
            ],
            b: [],
            c: []
        },
        'data':{
            a: [],
            b: [],
            c: []
        },
                 
    },

    'grow':{  
        'intrinsic':{ 
            a: [
                {
                    name:"ctr", 
                    bench: '2%', 
                    modelled: false,
                    copy: 'CTR benchmark here refers to click-through-rates from social media or Google Adword campaigns to a destination/landing page.'
                }, 
                // {
                //     name:"session", 
                //     bench: '1.9', 
                //     modelled: false,
                //     copy: 'Sessions per user per month is a good indicator for the relevance of your offering to the target user. 1.3 makes you an average website, 1.6. puts you in the top 20%, 1.9 and above puts you int the top 10% of websites.'
                // },
                {
                    name:"bcr", 
                    bench: '>1%', 
                    modelled: false,
                    copy: 'tbd'
                },
                {
                    name:"cac", 
                    bench: '', 
                    modelled: false,
                    copy: 'tbd'
                }, 
                {
                    name:"ltv", 
                    bench: '', 
                    modelled: true,
                    copy: 'General LTV benchmarks are not very helpful. Optimal LTV only makes sense in relation to CAC, i.e. once you know what CAC is, then can start building a view on what LTV is required to build sustainable unit economics. For LTV:CAC benchmarks, see LTV:CAC KPI.'
                }, 
                {
                    name:"ltvcac", 
                    bench: '>1:1', 
                    modelled: true,
                    copy: 'With a focus on improving CAC, LTV:CAC ratio should start improving in Optimize stage, moving above 1:1 (with LTV still being modelled, rather than observed.'
                }, 
                {
                    name:"aov", 
                    bench: '', 
                    modelled: false,
                    copy: 'No benchmark, as AOV is highly dependent on value proposition.'
                }, 
                {
                    name:"nsv", 
                    bench: '', 
                    modelled: false,
                    copy: 'No benchmark, as NSV is highly dependent on value proposition.'
                }, 
                {
                    name:"gmac", 
                    bench: '', 
                    modelled: false,
                    copy: 'No benchmark, as GMAC is highly dependent on value proposition. As a reference value consider that overall RC GMAC margin is X%, and X% for PN.'
                },
            ],
            b: [],
            c: []
        },
        'engagement':{ 
            a: [
                {
                    name: "nps", 
                    bench: '>20%', 
                    modelled: false,
                    copy: 'tbd'
                },
                {
                    name:"rp", 
                    bench: 'tbc', 
                    modelled: false,
                    copy: 'tbd'
                },
                {
                    name:"tc", 
                    bench: 'tbc', 
                    modelled: false,
                    copy: 'tbd'
                },
                {
                    name:"rr", 
                    bench: '>1%', 
                    modelled: false,
                    copy: 'tbd'
                }
            ],
            b: [],
            c: []
        },
        'data':{
            a: [],
            b: [],
            c: []
        },
        
         
    },
    'scale':{  
        'intrinsic':{ 
            a: [
                {
                    name:"ctr", 
                    bench: '>2%', 
                    modelled: false,
                    copy: 'CTR benchmark here refers to click-through-rates from social media or Google Adword campaigns to a destination/landing page.'
                }, 
                // {
                //     name:"session", 
                //     bench: '>1.6', 
                //     modelled: false,
                //     copy: 'Sessions per user per month is a good indicator for the relevance of your offering to the target user. 1.3 makes you an average website, 1.6. puts you in the top 20%, 1.9 and above puts you int the top 10% of websites.'
                // },
                {
                    name:"bcr", 
                    bench: '>2%',
                    modelled: false,
                    copy: 'tbd'
                },
                {
                    name:"cac", 
                    bench: '',
                    modelled: false,
                    copy: 'tbd'
                }, 
                {
                    name:"ltv", 
                    bench: '', 
                    modelled: false,
                    copy: 'General LTV benchmarks are not very helpful. Optimal LTV only makes sense in relation to CAC, i.e. once you know what CAC is, then can start building a view on what LTV is required to build sustainable unit economics. For LTV:CAC benchmarks, see LTV:CAC KPI.'
                }, 
                {
                    name:"ltvcac", 
                    bench: '>1:3', 
                    modelled: false,
                    copy: '3:1 LTV:CAC ratio is a general rule-of-thumb sign for string unit economics. Everything above 3:1 is considered a very strong business model.'
                }, 
                {
                    name:"aov", 
                    bench: '', 
                    modelled: false,
                    copy: 'No benchmark, as AOV is highly dependent on value proposition.'
                }, 
                {
                    name:"nsv", 
                    bench: '', 
                    modelled: false,
                    copy: 'No benchmark, as NSV is highly dependent on value proposition.'
                }, 
                {
                    name:"gmac", 
                    bench: '', 
                    modelled: false,
                    copy: 'No benchmark, as GMAC is highly dependent on value proposition. As a reference value consider that overall RC GMAC margin is X%, and X% for PN.'
                },
            ],
            b: [],
            c: []
        },
        'engagement':{ 
            a: [
                {
                    name: "nps", 
                    bench: '>40%', 
                    modelled: false,
                    copy: 'tbd'
                },
                {
                    name:"rp", 
                    bench: 'tbc', 
                    modelled: false,
                    copy: 'tbd'
                },
                {
                    name:"tc", 
                    bench: 'tbc', 
                    modelled: false,
                    copy: 'tbd'
                },
                {
                    name:"rr", 
                    bench: '>2%', 
                    modelled: false,
                    copy: 'tbd'
                }
            ],
            b: [],
            c: []
        },
        'data':{
            a: [],
            b: [],
            c: []
        },
        
         
    },   
  }
