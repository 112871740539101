import React from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { Input } from 'reactstrap';

const SmallSearchBar = ({ list, url, string, setString }) => {

  // //console.log(`searhc: ${JSON.stringify(list)}`)

  // const handleOnSearch = (string, results) => {
  //   // onSearch will have as the first callback parameter
  //   // the string searched and for the second the results.
  //   //console.log(string, results);
  // };

  // const handleOnHover = result => {
  //   // the item hovered
  //   //console.log(result);
  // };

  const handleOnSelect = item => {
    // let _obj =  JSON.parse(localStorage.getItem("filterObject"))
    // _obj.Brand = item.cid
    // localStorage.setItem("filterObject", JSON.stringify(_obj));
    // console.log(`obj: ${JSON.stringify(JSON.parse(localStorage.getItem("filterObject")))}`)
    //window.location.href = `/insight/brandoverview/${encodeURIComponent(item.cid)}`;
    //console.log(item);
  };

  // const handleOnFocus = () => {
  //   //console.log('Focused');
  // };

  return (
    <>
      <div class="ml-auto row w-100">
        <div class="ml-auto" style={{ width: '75%' }}>
          {/* <ReactSearchAutocomplete
            class="text-sans-serif"
            items={list}
            //onSearch={handleOnSearch}
            //onHover={handleOnHover}
            onSelect={handleOnSelect}
            //onFocus={handleOnFocus}
            placeholder="Search brands"
            //autoFocus
            showIcon={false}
            maxResults={3}
            styling={{
              borderRadius: `${window.innerWidth>991?"0.3rem 0rem 0rem 0.3rem":"0.3rem 0.3rem 0.3rem 0.3rem "}`,
              border: '1px solid rgb(210, 220, 240)',
              boxShadow: '0px',
              //zIndex: '5',
              height: '23px',
              fontSize: '11px',
              fontFamily: 'Poppins, sans-serif',
            }}
          /> */}
          <Input
            //style={{height: '25px', boxShadow: '0'}}
            class="text-sans-serif fs--2 mars-blue"
            //items={list}
            //onSearch={handleOnSearch}
            //onClear={e=>setString("")}
            //value="test"
            //onHover={handleOnHover}
            //onSelect={handleOnSelect}
            //onFocus={handleOnFocus}
            placeholder="Search Brands"
            onChange={e=>setString(e.target.value)}
            value={string}
            //showIcon={false}
            //maxResults={3}
            style={{
              borderRadius: '0.3rem 0rem 0rem 0.3rem',
              border: '1px solid rgb(210, 220, 240)',
              boxShadow: '0px 0px 0px #fff',
              shadowRadius: '0px',
              //zIndex: '5',
              height: '25px',
              fontSize: '11px',
              fontFamily: 'Poppins, sans-serif',
            }}
          />
        </div>
        <div
          class={`${window.innerWidth>991?"":"d-none"}`}
          style={{
            borderRadius: '0rem 0.3rem 0.3rem 0rem',
            border: '1px solid rgb(210, 220, 240)',
            height: '25px',
            width: '50px',
            marginLeft: '-1px',
            padding: 0,
            backgroundColor: '#F9FAFD',
          }}
        >
          <i
            class="fas fa-search"
            style={{ color: 'gray', fontSize: '12px', width: '100%', textAlign: 'center', verticalAlign: 'center' }}
          />
        </div>
      </div>
    </>
  );
};

export default SmallSearchBar;
