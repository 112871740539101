import React, { useRef, useEffect } from 'react';
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";

// react library for routing
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
// core components
//import HomeNavbar from '../components/navbar/HomeNavbar.js';
import AdminFooter from '../components/footer/AdminFooter.js';
//import Sidebar from '../components/side-panel/Sidebar.js';

import NavbarVertical from '../components/navbar/NavbarVertical';
import NavbarTop from '../components/navbar/NavbarTop';

import routes from '../Routing/routes.js';

const Home = () => {
  const { authState, oktaAuth } = useOktaAuth();
  // const [sidenavOpen, setSidenavOpen] = useState(false);
  const location = useLocation();
  const mainContentRef = useRef(null);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
      if (!authState.isAuthenticated) {
        localStorage.setItem("userGroup", []);
      } else {
        getUserInfo(authState.accessToken.claims.sub, (err, out) => {
          if(err) console.log('user not in auth groups')
          localStorage.setItem("customUserGroup", out.groups)
        })
        localStorage.setItem("userGroup", authState.accessToken.claims.groups);
      }
    }, [authState, oktaAuth]);

    const getUserInfo = (email, cb) => {
      console.log('email:', email)
      axios({
        url: process.env.REACT_APP_API,
        method: "post",
        data: {
          query: `query GetUser($user:UserInputType){
            GetUser(user:$user){
              email
              groups
            }
          }`,
          variables: { user: { email }}
        },
      })
      .then(result => cb(false, result.data.data.GetUser))
      .catch(err => cb(true, err));
    };

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/home') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  // const getBrandText = path => {
  //   for (let i = 0; i < routes.length; i++) {
  //     if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
  //       return routes[i].name;
  //     }
  //   }
  //   return 'Brand';
  // };
  // toggles collapse between mini sidenav and normal
  // const toggleSidenav = () => {
  //   if (document.body.classList.contains('g-sidenav-pinned')) {
  //     document.body.classList.remove('g-sidenav-pinned');
  //     document.body.classList.add('g-sidenav-hidden');
  //   } else {
  //     document.body.classList.add('g-sidenav-pinned');
  //     document.body.classList.remove('g-sidenav-hidden');
  //   }
  //   setSidenavOpen(!sidenavOpen);
  // };
  // const getNavbarTheme = () => {
  //   return location.pathname.indexOf('admin/alternative-dashboard') === -1 ? 'dark' : 'light';
  // };

  return (
    <>
      <div 
        class={`${window.innerWidth>991? "d-none" : "" }`}
        style={{
          height: '57px',
          backgroundColor: "white",
          position: "sticky", 
          top: "0", 
          zIndex: "100",
          boxShadow: window.location.pathname.includes("individual")? "" : "0 0 10px #ccc"
        }}
      >
        <NavbarTop/>
      </div>
      <div className="navbars-wrapper" style={{ maxWidth: '1500px', margin: 'auto', backgroundColor: 'white' }}>
        <NavbarVertical />
        <div className="content pt-0 px-3" ref={mainContentRef}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/insight/dashboard" />
          </Switch>
          <AdminFooter />
        </div>{' '}
      </div>
    {/*
      {sidenavOpen ? <div className="backdrop d-xl-none" onClick={toggleSidenav} /> : null} */}
    </>
  );
};

export default Home;
