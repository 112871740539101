export const colors = [
  'rgba(0, 0, 150, 1)',
  'rgba(0, 0, 150, 0.992)',
  'rgba(0, 0, 150, 0.984)',
  'rgba(0, 0, 150, 0.976)',
  'rgba(0, 0, 150, 0.968)',
  'rgba(0, 0, 150, 0.96)',
  'rgba(0, 0, 150, 0.952)',
  'rgba(0, 0, 150, 0.944)',
  'rgba(0, 0, 150, 0.9359999999999999)',
  'rgba(0, 0, 150, 0.9279999999999999)',
  'rgba(0, 0, 150, 0.92)',
  'rgba(0, 0, 150, 0.912)',
  'rgba(0, 0, 150, 0.904)',
  'rgba(0, 0, 150, 0.896)',
  'rgba(0, 0, 150, 0.888)',
  'rgba(0, 0, 150, 0.88)',
  'rgba(0, 0, 150, 0.872)',
  'rgba(0, 0, 150, 0.864)',
  'rgba(0, 0, 150, 0.856)',
  'rgba(0, 0, 150, 0.848)',
  'rgba(0, 0, 150, 0.84)',
  'rgba(0, 0, 150, 0.832)',
  'rgba(0, 0, 150, 0.8240000000000001)',
  'rgba(0, 0, 150, 0.8160000000000001)',
  'rgba(0, 0, 150, 0.808)',
  'rgba(0, 0, 150, 0.8)',
  'rgba(0, 0, 150, 0.792)',
  'rgba(0, 0, 150, 0.784)',
  'rgba(0, 0, 150, 0.776)',
  'rgba(0, 0, 150, 0.768)',
  'rgba(0, 0, 150, 0.76)',
  'rgba(0, 0, 150, 0.752)',
  'rgba(0, 0, 150, 0.744)',
  'rgba(0, 0, 150, 0.736)',
  'rgba(0, 0, 150, 0.728)',
  'rgba(0, 0, 150, 0.72)',
  'rgba(0, 0, 150, 0.712)',
  'rgba(0, 0, 150, 0.704)',
  'rgba(0, 0, 150, 0.696)',
  'rgba(0, 0, 150, 0.688)',
  'rgba(0, 0, 150, 0.6799999999999999)',
  'rgba(0, 0, 150, 0.6719999999999999)',
  'rgba(0, 0, 150, 0.6639999999999999)',
  'rgba(0, 0, 150, 0.6559999999999999)',
  'rgba(0, 0, 150, 0.648)',
  'rgba(0, 0, 150, 0.64)',
  'rgba(0, 0, 150, 0.632)',
  'rgba(0, 0, 150, 0.624)',
  'rgba(0, 0, 150, 0.616)',
  'rgba(0, 0, 150, 0.608)',
  'rgba(0, 0, 150, 0.6)',
  'rgba(0, 0, 150, 0.592)',
  'rgba(0, 0, 150, 0.584)',
  'rgba(0, 0, 150, 0.5760000000000001)',
  'rgba(0, 0, 150, 0.5680000000000001)',
  'rgba(0, 0, 150, 0.56)',
  'rgba(0, 0, 150, 0.552)',
  'rgba(0, 0, 150, 0.544)',
  'rgba(0, 0, 150, 0.536)',
  'rgba(0, 0, 150, 0.528)',
  'rgba(0, 0, 150, 0.52)',
  'rgba(0, 0, 150, 0.512)',
  'rgba(0, 0, 150, 0.504)',
  'rgba(0, 0, 150, 0.496)',
  'rgba(0, 0, 150, 0.488)',
  'rgba(0, 0, 150, 0.48)',
  'rgba(0, 0, 150, 0.472)',
  'rgba(0, 0, 150, 0.46399999999999997)',
  'rgba(0, 0, 150, 0.45599999999999996)',
  'rgba(0, 0, 150, 0.44799999999999995)',
  'rgba(0, 0, 150, 0.43999999999999995)',
  'rgba(0, 0, 150, 0.43199999999999994)',
  'rgba(0, 0, 150, 0.42399999999999993)',
  'rgba(0, 0, 150, 0.41600000000000004)',
  'rgba(0, 0, 150, 0.40800000000000003)',
  'rgba(0, 0, 150, 0.4)',
  'rgba(0, 0, 150, 0.392)',
  'rgba(0, 0, 150, 0.384)',
  'rgba(0, 0, 150, 0.376)',
  'rgba(0, 0, 150, 0.368)',
  'rgba(0, 0, 150, 0.36)',
  'rgba(0, 0, 150, 0.352)',
  'rgba(0, 0, 150, 0.344)',
  'rgba(0, 0, 150, 0.33599999999999997)',
  'rgba(0, 0, 150, 0.32799999999999996)',
  'rgba(0, 0, 150, 0.31999999999999995)',
  'rgba(0, 0, 150, 0.31199999999999994)',
  'rgba(0, 0, 150, 0.30399999999999994)',
  'rgba(0, 0, 150, 0.29600000000000004)',
  'rgba(0, 0, 150, 0.28800000000000003)',
  'rgba(0, 0, 150, 0.28)',
  'rgba(0, 0, 150, 0.272)',
  'rgba(0, 0, 150, 0.264)',
  'rgba(0, 0, 150, 0.256)',
  'rgba(0, 0, 150, 0.248)',
  'rgba(0, 0, 150, 0.24)',
  'rgba(0, 0, 150, 0.23199999999999998)',
  'rgba(0, 0, 150, 0.22399999999999998)',
  'rgba(0, 0, 150, 0.21599999999999997)',
  'rgba(0, 0, 150, 0.20799999999999996)',
  'rgba(0, 0, 150, 0.19999999999999996)',
  'rgba(0, 0, 150, 0.19199999999999995)',
  'rgba(0, 0, 150, 0.18399999999999994)',
  'rgba(0, 0, 150, 0.17599999999999993)',
  'rgba(0, 0, 150, 0.16799999999999993)',
  'rgba(0, 0, 150, 0.16000000000000003)',
  'rgba(0, 0, 150, 0.15200000000000002)',
  'rgba(0, 0, 150, 0.14400000000000002)',
  'rgba(0, 0, 150, 0.136)',
  'rgba(0, 0, 150, 0.128)',
  'rgba(0, 0, 150, 0.12)',
  'rgba(0, 0, 150, 0.11199999999999999)',
  'rgba(0, 0, 150, 0.10399999999999998)',
  'rgba(0, 0, 150, 0.09599999999999997)',
  'rgba(0, 0, 150, 0.08799999999999997)',
  'rgba(0, 0, 150, 0.07999999999999996)',
  'rgba(0, 0, 150, 0.07199999999999995)',
  'rgba(0, 0, 150, 0.06399999999999995)',
  'rgba(0, 0, 150, 0.05599999999999994)',
  'rgba(0, 0, 150, 0.04799999999999993)',
  'rgba(0, 0, 150, 0.040000000000000036)',
  'rgba(0, 0, 150, 0.03200000000000003)',
  'rgba(0, 0, 150, 0.02400000000000002)',
  'rgba(0, 0, 150, 0.016000000000000014)'
];
