import React from "react";
import ReactEcharts from "echarts-for-react";

const RaindropBarChart = ({ data }) => {

  const fixNumber = (input) => {
    //console.log(input.split('.')[0])
    let output = input.split('.')[0]
    output = output.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return output
  }

  let seriesData = [];

  Object.values(data).forEach(item => {
    seriesData.push(item);
  });

  seriesData = seriesData.slice(1);
  
  const option = {
    grid: {
      left: '1%',
      right: '30%',
      bottom: '20%',
      top: '20%',
      containLabel: true
    },
    legend: {
      type: 'plain',
      bottom: '0%',
      left: '0%',
      textStyle: {
        fontSize: '11.5',
        padding: [0, 0, 0, -7],
      },
      itemHeight: '12',
      icon: 'circle'
    },
    // tooltip: {
    //     trigger: 'axis',
    //     axisPointer: {
    //         type: 'shadow'
    //     },
    //     formatter: '{a}: {c}'
    // },
    xAxis: {
        show: false,
    },
    yAxis: {
        show: false,
    },
    series: [
      {
        name: "Total Investment (k$)",
        data: [seriesData[0]],
        type: 'bar',
        label: {
          show: true,
          position: `top`,
          color: "#000",
          fontWeight: "bold",
          fontSize: 10,
          
          formatter: function(d) {
            //console.log(data)
            return fixNumber(d.data)
          }
        },
        // markPoint: {
        //   name: "Total Investment (k$)",
        //   data: [{ type: 'max' }]
        // },
        color: "#000096",
      },
      {
        name: "NSV Potential (k$)",
        data: [seriesData[1]],
        type: 'bar',
        
        label: {
          show: true,
          position: `top`,
          color: "#000",
          fontWeight: "bold",
          fontSize: 10,
          
          formatter: function(d) {
            //console.log(data)
            return fixNumber(d.data)
          }
        },
        color: "#FF0000"
      }
    ]
  };

  return <ReactEcharts option={option} />;
};

export default RaindropBarChart;
