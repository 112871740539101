import axios from "axios";
import { getSpecificSocials } from "./queries/GetSpecificSocials";
import { getAllSocials } from './queries/GetAllSocials';
import { getSpecificWebTraffic } from './queries/GetSpecificWebTraffic';
import { getAllWebTraffic } from './queries/GetAllWebTraffic';
import { getRevenue } from './queries/GetRevenue';
import { getProductComparison } from './queries/GetProductComparison';
//import { socialAnalysisData } from "./queries/SocialAnalysisData";

import { getManyWebEngagementQuery } from "../Lists/getManyWebEngagementQuery";

export const GetManyWebEngagement = (setState) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: getManyWebEngagementQuery,
    },
  })
    .then((result) => {
      //console.log("WebEngagement: " , result.data.data)
      //console.log(`Web Engagement: ${JSON.stringify(result.data.data.GetManyWebEngagement)}`)
      setState([...result.data.data.GetManyWebEngagement]);
    })
    .catch((err) => console.log(err));
};


export const GetRevenue = (setState) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: getRevenue,
    },
  })
    .then((result) => {
      setState([...result.data.data.GetRevenues.Revenues]);
    })
    .catch((err) => console.log(err));
};

export const GetSpecificWebTraffic = (setState, companyCid) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: getSpecificWebTraffic,
      variables: {cid: companyCid },
    },
  })
    .then((result) => {
      setState([...result.data.data.GetSpecificWebTraffic.WebTraffic]);
    })
    .catch((err) => console.log(err));
};

export const GetAllWebTraffic = (setState) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: getAllWebTraffic,
    },
  })
    .then((result) => {
      setState({...result.data.data.GetAllWebTraffic});
    })
    .catch((err) => console.log(err));
};

export const GetSpecificSocials = (setState, companyCid) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: getSpecificSocials,
      variables: {cid: companyCid },
    },
  })
    .then((result) => {
      setState([...result.data.data.GetSpecificSocials.Socials]);
    })
    .catch((err) => console.log(err));
};

export const GetAllSocials = (setState) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: getAllSocials,
    },
  })
    .then((result) => {
      setState({...result.data.data.GetAllSocials});
    })
    .catch((err) => console.log(err));
};

export const GetProductComparison = (setState) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: getProductComparison,
    },
  })
    .then((result) => {
      setState([...result.data.data.GetProductComparison.ProductComparison]);
    })
    .catch((err) => console.log(err));
};

