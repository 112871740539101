export const lookUpList = {
    nsv: "NSV($)",
    gmac: "GMAC(%)",
    cac: "CAC($)",
    ltv: "LTV($)",
    ltvcac: "LTV:CAC(x:x)",
    ctr: "CTR(%)",
    session: "Sessions/User",
    bcr: "Conversion Rate(%)",
    aov: "AOV($)",
    rp: "Repeat Purchasers(%)",
    tc: "Churn rate(%)",
    rr: "Referral Rate(%)",
    nps: "NPS(%)",
    dvam: "Data value attribution model(t)",
    ppp: "Pet/Pet parent profiles(#)",
    cda: "Cost of data acquisition($)",
    sim: "Scaleable in markets(#)",
    tam: "TAM($)",
    sam: "SAM($)",
    som: "SOM($)",
    markets: "Markets",
    channels: "Channels",
    brands: "Brands",

    intrinsic: "Intrinsic",
    engagement: "Engagement",
    data: "Data",
    routetoscale: "Route To Scale",

    ideation: "1. Ideation",
    customerVal: "2. Customer discovery and validation",
    mvp: "3. MVP",
    optimize: "4. Optimize",
    grow: "5. Grow",
    scale: "6. Scale",

    //trend:
    '-1': 'Deteriorating',
    '0': 'Flat',
    '1': 'Improving',
    '2': 'Select',
    
    //actual/modelled
    //'true': 'Modelled',
    //'false': 'Actual',

    //valueModel
    'primary': 'Primary Value Driver',
    'secondary': 'Secondary Value Driver',
    'relevant': 'Not Relevant',

    // vp prop details card
    'CORE': 'Core (red)',
    'beyondTheCore': 'Beyond the core (blue)',

    'dog': 'Dog',
    'cat': 'Cat',
    'both': 'Cat & Dog',

    'false': 'No',
    'true': 'Yes',
  }