import React from "react";
import PipelineMixChannelCard from "./PipelineMixChannelCard";
import PipelineMixCountryCard from "./PipelineMixCountryCard";
import PipelineMixDivisionCard from "./PipelineMixDivisionCard";
import PipelineMixSpeciesCard from "./PipelineMixSpeciesCard";

export const PipelineMix = ({data, valueProps}) => {
  const [open, setOpen] = React.useState(true);
  const [init, setInit] = React.useState(false);
  const [divisions, setDivisions] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [species, setSpecies] = React.useState([]);
  const [channels, setChannels] = React.useState([]);

  // console.log("data: " , data);
 
  // React.useEffect(() => {
  //   if(!init && valueProps.length > 0 ){
  //     setInit(!init);
  //     getDivisions()
  //   }
  //   // eslint-disable-next-line
  // })

  React.useEffect(() => {
    if(valueProps.length > 0 ) {
      getDivisions();
      getCountries();
      getSpecies();
      getChannels();
    }
    // eslint-disable-next-line
  }, [valueProps]);

  const getDivisions = () => {
    let _div = []
    let obj = {}
    let _array = []

    valueProps.forEach(vp => {
      if(vp.division !== "") _div.push(vp.division)
      else _div.push("Unspecified")
    })

    let reduced = Array.from(new Set(_div));

    reduced.forEach(item => {
      obj[item] = _div.reduce((n, x) => n + (x === item), 0);
    })

    Object.keys(obj).map(item => {
      // let _obj = {item: obj[item]}
      let _obj = {}
      _obj[item] = obj[item]
      _array.push({..._obj})
    })
    _array.sort((a, b) => obj[Object.keys(a)[0]] - obj[Object.keys(b)[0]])
    _array.reverse()
    setDivisions([..._array])

  }

  const getCountries = () => {
    let _div = []
    let obj = {}
    let _array = []

    valueProps.forEach(vp => {
      if(vp.geography !== "") _div.push(vp.geography)
      else _div.push("Unspecified")
    })

    let reduced = Array.from(new Set(_div));

    reduced.forEach(item => {
      obj[item] = _div.reduce((n, x) => n + (x === item), 0);
    })

    Object.keys(obj).map(item => {
      // let _obj = {item: obj[item]}
      let _obj = {}
      _obj[item] = obj[item]
      _array.push({..._obj})
    })
    _array.sort((a, b) => obj[Object.keys(a)[0]] - obj[Object.keys(b)[0]])
    _array.reverse()
    setCountries([..._array])
  }

  const getSpecies = () => {
    let _div = []
    let obj = {}
    let _array = []

    valueProps.forEach(vp => {
      if(vp.species.length === 0 ) _div.push("Unspecified")
      if(vp.species.length === 2 ) _div.push("Both")
      if(vp.species.length === 1) {
        if(vp.species.includes("Cat")) _div.push("Cat")
        if(vp.species.includes("Dog")) _div.push("Dog")
      }
    })

    let reduced = Array.from(new Set(_div));

    reduced.forEach(item => {
      obj[item] = _div.reduce((n, x) => n + (x === item), 0);
    })

    Object.keys(obj).map(item => {
      //console.log(`item ${item}`)
      // let _obj = {item: obj[item]}
      let _obj = {}
      _obj[item] = obj[item]
      _array.push({..._obj})
    })
    _array.sort((a, b) => obj[Object.keys(a)[0]] - obj[Object.keys(b)[0]])
    _array.reverse()
    setSpecies([..._array])
  }

  const getChannels = () => {
    let _div = []
    let obj = {}
    let _array = []

    valueProps.forEach(vp => {
      if(vp.channel !== "") _div.push(vp.channel)
      else _div.push("Unspecified")
    })

    let reduced = Array.from(new Set(_div));

    reduced.forEach(item => {
      obj[item] = _div.reduce((n, x) => n + (x === item), 0);
    })

    Object.keys(obj).map(item => {
      // let _obj = {item: obj[item]}
      let _obj = {}
      _obj[item] = obj[item]
      _array.push({..._obj})
    })
    _array.sort((a, b) => obj[Object.keys(a)[0]] - obj[Object.keys(b)[0]])
    _array.reverse()
    setChannels([..._array])

  }


  return(<>
    <div class="card h-100 m-0 p-0">
      <div class="card-header bg-light d-flex flex-between-center py-2">
        <div class="col-auto col-sm-6 col-lg-7 pl-0">
          <h6 class="mb-0 text-nowrap py-2 py-xl-0 mt-2">Pipeline mix</h6>
          <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
            <i class="fas fa-info-circle mr-1"/>
              Quick guide
          </div>
        </div>
      </div>
      <div class="card-body pt-1">
        <div class={`${open ? "collapse" : ""}`} id="collapseMix">
          <div class="p-card rounded fs--2 mt-n3">
            <p class="fs--2">
              Number of value propositions by Division, country, species and channel
            </p>
          </div>
        </div>
        <div id="Pie" class="d-none">
          <div class="row mb-2">
            <div class="col-md-6 mt-n2">
              <span class="fs--2">by Division</span>
              <div id="container03" style={{height: "120px"}}></div>
            </div>
            <div class="col-md-6 mt-n2">
              <span class="fs--2">by Country</span>
              <div id="container04" style={{height: "120px"}}></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <span class="fs--2">by Species</span>
              <div id="container05" style={{height: "120px"}}></div>
            </div>
            <div class="col-md-6">
              <span class="fs--2">by Channel</span>
              <div id="container06" style={{height: "120px"}}></div>
            </div>
          </div>
        </div>
        <div id="Table">
          <div class="row mb-2">
            <div class="col-md-6 mt-3">
              <table class="table table-sm fs--2 mb-0 overflow-hidden">
                <colgroup>
                  <col style={{width: "70%"}}/>
                  <col style={{width: "30%"}}/>
                </colgroup>
                <thead class="bg-200 text-900">
                  <tr>
                    <th class="sort pl-1 align-middle white-space-nowrap" data-sort="name">VPs by Division</th>
                    <th class="sort pl-1 align-middle white-space-nowrap text-end" data-sort="perc">%</th>
                  </tr>
                </thead>
                <tbody class="list fs--2">
                  {divisions.map(division => (
                    <PipelineMixDivisionCard division={division} total={valueProps.length} />
                  ))}
                {/* {data[0] && Object.values(data[0].map((item) => <PipelineMixDivisionCard item={item} totalOccurrence={data[4].occurrences}  /> ))} */}
                </tbody>
              </table>
            </div>
            <div class="col-md-6 mt-3">
              <table class="table table-sm fs--2 mb-0 overflow-hidden">
                <colgroup>
                  <col style={{width: "70%"}}/>
                  <col style={{width: "30%"}}/>
                </colgroup>
                <thead class="bg-200 text-900">
                  <tr>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="name">VPs by Country</th>
                    <th class="sort pe-1 align-middle white-space-nowrap text-end" data-sort="perc">%</th>
                  </tr>
                </thead>
                <tbody class="list fs--2">
                  {countries.map(country => (
                    <PipelineMixCountryCard country={country} total={valueProps.length} />
                  ))}
                  {/* {data[1] && Object.values(data[1].map((item) => <PipelineMixCountryCard item={item} totalOccurrence={data[4].occurrences} /> ))} */}
                </tbody>
              </table>
            </div>
          </div>
          <div class="row mt-6">
            <div class="col-md-6">
              <table class="table table-sm fs--2 mb-0 overflow-hidden">
                <colgroup>
                  <col style={{width: "70%"}}/>
                  <col style={{width: "30%"}}/>
                </colgroup>
                <thead class="bg-200 text-900">
                  <tr>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="name">VPs by Species</th>
                    <th class="sort pe-1 align-middle white-space-nowrap text-end" data-sort="perc">%</th>
                  </tr>
                </thead>
                {species.map(specie => (
                    <PipelineMixSpeciesCard specie={specie} total={valueProps.length} />
                  ))}
                {/* {data[2] && <PipelineMixSpeciesCard data={data[2]} totalOccurrence={data[4].occurrences}/> } */}
              </table>
            </div>
            <div class="col-md-6">
              <table class="table table-sm fs--2 mb-0 overflow-hidden">
                <colgroup>
                  <col style={{width: "70%"}}/>
                  <col style={{width: "30%"}}/>
                </colgroup>
                <thead class="bg-200 text-900">
                  <tr>
                    <th class="sort pe-1 align-middle white-space-nowrap" data-sort="name">VPs by Channel</th>
                    <th class="sort pe-1 align-middle white-space-nowrap text-end" data-sort="perc">%</th>
                  </tr>
                </thead>
                <tbody class="list fs--2">
                  {channels.map(channel => (
                    <PipelineMixChannelCard channel={channel} total={valueProps.length} />
                  ))}
                  {/* {data[3] && Object.values(data[3].map((item) => <PipelineMixChannelCard item={item} totalOccurrence={data[4].occurrences}  /> ))} */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
        
  </>)}