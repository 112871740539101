  import React from 'react';
import { sortBrandTable } from '../Sort/SortBrandTable';
import ReactCountryFlag from 'react-country-flag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import SmallSearchBarVP from '../SearchBars/SmallSearchBarVP';
import { countryList } from '../Lists/countryList';

const VpPaginationTable = ({ page, setPage, setSelectedBrand, columns, itemsPerPage, tableItems, setTableItems, autofillList, origin, string, setString }) => {
  const [sorting, setSorting] = React.useState(false);
  //const [includePerished, setIncludePerished] = React.useState(false);
  // Columns = [ { string "columnName", string "field", bool isSortable, bool isNumeric }];
  // field refers to the name of the attribute which is used in the items array
  // itemsPerPage = number of idivtems per page in the table
  // items = [ {itemAttribute 1, itemAttribute 2, etc. }];
  //const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(itemsPerPage);
  const [open, setOpen] = React.useState(true);
  const catIcon = 'fas fa-cat fs--1 me-2 mr-1';
  const dogIcon = 'fas fas fa-dog fs--1 mr-1';

  const handleChangePage = ( event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCountryCode = (country) => {
    let code = countryList.flat().find(item => item.Name === country)
    if (code !== undefined) return code.Code
  }

  const getSpecies = (item) => {
    if(item.species !== ""){
      if(item.species.length === 2) return (
        <div>
          <div style={{ color: "#00d27a" }} class={dogIcon} /> 
          <div style={{ color: "#00d27a" }} class={catIcon} />
        </div>
      )
      if(item.species.length === 1) {
        if(item.species.includes("Dog")) return (
          <div>
            <div style={{ color: "#00d27a" }} class={dogIcon} /> 
            <div style={{ color: "#cccccc" }} class={catIcon} />
          </div>
        )
        if(item.species.includes("Cat")) return (
          <div>
            <div style={{ color: "#cccccc" }} class={dogIcon} /> 
            <div style={{ color: "#00d27a" }} class={catIcon} />
          </div>
        )
      }
    }
    else return (
      <div>
        <div style={{ color: "#ccc" }} class={dogIcon} /> 
        <div style={{ color: "#ccc" }} class={catIcon} />
      </div>
    )
  }

  const getChannel = (item) => {
    let obj = {}
    if(item.channel !== "") {
      obj.part1 = item.channel.split(":")[0]
      obj.part2 = item.channel.split(":")[1]
      if(obj.part1.includes("Online")) obj.icon = "fas fa-wifi"
      if(obj.part1.includes("Offline")) obj.icon = "fas fa-store"
    }
    return obj
  }

  const getBusiness = (item) => {
    let obj = {color: "#FFF", text: ""}

    if(item.business!== "" && item.business!==undefined){
      if(item.business.toLowerCase() === "core (red)") {
        obj.color = "#ff0000"
        obj.text="Core"
      }
      if(item.business.toLowerCase() === "beyond the core (blue)") {
        obj.color = "#000096"
        obj.text="Beyond"
      }
    }
    return obj
  }

  // const getBox = (item) => {
  //   console.log(item.box)
  //   let str = ""
  //   if(item.box == true) str = ""
  //   if(item.box == false) str = ""
  // }

  // This method is specific for handling the brands table on the M_Discover page
  //▼▲

  let icons = [ "unicorn-icon", "danger-icon", "cross-icon", "gamble-icon" ]

  const fillCells = (item, columns, key) => {
    let cells = [];
    //console.log(`item: ${JSON.stringify(item)}`)
    for (let column of columns) { // check
      // let align = 'top';
      let value = '';
      let className = 'table-cell table-cell-body';
      let extra = ""
      
      // if (column.id !== 'name' && column.id !== 'image') {
      //   align = 'top';
      // }

      if (column.id === 'image') {
        value = (
          <div className="ml-1 mr-1" />
        );
      } 
      else if (column.id === 'icon') { 
        value = (
          <div class="text-center">
            <img className="" src={item.specialIcon? require(`./../../assets/img/mars/${item.specialIcon}.png`) : require(`./../../assets/img/whiteBox.png`)} alt="..." width="30px" />
          </div>
        );
      } 

      else if (column.id === 'vpName') { 
        value = (
          <div class="text-left">
            {item.vpName}
          </div>
        );
      } 


      else if (column.id === 'name') {
        extra = "sticky-table"
        value =
          <Link
            to={`/monitor/individual/${origin === "review" ? "review" : "view"}/${item.uuid}`}
            className="mars-blue hoverhand"
            style={{ fontSize: '0.7rem', fontWeight: '500' }}
          >
            {item[column.id]}
          </Link>
      } 

      else if (column.id === 'stage') {
        value = (<>
          {window.innerWidth >= 1020 ? <>
            <span class={`fas fa-circle fs--2 mr-1 ${item.stage==="1. Ideation"?"mars-c1":"mars-cd"}`}></span>
            <span class={`fas fa-circle fs--2 mr-1 ${item.stage==="2. Customer discovery and validation"?"mars-c2":"mars-cd"}`}></span>
            <span class={`fas fa-circle fs--2 mr-1 ${item.stage==="3. MVP"?"mars-c3":"mars-cd"}`}></span>
            <span class={`fas fa-circle fs--2 mr-1 ${item.stage==="4. Optimize"?"mars-c4":"mars-cd"}`}></span>
            <span class={`fas fa-circle fs--2 mr-1 ${item.stage==="5. Grow"?"mars-c5":"mars-cd"}`}></span>
            <span class={`fas fa-circle fs--2 mr-1 ${item.stage==="6. Scale"?"mars-c6":"mars-cd"}`}></span>
          </> :
          item.stage 
        }
        </>)
      } 
      
      else if (column.id === 'species') {
        value = getSpecies(item)
      } 
      
      else if (column.id === 'country') {
        value = <ReactCountryFlag countryCode={getCountryCode(item.country)} style={{ width: '20px' }} svg />;
      } 

      else if (column.id === 'channel') {
        value = <div> <div class={`${getChannel(item).icon} fs--2`}></div><span> {getChannel(item).part2} </span> </div>
      } 

      else if (column.id === 'business') {
        
        value = <div> <span style={{color: getBusiness(item).color}} class="fas fa-circle fs--2 mr-1"/><span> {getBusiness(item).text} </span> </div>
        
      } 

      else if (column.id === 'box') {
        value = <span style={{ color: `${item.box === false ? "#ccc" : "#00d27a"}` }} class="fas fa-box-open fs--1 mr-2"/>
      } 

      else {
        value = item[column.id];
      }
      //authState.accessToken.claims.sub
      //console.log(`sponsor; ${JSON.stringify(item)}`)
      cells.push(
        <TableCell align="left" className={className + " " + extra}>
          {value}
        </TableCell>
      );
    }

    return cells;
    //console.log(`cells: ${JSON.stringify(cells)}`)
  };


  const minWidth = (column) => {
    //const { innerWidth: width, innerHeight: height } = window;
    if(window.innerWidth < 425){
      if(column.id !== "image") return 100}
    else return 10
  }

  // const displayWidth = () => {
  //   const { innerWidth: width, innerHeight: height } = window;
  //   return width
  // }



  const formatColumnTitle = (column) => {
    let align = 'left';
    let sortIcon = '';
    let extra = ""

    if (column.id === 'name') extra = "sticky-header"
    if (column.id === 'image' || column.id === 'name') {
      align = 'left';
    }
    if (column.isSortable) {
      sortIcon = (
        <Button
          color="none"
          size="sm"
          style={{ margin: 0, padding: 0, height: '16px', marginTop: '-2px' }}
          onClick={() => {
            sortBrandTable(column.id, sorting, tableItems, setTableItems)
            setSorting(!sorting)
          }}
        >
          <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
            <i className="fas fa-caret-up table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
            <i className="fas fa-caret-down table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
          </span>
        </Button>
      );
    }

    let justifyC = align === 'left' ? 'flex-start' : 'flex-end';

    return (
      <TableCell className={`table-cell ${extra}`} align={align}> 
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: `${justifyC}`, minWidth: minWidth(column) }}>
          <span>{column.columnName}</span>
          <span style={{ paddingLeft: '2px' }}>{sortIcon}</span>
        </div>
      </TableCell>
    );
  };

  // const testButtonPress = (sortCategory, order, state, setState) => {
  //   sortBrandTable(sortCategory, order, state, setState);
  // };

  // const getSortIcon = currentIcon => {
  //   let newIcon = '';

  //   // null = both up and down arrows
  //   if (currentIcon === null) {
  //     newIcon = (
  //       <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
  //         <i className="fas fa-caret-up table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
  //         <i className="fas fa-caret-down table-sort-icon" style={{ fontSize: '0.8rem', lineHeight: '60%' }} />
  //       </span>
  //     );
  //   }
  //   // true = sortIconUp
  //   else if (currentIcon) {
  //     newIcon = (
  //       <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
  //         <i
  //           className="fas fa-caret-up table-sort-icon"
  //           style={{ fontSize: '0.8rem', lineHeight: '100%', textAlign: 'center' }}
  //         />
  //       </div>
  //     );
  //   }
  //   // false = sortIconDown
  //   else if (!currentIcon) {
  //     newIcon = (
  //       <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
  //         <i
  //           className="fas fa-caret-down table-sort-icon"
  //           style={{ fontSize: '0.8rem', lineHeight: '100%', textAlign: 'center' }}
  //         />
  //       </div>
  //     );
  //   }

  //   return newIcon;
  // };

  const itemsOnThisPage = (rowsPerPage, page, list) => {
    let y = rowsPerPage * (page + 1)
    let x = y - rowsPerPage
    return list.slice(x, y)    
  }

  return (
    <>
    <div class="card mb-3">
      <div class="card-header pb-1 bg-light">
        <div class="row flex-between-center">
          <div class="col-auto col-sm-6 col-lg-7">
            <h6 class="mb-0 text-nowrap py-2 py-xl-0">Value Propositions</h6>
              <div class="me-2 fs--2 mars-blue" onClick={e => setOpen(!open)} role="button">
                <i class="fas fa-info-circle mr-1" />
                Quick guide & legend
              </div>
            </div>
            <div class="col-auto col-sm-6 col-lg-5">
              <div className={`pl-3 ${origin==="review"?"d-none":""}`} style={{ width: '100%' }}>
                <SmallSearchBarVP list={autofillList} string={string} setString={setString}/>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" style={{ padding: 0, marginLeft: 0, marginRight: 0, marginTop: 0 }}>
          <div class={`${open ? 'collapse' : ''}`} id="collapseFilter">
            <div class="p-card rounded fs--2">
              <ul>
                <li>
                  Search bar → to filter the list below, type your search criteria and the list will be filtered
                </li>
                <li>
                  Phase → Phase is indicated by color, search by typing phase name (i.e. Ideation)
                  <br />
                  <span style={{ color: '#add136' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />| Ideation
                  <br />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#50C1EF' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />| Customer validation
                  <br />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#FFC32D' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />| MVP
                  <br />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#00B050' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />| Optimize
                  <br />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#5F7D95' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />| Grow
                  <br />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#d8e2ef' }} class="fas fa-circle fs--2 mr-1" />
                  <span style={{ color: '#6F3996' }} class="fas fa-circle fs--2 mr-1" />| Scale
                  <br />
                  <span class="fas fa-times-circle fs--2 text-danger" />| Perished
                  <br />
                </li>
                <li>Country → filter by typing "country name"</li>
                <li>Division → filter by "RC" (= Royal Canin) or "PC" (= Mars Pet care)</li>
                <li>Species → filter by "Cat" or "Dog"</li>
                <li>Channel → filter by "Online", "Offline" or any part of the channel description</li>
                <li>
                  Business → filter by "<span style={{ color: 'red' }} class="fas fa-circle fs--2 mr-1" /> (Red
                  = Core)" or "<span style={{ color: '#000096' }} class="fas fa-circle fs--2 mr-1" /> (Blue =
                  Beyond the core)"
                </li>
                <li>Box → filter by "Boxed" (=plugged into the box) or "Unplugged"</li>
              </ul>
            </div>
          </div>
          {tableItems.length > 0 &&        
            <TableContainer style={{ marginTop: 0, paddingTop: 0 }}>
              <Table>
                <TableHead>
                  <TableRow className="table-header"> {columns.map((column) => formatColumnTitle(column))}</TableRow>
                </TableHead>
                <TableBody>
                  {itemsOnThisPage(rowsPerPage, page, tableItems).map((item, index) => (
                    <TableRow
                      // key={item.name}
                      onClick={e => setSelectedBrand !== undefined && setSelectedBrand(item.name)}
                    >
                      {(fillCells(item, columns, index))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> 
          }
          <div class="card-footer p-0">
            <TablePagination
                rowsPerPageOptions={[4, 8, 16]}
                labelRowsPerPage={<div style={{fontSize: 14}}>{"Items: "}</div>}
                //labelRowsPerPage={"Items: "}
                component="div"
                count={tableItems.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="pagination-item"
                style={{ marginLeft: '15px', padding: 0, borderStyle: 'none' }}
                SelectProps={{
                  style:{
                    fontSize: 13
                  }
                }}
              />
          </div>
        </div>
      </div>
    </>
  );
};

export default VpPaginationTable;
