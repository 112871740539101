import axios from "axios";
import { socialAnalysisData } from "./queries/SocialAnalysisData";
import { socialAnalysisWebTraffic } from "./queries/SocialAnalysisWebTraffic";
import { getBrands } from "./queries/GetBrands";
import { getVPs } from "./queries/GetVPs";
import { getManyBrandQuery } from "../../database/getFunctions/getManyBrandQuery";


// const GetCompaniesQuery = `
// query GetCompanies {
//   GetCompanies {
//     companyName
//     companyUrl
//   }
// }
// `;

// export const getCompanies = () => {
//   axios
//   .post(process.env.REACT_APP_API, {
//     query: GetCompaniesQuery,
//     variables: {}
//     })
//     .then(res => {
//       console.log(`newbrands: ${JSON.stringify(res.data.data.GetCompanies)}`)
//       //setNewBrands([...res.data.data.GetCompanies])
//     })
//       .catch(err => console.log(err))
// }

export const GetSocialAnalysisData = (setState) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: socialAnalysisData, 
    },
  })
    .then((result) => {
      setState([...result.data.data.GetSocials.Socials]);
    })
    .catch((err) => console.log(err));
};

export const GetBrands = (setState) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: getBrands,
    },
  })
    .then((result) => {
      setState([...result.data.data.GetBrands.AllBrands]);
    })
    .catch((err) => console.log(err));
};

export const GetManyBrand = (setState) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: getManyBrandQuery,
    },
  })
    .then((result) => {
      //console.log(`${JSON.stringify(result.data.data.GetManyBrand)}`)
      setState([...result.data.data.GetManyBrand]);
    })
    .catch((err) => console.log(err));
};

export const GetVPs = (setState) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: getVPs,
    },
  })
    .then((result) => {
      //console.log(`get vps ${JSON.stringify(result.data.data.GetManyVP)}`)
      setState([...result.data.data.GetManyVP]);
    })
    .catch((err) => console.log(err));
};

export const updateVP = (object) => {
  var md5 = require('md5');

  axios
    .post(process.env.REACT_APP_API, {
      query: `
      mutation UpdateVP($vp: ValuePropInputType) {
        UpdateVP(vp: $vp) {
          name
          uuid
        }
      }`,
      variables: {'vp': object }
    }) 
    .then(res => {
      //console.log(res.data.data.UpdateVP)
      //vpHandler.history.push(`/monitor/individual/view/${res.data.data.UpdateVP.uuid}`)
      //notify("Your changes have been saved successfully.", vpHandler.ref, "success")
      //vpHandler.setHash(md5(JSON.stringify(vpHandler.get)))
    })
    .catch(err => {
      console.log(err)
      //notify("There was an error saving your changes.", vpHandler.ref, "danger")
    })
  }

  export const updateBrand = (object) => {
  
    axios
      .post(process.env.REACT_APP_API, {
        query: `
        mutation UpdateBrand($brand: BrandInputType) {
          UpdateBrand(brand: $brand) {
            cid
          }
        }`,
        variables: {'brand': object }
      }) 
      .then(res => {
        console.log(`Brand has been updated!`)
        //vpHandler.history.push(`/monitor/individual/view/${res.data.data.UpdateVP.uuid}`)
        //notify("Your changes have been saved successfully.", vpHandler.ref, "success")
        //vpHandler.setHash(md5(JSON.stringify(vpHandler.get)))
      })
      .catch(err => {
        console.log(err)
        //notify("There was an error saving your changes.", vpHandler.ref, "danger")
      })
    }

  export const addBrand = (object) => {
    axios
    .post(process.env.REACT_APP_API, {
      query: `
      mutation AddBrand($brand: BrandInputType) {
        AddBrand(brand: $brand) {
          cid
        }
      }`,
      variables: {'brand': object }
    }) 
    .then(res => {
      console.log(`Brand has been added!`)
      //vpHandler.history.push(`/monitor/individual/view/${res.data.data.UpdateVP.uuid}`)
      //notify("Your changes have been saved successfully.", vpHandler.ref, "success")
      //vpHandler.setHash(md5(JSON.stringify(vpHandler.get)))
    })
    .catch(err => {
      console.log(err)
      //notify("There was an error saving your changes.", vpHandler.ref, "danger")
    })
  }

export const GetSocialAnalysisWebTraffic = (setState) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: socialAnalysisWebTraffic,
    },
  })
    .then((result) => {
      setState([...result.data.data.WebGetVisit.WebTraffic]);
    })
    .catch((err) => console.log(err));
};
